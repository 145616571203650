import LobbyIcon from '../api/ui/svgIcons/LobbyIcon';
import { AppRoutePath } from './appRoutePath';
import FavouriteIcon from '../api/ui/svgIcons/FavouriteIcon';
import RecentIcon from '../api/ui/svgIcons/RecentIcon';
import SlotIcon from '../api/ui/svgIcons/SlotIcon';
import LiveCasinoIcon from '../api/ui/svgIcons/LiveCasinoIcon';
import ProviderIcon from '../api/ui/svgIcons/ProviderIcon';
import InstantGameIcon from '../api/ui/svgIcons/InstantGameIcon';
import tableGameIcon from '../api/ui/svgIcons/TableGameIcon';
import GameShowIcon from '../api/ui/svgIcons/GameShowIcon';
import promotionIcon from '../api/ui/svgIcons/PromotionIcon';
import BlogIcon from '../api/ui/svgIcons/BlogIcon';
import LiveSupportIcon from '../api/ui/svgIcons/LiveSupportIcon';
import { IAirbetMenuItem, ILanguage } from '../types/common.type';
import FlagUnitedKingdom from '../api/ui/svgIcons/Flags/FlagUnitedKingdom';
import FlagSpanish from '../api/ui/svgIcons/Flags/FlagSpanish';
import FlagRussian from '../api/ui/svgIcons/Flags/FlagRussian';
import FlagGerman from '../api/ui/svgIcons/Flags/FlagGerman';
import AffiliateIcon from '../api/ui/svgIcons/AffiliateIcon';
import RankSystemIcon from '../api/ui/svgIcons/RankSystemIcon';
import FlagBrazil from '../api/ui/svgIcons/Flags/FlagBrazil';
import FlagTurkey from '../api/ui/svgIcons/Flags/FlagTurkey';
import FlagArabic from '../api/ui/svgIcons/Flags/FlagArabic';
import LiveSportIcon from '../api/ui/svgIcons/Sport/LiveSportIcon';
import SoccerSportIcon from '../api/ui/svgIcons/Sport/SoccerSportIcon';
import BasketballSportIcon from '../api/ui/svgIcons/Sport/BasketballSportIcon';
import TennisSportIcon from '../api/ui/svgIcons/Sport/TennisSportIcon';
import CricketSportIcon from '../api/ui/svgIcons/Sport/CricketSportIcon';
import AmericanFootballSportIcon from '../api/ui/svgIcons/Sport/AmericanFootballSportIcon';
import IceHockeySportIcon from '../api/ui/svgIcons/Sport/IceHockeySportIcon';
import BaseballSportIcon from '../api/ui/svgIcons/Sport/BaseballSportIcon';
import HandballSportIcon from '../api/ui/svgIcons/Sport/HandballSportIcon';
import AirbetOriginalMenuIcon from '../api/ui/svgIcons/AirbetOriginalMenuIcon';
import TicketIcon from '../api/ui/svgIcons/TicketIcon';

export const SidebarListLevel1: IAirbetMenuItem[] = [
  {
    icon: LobbyIcon,
    link: '/',
    text: 'airbetMenu.lobby',
  },
  {
    icon: FavouriteIcon,
    link: AppRoutePath.FAVORITE_LIST_PATE(),
    text: 'airbetMenu.favourites',
    needAuth: true,
  },
  {
    icon: RecentIcon,
    link: AppRoutePath.LASTPLAYED(),
    text: 'airbetMenu.recent',
    needAuth: true,
  },
  {
    icon: SlotIcon,
    link: AppRoutePath.GAMELIST(),
    text: 'airbetMenu.slots',
  },
  {
    icon: LiveCasinoIcon,
    link: AppRoutePath.LIVECASINO(),
    text: 'airbetMenu.liveCasino',
  },
  {
    icon: ProviderIcon,
    link: AppRoutePath.PROVIDER_LIST_PAGE(),
    text: 'airbetMenu.providers',
  },
  {
    icon: InstantGameIcon,
    link: AppRoutePath.INSTANT(),
    text: 'airbetMenu.instantGames',
  },
  {
    icon: AirbetOriginalMenuIcon,
    link: AppRoutePath.ORIGINAL(),
    text: 'airbetMenu.airbetOriginals',
  },
  {
    icon: tableGameIcon,
    link: AppRoutePath.TABLE(),
    text: 'airbetMenu.tableGames',
  },
  {
    icon: GameShowIcon,
    link: AppRoutePath.GAME_SHOWS(),
    text: 'airbetMenu.gameShows',
  },
  {
    icon: TicketIcon,
    link: AppRoutePath.LOTERRY(),
    text: 'airbetMenu.lottery',
  },
];

export const SidebarListLevel2: IAirbetMenuItem[] = [
  {
    icon: RankSystemIcon,
    link: AppRoutePath.RANKSYSTEM(),
    text: 'airbetMenu.rankSystem',
  },
  {
    icon: promotionIcon,
    link: AppRoutePath.PROMOTION(),
    text: 'airbetMenu.promotions',
  },
  {
    icon: BlogIcon,
    link: AppRoutePath.BLOG(),
    text: 'airbetMenu.blog',
  },
  {
    icon: AffiliateIcon,
    link: 'https://www.airbetpartners.com/',
    text: 'airbetMenu.affiliate',
  },
  {
    icon: LiveSupportIcon,
    link: '',
    text: 'airbetMenu.liveSupport',
  },
  /*  {
        icon: ProvablyFairIcon,
        link: '',
        text: 'airbetMenu.probablyFair',
        hidden: true,
      },*/
];

export const LANGUAGE_CHOICE: ILanguage[] = [
  {
    icon: FlagUnitedKingdom,
    label: 'English',
    value: 'en',
  },
  {
    icon: FlagSpanish,
    label: 'Español',
    value: 'es',
  },
  {
    icon: FlagBrazil,
    label: 'Português (Br)',
    value: 'pt',
  },
  {
    icon: FlagRussian,
    label: 'Руccкий',
    value: 'ru',
  },
  {
    icon: FlagGerman,
    label: 'German',
    value: 'de',
  },
  {
    icon: FlagArabic,
    label: 'Arabic',
    value: 'ar',
  },
  {
    icon: FlagTurkey,
    label: 'Turkish',
    value: 'tr',
  },
];

export const SidebarSportItems: IAirbetMenuItem[] = [
  {
    icon: LiveSportIcon,
    link: '2Flive',
    text: 'betBy.live',
  },
  {
    icon: SoccerSportIcon,
    link: '2Fsoccer-1',
    text: 'betBy.soccer',
  },
  {
    icon: TennisSportIcon,
    link: '2Ftennis-5',
    text: 'betBy.tennis',
  },
  {
    icon: BasketballSportIcon,
    link: '2Fbasketball-2',
    text: 'betBy.basketball',
  },
  {
    icon: CricketSportIcon,
    link: '2Fcricket-21',
    text: 'betBy.cricket',
  },
  /*  {
      icon: BasketballSportIcon,
      link: '2Fbasketball-2',
      text: 'betBy.fifa',
    },*/
  {
    icon: AmericanFootballSportIcon,
    link: '2Famerican-football-16',
    text: 'betBy.americanFootball',
  },
  {
    icon: IceHockeySportIcon,
    link: '2Fice-hockey-4',
    text: 'betBy.iceHockey',
  },
  {
    icon: BaseballSportIcon,
    link: '2Fbaseball-3',
    text: 'betBy.baseball',
  },
  {
    icon: HandballSportIcon,
    link: '2Fhandball-6',
    text: 'betBy.handball',
  },
];
