import GameContainer from './GameContainer';

const SlotContainer = () => {
  return (
    <GameContainer
      categorySlugName={'slots'}
      pageName={'slotGames'}
      showSwitcher={true}
    />
  );
};

export default SlotContainer;
