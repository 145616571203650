import { ISvgIcon } from '../../../types/common.type';

const BuyCryptoIcon = ({ size = 16, className, pathClassName }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.00328 2.66862H14.0033C14.3715 2.66862 14.67 2.9671 14.67 3.33529V12.6686C14.67 13.0368 14.3715 13.3353 14.0033 13.3353H2.00328C1.63509 13.3353 1.33661 13.0368 1.33661 12.6686V3.33529C1.33661 2.9671 1.63509 2.66862 2.00328 2.66862ZM4.3336 4H2.66694V5.66667C3.58741 5.66667 4.3336 4.92048 4.3336 4ZM11.667 4C11.667 4.92048 12.4132 5.66667 13.3336 5.66667V4H11.667ZM2.66694 10.3333V12H4.3336C4.3336 11.0795 3.58741 10.3333 2.66694 10.3333ZM11.667 12H13.3336V10.3333C12.4132 10.3333 11.667 11.0795 11.667 12ZM8.00029 10.6667C9.47302 10.6667 10.667 9.47274 10.667 8C10.667 6.52724 9.47302 5.33334 8.00029 5.33334C6.52751 5.33334 5.3336 6.52724 5.3336 8C5.3336 9.47274 6.52751 10.6667 8.00029 10.6667Z"
        fill="#9CA3AF"
        className={pathClassName}
      />
    </svg>
  );
};

export default BuyCryptoIcon;
