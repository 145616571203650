import { ISvgIcon } from '../../../../types/common.type';

const infoIcon = ({ className, size }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="24" height="24" rx="12" fill="#3B4CC7" />
      <path
        d="M12 8C12.5523 8 13 7.55229 13 7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7C11 7.55229 11.4477 8 12 8ZM10 10.6667H11.3333V16H10V17.3333H14V16H12.6667V9.33333H10V10.6667Z"
        fill="white"
      />
    </svg>
  );
};

export default infoIcon;
