import { IRakeBackOrCashBack } from '../../types/Bonus.type';

export const CalculateBonusCountdownTime = (data: IRakeBackOrCashBack) => {
  const finishAtParts: any = data.finish_at.split(/[- :]/);
  const finishAtDate = new Date(
    Date.UTC(
      finishAtParts[0],
      finishAtParts[1] - 1,
      finishAtParts[2],
      finishAtParts[3],
      finishAtParts[4],
      finishAtParts[5]
    )
  );

  const now = new Date();
  const nowUtc = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    )
  );

  const difference = finishAtDate.getTime() - nowUtc.getTime();

  if (difference > 0) {
    return difference;
  } else {
    return 0;
  }
};

export const GetBonusConvertedTime = (countdown: number) => {
  const totalSeconds = Math.floor(countdown / 1000);
  const days = Math.floor(totalSeconds / (24 * 3600));
  const remainingSecondsAfterDays = totalSeconds % (24 * 3600);
  const hours = Math.floor(remainingSecondsAfterDays / 3600);
  const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;
  const minutes = Math.floor(remainingSecondsAfterHours / 60);
  const seconds = remainingSecondsAfterHours % 60;

  return `${days.toString().padStart(2, '0')}:${hours
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export const getNameBySlug = (slug: string) => {
  if (slug === 'daily') {
    return 'dailyRakeback';
  }

  if (slug === 'weekly') {
    return 'weeklyRakeback';
  }

  if (slug === 'monthly') {
    return 'monthlyRakeback';
  }

  return '';
};
