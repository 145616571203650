import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    flexDirection: 'column',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '8px',
    padding: '16px',
  },
  fullWidthRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    flexDirection: 'row',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '8px',
    padding: '16px',
    gap: '24px',
  },
  container: {
    padding: '16px',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '16px',
    height: '100%',
  },
  text: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
  },
  nextLevel: {
    color: '#6B7280',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  progress: {
    width: '100%',
    background: '#1F2937',
    borderRadius: '8px',
    height: '8px',
    marginTop: '16px',
  },
}));

interface IUserRank {
  currentRankName: string;
  nextRankName: string;
  level: number;
  srcInfo: any;
  progress: number;
  maxLevel: number;
  fullWidth?: boolean;
}

const UserRank = ({
  currentRankName,
  nextRankName,
  srcInfo,
  level,
  progress,
  maxLevel,
  fullWidth,
}: IUserRank) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      className={!fullWidth ? classes.root : classes.fullWidthRoot}
      style={{
        background: srcInfo.background,
      }}
    >
      <Box className={classes.imgContainer}>
        <img
          alt={'rank img'}
          src={'/imgs/Rank/' + srcInfo.img}
          width={!fullWidth ? '30%' : '108px'}
          height={fullWidth ? '108px' : ''}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box className={classes.content}>
          <span
            className={classes.text}
            style={{
              color: '#fff',
            }}
          >
            {currentRankName}
          </span>
          <span
            className={classes.text}
            style={{
              color: '#9CA3AF',
            }}
          >
            {nextRankName}
          </span>
        </Box>
        <div className={classes.progress}>
          <div
            style={{
              height: '8px',
              borderRadius: '8px',
              width: `${progress}%`,
              ...srcInfo.progressStyle,
            }}
          ></div>
        </div>
        <Box
          marginTop={'16px'}
          display={'flex'}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'space-between'}
          className={classes.nextLevel}
        >
          <span>{t('nextLevel')}</span>
          <span>
            <span
              style={{
                color: '#fff',
              }}
            >
              {level}
            </span>
            /{maxLevel}
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default UserRank;
