import instance from '../axios';

export const initialize = (body: any) => {
  return instance.post('/betby/initialize', body);
};

export const unauthorizedInitialize = () => {
  return instance.post('/betby/unauthorised_initialize');
};

export const refreshToken = (body: any) => {
  return instance.post('/betby/refresh-jwt', body);
};
