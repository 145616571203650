import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#9CA3AF',
    fontWeight: 600,
    padding: '0px 16px',
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    fontSize: '14px',
    marginTop: '4px',
    lineHeight: '24px',
    fontWeight: 400,
    alignItems: 'center',
  },
  input: {
    border: 'none',
    backgroundColor: '#0D121D',
    outline: 'none',
    color: '#fff',
    paddingLeft: '16px',
    height: '48px',
    borderRadius: '8px',
    flex: '1',
  },
}));

interface IPersonalInput {
  onChange: (e: any) => void;
  label: string;
  placeHolder: string;
  value: string;
}

const PersonalInput = ({
  label,
  placeHolder,
  value,
  onChange,
}: IPersonalInput) => {
  const classes = useStyles();

  return (
    <>
      <Box flex={1}>
        <div className={classes.title}>{label}</div>
        <div style={{ display: 'flex' }}>
          <div className={classes.inputContainer}>
            <input
              className={classes.input}
              placeholder={placeHolder}
              value={value}
              onChange={onChange}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

export default PersonalInput;
