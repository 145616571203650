import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';

interface GameCardSkeleton {
  isMobile?: boolean;
}

const GameCardSkeleton = () => {
  return (
    <Box
      style={{
        minWidth: isMobile ? '104px' : '150px',
        width: isMobile ? '104px' : '150px',
        height: isMobile ? '139px' : '200px',
        borderRadius: '8px',
      }}
      className={'impulse-skeleton'}
    />
  );
};

export default GameCardSkeleton;
