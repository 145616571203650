import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { casinoMobileSwiperData } from './CasinoMobileSwitcher';

interface ICasinoMobileSwitcherIcon {
  item: casinoMobileSwiperData;
}

const useStyles = makeStyles(() => ({
  root: {
    width: isMobile ? '100px' : '180px',
    height: isMobile ? '70px' : '158px',
    maxHeight: isMobile ? '80px' : '96px',
    maxWidth: isMobile ? '100px' : '180px',
    minWidth: '0px',
    minHeight: '0px',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderRadius: '8px',
    background: '',
    transition: 'background 0.3s ease-in-out',
    cursor: 'pointer',
    gap: isMobile ? '5px' : '',
    '&:hover': {
      background: 'rgb(32 41 58)',
    },
    '& path': {
      fill: '#FFF',
    },
    '& svg': {
      width: isMobile ? '20px' : '24px',
      height: isMobile ? '20px' : '24px',
    },
  },
  imgContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: isMobile ? 'auto' : '100%',
    minHeight: '0px',
    minWidth: '0px',
  },
  title: {
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: isMobile ? '12px' : '14px',
    color: '#fff',
  },
}));
const CasinoMobileSwitcherIcon = ({ item }: ICasinoMobileSwitcherIcon) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const onClick = () => {
    navigate(item.link);
  };

  return (
    <Box
      className={classes.root}
      onClick={() => onClick()}
      style={{
        background: item.isActive ? '#111827' : '',
      }}
    >
      <Box className={classes.imgContent}>{<item.icon />}</Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        minHeight={isMobile ? '' : '40px'}
      >
        <span className={classes.title}>{t(item.text)}</span>
      </Box>
    </Box>
  );
};

export default CasinoMobileSwitcherIcon;
