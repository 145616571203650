import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface IContainer {
  children: ReactNode;
}

const Container = ({ children }: IContainer) => {
  return (
    <Box
      maxWidth={'1200px'}
      width={'100%'}
      margin={'0px auto'}
      boxSizing={'border-box'}
    >
      {children}
    </Box>
  );
};

export default Container;
