import { ReactNode, useState } from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
} from '@mui/styles';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import applicationTheme from '../../styles/theme/applicationTheme';

interface IThemeWrapper {
  children: ReactNode;
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const generateClassName = createGenerateClassName({
  seed: 'airbet',
});

const ThemeWrapper = ({ children }: IThemeWrapper) => {
  const [theme] = useState(createTheme(applicationTheme()));
  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};

export default ThemeWrapper;
