import Provider from '../../components/Provider/Provider';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { IProvider } from '../../types/Game.type';

interface IProviderContainer {
  providers: IProvider[];
}

const ProviderContainer = ({ providers }: IProviderContainer) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState<IProvider[]>([]);
  const [originalData, setOriginalData] = useState<IProvider[]>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (providers) {
      setLoading(true);
      setData(providers);
      setOriginalData(providers);
      setTotalResult(providers.length);
      setLoading(false);
    }
  }, [providers]);

  const onChangeSearchValue = (value: string): void => {
    setSearchValue(value);

    if (value.trim().length === 0) {
      setData([...originalData]);
      setTotalResult(originalData.length);
    }

    if (value.trim().length > 2) {
      const filteredGames: IProvider[] = [
        ...originalData.filter((provider: IProvider) =>
          provider.title.toLowerCase().includes(value.toLowerCase())
        ),
      ];
      setData(filteredGames);
      setTotalResult(filteredGames.length);
    }
  };

  const onClearParams = () => {
    setSearchValue('');
    setData([...originalData]);
    setTotalResult(originalData.length);
  };

  return (
    <Provider
      providers={data}
      onClearParams={onClearParams}
      loading={loading}
      currentSearchValue={searchValue}
      totalResult={totalResult}
      onChangeSearchValue={onChangeSearchValue}
    />
  );
};

const mapStateToProps = (state: any) => ({
  providers: state.game.providers,
});
const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(ProviderContainer);
