import { makeStyles } from '@mui/styles';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { LANGUAGE_CHOICE } from '../../util/SidebarListHelper';
import { ILanguage } from '../../types/common.type';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { changeLanguage } from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { getUserLanguage } from '../../util/appUtil';
import { changeBackendLanguage } from '../../api/services/User.api';
import { isMobile } from 'react-device-detect';

interface ILanguageSelectBox {
  changeLanguage: (language: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
    boxSizing: 'border-box',

    '& .MuiOutlinedInput-input': {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
    },

    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '8px',
      border: '1px solid #0D121D',
      background: '#111827',
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
      transition: 'border-color 0.3s ease-in-out',

      '&:hover': {
        borderColor: '#1F2937',
      },

      '&.selectOpen': {
        borderColor: '#1F2937',
      },

      '& fieldset': {
        border: 'none',
      },

      '& .MuiSvgIcon-root': {
        color: '#4B5563',
      },

      '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        '& svg': {
          borderRadius: '100%',
        },
      },
    },
  },
  label: {
    padding: '0px 16px',
    color: '#9CA3AF',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  paper: {
    padding: '0px',
    borderRadius: '8px',
    border: '1px solid #1F2937',
    background: '#111827',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.10)',
    marginTop: isMobile ? '-105px' : '-45px',
    '&.MuiPaper-root': {
      left: '14px !important',
    },
    '& .MuiList-root': {
      padding: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      alignItem: 'center',
      borderRadius: '4px',
      transition: 'background 0.3s ease-in-out, color 0.3s ease-in-out',
      padding: '8px',
      background: '#111827',
      gap: '8px',
      color: '#6B7280',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      '&:hover': {
        background: '#0D121D !important',
        color: '#fff',
      },
      '&.Mui-selected': {
        backgroundColor: '#0D121D !important',
      },
      '& svg': {
        borderRadius: '100%',
      },
    },
  },
}));

const LanguageSelectBox = ({ changeLanguage }: ILanguageSelectBox) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openClass = isOpen ? 'selectOpen' : '';
  const [value, setValue] = useState<string>('en');

  useEffect(() => {
    setValue(getUserLanguage());
    changeBackendLanguage(getUserLanguage())
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <Box className={classes.root}>
      <Select
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        value={value}
        label={''}
        className={openClass}
        displayEmpty
        onChange={(event: SelectChangeEvent) => {
          setValue(event.target.value);
          i18n.changeLanguage(event.target.value).then(() => {
            changeLanguage(event.target.value);
            changeBackendLanguage(event.target.value)
              .then(() => {})
              .catch(() => {});
          });
        }}
        open={isOpen}
        MenuProps={{
          PaperProps: {
            className: classes.paper,
          },
        }}
      >
        {LANGUAGE_CHOICE.map((item: ILanguage, index: number) => {
          return (
            <MenuItem value={item.value} key={index}>
              {item.icon && <item.icon />} {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

const mapStateToProps = () => ({});

const dispatchToProps = (dispatch: any) => ({
  changeLanguage: bindActionCreators(changeLanguage, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(LanguageSelectBox);
