import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IUserInfo } from '../../types/common.type';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  timeStyle: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '32px',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.25)',
    background:
      'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%)',
  },
}));

interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

interface BonusWheelCountDownTimerProps {
  playerInfo: IUserInfo;
  showSpin: () => void;
}

const BonusWheelCountDownTimer: React.FC<BonusWheelCountDownTimerProps> = ({
  playerInfo,
  showSpin,
}) => {
  const classes = useStyles();

  const calculateTimeLeft = (): TimeLeft => {
    const targetDateTimeString = playerInfo.player.can_spin_after;

    const targetDateTime = new Date(targetDateTimeString);

    const targetUtcTimestamp =
      targetDateTime.getTime() - targetDateTime.getTimezoneOffset() * 60000;
    const nowUtcTimestamp = Date.now();

    const difference = targetUtcTimestamp - nowUtcTimestamp;

    if (difference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(() => calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (
      timeLeft.hours === 0 &&
      timeLeft.minutes === 0 &&
      timeLeft.seconds === 0
    ) {
      showSpin();
    }
  }, [timeLeft]);

  const { hours, minutes, seconds } = timeLeft;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span className={classes.timeStyle}>
        {hours > 9 ? hours : '0' + hours}
      </span>
      <span className={classes.timeStyle}> : </span>
      <span className={classes.timeStyle}>
        {minutes > 9 ? minutes : '0' + minutes}
      </span>
      <span className={classes.timeStyle}> : </span>
      <span className={classes.timeStyle}>
        {seconds > 9 ? seconds : '0' + seconds}
      </span>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  playerInfo: state.user.playerInfo,
});

const dispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  dispatchToProps
)(BonusWheelCountDownTimer);
