import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const MAffiliate = () => {
  const navigate = useNavigate();
  const { stag } = useParams();

  useEffect(() => {
    if (stag) {
      const data = {
        date: new Date(),
        code: stag,
      };
      localStorage.setItem('stag', JSON.stringify(data));
    }
    navigate('/');
  }, []);
  return <div />;
};

export default MAffiliate;
