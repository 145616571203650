import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Container from '../../common/BoxContainer/Container';
import UserRank from '../../UserProfile/UserRank';
import { bindActionCreators } from 'redux';
import { changeUserProfileInfo } from '../../../redux/actions/userActions';
import { connect } from 'react-redux';
import { RANKS } from '../../../util/UserProfileHelper';
import { IRankInfo, IUserInfo } from '../../../types/common.type';
import { IRakeBackOrCashBack } from '../../../types/Bonus.type';
import Rakeback from '../Rakeback';
import Cashback from '../Cashback';
import DepositBonusItem from './DepositBonusItem';
import { getAllDepositBonuses } from '../../../api/services/Bonus.api';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  header: {
    ...theme.general.pageHeader,
    padding: '0px 20px',
  },
  headerText: {
    ...theme.general.pageHeaderTitle,
  },
  body: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '32px 20px 0px 20px',
    flexDirection: 'column',
    gap: '40px',
  },
  filterContainer: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
    alignItems: 'center',
    gap: '32px',
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column',
      gap: '16px',
    },

    '& .search-icon': {
      fill: '#6B7280',
    },
  },
  contentWrapper: {
    width: '100%',
    borderRadius: '12px',
    border: '1px solid #111827',
    gap: '16px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    boxSizing: 'border-box',
  },
  rankText: {
    fontWeight: 600,
    fontsize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    marginLeft: '16px',
  },
  generalBonusWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
  },
  generalBonusInnerWrapper: {
    boxSizing: 'border-box',
    flexBasis: 'calc(50% - 8px)',
    flexGrow: 1,
    maxWidth: 'calc(50% - 8px)',
    minWidth: '300px',

    [theme.breakpoints.down(890)]: {
      flexBasis: 'calc(100% - 8px)',
      maxWidth: 'calc(100% - 8px)',
    },
  },
}));

interface BonusesPageProps {
  userInfo: IUserInfo;
  ranksInfo: IRankInfo[];
  rakeBacks: IRakeBackOrCashBack[];
  cashBacks: IRakeBackOrCashBack[];
  initRakeBack: () => void;
  initCashBack: () => void;
}

const BonusesPage: FC<BonusesPageProps> = ({
  ranksInfo,
  userInfo,
  rakeBacks,
  cashBacks,
  initRakeBack,
  initCashBack,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [depositBonus, setDepositBonus] = useState<any>([]);

  useEffect(() => {
    initGetAllDepositBonus();
  }, []);

  const initGetAllDepositBonus = () => {
    getAllDepositBonuses()
      .then((resp: any) => {
        if (resp.data.data) {
          const player_bonuses = resp.data.data['player_bonuses'];
          const active_unused_bonuses = resp.data.data['active_unused_bonuses'];
          setDepositBonus([
            ...player_bonuses.map((item: any) => {
              return { ...item, unusedBonuses: false };
            }),
            ...active_unused_bonuses.map((item: any) => {
              return { ...item, unusedBonuses: true };
            }),
          ]);
        }
      })
      .catch(() => {});
  };

  const getNextRankName = () => {
    const currRankIndex: number = RANKS.findIndex(
      (rank: any) => rank.name === userInfo.player.rank.rank
    );

    if (currRankIndex + 1 >= RANKS.length) return '';
    return RANKS[currRankIndex + 1].name;
  };

  const getMaxRankLevel = () => {
    const currentUserRankName: string = userInfo.player.rank.rank;
    return ranksInfo.find(
      (rank: IRankInfo) => rank.name === currentUserRankName
    )?.max_level;
  };

  return (
    <Box className={classes.root}>
      <div className={classes.header}>
        <Container>
          <span className={classes.headerText}>{t('bonuses')}</span>
        </Container>
      </div>
      <Container>
        {userInfo && userInfo.player.rank && (
          <Box className={classes.contentWrapper}>
            <span className={classes.rankText}>{t('yourRank')}</span>
            <UserRank
              currentRankName={userInfo.player.rank.rank}
              nextRankName={getNextRankName()}
              srcInfo={RANKS.find(
                (item) => item.name === userInfo.player.rank.rank
              )}
              level={userInfo.player.rank.level}
              progress={userInfo.player.rank.progress}
              maxLevel={getMaxRankLevel() || 0}
              fullWidth={true}
            />
          </Box>
        )}

        <Box className={classes.contentWrapper}>
          <span className={classes.rankText}>{t('generalBonus')}</span>
          <Box className={classes.generalBonusWrapper}>
            {rakeBacks.map(
              (rakeBackInfo: IRakeBackOrCashBack, index: number) => {
                return (
                  <Box className={classes.generalBonusInnerWrapper} key={index}>
                    <Rakeback
                      rakeBackInfo={rakeBackInfo}
                      key={index}
                      reloadData={() => initRakeBack()}
                    />
                  </Box>
                );
              }
            )}
            {cashBacks.map(
              (cashBackInfo: IRakeBackOrCashBack, index: number) => {
                return (
                  <Box className={classes.generalBonusInnerWrapper} key={index}>
                    <Cashback
                      cashBackInfo={cashBackInfo}
                      key={index}
                      reloadData={() => initCashBack()}
                    />
                  </Box>
                );
              }
            )}
          </Box>
        </Box>

        <Box className={classes.contentWrapper}>
          <span className={classes.rankText}>{t('depositBonus')}</span>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: '16px',
            }}
          >
            {depositBonus.map((depositBonus: any, index: number) => {
              return (
                <DepositBonusItem
                  depositBonus={depositBonus}
                  key={index}
                  initGetAllDepositBonus={initGetAllDepositBonus}
                />
              );
            })}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  ranksInfo: state.user.ranksInfo,
  userInfo: state.user.playerInfo,
});

const dispatchToProps = (dispatch: any) => ({
  changeUserProfileInfo: bindActionCreators(changeUserProfileInfo, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(BonusesPage);
