import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Container from '../common/BoxContainer/Container';
import CasinoSwitcher from './CasinoSwitcher';
import { IGameSchema } from '../../types/Game.type';
import GameSwiper from '../common/GameSwiper/GameSwiper';
import AirbetFireIcon from '../../api/ui/svgIcons/AirbetFireIcon';
import { AppRoutePath } from '../../util/appRoutePath';
import StarIcon from '@mui/icons-material/Star';
import NoCryptoBanner from '../NoCryptoBanner/NoCryptoBanner';
import ProviderSwiper from '../Provider/ProviderSwiper';
import { Box } from '@mui/material';
import GameShowIcon from '../../api/ui/svgIcons/GameShowIcon';
import TableGameIcon from '../../api/ui/svgIcons/TableGameIcon';
import SlotIcon from '../../api/ui/svgIcons/SlotIcon';
import CasinoPageGlobalSearch from './CasinoPageGlobalSearch';
import CasinoMobileSwitcher from './CasinoMobileSwitcher';

interface ICasinoPage {
  trendingGames: IGameSchema[];
  recommendedGames: IGameSchema[];
  tableGames: IGameSchema[];
  slotGames: IGameSchema[];
  gameShows: IGameSchema[];
  trendingGamesLoading: boolean;
  recommendedGamesLoading: boolean;
  slotGameLoading: boolean;
  tableGameLoading: boolean;
  isUserAuthenticated: boolean;
  gameShowsLoading: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    paddingBottom: '40px',
    flexDirection: 'column',
  },
  header: {
    ...theme.general.pageHeader,
    padding: isMobile ? '0px 16px' : '0px 20px',
  },
  headerText: {
    ...theme.general.pageHeaderTitle,
  },
  body: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: isMobile ? '16px' : '24px 20px 0px 20px',
    gap: isMobile ? '25px' : '40px',
  },
  filterContainer: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
    gap: '32px',
    alignItems: 'center',
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column',
      gap: '16px',
    },

    '& .search-icon': {
      fill: '#6B7280',
    },
  },
}));

const CasinoPage = ({
  slotGameLoading,
  tableGameLoading,
  recommendedGamesLoading,
  slotGames,
  tableGames,
  trendingGamesLoading,
  trendingGames,
  recommendedGames,
  isUserAuthenticated,
  gameShows,
  gameShowsLoading,
}: ICasinoPage) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Container>
          <span className={classes.headerText}>{t('header.casino')}</span>
        </Container>
      </div>
      <Container>
        {!isMobile && <CasinoSwitcher pageName={'lobby'} />}
        {isMobile && <CasinoMobileSwitcher selectedPage={'lobby'} />}
        <Box padding={isMobile ? '16px' : '24px 20px 0px 20px'}>
          <CasinoPageGlobalSearch />
          <GameSwiper
            title={t('trendingGames')}
            icon={<AirbetFireIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={trendingGames}
            loading={trendingGamesLoading}
          />
          <GameSwiper
            title={t('recommendedGames')}
            icon={
              <StarIcon
                style={{
                  color: '#9CA3AF',
                  width: isMobile ? '16px' : '20px',
                  height: isMobile ? '16px' : '20px',
                }}
              />
            }
            seeAllLink={AppRoutePath.GAMELIST()}
            games={recommendedGames}
            loading={recommendedGamesLoading}
          />
          <GameSwiper
            title={t('airbetMenu.slots')}
            icon={<SlotIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={slotGames}
            loading={slotGameLoading}
          />
          {!isMobile && (
            <NoCryptoBanner isUserAuthenticated={isUserAuthenticated} />
          )}
          <GameSwiper
            title={t('airbetMenu.tableGames')}
            icon={<TableGameIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={tableGames}
            loading={tableGameLoading}
          />
          <ProviderSwiper />
          <GameSwiper
            title={t('airbetMenu.gameShows')}
            icon={<GameShowIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAME_SHOWS()}
            games={gameShows}
            loading={gameShowsLoading}
          />
        </Box>
      </Container>
    </div>
  );
};

export default CasinoPage;
