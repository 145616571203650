import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, SelectChangeEvent } from '@mui/material';
import DepositIcon from '../../api/ui/svgIcons/DepositIcon';
import CustomSelect from '../common/Select/CustomSelect';
import {
  ICurrency,
  ICurrencyNetworksId,
  INetwork,
} from '../../types/common.type';
import { getBaseUrlStatic } from '../../api/baseUrl';
import QRCode from 'react-qr-code';
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import MinimumInfoAmountIcon from '../../api/ui/svgIcons/MinimumInfoAmountIcon';
import { isMobile } from 'react-device-detect';
import DepositButton from '../common/Buttons/DepositButton';

const useStyles = makeStyles((theme: any) => ({
  root: {
    ...theme.general.accountBody,
  },
  rootMobile: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    background: '#111827',
  },
  header: {
    margin: '16px 0px',
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& path': {
      fill: '#fff',
    },
    '& span': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  itemName: {
    color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  qrContainer: {
    display: 'flex',
    background: '#0D121D',
    padding: '16px',
    gap: '16px',
    boxSizing: 'border-box',
    borderRadius: '8px',
    margin: isMobile ? '8px' : '8px 16px 16px 16px',
  },
  depositAddress: {
    color: '#6B7280',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  memoAddress: {
    color: '#6B7280',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  depositAddressDescription: {
    color: '#6B7280',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
  },
  address: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    lineBreak: 'anywhere',
  },
  hasTag: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    lineBreak: 'anywhere',
  },
  selectCurrencyAndNetwork: {
    color: '#9CA3AF',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '24px',
  },
  minimumAmountContainer: {
    margin: isMobile ? '8px' : '8px 16px 24px 16px',
    background: '#FFFFFF0D',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  minimumAmountContainerText: {
    color: '#FFFFFF80',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  minimumAmountContainerAmount: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
  },
  minimumAmountContainerAmountText: {
    color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  hideHeader: {
    border: 'none',
  },
  headerLine: {
    width: '100%',
    border: '1px solid #FFFFFF0D',
    marginTop: !isMobile ? '20px' : '5px',
  },
}));

interface IDeposit {
  currencies: ICurrency[];
  network: INetwork[];
  selectedCurrency: ICurrency | null;
  selectedNetwork: INetwork | null;
  address: string | null;
  loading: boolean;
  hasTag: string | null;
  setHasTag: (hasTag: string | null) => void;
  getFilteredNetworks: any;
  setAddress: any;
  setSelectedNetwork: any;
  setSelectedCurrency: any;
  hideHeader?: boolean;
}

const Deposit = ({
  currencies,
  selectedCurrency,
  selectedNetwork,
  network,
  address,
  loading,
  getFilteredNetworks,
  setSelectedNetwork,
  setSelectedCurrency,
  setAddress,
  hasTag,
  setHasTag,
  hideHeader,
}: IDeposit) => {
  const classes = useStyles({ hideHeader });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const currencyRendererData = () => {
    return currencies.map((item: ICurrency) => {
      return {
        ...item,
        value: item.name,
        renderer: () => (
          <Box
            display={'flex'}
            gap={'8px'}
            alignItems={'center'}
            height={'24px'}
          >
            {item.logo && (
              <img
                src={getBaseUrlStatic() + item.logo}
                alt={item.name}
                width={'24px'}
                height={'24px'}
              />
            )}
            <span className={classes.itemName}>{item.name}</span>
          </Box>
        ),
      };
    });
  };

  const networkRendererData = () => {
    if (selectedCurrency) {
      return getFilteredNetworks(selectedCurrency, network).map(
        (item: INetwork) => {
          return {
            ...item,
            value: item.id,
            renderer: () => (
              <span className={classes.itemName}>{item.name}</span>
            ),
          };
        }
      );
    }

    return [];
  };

  const onChangeCurrency = (value: string): void => {
    const findCurrency: ICurrency | undefined = currencies.find(
      (item: ICurrency) => item.name === value
    );
    if (findCurrency) {
      setHasTag(null);
      setAddress(null);
      setSelectedNetwork(null);
      setSelectedCurrency(findCurrency);
    }
  };

  const onChangeNetwork = (value: string): void => {
    const findNetwork: INetwork | undefined = network.find(
      (item: INetwork) => item.id === Number(value)
    );
    if (findNetwork) {
      setSelectedNetwork({ ...findNetwork });
      setHasTag(null);
    }
  };

  const getMinimumAmount = () => {
    if (selectedCurrency && selectedNetwork) {
      return selectedCurrency.networks_id.find(
        (item: ICurrencyNetworksId) =>
          item.coin_network_id === selectedNetwork.id
      )?.min_amount;
    }

    return '';
  };

  const onSelectNetworkAuto = (value: string) => {
    const findCurrency: ICurrency | undefined = currencies.find(
      (item: ICurrency) => item.name === value
    );

    const networks = getFilteredNetworks(findCurrency, network).map(
      (item: INetwork) => {
        return {
          ...item,
          value: item.id,
          renderer: () => <span className={classes.itemName}>{item.name}</span>,
        };
      }
    );

    if (networks && networks.length > 0) {
      onChangeNetwork(String(networks[0].value));
    }
  };

  return (
    <div
      className={
        !hideHeader
          ? isMobile
            ? classes.rootMobile
            : classes.root
          : classes.hideHeader
      }
    >
      {!isMobile && !hideHeader && (
        <div className={classes.header}>
          <DepositIcon size={20} />
          <span>{t('deposit')}</span>
        </div>
      )}
      {!isMobile && !hideHeader && (
        <Box
          style={{
            background: '#FFFFFF0D',
            width: '100%',
            height: '1px',
          }}
        />
      )}
      <Box
        padding={isMobile ? '16px 8px' : '16px'}
        display={'flex'}
        gap={'24px'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {currencies && (
          <CustomSelect
            onChange={(event: SelectChangeEvent) => {
              onChangeCurrency(event.target.value as string);
              onSelectNetworkAuto(event.target.value as string);
            }}
            value={selectedCurrency?.name || ''}
            label={t('currency')}
            data={currencyRendererData()}
          />
        )}

        {network && (
          <CustomSelect
            onChange={(event: SelectChangeEvent) =>
              onChangeNetwork(event.target.value as string)
            }
            value={selectedNetwork ? String(selectedNetwork?.id) : ''}
            label={t('network')}
            data={networkRendererData()}
          />
        )}
      </Box>
      {selectedCurrency && selectedNetwork && (
        <Box className={classes.minimumAmountContainer}>
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <MinimumInfoAmountIcon />
            <span className={classes.minimumAmountContainerAmount}>
              {getMinimumAmount()} <span>{selectedCurrency.name}</span>
            </span>
            <span className={classes.minimumAmountContainerAmountText}>
              {t('minimumDeposit')}
            </span>
          </Box>
          {!isMobile && (
            <Box className={classes.minimumAmountContainerText}>
              {t('minimumDepositDescription')}
            </Box>
          )}
        </Box>
      )}
      {address || loading ? (
        <Box className={classes.qrContainer}>
          {loading ? (
            <Box
              width={'100%'}
              height={'166px'}
              alignItems={'center'}
              justifyContent={'center'}
              display={'flex'}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!isMobile && (
                <Box
                  style={{
                    border: '3px solid #fff',
                    borderRadius: '4px',
                  }}
                >
                  <QRCode
                    size={128}
                    style={{
                      height: '154px',
                      maxWidth: 'auto',
                      width: '160px',
                    }}
                    value={address || ''}
                    viewBox={'0 0 128 128'}
                  />
                </Box>
              )}
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'8px'}
                width={'100%'}
              >
                <Box
                  display={'flex'}
                  flexDirection={!isMobile && hasTag ? 'row' : 'column'}
                  justifyContent={'space-between'}
                  alignItems={!isMobile && hasTag ? 'end' : ''}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <span className={classes.depositAddress}>
                      {t('depositAddress')}
                    </span>
                    <span className={classes.address}>{address}</span>
                  </Box>
                  <Box display={'flex'} marginTop={'20px'}>
                    {address && (
                      <CopyToClipboard
                        text={address || ''}
                        onCopy={() =>
                          enqueueSnackbar(t('addressCopied'), {
                            variant: 'success',
                          })
                        }
                      >
                        <DepositButton
                          style={{
                            textTransform: 'uppercase',
                            width: '81px',
                          }}
                        >
                          {t('copy')}
                        </DepositButton>
                      </CopyToClipboard>
                    )}
                  </Box>

                  {/* <span className={classes.depositAddressDescription}>
                    {t('depositAddressDescription')}
                  </span>*/}
                </Box>

                {hasTag && <Box className={classes.headerLine} />}

                {hasTag && (
                  <Box
                    display={'flex'}
                    flexDirection={!isMobile ? 'row' : 'column'}
                    justifyContent={'space-between'}
                    alignItems={!isMobile ? 'end' : ''}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                      }}
                    >
                      <span className={classes.memoAddress}>{t('memo')}</span>
                      <span className={classes.hasTag}>{hasTag}</span>
                    </Box>
                    <Box display={'flex'} marginTop={'20px'}>
                      {hasTag && (
                        <CopyToClipboard
                          text={hasTag || ''}
                          onCopy={() =>
                            enqueueSnackbar(t('memoCopied'), {
                              variant: 'success',
                            })
                          }
                        >
                          <DepositButton
                            style={{
                              textTransform: 'uppercase',
                              width: '81px',
                            }}
                          >
                            {t('copy')}
                          </DepositButton>
                        </CopyToClipboard>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Box margin={'8px 24px 24px 24px'}>
          <span className={classes.selectCurrencyAndNetwork}>
            {t('pleaseSelectCurrencyAndNetwork')}
          </span>
        </Box>
      )}
    </div>
  );
};

export default Deposit;
