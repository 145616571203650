import { ISvgIcon } from '../../../../types/common.type';

const CustomIcon = ({ className, size }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="24" height="24" rx="12" fill="#3B4CC7" />
      <path
        d="M14.8892 6.28723C13.9366 6.00684 12.9571 5.97319 11.9724 6.0146C11.0753 6.05256 10.1965 6.17162 9.3645 6.54173C8.10566 7.10164 7.45325 8.11016 7.14787 9.44048H7.39513C8.13169 9.44048 8.86912 9.43531 9.60569 9.4448C9.77399 9.44738 9.86249 9.40252 9.92842 9.24206C10.1358 8.74081 10.5218 8.41557 11.0372 8.26631C11.7625 8.05667 12.5042 8.04373 13.2443 8.17745C13.6338 8.2482 14.0034 8.38796 14.1986 8.76928C14.3678 9.09884 14.3331 9.44911 14.2593 9.79938C14.1968 10.0962 14.0095 10.2661 13.724 10.3196C13.4629 10.3679 13.1965 10.3929 12.9311 10.4171C11.7607 10.5241 10.5878 10.6095 9.41916 10.7363C8.70081 10.8148 8.00503 11.0063 7.37257 11.373C6.51628 11.8691 6.12327 12.6438 6.0235 13.5971C5.85346 15.2233 6.60998 16.3682 8.16639 16.7831C9.23003 17.067 10.3049 17.0696 11.3764 16.8064C12.0887 16.6313 12.7046 16.2948 13.1506 15.6978C13.2642 15.5468 13.357 15.3803 13.4672 15.2095V16.8263C14.1405 16.7762 14.8067 16.7141 15.4748 16.683C15.6891 16.6727 15.7489 16.5925 15.781 16.4027C16.0482 14.8411 16.3293 13.2813 16.5965 11.7198C16.7354 10.9071 16.8733 10.0944 16.9765 9.27656C17.0503 8.69077 16.9514 8.11793 16.6633 7.59166C16.2764 6.88423 15.637 6.50636 14.8883 6.28636L14.8892 6.28723ZM12.8027 14.2976C12.2431 14.7687 11.5768 14.9619 10.8663 14.98C10.4464 14.9904 10.0134 14.9481 9.60655 14.8506C9.08428 14.7255 8.75894 14.3701 8.74246 13.8085C8.72597 13.2537 8.86999 12.7456 9.41395 12.4954C9.75231 12.3392 10.134 12.2409 10.5062 12.1986C11.4007 12.0959 12.3012 12.0468 13.1991 11.9648C13.4256 11.9441 13.6477 11.8829 13.8915 11.8363C13.7691 12.7577 13.5739 13.6506 12.8044 14.2985L12.8027 14.2976Z"
        fill="white"
      />
    </svg>
  );
};

export default CustomIcon;
