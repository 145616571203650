import { useEffect, useState } from 'react';
import { IGameSchema } from '../../types/Game.type';
import { getRecentGame } from '../../api/services/Game.api';
import GamePage from '../../components/GamesPage/GamePage';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  addGameToFavourite,
  removeGameFromFavourite,
} from '../../redux/actions/gameActions';

interface IRecentContainer {
  favourite: IGameSchema[];
  addToFavoriteGameList: (gameId: string) => void;
  removeFromFavoriteGameList: (gameId: string) => void;
}

const RecentContainer = ({
  favourite,
  removeFromFavoriteGameList,
  addToFavoriteGameList,
}: IRecentContainer) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [totalResult, setTotalResult] = useState<number>(0);
  const [games, setGames] = useState<IGameSchema[]>([]);
  const [originalGames, setOriginalGames] = useState<IGameSchema[]>([]);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getRecentGame()
      .then((resp: any) => {
        const games = resp.data;
        if (games) {
          setTotalResult(games.length);
          setGames(games);
          setOriginalGames(games);
        }
      })
      .finally(() => {
        setLoading(false);
        if (firstLoading) {
          setFirstLoading(false);
        }
      });
  }, []);

  const onChangeSearchValue = (value: string): void => {
    setSearchValue(value);
    if (value.trim().length > 2) {
      const filteredGame: IGameSchema[] = [
        ...originalGames.filter((game: IGameSchema) =>
          game.game_title.toLowerCase().includes(value.toLowerCase())
        ),
      ];
      setGames(filteredGame);
      setTotalResult(filteredGame.length);
    }

    if (value.trim().length === 0) {
      setGames([...originalGames]);
      setTotalResult(originalGames.length);
    }
  };

  const onClearParams = () => {
    setSearchValue('');
    setGames([...originalGames]);
    setTotalResult(originalGames.length);
  };

  return (
    <GamePage
      onChangeSearchValue={onChangeSearchValue}
      onChangeBlockedGames={() => {}}
      onChangeProviders={() => {}}
      onChangeSort={() => {}}
      currentSearchValue={searchValue}
      selectedBlockGame={''}
      selectedProviders={[]}
      selectedSortBy={''}
      totalResult={totalResult}
      games={games}
      loading={loading}
      loadMoreLoading={false}
      loadMoreGames={() => {}}
      firstLoading={firstLoading}
      onClearParams={onClearParams}
      pageName={t('airbetMenu.recent')}
      isOriginalGame={false}
      hideGameProviderFilter={true}
      hideGameSortByFilter={true}
      hideBlockedGameFilter={true}
      hideFooter={true}
      favourite={favourite}
      removeFromFavoriteGameList={removeFromFavoriteGameList}
      addToFavoriteGameList={addToFavoriteGameList}
    />
  );
};

const mapStateToProps = (state: any) => ({
  favourite: state.game.favourite,
});
const dispatchToProps = (dispatch: any) => ({
  addToFavoriteGameList: bindActionCreators(addGameToFavourite, dispatch),
  removeFromFavoriteGameList: bindActionCreators(
    removeGameFromFavourite,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(RecentContainer);
