import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { Box } from '@mui/material';
import SettingsButton from '../SettingsButton';

const useStyles = makeStyles(() => ({
  description: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: '14px',
    gap: '8px',
    color: '#fff',
    padding: isMobile ? '0 8px' : '0 16px',

    '& div:last-child': {
      color: '#6B7280',
    },
  },
}));

interface ISecurityDescription {
  onOpen: () => void;
  securityType: string;
  description: string;
  buttonText: string;
  disabled?: boolean;
}

const SecurityDescription = ({
  onOpen,
  securityType,
  description,
  buttonText,
  disabled = false,
}: ISecurityDescription) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.description}>
        <div>{securityType}</div>
        <div>{description}</div>
      </Box>
      <SettingsButton
        action={onOpen}
        buttonText={buttonText}
        disabled={disabled}
      />
    </>
  );
};

export default SecurityDescription;
