import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AirbetIcon from '../../../api/ui/svgIcons/NotificationIcons/AirbetIcon';
import DepositIcon from '../../../api/ui/svgIcons/DepositIcon';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import WithdrawIcon from '../../../api/ui/svgIcons/WithdrawIcon';
import BuyCryptoIcon from '../../../api/ui/svgIcons/BuyCryptoIcon';
import TransactionIcon from '../../../api/ui/svgIcons/TransactionIcon';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../../util/appRoutePath';
import { connect } from 'react-redux';
import { IUserInfo } from '../../../types/common.type';

interface IWalletMobilePage {
  playerInfo: IUserInfo;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 8px',
    gap: '16px',
  },
  header: {
    width: '100%',
    height: '56px',
    background: '#111827',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    boxSizing: 'border-box',
  },
  icon: {
    '& rect': {
      fill: '#374151',
    },
  },
  balance: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
  },
  body: {
    borderRadius: '8px',
    background: '#111827',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: 'column',
  },
  item: {
    padding: '12px 16px',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
  itemChild: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#9CA3AF',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

const WalletMobilePage = ({ playerInfo }: IWalletMobilePage) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <AirbetIcon size={24} className={classes.icon} />{' '}
        <span className={classes.balance}>
          {Number(playerInfo.player.balance).toFixed(2)}
        </span>
      </Box>
      <Box className={classes.body}>
        <Box
          className={classes.item}
          onClick={() => navigate(AppRoutePath.DEPOSIT())}
        >
          <Box className={classes.itemChild}>
            <DepositIcon /> <span>{t('deposit')}</span>
          </Box>
          <KeyboardArrowRightIcon />
        </Box>
        <Box
          className={classes.item}
          onClick={() => navigate(AppRoutePath.WITHDRAW())}
        >
          <Box className={classes.itemChild}>
            <WithdrawIcon /> <span>{t('withdraw')}</span>
          </Box>
          <KeyboardArrowRightIcon />
        </Box>
        <Box
          className={classes.item}
          onClick={() => navigate(AppRoutePath.BUYCRYPTO())}
        >
          <Box className={classes.itemChild}>
            <BuyCryptoIcon /> <span>{t('buyCrypto')}</span>
          </Box>
          <KeyboardArrowRightIcon />
        </Box>
        <Box
          className={classes.item}
          onClick={() => navigate(AppRoutePath.TRANSACTIONS())}
        >
          <Box className={classes.itemChild}>
            <TransactionIcon /> <span>{t('transactions')}</span>
          </Box>
          <KeyboardArrowRightIcon />
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  playerInfo: state.user.playerInfo,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(WalletMobilePage);
