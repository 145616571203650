import RecentWins from '../../components/RecentWins/RecentWins';
import { useEffect, useState } from 'react';
import { socket } from '../../api/airbet.socket';

const LastWinsContainer = () => {
  const [data, setData] = useState<any>([]);

  const updateData = (newItem: any, oldData: any) => {
    const removeLastItem =
      oldData.length <= 200 ? oldData : oldData.slice(149, oldData.length);
    return [...removeLastItem, newItem];
  };

  const handleRecentWinsUpdate = (arr: any) => {
    if (Array.isArray(arr)) {
      setData(arr.reverse());
    } else {
      setData((prev: any) => updateData(arr, prev));
    }
  };

  useEffect(() => {
    socket.emit('lastWins', {});
    socket.on('lastWins', handleRecentWinsUpdate);

    return () => {
      socket.off('lastWins', handleRecentWinsUpdate);
    };
  }, []);

  return <RecentWins data={data} />;
};

export default LastWinsContainer;
