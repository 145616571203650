import GameContainer from './GameContainer';

const TableGameContainer = () => {
  return (
    <GameContainer
      categorySlugName={'table'}
      pageName={'airbetMenu.tableGames'}
      showSwitcher={true}
    />
  );
};

export default TableGameContainer;
