const GooglePayIcon = () => {
  return (
    <svg
      width="48"
      height="20"
      viewBox="0 0 48 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4102 15.6443V9.77497H25.3726C26.5865 9.77497 27.6111 9.35903 28.4464 8.53871L28.6469 8.33074C30.1726 6.63232 30.0724 3.9865 28.4464 2.41518C27.6334 1.5833 26.5197 1.1327 25.3726 1.15581H20.6171V15.6443H22.4102ZM22.4104 7.99555V2.93497H25.4179C26.0639 2.93497 26.6765 3.18915 27.1332 3.65131C28.1023 4.62183 28.1246 6.23937 27.1889 7.24455C26.7322 7.74136 26.0862 8.01866 25.4179 7.99555H22.4104Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0108 6.50514C36.2423 5.77725 35.1954 5.40753 33.8701 5.40753C32.1662 5.40753 30.8854 6.05454 30.039 7.33702L31.6205 8.36531C32.1996 7.48722 32.9903 7.04817 33.9926 7.04817C34.6275 7.04817 35.24 7.2908 35.7189 7.72985C36.1866 8.14579 36.4539 8.74659 36.4539 9.38205V9.80954C35.7634 9.41671 34.8947 9.20874 33.8256 9.20874C32.5783 9.20874 31.5759 9.50914 30.8297 10.1215C30.0836 10.7338 29.7049 11.5426 29.7049 12.5709C29.6826 13.5068 30.0724 14.3964 30.7629 14.9972C31.4646 15.6442 32.3555 15.9677 33.4024 15.9677C34.6386 15.9677 35.6186 15.4016 36.3648 14.2693H36.4428V15.6442H38.1579V9.53225C38.1579 8.24977 37.7792 7.23303 37.0108 6.50514ZM32.1442 13.8188C31.7766 13.5415 31.5539 13.0909 31.5539 12.6056C31.5539 12.0626 31.7989 11.612 32.2779 11.2538C32.768 10.8956 33.3806 10.7108 34.1046 10.7108C35.1071 10.6992 35.8868 10.9303 36.4438 11.3925C36.4438 12.1781 36.143 12.8598 35.5527 13.4375C35.018 13.9921 34.294 14.304 33.5366 14.304C33.0353 14.3156 32.5452 14.1423 32.1442 13.8188Z"
        fill="white"
      />
      <path
        d="M42.0113 20L48.003 5.73102H46.054L43.2809 12.8482H43.2475L40.4076 5.73102H38.4586L42.39 15.0087L40.1626 20H42.0113Z"
        fill="white"
      />
      <path
        d="M16.2199 8.50409C16.2199 7.93796 16.1754 7.37182 16.0863 6.81723H8.52426V10.0176H12.8565C12.6783 11.0459 12.0992 11.9702 11.2528 12.5479V14.6276H13.8366C15.3512 13.1834 16.2199 11.0459 16.2199 8.50409Z"
        fill="white"
      />
      <path
        d="M8.52457 16.6381C10.6851 16.6381 12.5113 15.8985 13.8366 14.6276L11.2528 12.5479C10.5289 13.0563 9.60486 13.3452 8.52457 13.3452C6.43082 13.3452 4.66004 11.8779 4.02524 9.91374H1.3635V12.0628C2.72221 14.8703 5.49532 16.6381 8.52457 16.6381Z"
        fill="white"
      />
      <path
        d="M4.02524 9.91374C3.69108 8.88545 3.69139 7.76468 4.02555 6.72483V4.58737H1.3634C0.216107 6.9328 0.216107 9.70572 1.3634 12.0511L4.02524 9.91374Z"
        fill="white"
      />
      <path
        d="M8.52457 3.29335C9.67168 3.27024 10.7742 3.72084 11.5984 4.54116L13.8926 2.16107C12.4336 0.751502 10.5181 -0.0226048 8.52457 0.000502834C5.49532 0.000502834 2.72211 1.77979 1.3634 4.58737L4.02524 6.73639C4.66004 4.76068 6.43082 3.29335 8.52457 3.29335Z"
        fill="white"
      />
    </svg>
  );
};

export default GooglePayIcon;
