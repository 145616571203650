import * as React from 'react';
import CustomModal from '../common/Modal/CustomModal';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import AirbetAuthorizationIcon from '../../api/ui/svgIcons/AirbetAuthorizationIcon';
import CloseIcon from '@mui/icons-material/Close';

interface ICountryRestrictionModal {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#111827',
    borderRadius: '12px',
    width: '360px',
    height: '480px',
    position: 'relative',
    overflow: 'hidden',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    width: '240px',
  },
  title: {
    color: '#fff',
    lineHeight: '24px',
    fontSize: '24px',
    fontWeight: 700,
    letterSpacing: '0.5px',
  },
  description: {
    color: '#FFFFFF80',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    letterSpacing: '0.5px',
  },
  icon: {
    position: 'absolute',
    bottom: '-140px',
  },
  close: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    backgroundColor: '#0D121D',
    color: '#fff',
    padding: '7px',
    borderRadius: '8px',
    cursor: 'pointer',
    zIndex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '9px',
      height: '9px',
    },
  },
}));

const CountryRestrictionModal = ({
  open,
  setOpen,
}: ICountryRestrictionModal) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  return (
    <CustomModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.root}>
        <div className={classes.close} onClick={() => setOpen(false)}>
          <CloseIcon />
        </div>
        <div className={classes.icon}>
          <AirbetAuthorizationIcon />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.title}>{t('countryRestriction.title')}</div>
          <div className={classes.description}>
            {t('countryRestriction.description')}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default CountryRestrictionModal;
