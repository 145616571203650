import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import LeaderBoardsCompetition from './LeaderBoardsCompetition';
import LeaderBoardIcon from '../../api/ui/svgIcons/LeaderBoardIcon';
import { useTranslation } from 'react-i18next';
import LeaderBoardsPlayer from './LeaderBoardsPlayer';
import { isMobile } from 'react-device-detect';
import { ILeaderboard, ILeaderBoardInfo } from '../../types/LeaderBoard.type';
import { connect } from 'react-redux';
import { IUserInfo } from '../../types/common.type';
import { getBaseUrlStatic } from '../../api/baseUrl';
import LeaderBoardPlayerPositionSkeleton from './LeaderBoardPlayerPositionSkeleton';
import LeaderBoardInfoModal from './LeaderBoardInfoModal';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: '#0D121D',
    overflow: 'hidden',
  },
  container: {
    margin: '0 auto',
    maxWidth: '480px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '24px',
    padding: isMobile ? '0px 15px' : '',
  },
  leaderBoardHistoryWrapper: {
    width: '100%',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  leaderBoardHistoryTitle: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '8px',
    '& :nth-child(2)': {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      background: 'rgba(255, 255, 255, 1)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  infoBtn: {
    padding: '8px 24px',
    borderRadius: '12px',
    background: 'rgba(17, 24, 39, 1)',
    cursor: 'pointer',
    '& span': {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
      background: 'rgba(255, 255, 255, 1)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textTransform: 'uppercase',
    },
  },
  userPositionWrapper: {
    width: '100%',
    height: '64px',
    borderRadius: '8px',
    background: 'rgba(17, 24, 39, 1)',
    border: '1px solid rgba(31, 41, 55, 1)',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  img: {
    width: '24px',
    height: '24px',
  },
  userPositionsEachWrapper: {
    width: !isMobile ? '160px' : '100px',
    padding: '12px 16px',
    gap: '10px',
    display: 'flex',
  },
  userPositionSameFonts: {
    fontSize: '14px ',
    fontWeight: 700,
    lineHeight: '24px',
    background: 'rgba(255, 255, 255, 1)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  userPositionLightTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    background: 'rgba(255, 255, 255, 0.5)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  playersHeaderTitleBox: {
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    background: 'rgba(17, 24, 39, 1)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  playersHeaderEachWrapper: {
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    background: 'rgba(17, 24, 39, 1)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '16px',
  },
  playersHeaderLightTitle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
    background: 'rgba(156, 163, 175, 1)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textTransform: 'uppercase',
  },
  playerListWrapper: {
    gap: '8px',
    width: '100%',
  },
  playerInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    width: '100%',
  },
}));

interface LeaderBoardsPageProps {
  leaderBoard: ILeaderboard | undefined;
  playerInfo: IUserInfo;
  loading: boolean;
  isUserAuthenticated: boolean;
  headerInfo: ILeaderBoardInfo | undefined;
}

const LeaderBoardsPage: FC<LeaderBoardsPageProps> = ({
  leaderBoard,
  playerInfo,
  loading,
  isUserAuthenticated,
  headerInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);

  const img = playerInfo?.player?.avatar;

  return (
    <Box className={classes.root}>
      <LeaderBoardsCompetition
        startDate={leaderBoard?.start_date || ''}
        endDate={leaderBoard?.end_date || ''}
        name={headerInfo?.name || ''}
        subName={headerInfo?.sub_name || t('VIPCompetition')}
        subSubName={headerInfo?.sub_sub_name || ''}
      />

      <Box className={classes.container}>
        <Box className={classes.leaderBoardHistoryWrapper}>
          <Box className={classes.leaderBoardHistoryTitle}>
            <LeaderBoardIcon size={24} />
            <span>{t('Leaderboard')}</span>
          </Box>
          <Box
            className={classes.infoBtn}
            onClick={() => setIsOpenInfoModal(true)}
          >
            <span>{t('info')}</span>
          </Box>
        </Box>

        {isUserAuthenticated && (
          <Box className={classes.userPositionWrapper}>
            <Box
              className={classes.userPositionsEachWrapper}
              style={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <Box className={classes.img}>
                {img ? (
                  <img
                    src={
                      img
                        ? img.includes('google')
                          ? img
                          : getBaseUrlStatic() + img
                        : '/imgs/DefaultAvatars/Avatar.png'
                    }
                    alt={'user_profile_img'}
                    width={'24px'}
                    height={'24px'}
                    style={{
                      borderRadius: '100%',
                    }}
                  />
                ) : (
                  <img
                    src={`/imgs/DefaultAvatars/Avatar.png`}
                    alt={'user_profile_img'}
                    width={'24px'}
                    height={'24px'}
                    style={{
                      borderRadius: '100%',
                    }}
                  />
                )}
              </Box>
              <span
                className={classes.userPositionSameFonts}
                style={{ width: '95px' }}
              >
                {leaderBoard?.current_player_position?.username
                  ? leaderBoard?.current_player_position?.username
                  : playerInfo?.player?.username}
              </span>
            </Box>
            <Box
              className={classes.userPositionsEachWrapper}
              style={{ flexDirection: 'column', gap: 0 }}
            >
              <span className={classes.userPositionLightTitle}>
                {t('myPosition')}
              </span>
              <span className={classes.userPositionSameFonts}>
                {leaderBoard?.current_player_position?.position}
              </span>
            </Box>
            <Box
              className={classes.userPositionsEachWrapper}
              style={{ flexDirection: 'column', gap: 0 }}
            >
              <span className={classes.userPositionLightTitle}>
                {t('Wagered')}
              </span>
              <span className={classes.userPositionSameFonts}>
                {leaderBoard?.current_player_position?.wagered_amount
                  ? '$' +
                    parseFloat(
                      leaderBoard.current_player_position.wagered_amount
                    ).toFixed(2)
                  : '$0.00'}
              </span>
            </Box>
          </Box>
        )}

        <Box className={classes.playerInfoWrapper}>
          <Box className={classes.playersHeaderTitleBox}>
            <Box className={classes.playersHeaderEachWrapper}>
              <span className={classes.playersHeaderLightTitle}>
                {t('player')}
              </span>
            </Box>
            <Box className={classes.playersHeaderEachWrapper}>
              <span className={classes.playersHeaderLightTitle}>
                {t('Wagered')}
              </span>
            </Box>
            <Box
              className={classes.playersHeaderEachWrapper}
              style={{ paddingRight: '16px', justifyContent: 'flex-end' }}
            >
              <span className={classes.playersHeaderLightTitle}>
                {t('prize')}
              </span>
            </Box>
          </Box>
          {!loading && (
            <Box className={classes.playerListWrapper}>
              {leaderBoard &&
                leaderBoard.winners &&
                leaderBoard.winners.map((winner) => (
                  <LeaderBoardsPlayer key={winner.player_id} winner={winner} />
                ))}
            </Box>
          )}
          {loading && (
            <Box
              style={{
                gap: '8px',
                width: '100%',
                height: '600px',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <LeaderBoardPlayerPositionSkeleton />
            </Box>
          )}
        </Box>
      </Box>
      {isOpenInfoModal && (
        <LeaderBoardInfoModal
          isOpenInfoModal={isOpenInfoModal}
          setIsOpenInfoModal={setIsOpenInfoModal}
          data={headerInfo}
          image={leaderBoard && leaderBoard.image}
        />
      )}
    </Box>
  );
};
const mapStateToProps = (state: any) => ({
  playerInfo: state.user.playerInfo,
  isUserAuthenticated: state.user.isUserAuthorized,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(LeaderBoardsPage);
