import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { INotification as INotificationItem } from '../../types/common.type';
import NoResultNotificationIcon from '../../api/ui/svgIcons/NoResultNotificationIcon';
import NotificationItem from './NotificationItem';

interface INotification {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  data: INotificationItem[];
}

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    minHeight: '0px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    borderLeft: '1px solid rgba(255, 255, 255, 0.05)',
    background: '#0D121D',
    boxShadow: '0px 2px 4px 0px rgba(3, 7, 18, 0.50)',
    minHeight: '73px',
    height: '73px',
    padding: '0px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  title: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  closeIcon: {
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
      color: '#9CA3AF',
    },
  },
  body: {
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
    minHeight: '0px',
    padding: '16px',
    background: '#030712',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  emptyNotification: {
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
    minHeight: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

const Notification = ({ isOpen, setIsOpen, data }: INotification) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getTitle = (notificationText: string) => {
    return notificationText.split('||')[0];
  };

  const getText = (notificationText: string) => {
    return notificationText.split('||')[1];
  };

  return (
    <div
      className={classes.root}
      style={{
        width: isOpen ? '100%' : '0%',
      }}
    >
      <Box className={classes.header}>
        <span className={classes.title}>{t('notifications')}</span>
        <span className={classes.closeIcon} onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </span>
      </Box>
      <Box className={classes.body}>
        {data.length > 0 ? (
          data.map((notification: INotificationItem, index: number) => {
            return (
              <NotificationItem
                key={index}
                type={notification.type}
                title={getTitle(notification.text)}
                message={getText(notification.text)}
                createDt={notification.create_dt}
              />
            );
          })
        ) : (
          <Box className={classes.emptyNotification}>
            <NoResultNotificationIcon />
            <span
              style={{
                color: '#fff',
                fontWeight: 600,
                lineHeight: '20px',
                fontSize: '14px',
                marginTop: '16px',
                marginBottom: '4px',
              }}
            >
              {t('opps')}
            </span>
            <span
              style={{
                color: '#9CA3AF',
                fontWeight: 600,
                lineHeight: '20px',
                fontSize: '12px',
              }}
            >
              {t('notificationNoResult')}
            </span>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Notification;
