import Registration from '../../components/Authorization/Registration';
import { useEffect, useRef, useState } from 'react';
import {
  CheckEmailValidation,
  registrationGoogleManagerHelper,
  setLocalStorageValue,
} from '../../util/appUtil';
import { SignUp } from '../../api/services/Auth.api';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';
import {
  changeAccessToken,
  changeUserAuthorization,
  changeUserInfo,
} from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { IAccessToken, IUserInfo } from '../../types/common.type';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IRegistrationContainer {
  onClose: () => void;
  changeUserAuthorizationFunction: (isAuthorized: boolean) => void;
  changeUserAccessToken: (data: IAccessToken) => void;
  changeUserInfo: (data: IUserInfo) => void;
}

interface IRegistrationInfo {
  username: string;
  email: string;
  password: string;
  stag?: string;
}

const RegistrationContainer = ({
  onClose,
  changeUserInfo,
  changeUserAuthorizationFunction,
  changeUserAccessToken,
}: IRegistrationContainer) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const registrationRef = useRef<IRegistrationInfo>({
    username: '',
    email: '',
    password: '',
  });
  const [disableRegistration, setDisableRegistration] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [agreeMarketingPromotions, setAgreeMarketingPromotions] =
    useState<boolean>(false);
  const [agreeTermsAndConditions, setAgreeTermsAndConditions] =
    useState<boolean>(false);

  useEffect(() => {
    checkValidation();
  }, [agreeTermsAndConditions]);

  const onChangeLoginInfo = (key: string, value: string): void => {
    registrationRef.current = { ...registrationRef.current, [key]: value };
    checkValidation();
  };

  const checkValidation = () => {
    setDisableRegistration(
      Boolean(
        !(
          registrationRef.current.username &&
          registrationRef.current.password.trim() &&
          CheckEmailValidation(registrationRef.current.email.trim()) &&
          agreeTermsAndConditions
        )
      )
    );
  };

  const onRegister = (): void => {
    setLoading(true);
    const result: any = { ...registrationRef.current };
    const stagData = localStorage.getItem('stag');
    if (stagData) {
      const convertStagData = JSON.parse(stagData);
      const stagDate = new Date(convertStagData.date);
      stagDate.setDate(stagDate.getDate() + 7);
      if (stagDate > new Date()) {
        result.stag = convertStagData.code;
      }
    }

    SignUp(result)
      .then((resp: any) => {
        if (resp.data.data) {
          registrationGoogleManagerHelper(
            resp.data.data.id,
            resp.data.data.register_country,
            'Manual'
          );

          setLocalStorageValue('isAuthorized', true);
          setLocalStorageValue(
            'token_expire_at',
            resp.data.data.access_token.expire_at
          );
          setLocalStorageValue('userId', resp.data.data.player.id);
          setLocalStorageValue('token', resp.data.data.access_token.token);

          changeUserAuthorizationFunction(true);
          changeUserAccessToken(resp.data.data.access_token);
          changeUserInfo(resp.data.data);
          setLocalStorageValue('userInfo', JSON.stringify(resp.data.data));
          onClose();

          enqueueSnackbar(t('userRegisterSuccessfully'), {
            variant: 'success',
          });
          navigate('/account/deposit');
        }
      })
      .catch((resp: any) => {
        const result: any = resp.response.data.data;
        if (result['email']) {
          enqueueSnackbar(result['email'][0], {
            variant: 'error',
          });
        } else if (result['password']) {
          enqueueSnackbar(result['password'][0], {
            variant: 'error',
          });
        } else if (result['username']) {
          enqueueSnackbar(result['username'][0], {
            variant: 'error',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Registration
      disableRegistration={disableRegistration}
      onChangeLoginInfo={onChangeLoginInfo}
      onRegister={onRegister}
      loading={loading}
      agreeMarketingPromotions={agreeMarketingPromotions}
      setAgreeMarketingPromotions={setAgreeMarketingPromotions}
      agreeTermsAndConditions={agreeTermsAndConditions}
      setAgreeTermsAndConditions={setAgreeTermsAndConditions}
    />
  );
};

const mapStateToProps = () => ({});

const dispatchToProps = (dispatch: any) => ({
  changeUserAccessToken: bindActionCreators(changeAccessToken, dispatch),
  changeUserInfo: bindActionCreators(changeUserInfo, dispatch),
  changeUserAuthorizationFunction: bindActionCreators(
    changeUserAuthorization,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(RegistrationContainer);
