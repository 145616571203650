import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PlayIcon from '../../../api/ui/svgIcons/PlayIcon';

interface IPlayButton {
  onClick?: () => void;
  isMobile?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgba(255, 255, 255, 0.10)',
    background: '#1E40AF',
    width: '48px',
    height: '48px',
    borderRadius: '48px',
    minWidth: '0px',
    boxShadow:
      '0px 2px 0px 0px #172554, 0px 8px 12px 0px #3B82F6 inset, 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset',
    transition: 'background 0.3s, box-shadow 0.3s, opacity 0.3s',
    '&:hover': {
      background: '#1D4ED8',
      boxShadow:
        '0px 2px 0px 0px #172554, 0px 8px 12px 0px #3B82F6 inset, 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset',
    },
    '&:active': {
      background: '#1E3A8A',
      boxShadow:
        '0px 2px 0px 0px #172554, 0px -8px 12px 0px #1D4ED8 inset, 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset',
    },
  },
}));
const PlayButton = ({ onClick }: IPlayButton) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.root}
      disableRipple
      onClick={() => onClick && onClick()}
    >
      <PlayIcon />
    </Button>
  );
};

export default PlayButton;
