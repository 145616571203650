import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import ru from './ru.json';
import ar from './ar.json';
import de from './de.json';
import es from './es.json';
import pt from './pt.json';
import tr from './tr.json';
import { getUserLanguage } from '../util/appUtil';

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  ar: {
    translation: ar,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  pt: {
    translation: pt,
  },
  tr: {
    translation: tr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: getUserLanguage(),
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
