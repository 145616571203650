import Security from '../../components/Settings/Security/Security';
import { connect } from 'react-redux';
import { IUserInfo } from '../../types/common.type';

export interface IPlayerAccountInfo {
  playerInfo: IUserInfo;
  onClose: () => void;
}

const SecurityContainer = ({ playerInfo, onClose }: IPlayerAccountInfo) => {
  return <Security playerInfo={playerInfo} onClose={onClose} />;
};

const mapStateToProps = (state: any) => ({
  playerInfo: state.user.playerInfo,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(SecurityContainer);
