import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import TransactionIcon from '../../api/ui/svgIcons/TransactionIcon';
import { useEffect, useState } from 'react';
import WithdrawIcon from '../../api/ui/svgIcons/WithdrawIcon';
import AirbetIcon from '../../api/ui/svgIcons/NotificationIcons/AirbetIcon';
import AirbetPagination from '../common/Pagination/AirbetPagination';
import { isMobile } from 'react-device-detect';

interface ITransaction {
  loading: boolean;
  getTableData: (type: string) => any;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    ...theme.general.accountBody,
  },
  rootMobile: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    background: '#111827',
    padding: '16px 8px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    color: '#fff',

    '& path': {
      fill: '#fff',
    },
    '& div': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  transactionTypes: {
    '& span': {
      borderRadius: '8px',
      padding: '8px',
      cursor: 'pointer',
      color: '#9CA3AF',
      transition: 'all .3s ease-in-out',
    },
    '& span:hover': {
      color: '#fff',
    },
    '& .active': {
      backgroundColor: '#1F2937',
      color: '#fff',
    },
  },
  tableHeader: {
    display: 'flex',
    color: '#9CA3AF',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    '& div:last-child': {
      textAlign: 'right',
    },
  },
  tableBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    borderRadius: '8px',
    overflow: 'auto',
    maxHeight: '500px',
  },
  row: {
    display: 'flex',
    color: '#fff',
    backgroundColor: '#0D121D',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    '& div': {
      display: 'flex',
      flex: '1',
      padding: '12px 16px',
      alignItems: 'center',
      gap: '8px',
      '& rect': {
        fill: '#374151',
      },
    },
    '& div:last-child': {
      justifyContent: 'flex-end',
    },
  },
  tabsMobile: {
    background: '#0D121D',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '4px',
    height: '40px',
    marginBottom: '16px',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  activeTabMobile: {
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    background: '#1F2937',
    flex: 1,
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inActiveTabMobile: {
    flex: 1,
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResultContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    marginTop: '20px',
    gap: '8px',
    flexDirection: 'column',
  },
}));

const Transaction = ({ getTableData, loading }: ITransaction) => {
  const [activeTransactionIndex, setActiveTransactionIndex] = useState(0);
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState<number>(1);
  const classes = useStyles();
  const { t } = useTranslation();
  const tableHeader = isMobile
    ? ['type', 'amount', 'status']
    : ['type', 'amount', 'time', 'status'];
  const transactionTypes = ['all', 'deposits', 'withdrawals'];
  const rowsPerPage = 8;

  useEffect(() => {
    if (!loading) {
      const res = getTableData(
        activeTransactionIndex === 0
          ? 'all'
          : activeTransactionIndex === 1
            ? 'deposit'
            : 'withdraw'
      );
      setData(res);
    }
  }, [activePage, loading, activeTransactionIndex]);

  const getStatusIndicator = (status: string) => {
    return (
      <Box
        style={{
          borderRadius: '100%',
          padding: '2px',
          background:
            status === 'completed'
              ? '#84CC16'
              : status === 'failed' || status === 'canceled'
                ? '#EF5B4F'
                : '#6B7280',
        }}
      />
    );
  };

  return (
    <div className={isMobile ? classes.rootMobile : classes.root}>
      {!isMobile && (
        <Box className={classes.header}>
          <div>
            <TransactionIcon size={20} />
            <span>{t('transactions')}</span>
          </div>
          <div className={classes.transactionTypes}>
            {transactionTypes.map((type, index) => (
              <span
                key={index}
                onClick={() => setActiveTransactionIndex(index)}
                className={`${activeTransactionIndex === index ? 'active' : ''}`}
              >
                {t(type)}
              </span>
            ))}
          </div>
        </Box>
      )}
      {isMobile && (
        <Box className={classes.tabsMobile}>
          <Box
            className={
              activeTransactionIndex === 0
                ? classes.activeTabMobile
                : classes.inActiveTabMobile
            }
            onClick={() => setActiveTransactionIndex(0)}
          >
            {t('all')}
          </Box>
          <Box
            className={
              activeTransactionIndex === 1
                ? classes.activeTabMobile
                : classes.inActiveTabMobile
            }
            onClick={() => setActiveTransactionIndex(1)}
          >
            {t('deposits')}
          </Box>
          <Box
            className={
              activeTransactionIndex === 2
                ? classes.activeTabMobile
                : classes.inActiveTabMobile
            }
            onClick={() => setActiveTransactionIndex(2)}
          >
            {t('withdrawals')}
          </Box>
        </Box>
      )}
      {!isMobile && (
        <Box
          style={{
            background: '#FFFFFF0C',
            width: '100%',
            height: '1px',
          }}
        />
      )}
      <Box padding={isMobile ? '0px' : '16px'}>
        {!isMobile && (
          <Box className={classes.tableHeader}>
            {tableHeader.map((header: string, index: number) => {
              return (
                <Box key={index} flex={'1'} padding={'8px 16px'}>
                  {t(header)}
                </Box>
              );
            })}
          </Box>
        )}
        <Box className={classes.tableBody}>
          {!loading && data.length === 0 && (
            <Box className={classes.noResultContainer}>
              <span
                style={{
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                }}
              >
                {t('noTransactionsYet')}
              </span>
              <span
                style={{
                  color: '#9CA3AF',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                }}
              >
                {t('emptyTransactions')}
              </span>
            </Box>
          )}
          {data
            .slice(
              (activePage - 1) * rowsPerPage,
              (activePage - 1) * rowsPerPage + rowsPerPage
            )
            .map((row: any, index: number) => (
              <Box key={index} className={`${classes.row}`}>
                <div>
                  <WithdrawIcon size={20} />
                  <span>{t(row.type)}</span>
                </div>
                <div>
                  <AirbetIcon size={16} className="amount" />
                  <span
                    style={{
                      color:
                        row.type === 'deposit' || row.type === 'manual_deposit'
                          ? '#84CC16'
                          : '#fff',
                    }}
                  >
                    {row.exchanged_amount}
                  </span>
                </div>
                {!isMobile && (
                  <div>
                    <span
                      style={{
                        color: '#9CA3AF',
                      }}
                    >
                      {row.requested_at}
                    </span>
                  </div>
                )}
                <div>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    {getStatusIndicator(row.status)} {row.status}
                  </span>
                </div>
              </Box>
            ))}
        </Box>
        <Box>
          <AirbetPagination
            onPageChange={(page: number) => setActivePage(page)}
            initialPage={activePage}
            rowsPerPage={rowsPerPage}
            totalAmount={data.length}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Transaction;
