import { ISvgIcon } from '../../../types/common.type';

const GameShowIcon = ({
  size = 16,
  className,
  pathClassName,
  fill,
}: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.0033 1.33527C11.476 1.33527 12.6699 2.52917 12.6699 4.00193C12.6699 4.48795 12.5399 4.94361 12.3127 5.33601L15.3366 5.33527V6.66858H14.0033V13.3352C14.0033 13.7034 13.7048 14.0019 13.3366 14.0019H2.66992C2.30173 14.0019 2.00325 13.7034 2.00325 13.3352V6.66858H0.66992V5.33527L3.69377 5.33601C3.46661 4.94361 3.33659 4.48795 3.33659 4.00193C3.33659 2.52917 4.53049 1.33527 6.00325 1.33527C6.80007 1.33527 7.51527 1.68474 8.00387 2.23877C8.49127 1.68474 9.20647 1.33527 10.0033 1.33527ZM8.66993 6.66858H7.3366V13.3352H8.66993V6.66858ZM6.00325 2.6686C5.26687 2.6686 4.66992 3.26555 4.66992 4.00193C4.66992 4.70484 5.21384 5.28071 5.90375 5.33161L6.00325 5.33527H7.3366V4.00193C7.3366 3.33417 6.84573 2.78106 6.20511 2.68378L6.10277 2.67226L6.00325 2.6686ZM10.0033 2.6686C9.30033 2.6686 8.72447 3.21252 8.6736 3.90243L8.66993 4.00193V5.33527H10.0033C10.7061 5.33527 11.282 4.79135 11.3329 4.10144L11.3366 4.00193C11.3366 3.26555 10.7397 2.6686 10.0033 2.6686Z"
        fill={fill || '#9CA3AF'}
        className={pathClassName}
      />
    </svg>
  );
};

export default GameShowIcon;
