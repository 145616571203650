const IceHockeySportIcon = () => {
  return (
    <svg
      data-editor-id="navbarIcon"
      data-cy="sport-icehockey"
      width="20"
      height="20"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.4325 12.3083L23.0427 13.8744L23.8906 12.2503L21.2841 10.6864L20.4325 12.3083ZM20.0245 13.0855L16.2598 20.2553C15.877 20.9843 15.027 21.3345 14.2418 21.0868L8.91739 19.4069C8.68698 19.3342 8.44699 19.2964 8.20538 19.2947C6.8673 19.2853 5.77496 20.3625 5.76558 21.7005L5.75277 23.5268C5.75272 23.5362 5.75272 23.5362 5.75272 23.5456C5.75374 24.8838 6.83933 25.9677 8.17744 25.9667L15.5154 25.961C16.2636 25.9605 16.9497 25.5444 17.296 24.8811L22.6364 14.6526L20.0245 13.0855ZM21.6922 9.90922L24.2969 11.4721L26.0634 8.0887C26.4638 7.32187 26.1886 6.37575 25.4395 5.94323C25.4148 5.92919 25.4148 5.92919 25.3897 5.91581C24.6967 5.55195 23.84 5.81875 23.4761 6.51172L21.6922 9.90922ZM6.81056 10.4124V12.1744C6.81056 13.4187 8.65447 14.4613 11.1922 14.4613C13.7304 14.4613 15.5741 13.4187 15.5741 12.1738V10.2665C14.6513 10.9983 13.0775 11.3941 11.1677 11.3941C9.22393 11.3941 7.70017 11.0687 6.81056 10.4124ZM6.81414 8.76685C6.81599 9.8361 8.43009 10.5177 11.1677 10.5177C13.6624 10.5177 15.3833 9.78719 15.5699 8.75879C15.4716 7.55988 13.6601 6.57411 11.1922 6.57411C8.71901 6.57411 6.90504 7.56432 6.81414 8.76685ZM11.1922 15.3377C8.23474 15.3377 5.9342 14.0369 5.9342 12.1744V8.86166C5.9342 6.9987 8.23457 5.69776 11.1922 5.69776C14.1501 5.69776 16.4505 6.99866 16.4505 8.86166V12.1738C16.4505 14.0368 14.1501 15.3377 11.1922 15.3377ZM21.9243 5.69691C22.7382 4.1469 24.6545 3.55014 26.2045 4.364C26.2606 4.39392 26.2606 4.39392 26.3158 4.42533C27.8783 5.3274 28.4521 7.30061 27.6171 8.89989L18.8497 25.6923C18.2015 26.9338 16.9173 27.7127 15.5167 27.7138L8.17878 27.7194C5.87267 27.7211 4.00176 25.8531 4 23.547C4.00001 23.5307 4.00001 23.5307 4.0001 23.5145L4.01291 21.6882C4.02908 19.3822 5.91161 17.5259 8.21767 17.542C8.63405 17.545 9.04766 17.6101 9.44476 17.7354L14.728 19.4023L21.9243 5.69691Z"></path>
    </svg>
  );
};

export default IceHockeySportIcon;
