import { connect } from 'react-redux';
import { IGameSchema } from '../../types/Game.type';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import GamePage from '../../components/GamesPage/GamePage';
import { bindActionCreators } from 'redux';
import {
  addGameToFavourite,
  removeGameFromFavourite,
} from '../../redux/actions/gameActions';

interface IFavouriteGameContainer {
  favourite: IGameSchema[];
  addToFavoriteGameList: (gameId: string) => void;
  removeFromFavoriteGameList: (gameId: string) => void;
}

const FavouriteGameContainer = ({
  favourite,
  removeFromFavoriteGameList,
  addToFavoriteGameList,
}: IFavouriteGameContainer) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [totalResult, setTotalResult] = useState<number>(0);
  const [games, setGames] = useState<IGameSchema[]>([]);
  const [originalGames, setOriginalGames] = useState<IGameSchema[]>([]);

  useEffect(() => {
    setTotalResult(favourite.length);
    setGames(favourite);
    setOriginalGames(favourite);
  }, [favourite]);

  const onClearParams = () => {
    setSearchValue('');
    setGames([...originalGames]);
    setTotalResult(originalGames.length);
  };

  const onChangeSearchValue = (value: string): void => {
    setSearchValue(value);

    if (value.trim().length === 0) {
      setGames([...originalGames]);
      setTotalResult(originalGames.length);
    }

    if (value.trim().length > 2) {
      const filteredGames: IGameSchema[] = [
        ...originalGames.filter((game: IGameSchema) =>
          game.game_title.toLowerCase().includes(value.toLowerCase())
        ),
      ];
      setGames(filteredGames);
      setTotalResult(filteredGames.length);
    }
  };

  return (
    <GamePage
      onChangeSearchValue={onChangeSearchValue}
      onChangeBlockedGames={() => {}}
      onChangeProviders={() => {}}
      onChangeSort={() => {}}
      currentSearchValue={searchValue}
      selectedBlockGame={''}
      selectedProviders={[]}
      selectedSortBy={''}
      totalResult={totalResult}
      games={games}
      loading={false}
      loadMoreLoading={false}
      loadMoreGames={() => {}}
      firstLoading={false}
      onClearParams={onClearParams}
      pageName={t('airbetMenu.recent')}
      isOriginalGame={false}
      hideGameProviderFilter={true}
      hideGameSortByFilter={true}
      hideBlockedGameFilter={true}
      hideFooter={true}
      favourite={favourite}
      removeFromFavoriteGameList={removeFromFavoriteGameList}
      addToFavoriteGameList={addToFavoriteGameList}
    />
  );
};

const mapStateToProps = (state: any) => ({
  favourite: state.game.favourite,
});
const dispatchToProps = (dispatch: any) => ({
  addToFavoriteGameList: bindActionCreators(addGameToFavourite, dispatch),
  removeFromFavoriteGameList: bindActionCreators(
    removeGameFromFavourite,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  dispatchToProps
)(FavouriteGameContainer);
