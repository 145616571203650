import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    borderRadius: '16px',
    background: '#111827',
  },
  header: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '32px',
  },
  description: {
    color: '#9CA3AF',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '32px',
  },
}));

const LobbyAirbetInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <span className={classes.header}>
        {t('lobbyPageAirbetCryptoCasinoDescription')}
      </span>
      <span className={classes.description}>
        {t('lobbyPageAirbetCryptoCasinoDescriptionPart1')}
      </span>
      <span className={classes.description}>
        {t('lobbyPageAirbetCryptoCasinoDescriptionPart2')}
      </span>
    </Box>
  );
};

export default LobbyAirbetInfo;
