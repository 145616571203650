import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAirCrashSlug } from '../../api/baseUrl';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: !isMobile ? '24px 0px' : '8px 0px',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    gap: isMobile ? '8px' : '24px',
    flexDirection: isMobile ? 'column' : 'row',
    [theme.breakpoints.down(1100)]: {
      flexDirection: 'column',
    },
  },
  item: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px',
    padding: isMobile ? '16px' : '24px',
    gap: isMobile ? '16px' : '24px',
    cursor: 'pointer',
  },
  itemTitle: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
}));

const AirbetCrashBannerV2 = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/games/${getAirCrashSlug()}`);
  };

  return (
    <Box className={classes.root}>
      <Box
        className={classes.item}
        style={{
          background:
            'linear-gradient(287.43deg, #111827 -8.98%, #111827 60.22%, #D6B756 129.42%)',
        }}
        onClick={() => handleClick()}
      >
        <img
          src={'/imgs/airCrashBannerV2.png'}
          alt={'AIR CRASH img'}
          width={isMobile ? '32px' : '52px'}
        />
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          boxSizing="border-box"
          gap={'8px'}
        >
          <Box
            display={'flex'}
            gap={'8px'}
            justifyContent={'left'}
            alignItems={'center'}
          >
            <span className={classes.itemTitle}>{t('airbetCrush')}</span>
            <Box
              style={{
                borderRadius: '8px',
                padding: '8px 8px',
                backgroundColor: '#EDBC1A40',
                maxHeight: '28px',
              }}
            >
              <span
                style={{
                  color: '#EDBC1A',
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: '12px',
                  textTransform: 'uppercase',
                  maxHeight: '28px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {t('originals')}
              </span>
            </Box>
          </Box>
          {!isMobile && (
            <span
              style={{
                color: '#FFFFFF80',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
              }}
            >
              {t('crashGameBannerDescription')}
            </span>
          )}
        </Box>
        <CustomMinimalButton
          style={{
            height: '40px',
            minWidth: '0px',
            width: '40px',
            background: '#1F2937',
          }}
          onClick={() => {}}
        >
          <span
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <KeyboardArrowRightIcon />
          </span>
        </CustomMinimalButton>
      </Box>
    </Box>
  );
};

export default AirbetCrashBannerV2;
