import { Box } from '@mui/material';
import { IBonusWheel } from '../../types/common.type';
import { makeStyles } from '@mui/styles';
import BonusWheelDiamondIcon from '../../api/ui/svgIcons/BonusWheelDiamondIcon';
import { useTranslation } from 'react-i18next';
import LockIcon from '../../api/ui/svgIcons/LockIcon';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { globalBonusWheelModalClose } from '../../redux/actions/userActions';
import { isMobile } from 'react-device-detect';
import BonusWheelCountDownTimer from './BonusWheelCountDownTimer';
import BonusWheelWinModal from './BonusWheelWinModal';
import { IBonusWheelWinPrize } from '../../types/Bonus.type';
import { getBaseUrlStatic } from '../../api/baseUrl';

const useStyles = makeStyles(() => ({
  mainContainerWrapper: {
    width: !isMobile ? '500px' : '100%',
    minWidth: '340px',
    height: !isMobile ? '715px' : 'auto',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(27, 40, 69, 0.20) 100%), #111827',
    borderRadius: '12px',
    border: '1px solid rgba(31, 41, 55, 1)',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    marginTop: '40px',
    display: 'flex',
    boxSizing: 'border-box',
    position: 'relative',
    transition: '5s',
  },
  outlineContainer: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    border: '3px solid #E2B776',
    borderRadius: '50%',
    position: 'relative',
  },
  rotateContainer: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    transition: '5s',
    boxSizing: 'border-box',
    boxShadow: '0 0 0 5px #E2B776, 0 0 0 18px #F2D9AE, 0 0 0 23px #F7E4C6',
    background: '#E2B776',
  },
  rotateContainerItem: {
    width: '100%',
    height: '50%',
    position: 'absolute',
    clipPath: 'polygon(100% 0, 50% 100%, 0 0)',
    transformOrigin: 'bottom',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    color: '#fff',
  },
  spinContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 10,
    border: '4px solid #E2B776',
    borderRadius: '50%',
  },
  spin: {
    background: 'linear-gradient(180deg, #2178FB 0%, #103CA9 100%)',
    border: '1px solid #1241AF',
    borderRadius: '50%',
    cursor: 'pointer',
    width: '72px',
    height: '72px',
    boxShadow:
      '0px 4px 0px 0px rgba(255, 255, 255, 0.35) inset, 0px 12px 2px 0px rgba(255, 255, 255, 0.25) inset',
  },
  spinText: {
    background: 'linear-gradient(180deg, #FBFFFF 0%, #BBDFEA 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '24px',
    fontWeight: 900,
    fontSize: '20px',
    textAlign: 'center',
    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.25)',
  },
  wheelSector: {
    boxSizing: 'border-box',
    position: 'absolute',
    minWidth: '0px',
    minHeight: '0px',
    border: '3px solid #E2B776',
    transformOrigin: 'bottom right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    cursor: 'pointer',
  },
  prizePhoto: {
    width: !isMobile ? '24px' : '16px',
    height: !isMobile ? '24px' : '16px',
    borderRadius: '50%',
  },
  prizeName: {
    lineHeight: '24px',
    textAlign: 'right',
    textShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.25)',
    fontSize: !isMobile ? '12px' : '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    background:
      'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.64) 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    maxWidth: !isMobile ? '80px' : '70px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'auto',
  },
  prizeSectorWrapper: {
    position: 'relative',
    transform: 'rotate(45deg)',
    top: !isMobile ? '10px' : '7px',
    left: !isMobile ? '10px' : '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5px',
    width: !isMobile ? '100px' : '88px',
  },
  blockedSpinContainer: {
    boxSizing: 'border-box',
    width: '172px',
    height: '172px',
    borderRadius: '50%',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#141C2D',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 10,
    gap: '8px',
  },
  blockedSpinContainerSpinText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '32px',
    color: 'rgba(255, 255, 255, 0.64)',
    background:
      'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.25)',
  },
  blockedSpinContainerGoodLuckText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '32px',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.32) 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.25)',
  },
  winBox: {
    marginTop: '60px',
    width: '207px',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(1, 6, 23, 0.25)',
    gap: '16px',
    padding: '16px 32px',
    '& :nth-child(1)': {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '16px',
      background: 'rgba(255, 255, 255, 0.5)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    '& :nth-child(2)': {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '24px',
      background: 'linear-gradient(180deg, #ECCF9C 1.5%, #E2B776 101.5%)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  spinButton: {
    marginTop: '35px',
    boxSizing: 'border-box',
    width: '115px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '32px',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '16px 32px',
    boxShadow: '0px 2px 8px 0px rgba(101, 163, 13, 0.3)',
    background: 'rgba(101, 163, 13, 1)',
    cursor: 'pointer',
    '& span': {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '24px',
      background: 'rgba(255, 255, 255, 1)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  lockedSpinButton: {
    marginTop: '35px',
    boxSizing: 'border-box',
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '32px',
    padding: '16px 32px',
    flexWrap: 'nowrap',
    background: 'rgba(32, 41, 58, 1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    gap: '6px',
    '& span': {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '24px',
      background: 'rgba(255, 255, 255, 0.25)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  closeButtonBox: {
    marginTop: '16px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    paddingRight: '16px',
  },
}));

interface IBonusWheelPage {
  prizes: IBonusWheel[];
  width: number;
  height: number;
  getWinPrize: () => void;
  rotate: number;
  eachItemDeg: number;
  isDisableSpinButton: boolean;
  isFinishedSpin: boolean;
  showSpin: () => void;
  isOpenBonusWheelWinModal: boolean;
  onCloseBonusWheelWinModal: () => void;
  wonPrize: null | IBonusWheelWinPrize;
}

const BonusWheelPage = ({
  prizes,
  width,
  height,
  getWinPrize,
  rotate,
  eachItemDeg,
  isDisableSpinButton,
  isFinishedSpin,
  showSpin,
  isOpenBonusWheelWinModal,
  onCloseBonusWheelWinModal,
  wonPrize,
}: IBonusWheelPage) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const backgroundArray = [
    'linear-gradient(187.63deg, #0A34BC 10.32%, #0A34BC 79.89%)',
    'linear-gradient(205.33deg, #016FFF 19.09%, #016FFF 81.28%)',
    'linear-gradient(110.32deg, #007257 39.63%, #007257 83.45%)',
    'linear-gradient(312.76deg, #00CE02 24.51%, #00CE02 83.84%)',
    'linear-gradient(294.6deg, #FFBC00 21.49%, #FFBC00 82.26%)',
    'linear-gradient(180.73deg, #FF8E00 22.51%, #FF8E00 95.26%)',
    'linear-gradient(54.97deg, #FF5400 31%, #FF5400 83.79%)',
    'linear-gradient(58.28deg, #E41300 19%, #E41300 81.72%)',
    'linear-gradient(90.08deg, #FF0092 6.17%, #FF0092 78.24%)',
    'linear-gradient(249.33deg, #FD02FF 39.73%, #FD02FF 83.94%)',
    'linear-gradient(157.53deg, #6C00D8 19.05%, #6C00D8 82.08%)',
    'linear-gradient(171.78deg, #3200A7 10.02%, #3200A7 79.18%)',
  ];

  return (
    <Box className={classes.mainContainerWrapper}>
      <Box className={classes.closeButtonBox}>
        <CloseIcon
          style={{
            color: 'rgba(255, 255, 255, 0.5)',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(globalBonusWheelModalClose(false))}
        />
      </Box>
      <Box className={classes.root} width={width} height={height}>
        <Box className={classes.outlineContainer}>
          <Box
            style={{
              position: 'absolute',
              top: '25px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 3,
            }}
          >
            <img src={'/imgs/Arrow.png'} alt="arrow" />
          </Box>
          {prizes.map((_, index: number) => {
            return (
              <Box
                key={index}
                style={{
                  position: 'absolute',
                  width: 'auto',
                  height: 'calc(100% + 65px)',
                  background: 'transparent',
                  top: '50%',
                  left: '50%',
                  transform:
                    'translate(-50%, -50%) rotate(' +
                    index * eachItemDeg +
                    'deg)',
                  zIndex: 2,
                }}
              >
                <BonusWheelDiamondIcon />
              </Box>
            );
          })}
          {prizes.map((_, index: number) => {
            return (
              <Box
                key={index}
                style={{
                  position: 'absolute',
                  width: 'auto',
                  height: 'calc(100% + 30px)',
                  background: 'transparent',
                  top: '50%',
                  left: '50%',
                  transform: `translate(-50%, -50%) rotate(${15 + index * eachItemDeg}deg)`,
                  zIndex: 2,
                }}
              >
                <Box
                  width={'4px'}
                  height={'4px'}
                  style={{
                    background: '#FFEBCC',
                    boxShadow: '0px 0px 4px 2px #FFFFFF',
                  }}
                />
              </Box>
            );
          })}

          <Box
            className={classes.rotateContainer}
            style={{ transform: `rotate(${rotate}deg)` }}
          >
            <Box
              width={width}
              height={height}
              position={'relative'}
              borderRadius={'50%'}
            >
              {prizes.map((item, index: number) => {
                return (
                  <Box
                    key={index}
                    className={classes.wheelSector}
                    style={{
                      width: `${width / 2}px`,
                      height: `${height / 2}px`,
                      maxWidth: `${width / 2}px`,
                      maxHeight: `${height / 2}px`,
                      background: backgroundArray[index],
                      transform: `rotate(${index * eachItemDeg - 0.7}deg)`,
                      zIndex: prizes.length - index,
                      clipPath: `polygon(0 0, ${3.418 * prizes.length}% 0, 98% 98%, 0 ${3.418 * prizes.length}%)`,
                    }}
                  >
                    <Box className={classes.prizeSectorWrapper}>
                      <img
                        src={
                          getBaseUrlStatic() +
                          '/' +
                          item.bonus_wheel_prize.image
                        }
                        className={classes.prizePhoto}
                      />
                      <span className={classes.prizeName}>
                        {item.bonus_wheel_prize.name}
                      </span>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
          {!isFinishedSpin ? (
            <Box className={classes.spinContainer}>
              <button
                className={classes.spin}
                onClick={() => {
                  if (!isDisableSpinButton) {
                    getWinPrize();
                  }
                }}
              >
                <span className={classes.spinText}>SPIN!</span>
              </button>
            </Box>
          ) : (
            <Box className={classes.blockedSpinContainer}>
              <span className={classes.blockedSpinContainerSpinText}>
                Next Spin In
              </span>
              <BonusWheelCountDownTimer showSpin={showSpin} />
              <span className={classes.blockedSpinContainerGoodLuckText}>
                Good Luck!
              </span>
            </Box>
          )}
        </Box>
      </Box>

      <Box className={classes.winBox}>
        <span>{t('spinTheWheelAndWin')}</span>
        <span>1 BTC</span>
      </Box>

      {!isDisableSpinButton && !isFinishedSpin ? (
        <Box
          className={classes.spinButton}
          onClick={() => {
            if (!isDisableSpinButton) {
              getWinPrize();
            }
          }}
        >
          <span>SPIN</span>
        </Box>
      ) : (
        <Box className={classes.lockedSpinButton}>
          <LockIcon size={24} />
          <span>SPIN</span>
        </Box>
      )}

      {isOpenBonusWheelWinModal && (
        <BonusWheelWinModal
          isOpenBonusWheelWinModal={isOpenBonusWheelWinModal}
          onCloseBonusWheelWinModal={onCloseBonusWheelWinModal}
          wonPrize={wonPrize}
        />
      )}
    </Box>
  );
};

export default BonusWheelPage;
