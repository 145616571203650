import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import LiteCoinIcon from '../../api/ui/svgIcons/CryptoIcons/LiteCoinIcon';
import EthereumIcon from '../../api/ui/svgIcons/CryptoIcons/EthereumIcon';
import BitCoinIcon from '../../api/ui/svgIcons/CryptoIcons/BitCoinIcon';
import BitCoinCashIcon from '../../api/ui/svgIcons/CryptoIcons/BitCoinCashIcon';
import TronIcon from '../../api/ui/svgIcons/CryptoIcons/TronIcon';
import MasterCardIcon from '../../api/ui/svgIcons/CryptoIcons/MasterCardIcon';
import VisaIcon from '../../api/ui/svgIcons/CryptoIcons/VisaIcon';
import ApplePayIcon from '../../api/ui/svgIcons/CryptoIcons/ApplePayIcon';
import RippleIcon from '../../api/ui/svgIcons/CryptoIcons/RippleIcon';
import TetherIcon from '../../api/ui/svgIcons/CryptoIcons/TetherIcon';
import AirbetLogo from '../../api/ui/svgIcons/AirbetLogo';
import AgeIcon from '../../api/ui/svgIcons/AgeIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { AppRoutePath } from '../../util/appRoutePath';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { generalTermsOfServices } from '../../redux/actions/settingActions';
import { useEffect } from 'react';
import { getStaticPages } from '../../api/services/Policies.api';
import { useSnackbar } from 'notistack';
import LicenseIcon from '../../api/ui/svgIcons/License/LicenseIcon';

interface IMainFooter {
  isMobile: boolean;
  saveTermsOfServicesInGeneral: (data: any) => void;
  lang: string;
  generalTermsOfServices: any;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: isMobile ? '0px' : '24px',
    borderTop: '1px solid rgba(255, 255, 255, 0.05))',
    background: '#0D121D',
    color: '#fff',
    boxSizing: 'border-box',
  },
  coins: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0 24px 0',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexGrow: 1,
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      flex: 1,
    },
    '& svg': {
      cursor: 'pointer',
      '& path': {
        transition: 'fill 0.3s ease-in-out',
      },
      '&:hover': {
        '& path': {
          fill: '#fff !important',
        },
      },
    },
  },
  main: {
    display: 'flex',
    gap: isMobile ? '30px' : '96px',
    padding: '24px 16px',
    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxWidth: '320px',
    '& span': {
      color: '#4B5563',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    flexGrow: 1,
    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      flexGrow: 1,
      '& :first-Child': {
        color: '#fff',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'uppercase',
      },
      '& span': {
        color: '#9CA3AF',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        opacity: 0.92,
        cursor: 'pointer',
      },
    },
  },
  license: {
    display: 'flex',
    paddingTop: '24px',
    paddingLeft: '16px',
    alignItems: 'center',
    '& .iFrame': {
      border: 'none',
      overFlow: 'hidden',
    },
  },
  mobileDevice: {
    flexDirection: 'column',
    gap: '10px',
    '& div': {
      width: '100%',
    },
  },
}));

const MainFooter = ({
  isMobile,
  saveTermsOfServicesInGeneral,
  lang,
  generalTermsOfServices,
}: IMainFooter) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const openNewTab = (link: string) => {
    window.open(link, '_blank');
  };

  const openLiveSupport = () => {
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call('maximize');
    }
  };

  useEffect(() => {
    getStaticPages(lang)
      .then((res) => {
        saveTermsOfServicesInGeneral(res.data.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.data.data.message, {
          variant: 'error',
        });
      });
  }, [lang]);

  return (
    <Box className={classes.root}>
      {isMobile && <div></div>}
      <Box
        className={`${classes.coins} ${isMobile ? classes.mobileDevice : ''}`}
      >
        {isMobile ? (
          <>
            <div>
              <LiteCoinIcon />
              <EthereumIcon />
              <BitCoinIcon />
            </div>
            <div>
              <TronIcon />
              <MasterCardIcon />
              <VisaIcon />
              <ApplePayIcon />
            </div>
            <div>
              <RippleIcon />
              <BitCoinCashIcon />
              <TetherIcon />
            </div>
          </>
        ) : (
          <>
            <LiteCoinIcon />
            <EthereumIcon />
            <BitCoinIcon />
            <TronIcon />
            <MasterCardIcon />
            <VisaIcon />
            <ApplePayIcon />
            <RippleIcon />
            <BitCoinCashIcon />
            <TetherIcon />
          </>
        )}
      </Box>
      <Box
        className={`${classes.main} ${isMobile ? classes.mobileDevice : ''}`}
      >
        <Box className={classes.description}>
          <Box>
            <AirbetLogo />
          </Box>
          <span>{t('footer.Description')}</span>
        </Box>
        <Box
          className={`${classes.info} ${isMobile ? classes.mobileDevice : ''}`}
        >
          <Box>
            <div>{t('footer.TermsOfServices')}</div>
            {generalTermsOfServices.length &&
              generalTermsOfServices.map((item: any) => (
                <span
                  key={item.id}
                  onClick={() => navigate(`/policies/${item.slug}`)}
                >
                  {item.title}
                </span>
              ))}
          </Box>
          <Box>
            <div>{t('footer.AboutUs')}</div>
            {/*<span>{t('footer.Support')}</span>*/}
            <span onClick={() => navigate('/blog/')}>{t('footer.Blog')}</span>
            {/*    <span>{t('footer.PartnershipProgram')}</span>*/}
            <span onClick={() => openNewTab('https://airbet.crisp.help/en/')}>
              {t('footer.HelpCenter')}
            </span>
            <span onClick={() => openLiveSupport()}>
              {t('footer.LiveSupport')}
            </span>
            <span onClick={() => navigate(AppRoutePath.PROMOTION())}>
              {t('airbetMenu.promotions')}
            </span>
            <span onClick={() => openNewTab('https://www.airbetpartners.com/')}>
              {t('airbetMenu.affiliate')}
            </span>
          </Box>
          <Box>
            <div>{t('footer.Community')}</div>
            <span
              onClick={() =>
                openNewTab(
                  'https://www.facebook.com/people/Airbet/61557377945830/'
                )
              }
            >
              {t('footer.Facebook')}
            </span>
            <span onClick={() => openNewTab('https://twitter.com/Airbetio')}>
              {t('footer.Twitter')}
            </span>
            <span
              onClick={() => openNewTab('https://www.instagram.com/airbetio/')}
            >
              {t('footer.Instagram')}
            </span>
            <span onClick={() => openNewTab('https://t.me/airbetofficial')}>
              {t('footer.Telegram')}
            </span>
            {/*<span>{t('footer.Discord')}</span>*/}
          </Box>
        </Box>
      </Box>
      <Box className={classes.license}>
        <div style={{ cursor: 'pointer' }} onClick={() => navigate(``)}>
          <LicenseIcon />
        </div>
        {/*<iframe
          src="https://licensing.gaming-curacao.com/validator/?lh=545603b6ce2523a0a73434ec9caaea5d&template=tseal"
          width={150}
          height={50}
          className="iFrame"
        ></iframe>*/}
        <AgeIcon />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  lang: state.user.language,
  generalTermsOfServices: state.settings.termsOfServices,
});

const dispatchToProps = (dispatch: any) => ({
  saveTermsOfServicesInGeneral: bindActionCreators(
    generalTermsOfServices,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(MainFooter);
