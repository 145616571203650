import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import SuccessIcon from '../../../api/ui/svgIcons/NotificationIcons/SuccessIcon';
import WarningIcon from '../../../api/ui/svgIcons/NotificationIcons/WarningIcon';
import { useState } from 'react';
import { IUserInfo } from '../../../types/common.type';
import { useSnackbar } from 'notistack';
import { emailVerification } from '../../../api/services/Settings.api';
import EmailModal from './EmailModal';
import { isMobile } from 'react-device-detect';
import SettingsButton from '../SettingsButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    background: '#0D121D',
    border: '1px solid',
    borderColor: 'transparent',
    transition: 'background 0.3s ease-in-out, border-color 0.3s ease-in-out',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: isMobile ? 'space-between' : 'center',
    width: '100%',
    gap: '16px',
    overflow: 'hidden',
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '24px',
    fontWeight: 700,
    fontSize: '14px',
    overflow: 'hidden',
    maxWidth: '50%',
    '& div:first-child': {
      fontSize: '12px',
      color: '#6B7280',
      lineHeight: '16px',
      fontWeight: 600,
    },
  },

  verify: {
    display: 'flex',
    gap: '8px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    alignItems: 'center',
    flexWrap: 'wrap',

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  buttons: {
    display: 'flex',
    width: isMobile ? '100%' : 'auto',
    justifyContent: 'space-between',
    gap: '24px',
    '& div': {
      flex: 1,
    },
  },
}));

interface IEmailInformation {
  playerInfo: IUserInfo;
  onChangeEmail: (email: string) => void;
}

const EmailInformation = ({ playerInfo, onChangeEmail }: IEmailInformation) => {
  const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean | string>(
    playerInfo.player.email_verified
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const havePassword = playerInfo.player.have_password;

  const setVerifiedFalse = () => {
    setIsVerified(false);
  };
  const openEmailModalHandler = () => {
    if (havePassword === 1) {
      setOpenEmailModal(true);
    } else {
      enqueueSnackbar(t('setPasswordError'), {
        variant: 'error',
      });
    }
  };

  const closeEmailModalHandler = () => {
    setOpenEmailModal(false);
  };

  const emailVerificationRequest = () => {
    if (playerInfo.player.email === '') {
      enqueueSnackbar(t('setEmailFirst'), {
        variant: 'error',
      });
      return;
    }
    emailVerification()
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar(res.data.data.message, {
            variant: 'success',
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.data.data.message, {
          variant: 'error',
        });
      });
  };

  return (
    <Box className={classes.root}>
      {openEmailModal && (
        <EmailModal
          isOpen={openEmailModal}
          onClose={closeEmailModalHandler}
          email={playerInfo.player.email}
          onChangeEmail={onChangeEmail}
          setVerifiedFalse={setVerifiedFalse}
        />
      )}
      <Box className={classes.container}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          <Box className={classes.contact}>
            <div>{t('email')}</div>
            <div>{playerInfo.player.email}</div>
          </Box>
          <Box className={classes.verify}>
            <div>
              {isVerified ? (
                <SuccessIcon size={16} />
              ) : (
                <WarningIcon size={16} fill={'#9197A3'} color={'#0D121D'} />
              )}
            </div>
            <div
              style={{
                color: isVerified ? '#84CC16' : '#9197A3',
              }}
            >
              {isVerified ? t('verified') : t('requiresVerification')}
            </div>
          </Box>
        </div>
        <Box>
          <Box className={classes.buttons}>
            <SettingsButton
              action={openEmailModalHandler}
              buttonText={t('edit')}
            />
            {!isVerified && (
              <SettingsButton
                action={emailVerificationRequest}
                buttonText={t('verify')}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default EmailInformation;
