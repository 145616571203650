import React, { useEffect } from 'react';
import { initialize } from '../../api/services/BetBy.api';

const Example = () => {
  useEffect(() => {
    let bt = '';
    const script = document.createElement('script');
    script.src = 'https://ui.invisiblesport.com/bt-renderer.min.js';
    script.async = true;

    initialize({
      lang: 'en',
      currency: 'USDT',
    })
      .then((resp) => {
        script.onload = () => {
          bt = new window.BTRenderer().initialize({
            brand_id: resp.data.data.payload.iss,
            token: resp.data.data.jwt_token,
            lang: 'en',
            target: document.getElementById('widget'),
            themeName: 'default',
            widgetName: 'promo',
            widgetParams: {
              placeholder: 'operator_page3',
              onSportClick: (arg) => {
                console.log(arg);
              },
              onBannerClick: (arg) => {
                console.log(arg);
              },
            },
          });
        };
        document.body.appendChild(script);
      })
      .catch(() => {})
      .finally(() => {});

    return () => {
      if (bt) {
        document.body.removeChild(script);
        bt.kill();
      }
    };
  }, []);

  return (
    <div
      style={{
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      <div id="widget"></div>
    </div>
  );
};

export default Example;
