import { Box, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';

interface ICustomButton {
  children: ReactNode;
  style?: object;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: isMobile ? '7px 16px' : '7px 23px',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    background: '#0D121D',
    boxShadow:
      '0px 2px 0px 0px #030712, 0px 8px 12px 0px #1F2937 inset, 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset',
    color: ' #FFF',
    fontSize: isMobile ? '12px' : '14px',
    fontWeight: 700,
    lineHeight: '24px',
    transition: 'background 0.3s, box-shadow 0.3s',
    textTransform: 'none',
    height: isMobile ? '32px' : '',
    '&:hover': {
      background: '#111827',
      boxShadow:
        '0px 2px 0px 0px #030712, 0px 8px 12px 0px #1F2937 inset, 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset',
    },
    '&:active': {
      background: '#0D121D',
      boxShadow:
        '0px 2px 0px 0px #030712, 0px -8px 12px 0px #1F2937 inset, 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset',
    },
  },
  loader: {
    position: 'absolute',
    color: '#fff',
    height: '16px',
  },
}));
const CustomSecondaryButton = ({
  children,
  style,
  onClick,
  disabled = false,
  loading = false,
}: ICustomButton) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      position="relative"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        className={classes.root}
        disableRipple
        style={style}
        onClick={() => onClick && onClick()}
        disabled={disabled || loading}
        sx={{
          opacity: disabled || loading ? '0.3' : '',
        }}
      >
        <div
          style={{
            opacity: loading ? '0' : '',
          }}
        >
          {children}
        </div>
      </Button>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress size={16} color="inherit" />
        </div>
      )}
    </Box>
  );
};

export default CustomSecondaryButton;
