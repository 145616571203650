import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Rakeback from './Rakeback';
import Cashback from './Cashback';
import BonusDashboardButton from './BonusDashboardButton';
import { getCashBacks, getRakeBacks } from '../../api/services/Bonus.api';
import { IRakeBackOrCashBack } from '../../types/Bonus.type';
import HeaderButtonContainer from '../common/ButtonContainer/HeaderButtonContainer';
import GameShowIcon from '../../api/ui/svgIcons/GameShowIcon';
import { isMobile } from 'react-device-detect';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface BonusPopoverProps {}

const useStyles = makeStyles(() => ({
  newNotification: {
    background: '#84CC16',
    width: '6px',
    height: '6px',
    borderRadius: '100%',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
}));

const BonusPopover: React.FC<BonusPopoverProps> = () => {
  const classes = useStyles();
  const [rakeBacks, setRakeBacks] = useState<IRakeBackOrCashBack[]>([]);
  const [cashBacks, setCashBacks] = useState<IRakeBackOrCashBack[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'bonus-popover' : undefined;

  const [activeStatus, setActiveStatus] = useState<boolean>(false);

  useEffect(() => {
    initRakeBack();
    initCashBack();
  }, []);

  useEffect(() => {
    let hasActiveStatus = false;
    rakeBacks.forEach((item: any) => {
      if (!item['is_claimed']) {
        const endDate = new Date(item.created_at);
        const now = new Date(item.finish_at);

        const difference = endDate.getTime() - now.getTime();

        if (difference > 0) {
          hasActiveStatus = true;
        }
      }
    });

    cashBacks.forEach((item: any) => {
      if (!item['is_claimed']) {
        const endDate = new Date(item.created_at);
        const now = new Date(item.finish_at);

        const difference = endDate.getTime() - now.getTime();

        if (difference > 0) {
          hasActiveStatus = true;
        }
      }
    });

    setActiveStatus(hasActiveStatus);
  }, [rakeBacks, cashBacks]);

  const openBonusPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeBonusPopover = () => {
    setAnchorEl(null);
  };

  const initRakeBack = () => {
    getRakeBacks()
      .then((resp: any) => {
        if (resp.data.data) {
          setRakeBacks(resp.data.data);
        }
      })
      .catch(() => {});
  };

  const initCashBack = () => {
    getCashBacks()
      .then((resp: any) => {
        if (resp.data.data) {
          setCashBacks(resp.data.data);
        }
      })
      .catch(() => {});
  };

  return (
    <Box position={'relative'}>
      <HeaderButtonContainer
        onClick={(event) => openBonusPopover(event as any)}
      >
        <GameShowIcon size={isMobile ? 16 : 20} fill={'#FFF'} />
        {activeStatus && <Box className={classes.newNotification} />}
      </HeaderButtonContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeBonusPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: '#111827',
            border: '1px solid #1F2937',
            marginTop: '8px',
            width: '272px',
            borderRadius: '8px',
            boxShadow: '0px 16px 32px 0px #0000001A',
          },
        }}
      >
        <div
          style={{
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              maxHeight: '380px',
              overflow: 'auto',
            }}
          >
            {rakeBacks.map(
              (rakeBackInfo: IRakeBackOrCashBack, index: number) => {
                return (
                  <Rakeback
                    rakeBackInfo={rakeBackInfo}
                    key={index}
                    reloadData={() => initRakeBack()}
                  />
                );
              }
            )}
            {cashBacks.map(
              (cashBackInfo: IRakeBackOrCashBack, index: number) => {
                return (
                  <Cashback
                    cashBackInfo={cashBackInfo}
                    key={index}
                    reloadData={() => initCashBack()}
                  />
                );
              }
            )}
          </Box>
          <BonusDashboardButton setAnchorEl={setAnchorEl} />
        </div>
      </Popover>
    </Box>
  );
};

export default BonusPopover;
