import Notification from '../../components/Notification/Notification';
import { useEffect, useState } from 'react';
import { socket, useNotificationUpdates } from '../../api/airbet.socket';
import { INotification } from '../../types/common.type';
import { getLocalStorageValue } from '../../util/appUtil';

interface INotificationContainer {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const NotificationContainer = ({
  isOpen,
  setIsOpen,
}: INotificationContainer) => {
  const socketNotification = useNotificationUpdates();
  const [data, setData] = useState<INotification[]>([]);

  useEffect(() => {
    const userId = getLocalStorageValue('userId', 0);
    const token = getLocalStorageValue('token', '');

    setTimeout(() => {
      socket.emit(
        'getNotification',
        { token: token, userId: userId },
        () => {}
      );
    }, 500);

    return () => {
      socket.off('getNotification');
    };
  }, []);

  useEffect(() => {
    if (socketNotification) {
      if (Array.isArray(socketNotification)) {
        setData(socketNotification);
      } else {
        setData((prev: INotification[]) => [
          socketNotification,
          ...prev.filter((item) => item.id !== socketNotification.id),
        ]);
      }
    }
  }, [socketNotification]);

  return <Notification isOpen={isOpen} setIsOpen={setIsOpen} data={data} />;
};

export default NotificationContainer;
