import { ISvgIcon } from '../../../types/common.type';

const AirbetDepositIcon = ({ size = 16, className }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="8" cy="8" r="8" fill="#374151" />
      <path
        d="M10.0544 4.84068C9.47704 4.67075 8.88341 4.65036 8.28663 4.67545C7.74296 4.69846 7.21033 4.77062 6.70609 4.99492C5.94315 5.33426 5.54775 5.94549 5.36267 6.75174H5.51252C5.95893 6.75174 6.40585 6.74861 6.85226 6.75436C6.95426 6.75593 7.00789 6.72874 7.04785 6.63149C7.17352 6.3277 7.4075 6.13058 7.71982 6.04013C8.15939 5.91307 8.60895 5.90523 9.05745 5.98627C9.29354 6.02915 9.51753 6.11385 9.63583 6.34496C9.73836 6.54469 9.71733 6.75697 9.67264 6.96925C9.63478 7.14912 9.52121 7.25212 9.34822 7.28454C9.18996 7.31382 9.02854 7.32898 8.86764 7.34362C8.15834 7.40846 7.44746 7.46022 6.73921 7.53708C6.30385 7.58466 5.88216 7.70074 5.49885 7.92296C4.97989 8.2236 4.7417 8.69313 4.68124 9.2709C4.57818 10.2565 5.03668 10.9503 5.97996 11.2018C6.62459 11.3739 7.27605 11.3754 7.92541 11.2159C8.35709 11.1098 8.73041 10.9059 9.00067 10.5441C9.06955 10.4526 9.12581 10.3517 9.19258 10.2481V11.228C9.6006 11.1976 10.0044 11.16 10.4093 11.1412C10.5392 11.1349 10.5754 11.0863 10.5949 10.9712C10.7568 10.0249 10.9272 9.07953 11.0891 8.13315C11.1733 7.64061 11.2569 7.14807 11.3194 6.6524C11.3641 6.29738 11.3042 5.95019 11.1296 5.63125C10.8951 5.2025 10.5076 4.97349 10.0538 4.84016L10.0544 4.84068ZM8.78982 9.69546C8.45068 9.98095 8.04687 10.0981 7.61624 10.109C7.36176 10.1153 7.09938 10.0897 6.85278 10.0306C6.53625 9.9548 6.33908 9.73938 6.32909 9.399C6.3191 9.0628 6.40638 8.75483 6.73606 8.6032C6.94112 8.50856 7.17247 8.44896 7.39804 8.42334C7.94013 8.36112 8.48591 8.33131 9.03011 8.28164C9.16735 8.26909 9.30195 8.23197 9.4497 8.20373C9.37556 8.76215 9.25726 9.30331 8.79087 9.69598L8.78982 9.69546Z"
        fill="white"
      />
    </svg>
  );
};

export default AirbetDepositIcon;
