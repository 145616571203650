import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'center',

    '& .MuiOutlinedInput-input': {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
      display: 'flex',
    },

    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '8px',
      border: '1px solid #0D121D',
      background: '#0D121D',
      transition: 'border-color 0.3s ease-in-out',

      '&:hover': {
        borderColor: '#1F2937',
      },

      '& fieldset': {
        border: 'none',
      },

      '& .MuiSvgIcon-root': {
        color: '#4B5563',
      },
    },
  },
  label: {
    padding: '0px 16px',
    color: '#9CA3AF',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'uppercase',
    boxSizing: 'border-box',
  },
  paper: {
    borderRadius: '8px',
    border: '1px solid #1F2937',
    background: '#111827',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.10)',
    maxHeight: '250px',
    textAlign: 'center',
    padding: '0 8px',
    boxSizing: 'border-box',
    marginTop: '8px',

    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      boxSizing: 'border-box',
    },
    '& .MuiButtonBase-root': {
      boxSizing: 'border-box',
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      alignItem: 'center',
      borderRadius: '4px',
      transition: 'background 0.3s ease-in-out',
      background: '#111827',
      boxSizing: 'border-box',

      '&:hover': {
        background: '#0D121D !important',
      },
      '&.Mui-selected': {
        backgroundColor: '#0D121D !important',
      },
    },
  },
}));

interface ICustomSelect {
  label?: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  data: any[];
  separated: boolean;
}

const CustomSettingsSelect = ({
  label,
  value,
  onChange,
  data = [],
  separated,
}: ICustomSelect) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {label && <span className={classes.label}>{label}</span>}
      <Select
        value={value}
        displayEmpty
        renderValue={(selectedValue: string) => {
          const selectedItem = data.find((item) => item.value == selectedValue);
          return selectedItem
            ? separated
              ? selectedItem.value
              : selectedItem.renderer()
            : label;
        }}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            className: classes.paper,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {data.map((item: any, index: number) => {
          return (
            <MenuItem value={item.value} key={index}>
              {item.renderer()}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default CustomSettingsSelect;
