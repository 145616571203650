import { IPromotionDataType } from "../PromotionContainer";
import i18n from "i18next";

export const getNameByType = (promotion: IPromotionDataType) => {
  if (new Date(promotion.start_date) > new Date()) {
    return i18n.t("upcoming");
  }

  if (new Date(promotion.end_date) > new Date()) {
    return i18n.t("inprogress");
  }

  return i18n.t("archived");
};

export const getStyleByType = (promotion: IPromotionDataType) => {
  if (new Date(promotion.start_date) > new Date()) {
    return {
      background: "#1D4ED8",
      color: "#fff",
    };
  }

  if (new Date(promotion.end_date) > new Date()) {
    return {
      background: "#65A30D",
      color: "#fff",
    };
  }

  return {
    background: "#030712",
    color: "#FFFFFF80",
  };
};

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

const getFormattedDate = (date: Date) => {
  return (
    [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("/") +
    ", " +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(":")
  );
};

export const getFormattedDateWithText = (promotion: IPromotionDataType) => {
  if (new Date(promotion.start_date) > new Date()) {
    return `${i18n.t("startsAt")} ${getFormattedDate(
      new Date(promotion.start_date)
    )}`;
  }

  if (new Date(promotion.end_date) > new Date()) {
    return `${i18n.t("endsAT")} ${getFormattedDate(
      new Date(promotion.end_date)
    )}`;
  }

  return `${i18n.t("endedAt")} ${getFormattedDate(
    new Date(promotion.end_date)
  )}`;
};
