import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import WarningIcon from '../../api/ui/svgIcons/NotificationIcons/WarningIcon';
import moment from 'moment';
import SuccessIcon from '../../api/ui/svgIcons/NotificationIcons/SuccessIcon';
import InfoIcon from '../../api/ui/svgIcons/NotificationIcons/InfoIcon';
import AccountIcon from '../../api/ui/svgIcons/NotificationIcons/AccountIcon';
import FinancialIcon from '../../api/ui/svgIcons/NotificationIcons/FinancialIcon';
import CustomIcon from '../../api/ui/svgIcons/NotificationIcons/AirbetIcon';

interface INotificationItem {
  type: string;
  title: string;
  message: string;
  createDt: string;
}

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    color: '#fff',
    height: 'fit-content',
    padding: '12px 16px',
    borderRadius: '12px',
    background: '#111827',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    cursor: 'pointer',
    transition: 'background 0.2s ease-in-out',
    '&:hover': {
      background: '#1F2937',
    },
  },
  icon: {},
  title: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    opacity: 0.92,
  },
  message: {
    color: '#9CA3AF',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    opacity: 0.92,
    maxHeight: '48px',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    webkitBoxOrient: 'vertical',
  },
  footer: {
    color: '#6B7280',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    opacity: 0.92,
  },
}));

const NotificationItem = ({
  type,
  title,
  message,
  createDt,
}: INotificationItem) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.icon}>
        {type === 'custom' && <CustomIcon size={24} />}
        {type === 'financial' && <FinancialIcon size={24} />}
        {type === 'account' && <AccountIcon size={24} />}
        {type === 'info' && <InfoIcon size={24} />}
        {type === 'success' && <SuccessIcon size={24} />}
        {type === 'warning' && (
          <WarningIcon size={24} fill={'#3B4CC7'} color={'#fff'} />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Box className={classes.title}>{title}</Box>
          <Box className={classes.message}>{message}</Box>
        </Box>
        <Box className={classes.footer}>
          {moment(new Date(createDt)).fromNow()}
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationItem;
