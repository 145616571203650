import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import {
  SidebarListLevel1,
  SidebarListLevel2,
  SidebarSportItems,
} from '../../util/SidebarListHelper';
import { IAirbetMenuItem } from '../../types/common.type';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowIcon from '../../api/ui/svgIcons/ArrowIcon';
import LanguageSelectBox from '../LanguageSelectBox/LanguageSelectBox';
import { isMobile } from 'react-device-detect';
import AirbetLogo from '../../api/ui/svgIcons/AirbetLogo';
import CloseIcon from '@mui/icons-material/Close';
import BonusWheelBanner from '../BonusWheelPage/BonusWheelBanner';
import LeaderBoardsBannerContainer from '../../containers/LeaderBoards/LeaderBoardsBannerContainer';
import { AppRoutePath } from '../../util/appRoutePath';
import { bindActionCreators } from 'redux';
import { changeSideBarOpen } from '../../redux/actions/userActions';

interface IAppSidebar {
  isSidebarOpen: boolean;
  isUserAuthenticated: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  currentSportPageUrl: string;
  changeSideBarFun: (isOpen: boolean) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    borderRight: '1px solid rgba(255, 255, 255, 0.05)',
    background: '#0D121D',
    boxShadow: '2px 0px 4px 0px rgba(3, 7, 18, 0.50)',
    width: '64px',
    minWidth: '64px',
    height: '100%',
    transition: 'width 0.3s, min-width 0.3s',
    overflow: 'auto',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  menuItemsContainer: {
    width: '100%',
    minWidth: '0px',
    minHeight: '0px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '12px 12px',
    overflow: 'auto',
    height: '100%',
  },
  menuItems: {
    width: '28px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    paddingLeft: '12px',
    boxSizing: 'border-box',
    '& path': {
      fill: '#9CA3AF',
    },
  },
  selectedMenuItem: {
    background: '#111827',
    '& path': {
      fill: 'white',
    },
    '& .MuiBox-root': {
      color: '#fff !important',
    },
  },
  menuItemsText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#9CA3AF',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: '#fff',
    },
  },
  bonusWheel: {
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    background: 'linear-gradient(92deg, #392355 0.37%, #270355 98.56%)',
    cursor: 'pointer',
    width: '100%',
    height: '40px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '0px',
    minHeight: '42px',
    boxSizing: 'border-box',
  },
  bonusWheelDescriptionContainer: {
    textTransform: 'uppercase',
    display: 'flex',
    marginLeft: '8px',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: '8px',
    opacity: 1,
    animation: '$bonusWheelAnimation 0.2s ease',
  },
  bonusWheelDescriptionLeft: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
  },
  bonusWheelDescriptionRight: {
    color: '#FFFFFF80',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
  },
  '@keyframes bonusWheelAnimation': {
    '0%': {
      opacity: 0,
    },
    '99%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  disableMenuItem: {
    '& path': {
      fill: '#4B5563 !important',
    },
    '& .MuiBox-root': {
      color: '#4B5563 !important',
    },
    cursor: 'default',
  },
  listItemHeaderText: {
    color: '#9CA3AF',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
    transition: 'color 0.3s ease-in-out',
  },
  listItemHeaderContent: {
    marginLeft: '12px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '& path': {
      fill: '#9CA3AF',
      transition: 'fill 0.3s ease-in-out',
    },
    '&:hover': {
      '& span': {
        color: '#fff',
      },
      '& path': {
        fill: '#fff',
      },
    },
  },
  sportIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  sportSoon: {
    color: '#FFF',
    fontSize: '9px',
    fontWeight: 700,
    lineHeight: '12px',
    textTransform: 'uppercase',
    background: '#374151',
    padding: '0px 4px',
    borderRadius: '6px 2px',
  },
  appSideMobileHeader: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderBottom: '1px solid #ffffff0d',
  },
}));

const AppSidebar = ({
  isSidebarOpen,
  isUserAuthenticated,
  setIsSidebarOpen,
  currentSportPageUrl,
  changeSideBarFun,
}: IAppSidebar) => {
  const classes = useStyles({ isSidebarOpen });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('/');
  const [isCasinoOpen, setIsCasinoOpen] = useState<boolean>(true);
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(true);
  const sideBarRef = useRef<any>();
  const location = useLocation();

  const [isSportOpen, setIsSportOpen] = useState(false);

  useEffect(() => {
    if (!isSidebarOpen) {
      setIsSportOpen(true);
      setIsMoreOpen(true);
      setIsCasinoOpen(true);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isSidebarOpen && isMobile && sideBarRef && sideBarRef.current) {
      sideBarRef.current.scrollTop = 0;
    }
    if (location.pathname.includes('sport')) {
      changeSideBarFun(isSidebarOpen);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    const currentUrl = window.location.href;
    const mainUrl: string = currentUrl.split('/')[3];
    if (mainUrl.includes('bt-path')) {
      setSelectedMenuItem(mainUrl.split('bt-path=%')[1]);
    } else {
      if (!mainUrl.includes('?')) {
        setSelectedMenuItem('/' + mainUrl);
      } else {
        setSelectedMenuItem('/' + mainUrl.split('?')[0]);
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (currentSportPageUrl) {
      setSelectedMenuItem('2F' + currentSportPageUrl.replace('/', ''));
    }
  }, [currentSportPageUrl]);

  const openLiveSupport = () => {
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call('maximize');
    }
  };

  return (
    <div
      className={classes.root}
      style={{
        minWidth: isSidebarOpen
          ? isMobile
            ? '100vw'
            : '241px'
          : isMobile
            ? '0px'
            : '64px',
        display: isMobile && !isSidebarOpen ? 'none' : '',
        position: isMobile ? 'absolute' : 'static',
        zIndex: 9,
        top: '0',
        paddingBottom: isMobile ? '61px' : '',
      }}
    >
      {isMobile && (
        <Box className={classes.appSideMobileHeader}>
          <AirbetLogo width={62} height={16} />
          <CloseIcon
            style={{
              color: 'rgb(88, 100, 124)',
              width: '20px',
              height: '20px',
            }}
            onClick={() => setIsSidebarOpen(false)}
          />
        </Box>
      )}
      <div className={classes.menuItemsContainer} ref={sideBarRef}>
        <BonusWheelBanner
          isUserAuthenticated={isUserAuthenticated}
          isSidebarOpen={isSidebarOpen}
        />

        <LeaderBoardsBannerContainer
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        <Box
          gap={'4px'}
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          style={{
            height: isCasinoOpen ? '436px' : '40px',
            minHeight: isCasinoOpen ? '436px' : '40px',
            transition: 'height 0.3s ease-in-out, min-height 0.3s ease-in-out',
            overflow: 'hidden',
          }}
        >
          {isSidebarOpen && (
            <Box
              display={'flex'}
              width={'100%'}
              alignItems={'center'}
              gap={'8px'}
              className={classes.listItemHeaderContent}
              onClick={() => setIsCasinoOpen(!isCasinoOpen)}
              style={{
                minWidth: '0px',
                boxSizing: 'border-box',
                width: 'auto',
              }}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: isCasinoOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
              >
                <ArrowIcon />
              </Box>
              <span className={classes.listItemHeaderText}>
                {t('header.casino')}
              </span>
            </Box>
          )}

          {SidebarListLevel1.map((menuItem: IAirbetMenuItem, index: number) => {
            return (
              <Tooltip
                title={isSidebarOpen ? '' : t(menuItem.text)}
                key={index}
                arrow={true}
                placement="right-end"
              >
                <div
                  key={index}
                  className={`${classes.menuItems} ${menuItem.needAuth && !isUserAuthenticated ? classes.disableMenuItem : menuItem.link === selectedMenuItem && classes.selectedMenuItem}`}
                  onClick={() => {
                    if (menuItem.needAuth) {
                      if (isUserAuthenticated) {
                        navigate(menuItem.link);
                        setSelectedMenuItem(menuItem.link);
                        isMobile && setIsSidebarOpen(false);
                      }
                    } else {
                      navigate(menuItem.link);
                      setSelectedMenuItem(menuItem.link);
                      isMobile && setIsSidebarOpen(false);
                    }
                  }}
                  style={{
                    width: isSidebarOpen
                      ? isMobile
                        ? '100%'
                        : '204px'
                      : '28px',
                    minHeight: '32px',
                    opacity: isCasinoOpen ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                >
                  {<menuItem.icon />}
                  {isSidebarOpen && (
                    <Box marginLeft={'8px'} className={classes.menuItemsText}>
                      {t(menuItem.text)}
                    </Box>
                  )}
                </div>
              </Tooltip>
            );
          })}
        </Box>

        {isSidebarOpen && (
          <Box
            style={{ background: '#FFFFFF0D' }}
            height={'1px'}
            width={'100%'}
            marginBottom={'20px'}
          />
        )}

        {isSidebarOpen && (
          <Box
            gap={'4px'}
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            style={{
              height: isSportOpen ? '360px' : '35px',
              minHeight: isSportOpen ? '360px' : '35px',
              transition:
                'height 0.3s ease-in-out, min-height 0.3s ease-in-out',
              overflow: 'hidden',
            }}
          >
            {isSidebarOpen && (
              <Box
                display={'flex'}
                width={'100%'}
                alignItems={'center'}
                gap={'8px'}
                className={classes.listItemHeaderContent}
                onClick={() => setIsSportOpen(!isSportOpen)}
                style={{
                  minWidth: '0px',
                  boxSizing: 'border-box',
                  width: 'auto',
                }}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: isSportOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                >
                  <ArrowIcon />
                </Box>
                <span className={classes.listItemHeaderText}>
                  {t('airbetMenu.sports')}
                </span>
              </Box>
            )}
            {SidebarSportItems.map(
              (menuItem: IAirbetMenuItem, index: number) => {
                return (
                  <Tooltip
                    title={isSidebarOpen ? '' : t(menuItem.text)}
                    key={index}
                    arrow={true}
                    placement="right-end"
                  >
                    <div
                      key={index}
                      className={`${classes.menuItems} ${menuItem.hidden ? classes.disableMenuItem : menuItem.link === selectedMenuItem && classes.selectedMenuItem}`}
                      onClick={() => {
                        setSelectedMenuItem(menuItem.link);
                        navigate(
                          AppRoutePath.SPORT() + `?bt-path=%${menuItem.link}`
                        );
                      }}
                      style={{
                        width: isSidebarOpen
                          ? isMobile
                            ? '100%'
                            : '204px'
                          : '28px',
                        minHeight: '32px',
                        opacity: isSportOpen ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out',
                      }}
                    >
                      {<menuItem.icon />}
                      {isSidebarOpen && (
                        <Box
                          marginLeft={'8px'}
                          className={classes.menuItemsText}
                        >
                          {t(menuItem.text)}
                        </Box>
                      )}
                    </div>
                  </Tooltip>
                );
              }
            )}
          </Box>
        )}

        <Box
          style={{ background: '#FFFFFF0D' }}
          height={'1px'}
          width={'100%'}
          marginBottom={'20px'}
        />
        <Box
          gap={'4px'}
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          style={{
            height: isMoreOpen ? '200px' : '35px',
            minHeight: isMoreOpen ? '200px' : '35px',
            transition: 'height 0.3s ease-in-out, min-height 0.3s ease-in-out',
            overflow: 'hidden',
          }}
        >
          {isSidebarOpen && (
            <Box
              display={'flex'}
              width={'100%'}
              alignItems={'center'}
              gap={'8px'}
              className={classes.listItemHeaderContent}
              onClick={() => setIsMoreOpen(!isMoreOpen)}
              style={{
                minWidth: '0px',
                boxSizing: 'border-box',
                width: 'auto',
              }}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: isMoreOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
              >
                <ArrowIcon />
              </Box>
              <span className={classes.listItemHeaderText}>{t('more')}</span>
            </Box>
          )}
          {SidebarListLevel2.map((menuItem: IAirbetMenuItem, index: number) => {
            return (
              <Tooltip
                title={isSidebarOpen ? '' : t(menuItem.text)}
                key={index}
                arrow={true}
                placement="right-end"
              >
                <div
                  key={index}
                  className={`${classes.menuItems} ${menuItem.hidden ? classes.disableMenuItem : menuItem.link === selectedMenuItem && classes.selectedMenuItem}`}
                  onClick={() => {
                    if (menuItem.text === 'airbetMenu.affiliate') {
                      window.open(menuItem.link, '_blank');
                    } else if (menuItem.text === 'airbetMenu.liveSupport') {
                      openLiveSupport();
                    } else {
                      setSelectedMenuItem(menuItem.link);
                      navigate(menuItem.link);
                      isMobile && setIsSidebarOpen(false);
                    }
                  }}
                  style={{
                    width: isSidebarOpen
                      ? isMobile
                        ? '100%'
                        : '204px'
                      : '28px',
                    minHeight: '32px',
                    opacity: isMoreOpen ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                >
                  {<menuItem.icon />}
                  {isSidebarOpen && (
                    <Box marginLeft={'8px'} className={classes.menuItemsText}>
                      {t(menuItem.text)}
                    </Box>
                  )}
                </div>
              </Tooltip>
            );
          })}
        </Box>
      </div>
      {isSidebarOpen && (
        <Box padding={'0px 12px'} minHeight={'57px'} height={'57px'}>
          <LanguageSelectBox />
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: state.user.isUserAuthorized,
  currentSportPageUrl: state.sports.currentSportPageUrl,
});

const dispatchToProps = (dispatch: any) => ({
  changeSideBarFun: bindActionCreators(changeSideBarOpen, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(AppSidebar);
