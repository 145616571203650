import CustomGameFilterSelect from '../../common/Game/CustomGameFilterSelect';
import { useTranslation } from 'react-i18next';
import { Box, SelectChangeEvent } from '@mui/material';
import ShowIcon from '../../../api/ui/svgIcons/ShowIcon';
import HideIcon from '../../../api/ui/svgIcons/HideIcon';
import { makeStyles } from '@mui/styles';

interface ICustomShowOrHideSelect {
  selectedBlockGame: string;
  onChangeBlockedGames: (selectedItem: string) => void;
}

const useStyles = makeStyles(() => ({
  renderItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& span': {
      color: '#9CA3AF',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
  selectedItem: {
    '& span': {
      color: '#fff',
      transition: 'color 0.3 ease-int-out',
    },
    '& path': {
      fill: '#84CC16',
      transition: 'fill 0.3 ease-int-out',
    },
  },
}));

const CustomShowOrHideSelect = ({
  selectedBlockGame,
  onChangeBlockedGames,
}: ICustomShowOrHideSelect) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const selectData = [
    {
      renderer: () => (
        <Box
          className={`${classes.renderItem} ${selectedBlockGame === 'show' && classes.selectedItem}`}
        >
          <ShowIcon /> <span>{t('show')}</span>
        </Box>
      ),
      value: 'show',
    },
    {
      renderer: () => (
        <Box
          className={`${classes.renderItem} ${selectedBlockGame === 'hide' && classes.selectedItem}`}
        >
          <HideIcon /> <span>{t('hide')}</span>
        </Box>
      ),
      value: 'hide',
    },
  ];

  return (
    <CustomGameFilterSelect
      value={selectedBlockGame}
      onChange={(event: SelectChangeEvent) => {
        onChangeBlockedGames(event.target.value);
      }}
      data={selectData}
      label={t('blockedGames')}
      secondLabel={t(selectedBlockGame)}
      minWith={200}
    />
  );
};

export default CustomShowOrHideSelect;
