import CustomModal from '../common/Modal/CustomModal';
import { isMobile } from 'react-device-detect';
import React, { ChangeEvent, FC, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import CustomInput from '../common/Inputs/CustomInput';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { enqueueSnackbar } from 'notistack';
import { CheckEmailValidation } from '../../util/appUtil';

const useStyles = makeStyles(() => ({
  root: {
    width: isMobile ? '100%' : '416px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    border: '1px solid rgb(31, 41, 55)',
    background: 'rgb(17, 24, 39)',
    boxShadow: 'rgb(0 0 0 / 50%)',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    boxSizing: 'border-box',
    '& span': {
      lineHeight: '24px',
      fontWeight: 600,
      fontSize: '16px',
      color: '#FFF',
    },
  },
  emailPart: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    flexDirection: 'column',
    gap: '5px',
    width: '100%',
    boxSizing: 'border-box',
    borderTop: '1px solid rgb(32 41 58)',
    borderBottom: '1px solid rgb(32 41 58)',
  },
}));

interface ResetPasswordModalProps {
  setIsOpenResetPasswordModal: (isOpen: boolean) => void;
  isOpenResetPasswordModal: boolean;
  onResetPassword: (email: string) => void;
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  setIsOpenResetPasswordModal,
  isOpenResetPasswordModal,
  onResetPassword,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');

  const handleResetPassword = () => {
    if (email.trim() === '') {
      enqueueSnackbar(t('emailMustNotBeEmpty'), { variant: 'error' });
    } else if (!CheckEmailValidation(email)) {
      enqueueSnackbar(t('emailFormatIsInvalid'), { variant: 'error' });
    } else {
      onResetPassword(email);
    }
  };

  return (
    <CustomModal
      open={isOpenResetPasswordModal}
      onClose={() => setIsOpenResetPasswordModal(false)}
      customStyle={{ width: isMobile ? '85%' : '' }}
    >
      <Box className={classes.root}>
        <Box className={classes.header}>
          <span>{t('resetPassword')}</span>
          <CloseIcon
            sx={{ color: 'rgb(88, 100, 124)', cursor: 'pointer' }}
            fontSize={'small'}
            onClick={() => setIsOpenResetPasswordModal(false)}
          />
        </Box>

        <Box className={classes.emailPart}>
          <Box style={{ width: '100%', boxSizing: 'border-box' }}>
            <CustomInput
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
              label={t('email')}
              placeholder={t('enterYourEmail')}
              onKeyPress={handleResetPassword}
              isGap
            />
          </Box>
        </Box>

        <Box
          width="100%"
          style={{
            padding: '16px',
            boxSizing: 'border-box',
          }}
        >
          <CustomPrimaryButton
            style={{ width: '100%', textTransform: 'uppercase' }}
            onClick={handleResetPassword}
            // loading={loading}
          >
            {t('resetPassword')}
          </CustomPrimaryButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ResetPasswordModal;
