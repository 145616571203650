import { IAction } from '../../types/common.type';
import { CHANGE_SPORT_PAGE_URL } from '../constants/sportsConstants';

const initialState = {
  currentSportPageUrl: '',
};

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case CHANGE_SPORT_PAGE_URL:
      return {
        ...state,
        currentSportPageUrl: action.payload,
      };
    default:
      return state;
  }
}
