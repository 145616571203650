import { ISvgIcon } from '../../../types/common.type';

const AffiliateIcon = ({ size = 20, className, pathClassName }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.3333 13.3334C14.7141 13.3334 15.8333 14.4526 15.8333 15.8334C15.8333 17.2141 14.7141 18.3334 13.3333 18.3334C11.9526 18.3334 10.8333 17.2141 10.8333 15.8334C10.8333 14.4526 11.9526 13.3334 13.3333 13.3334ZM5 10C6.84095 10 8.33333 11.4924 8.33333 13.3334C8.33333 15.1743 6.84095 16.6667 5 16.6667C3.15905 16.6667 1.66667 15.1743 1.66667 13.3334C1.66667 11.4924 3.15905 10 5 10ZM12.0833 1.66669C14.6147 1.66669 16.6667 3.71871 16.6667 6.25002C16.6667 8.78135 14.6147 10.8334 12.0833 10.8334C9.552 10.8334 7.5 8.78135 7.5 6.25002C7.5 3.71871 9.552 1.66669 12.0833 1.66669Z"
        fill="#9CA3AF"
        className={pathClassName}
      />
    </svg>
  );
};

export default AffiliateIcon;
