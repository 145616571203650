import { makeStyles } from '@mui/styles';
import LobbyIcon from '../../api/ui/svgIcons/LobbyIcon';
import { useTranslation } from 'react-i18next';
import LiveCasinoIcon from '../../api/ui/svgIcons/LiveCasinoIcon';
import SlotIcon from '../../api/ui/svgIcons/SlotIcon';
import TableGameIcon from '../../api/ui/svgIcons/TableGameIcon';
import GameShowIcon from '../../api/ui/svgIcons/GameShowIcon';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import AirbetOriginalMenuIcon from '../../api/ui/svgIcons/AirbetOriginalMenuIcon';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    gap: '8px',
    marginTop: '24px',
    padding: isMobile ? '16px' : '0px 20px 0px 20px',
  },
  itemContainer: {
    width: '100%',
    height: '84px',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    transition: 'background 0.3s ease-in-out, border 0.3s ease-in-out',
    borderRadius: '8px',
    border: '1px solid transparent',
    cursor: 'pointer',
    '& path': {
      fill: '#9CA3AF',
    },
    '&:hover': {
      background: '#111827',
      border: '1px solid #1F2937',
    },
  },
  selectedItemContainer: {
    background: '#111827',
    border: '1px solid #1F2937',
    '& path': {
      fill: '#fff',
    },
  },
  text: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}));

interface ICasinoSwitcher {
  pageName: string;
}

const CasinoSwitcher = ({ pageName }: ICasinoSwitcher) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <div
        className={`${classes.itemContainer} ${pageName === 'lobby' && classes.selectedItemContainer}`}
        onClick={() => navigate(AppRoutePath.CASINO())}
      >
        <LobbyIcon width={20} />
        <span className={classes.text}>{t('airbetMenu.lobby')}</span>
      </div>
      <div
        className={`${classes.itemContainer} ${pageName === 'slotGames' && classes.selectedItemContainer}`}
        onClick={() => navigate(AppRoutePath.GAMELIST())}
      >
        <SlotIcon width={20} />
        <span className={classes.text}>{t('airbetMenu.slots')}</span>
      </div>
      <div
        className={`${classes.itemContainer} ${pageName === 'airbetMenu.liveCasino' && classes.selectedItemContainer}`}
        onClick={() => navigate(AppRoutePath.LIVECASINO())}
      >
        <LiveCasinoIcon width={20} />
        <span className={classes.text}>{t('airbetMenu.liveCasino')}</span>
      </div>
      <div
        className={`${classes.itemContainer} ${pageName === 'airbetMenu.tableGames' && classes.selectedItemContainer}`}
        onClick={() => navigate(AppRoutePath.TABLE())}
      >
        <TableGameIcon width={20} />
        <span className={classes.text}>{t('airbetMenu.tableGames')}</span>
      </div>
      <div
        className={`${classes.itemContainer} ${pageName === 'airbetMenu.gameShows' && classes.selectedItemContainer}`}
        onClick={() => navigate(AppRoutePath.GAME_SHOWS())}
      >
        <GameShowIcon width={20} />
        <span className={classes.text}>{t('airbetMenu.gameShows')}</span>
      </div>
      <div
        className={`${classes.itemContainer} ${pageName === 'airbetMenu.airbetOriginals' && classes.selectedItemContainer}`}
        onClick={() => navigate(AppRoutePath.ORIGINAL())}
      >
        <AirbetOriginalMenuIcon width={20} />
        <span className={classes.text}>{t('airbetMenu.airbetOriginals')}</span>
      </div>
    </div>
  );
};

export default CasinoSwitcher;
