import React, { FC, useEffect, useState } from 'react';
import LeaderBoardsPage from '../../components/LeaderBoards/LeaderBoardsPage';
import { getLeaderBoardBySlug } from '../../api/services/LeaderBoards';
import { ILeaderboard, ILeaderBoardInfo } from '../../types/LeaderBoard.type';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserLanguage } from '../../util/appUtil';

interface LeaderBoardsContainerProps {
  isUserAuthenticated: boolean;
}

const LeaderBoardsContainer: FC<LeaderBoardsContainerProps> = ({
  isUserAuthenticated,
}) => {
  const [leaderBoard, setLeaderBoard] = useState<ILeaderboard | undefined>();
  const [headerInfo, setHeaderInfo] = useState<ILeaderBoardInfo>();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const pathnameParts = location.pathname.split('/');
  const slug = pathnameParts[pathnameParts.length - 1];

  useEffect(() => {
    getLeaderBoard();
  }, [slug, isUserAuthenticated]);

  const getLeaderBoard = () => {
    setLoading(true);
    getLeaderBoardBySlug(slug)
      .then((resp) => {
        const data = resp.data.data;
        setLeaderBoard(data);
        const userLangCode = getUserLanguage();
        const headerInfo = data.leaderboardinfos.find(
          (item: ILeaderBoardInfo) => item.lang === userLangCode
        );
        setHeaderInfo(headerInfo);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <LeaderBoardsPage
      leaderBoard={leaderBoard}
      loading={loading}
      headerInfo={headerInfo}
    />
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: state.user.isUserAuthorized,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(LeaderBoardsContainer);
