import { ISvgIcon } from '../../../../types/common.type';

const VisaIcon = ({ className, pathClassName, fill = '#374151' }: ISvgIcon) => {
  return (
    <svg
      width="62"
      height="20"
      viewBox="0 0 62 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath={'url(#clip0_508_1306)'}>
        <path
          d="M40.1916 0.0244141C35.8195 0.0244141 31.9123 2.2906 31.9123 6.47754C31.9123 11.2791 38.8418 11.6108 38.8418 14.023C38.8418 15.0386 37.6779 15.9479 35.6898 15.9479C32.8685 15.9479 30.7598 14.6774 30.7598 14.6774L29.8575 18.9025C29.8575 18.9025 32.2867 19.9756 35.5118 19.9756C40.292 19.9756 44.0534 17.5981 44.0534 13.3397C44.0534 8.26591 37.0951 7.94409 37.0951 5.70522C37.0951 4.90947 38.0505 4.03773 40.0329 4.03773C42.2695 4.03773 44.0943 4.96168 44.0943 4.96168L44.9773 0.880997C44.9773 0.880997 42.9918 0.0244141 40.1916 0.0244141ZM0.190611 0.332419L0.0847168 0.948368C0.0847168 0.948368 1.92412 1.28497 3.58076 1.95652C5.71381 2.7265 5.86581 3.17479 6.22503 4.56707L10.1397 19.658H15.3874L23.4718 0.332419H18.2362L13.0415 13.472L10.9217 2.33424C10.7273 1.05956 9.74259 0.332419 8.53726 0.332419H0.190611ZM25.5771 0.332419L21.47 19.658H26.4626L30.5553 0.332357H25.5771V0.332419ZM53.4227 0.332419C52.2187 0.332419 51.5809 0.976968 51.1128 2.10328L43.7983 19.658H49.034L50.0469 16.7321H56.4254L57.0414 19.658H61.6611L57.6309 0.332419H53.4227ZM54.1036 5.55359L55.6555 12.8055H51.4978L54.1036 5.55359Z"
          fill={fill}
          className={pathClassName}
        />
      </g>
      <defs>
        <clipPath id="clip0_508_1306">
          <rect
            width="61.5764"
            height="20"
            fill="white"
            transform="translate(0.0847168)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VisaIcon;
