export const AppRoutePath = {
  HOME: () => '/',
  ACCOUNT: () => '/account/:accountType',
  WALLET: () => '/account/wallet',
  DEPOSIT: () => '/account/deposit',
  WITHDRAW: () => '/account/withdraw',
  BUYCRYPTO: () => '/account/buycrypto',
  TRANSACTIONS: () => '/account/transactions',
  PROFILE_LEAN: () => `/profile`,
  PROFILE: (userId: string) => `/profile/${userId}`,
  GAMELIST: () => '/slots',
  CASINO: () => '/casino',
  LIVECASINO: () => '/livecasino',
  INSTANT: () => '/instant-games',
  TABLE: () => '/table-games',
  GAMES: () => `/games/:slug`,
  SOCIAL_AUTH: (type: string, access_token: string) =>
    `/auth/social/${type}/${access_token}`,
  GAME_CRASH: () => '/game-crash',
  POLICIES: () => '/policies/:policyType',
  PRIVACY_POLICY: () => '/policies/privacy-policy',
  TERMS_AND_CONDITIONS: () => '/policies/terms-and-conditions',
  RESPONSIBLE_GAMBLING: () => '/policies/responsible-gambling',
  TERMS_OF_SERVICE: () => '/policies/terms-of-service',
  LICENSES: () => '/policies/licenses',
  LOGOUT: () => '/logout',
  AML_POLICY: () => '/policies/aml-policy',
  PROVIDER_AVAILABILITY_POLICY: () => '/policies/Provider-availability-policy',
  PROVIDER_LIST_PAGE: () => '/providers',
  FAVORITE_LIST_PATE: () => '/favourites',
  ANNOUNCEMENT_PAGE: () => '/announcement',
  SETTINGS: () => '/settings/:settingsType',
  ACCOUNT_INFO: () => '/settings/account-info',
  SECURITY: () => '/settings/security',
  PREFERENCES: () => '/settings/preferences',
  VERIFICATION: () => '/settings/verification',
  ORIGINAL: () => '/originals',
  GAME_SHOWS: () => '/game-shows',
  EMAIL_VERIFY: () => '/verify-email',
  BLOG: () => '/blog',
  // BLOGBYID: () => '/blog/:id',
  BLOGBYSLUG: () => '/blog/:slug',
  LASTPLAYED: () => '/recent',
  RESETPASSWORD: () => `/reset-password`,
  RANKSYSTEM: () => `/rank`,
  PROMOTION: () => '/promotion',
  PROMOTIONITEM: () => '/promotion/:slug',
  LEADERBOARDS: () => '/leaderBoards/:slug',
  ACCOUNT_MOBILE_PAGE: () => '/account',
  BONUS: () => '/bonus',
  WALLET_MOBILE_PAGE: () => '/wallet',
  AFFILIATE: () => '/affiliate',
  MAFFILIATE: () => '/maffiliate/:stag',
  SPORT: () => '/sports',
  LOTERRY: () => '/lottery',
  REGISTRATION: () => '/registration-page',
};
