import { io } from 'socket.io-client';
import { getBaseSocketUrl, getChatSocketUrl } from './baseUrl';
import { useEffect, useState } from 'react';
import { INotification } from '../types/common.type';

export const socket = io(getBaseSocketUrl() || 'https://ws.airbet.io', {
  withCredentials: true,
});

export const chatSocket = io(getChatSocketUrl() || 'https://chat.airbet.io', {
  withCredentials: true,
});

export const registerUser = (token: string, userId: number) => {
  socket
    .emitWithAck('regiserUser', { token: token, userId: userId })
    .then(() => {})
    .catch(() => {});
};

export const useNotificationUpdates = () => {
  const [notification, setNotification] = useState<INotification | null>(null);

  const handleNotificationUpdate = (notification: INotification) => {
    setNotification(notification);
  };

  useEffect(() => {
    setTimeout(() => {
      socket.on('notification', handleNotificationUpdate);
    }, 500);

    return () => {
      socket.off('notification', handleNotificationUpdate);
    };
  }, []);

  return notification;
};

export const useTransactionUpdated = () => {
  const [transactions, setTransactions] = useState<any>(null);

  const handleTransactionUpdate = (transaction: any) => {
    setTransactions(transaction);
  };

  useEffect(() => {
    socket.on('transactions', handleTransactionUpdate);

    return () => {
      socket.off('transactions', handleTransactionUpdate);
    };
  }, []);

  return transactions;
};

export const useWithdrawUpdated = () => {
  const [withdraw, setWithdraw] = useState<any>(null);

  const convertTime = (inputDate: Date) => {
    const date = new Date(inputDate);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleWithdrawUpdate = (data: any) => {
    setWithdraw(
      data.map((item: any) => {
        return {
          ...item,
          type: 'transaction',
          requested_at: convertTime(item.create_time),
          exchanged_amount: item.amount,
        };
      })
    );
  };

  useEffect(() => {
    socket.on('withdrawRequest', handleWithdrawUpdate);

    return () => {
      socket.off('withdrawRequest', handleWithdrawUpdate);
    };
  }, []);

  return withdraw;
};
