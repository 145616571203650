import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { IPlayerAccountInfo } from '../../../containers/Settings/SecurityContainer';
import { useEffect, useState } from 'react';
import PreferencesModal from './PreferencesModal';
import { getUserInfo } from '../../../api/services/User.api';
import { isMobile } from 'react-device-detect';
import SubpageHeader from '../SubpageHeader';
import SettingsButton from '../SettingsButton';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: isMobile ? 0 : '12px',
    border: isMobile ? '' : '1px solid #1F2937',
    width: '100%',
    background: '#111827',
    boxSizing: 'border-box',
    paddingBottom: isMobile ? '8px' : '',
    position: isMobile ? 'fixed' : 'static',
  },
  body: {
    margin: isMobile ? '16px 8px' : '16px',
    background: '#0D121D',
    borderRadius: '8px',
    display: 'flex',
    padding: isMobile ? '16px' : '16px 8px',
    gap: '16px',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: isMobile ? 'space-between' : 'center',
    color: '#fff',
    flexDirection: isMobile ? 'column' : 'row',
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: '14px',
    color: '#fff',
    gap: '8px',
    padding: isMobile ? '0 8px' : '0 16px',

    '& div:last-child': {
      color: '#6B7280',
    },
  },
}));

const Preferences = ({ playerInfo, onClose }: IPlayerAccountInfo) => {
  const [openPreferencesModal, setOpenPreferencesModal] = useState(false);
  const [activeLangIndex, setActiveLangIndex] = useState<number | null>(null);

  const classes = useStyles();
  const { t } = useTranslation();

  const languages = ['English', 'Spanish', 'Português', 'Руccкий'];
  const langs = ['en', 'es', 'pt', 'ru'];

  const currentLanguage = activeLangIndex
    ? languages[activeLangIndex]
    : 'choose language';
  const changeLanguageHandler = (index: number) => {
    setActiveLangIndex(index);
  };
  const openPreferenceModalHandler = () => {
    setOpenPreferencesModal(true);
  };
  const closePreferencesModalHandler = () => {
    setOpenPreferencesModal(false);
  };

  useEffect(() => {
    getUserInfo().then((res) => {
      const index = langs.findIndex((lang) => lang === res.data.data.lang);
      setActiveLangIndex(index);
    });
  }, []);

  if (!playerInfo.player || Object.keys(playerInfo.player).length === 0)
    return null;

  return (
    <div className={classes.root}>
      <SubpageHeader onClose={onClose} heading={t('preferences')} />
      <div
        style={{
          height: isMobile ? 'calc(100vh - 100px)' : 'auto',
          overflow: 'scroll',
        }}
      >
        <Box
          style={{
            background: '#FFFFFF0D',
            width: '100%',
            height: '1px',
          }}
        />
        <Box className={classes.body}>
          <Box className={classes.contact}>
            <div>{t('systemLanguage')}</div>
            <div style={{ opacity: activeLangIndex === null ? 0 : 1 }}>
              {activeLangIndex !== null
                ? languages[activeLangIndex]
                : currentLanguage}
            </div>
          </Box>
          <div>
            <SettingsButton
              action={openPreferenceModalHandler}
              buttonText={t('change')}
            />
          </div>
        </Box>
        {openPreferencesModal && (
          <PreferencesModal
            isOpen={openPreferencesModal}
            onClose={closePreferencesModalHandler}
            activeLangIndex={activeLangIndex}
            onChangeLanguage={changeLanguageHandler}
            languages={languages}
            langs={langs}
          />
        )}
      </div>
    </div>
  );
};

export default Preferences;
