import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { changeGlobalUserRegistrationModalOpen } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';

const RegistrationModalOpenerPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeGlobalUserRegistrationModalOpen(true));
    navigate('/');
  }, []);

  return <div />;
};

export default RegistrationModalOpenerPage;
