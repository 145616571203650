import * as React from 'react';
import { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import formatDate from './helpers';
import { getBaseUrlStatic } from '../../api/baseUrl';
import { Box } from '@mui/material';
import { IBlog } from '../../types/common.type';
import { isMobile } from 'react-device-detect';
import RelatedTopics from './RelatedTopics';

const useStyles = makeStyles(() => ({
  containerFluid: {
    width: '100%',
    margin: '0 auto',
    backgroundColor: '#0D121D',
    overflowY: 'auto',
    maxHeight: '100%',
    userSelect: 'text',
  },
  root: {
    maxWidth: isMobile ? '100%' : '640px',
    margin: '0 auto',
    padding: '0 16px',
    paddingTop: isMobile ? '16px' : '24px',
    paddingBottom: '64px',
    backgroundColor: '#0D121D',
  },

  buttonContainer: {
    padding: isMobile ? '0 8px' : '0',
    display: 'flex',
    gap: '8px',
    '& .category': {
      backgroundColor: '#3B82F6',
    },
  },

  button: {
    backgroundColor: '#1F2937',
    color: '#fff',
    padding: '4px 8px',
    fontSize: '12px',
    fontWeight: 600,
    border: 'none',
    borderRadius: '4px',
  },

  title: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    color: '#fff',
    marginTop: '24px',
    padding: isMobile ? '0 8px' : '0',
  },
  message: {
    padding: isMobile ? '0 8px' : '0',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#9CA3AF',
  },
  bannerImage: {
    userSelect: 'none',
    '& img': {
      width: '100%',
      margin: '48px 0',
    },
  },
}));

interface IBlogDetailsProps {
  blog: Partial<IBlog>;
  selectBlog: (slug: string) => void;
}

const BlogDetails = ({ blog, selectBlog }: IBlogDetailsProps) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [blog]);

  return (
    <Box ref={containerRef} className={classes.containerFluid}>
      <div className={classes.root}>
        <div className={classes.buttonContainer}>
          <button className={`${classes.button} category`}>
            {blog.category}
          </button>
          <button className={classes.button}>
            {formatDate(blog.update_dt ?? blog.create_dt ?? '')}
          </button>
        </div>
        <div className={classes.title}>{blog.title}</div>
        <div className={classes.bannerImage}>
          <img src={getBaseUrlStatic() + '/' + blog.image} alt={'blog img'} />
        </div>
        <div className={classes.message}>
          <div
            dangerouslySetInnerHTML={{
              __html: blog.post ?? '',
            }}
          />
        </div>
      </div>
      <div style={{ height: '1px', backgroundColor: '#1F2937' }} />
      <RelatedTopics blog={blog} selectBlog={selectBlog} />
    </Box>
  );
};

export default BlogDetails;
