import Lobby from '../../components/Lobby/Lobby';
import { connect } from 'react-redux';
import { ICategory, IGameSchema } from '../../types/Game.type';
import { useEffect, useState } from 'react';
import {
  getRecommendedGames,
  getTrendingGames,
} from '../../api/services/Game.api';

interface ILobbyContainer {
  categories: ICategory[];
  isUserAuthenticated: boolean;
  //providers: IProvider[];
}

const LobbyContainer = ({
  categories,
  //providers,
  isUserAuthenticated,
}: ILobbyContainer) => {
  //const [originalGames, setOriginalGames] = useState<IGameSchema[]>([]);
  const [trendingGames, setTrendingGames] = useState<IGameSchema[]>([]);
  const [recommendedGames, setRecommendedGames] = useState<IGameSchema[]>([]);
  //const [liveCasinoGames, setLiveCasinoGames] = useState<IGameSchema[]>([]);
  //const [tableGames, setTableGames] = useState<IGameSchema[]>([]);
  //const [slotGames, setSlotGmaes] = useState<IGameSchema[]>([]);
  //const [originalGamesLoading, setOriginalGamesLoading] = useState<boolean>(true);
  const [trendingGamesLoading, setTrendingGamesLoading] =
    useState<boolean>(true);
  const [recommendedGamesLoading, setRecommendedGamesLoading] =
    useState<boolean>(true);
  //const [slotGameLoading, setSlotGameLoading] = useState<boolean>(true);
  // const [liveGameLoading, setLiveGameLoading] = useState<boolean>(true);
  //const [tableGameLoading, setTableGameLoading] = useState<boolean>(true);
  /*  const params = {
      per_page: '28',
      device: isMobile ? 'mobile' : 'desktop',
      provider: '',
      search_name: '',
      page: 1,
    };*/

  /*  const getCategoryIdBySlugName = async (categorySlugName: string) => {
      const gameParamObjInfo = categories.find(
        (item: ICategory) => item.slug === categorySlugName
      );
  
      if (gameParamObjInfo) {
        return gameParamObjInfo.id;
      }
  
      return 0;
    };*/

  useEffect(() => {
    init();
  }, [categories]);

  /*  useEffect(() => {
      onLoadOriginalGames();
    }, [providers]);*/

  const init = async () => {
    if (categories && categories.length > 0) {
      onLoadTrendingGame();

      onLoadRecommendedGame();

      /*      const slotGameCategoryId = await getCategoryIdBySlugName('slot');
            onLoadGames(slotGameCategoryId, setSlotGmaes, 'slot');
      
            const liveCasinoGameCategoryId = await getCategoryIdBySlugName('live');
            onLoadGames(liveCasinoGameCategoryId, setLiveCasinoGames, 'live');
      
            const tableGameCategoryId = await getCategoryIdBySlugName('table');
            onLoadGames(tableGameCategoryId, setTableGames, 'table');*/
    }
  };

  /*  const onLoadGames = (categoryId: number, setState: any, gameType: string) => {
      getGames({
        ...params,
        category: categoryId,
      })
        .then((resp: any) => {
          const games = resp.data.data['games'];
          if (games) {
            setState(games.data);
          }
        })
        .finally(() => {
          if (gameType === 'slot') {
            setSlotGameLoading(false);
          }
  
          if (gameType === 'live') {
            setLiveGameLoading(false);
          }
  
          if (gameType === 'table') {
            setTableGameLoading(false);
          }
        });
    };*/

  const onLoadTrendingGame = () => {
    getTrendingGames()
      .then((resp: any) => {
        const games = resp.data.data;
        if (games) {
          setTrendingGames(games);
        }
      })
      .finally(() => {
        setTrendingGamesLoading(false);
      });
  };

  const onLoadRecommendedGame = () => {
    getRecommendedGames()
      .then((resp: any) => {
        const games = resp.data.data;
        if (games) {
          setRecommendedGames(games);
        }
      })
      .finally(() => {
        setRecommendedGamesLoading(false);
      });
  };

  /*  const onLoadOriginalGames = () => {
      const id: number | undefined = providers.find(
        (provider) => provider.slug === 'inhouse'
      )?.id;
  
      getGames({
        ...params,
        provider: id,
      })
        .then((resp: any) => {
          const games = resp.data.data['games'];
          if (games) {
            setOriginalGames(games.data);
          }
        })
        .catch(() => {})
        .finally(() => {
          setOriginalGamesLoading(false);
        });
    };*/

  return (
    <Lobby
      /*originalGames={originalGames}*/
      trendingGames={trendingGames}
      recommendedGames={recommendedGames}
      /*liveCasinoGames={liveCasinoGames}
      tableGames={tableGames}
      slotGames={slotGames}*/
      /*originalGamesLoading={originalGamesLoading}*/
      trendingGamesLoading={trendingGamesLoading}
      recommendedGamesLoading={recommendedGamesLoading}
      /*slotGameLoading={slotGameLoading}
      liveGameLoading={liveGameLoading}
      tableGameLoading={tableGameLoading}*/
      isUserAuthenticated={isUserAuthenticated}
    />
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.game.categories,
  isUserAuthenticated: state.user.isUserAuthorized,
  providers: state.game.providers,
});
const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(LobbyContainer);
