import { IAction } from '../../types/common.type';
import {
  CHANGE_SETTINGS,
  TERMS_OF_SERVICES,
} from '../constants/settingConstants';

const initialState = {
  generalSetting: [],
  termsOfServices: [],
};

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case CHANGE_SETTINGS:
      return {
        ...state,
        generalSetting: action.payload,
      };
    case TERMS_OF_SERVICES:
      return {
        ...state,
        termsOfServices: action.payload.filter((item: any) => item.is_active),
      };
    default:
      return state;
  }
}
