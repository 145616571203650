const FlagBrazil = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#6DA544"
      />
      <path
        d="M12.0002 4.6969L21.9132 12.0012L12.0002 19.3056L2.08716 12.0012L12.0002 4.6969Z"
        fill="#FFDA44"
      />
      <path
        d="M12.0003 16.1736C14.3055 16.1736 16.1743 14.3049 16.1743 11.9997C16.1743 9.69447 14.3055 7.82581 12.0003 7.82581C9.69509 7.82581 7.82642 9.69447 7.82642 11.9997C7.82642 14.3049 9.69509 16.1736 12.0003 16.1736Z"
        fill="#F0F0F0"
      />
      <path
        d="M9.9133 11.7399C9.18743 11.7399 8.48698 11.8503 7.82764 12.0551C7.85684 14.3351 9.71326 16.1747 12.0002 16.1747C13.4143 16.1747 14.6633 15.4708 15.4183 14.3949C14.1266 12.7779 12.1391 11.7399 9.9133 11.7399Z"
        fill="#0052B4"
      />
      <path
        d="M16.0967 12.7993C16.1469 12.5405 16.1741 12.2733 16.1741 11.9997C16.1741 9.69447 14.3053 7.82581 12.0001 7.82581C10.2801 7.82581 8.80355 8.86648 8.16455 10.3523C8.72939 10.2353 9.31421 10.1736 9.91318 10.1736C12.3374 10.1736 14.5305 11.1813 16.0967 12.7993Z"
        fill="#0052B4"
      />
    </svg>
  );
};

export default FlagBrazil;
