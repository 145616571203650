import MetaMaskAuthIcon from '../../api/ui/svgIcons/MetaMaskAuthIcon';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Web3 } from 'web3';
import { getMetaMaskNonce, metaMaskAuth } from '../../api/services/Auth.api';
import {
  registrationGoogleManagerHelper,
  setLocalStorageValue,
} from '../../util/appUtil';
import { bindActionCreators } from 'redux';
import {
  changeAccessToken,
  changeUserAuthorization,
  changeUserInfo,
} from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { IAccessToken, IUserInfo } from '../../types/common.type';

interface IMetamaskAuthContainer {
  className: string;
  onClose: () => void;
  changeUserAuthorizationFunction: (isAuthorized: boolean) => void;
  changeUserAccessToken: (data: IAccessToken) => void;
  changeUserInfo: (data: IUserInfo) => void;
}

const MetamaskAuthContainer = ({
  className,
  onClose,
  changeUserInfo,
  changeUserAuthorizationFunction,
  changeUserAccessToken,
}: IMetamaskAuthContainer) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const onConnect = async () => {
    const currentProvider = detectCurrentProvider();
    if (currentProvider) {
      await currentProvider.request({ method: 'eth_requestAccounts' });
      const web3 = new Web3(currentProvider);
      const userAccount = await web3.eth.getAccounts();
      getMetaMaskNonce({
        type: 'auth',
        wallet_address: userAccount[0],
      })
        .then((resp: any) => {
          if (resp.data.data) {
            inCreateSignature(web3, resp.data.data.nonce, userAccount[0]);
          }
        })
        .catch(() => {});
    }
  };

  const detectCurrentProvider = () => {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      enqueueSnackbar(t('needToInstallMetamask'), {
        variant: 'error',
      });
    }
    return provider;
  };

  const inCreateSignature = async (web3: any, nonce: any, address: any) => {
    try {
      const signature = await web3.eth.personal.sign(
        web3.utils.utf8ToHex(nonce),
        address,
        ''
      );

      const res: any = {
        signature: signature,
        wallet_address: address,
      };
      const stagData = localStorage.getItem('stag');
      if (stagData) {
        const convertStagData = JSON.parse(stagData);
        const stagDate = new Date(convertStagData.date);
        stagDate.setDate(stagDate.getDate() + 7);
        if (stagDate > new Date()) {
          res.stag = convertStagData.code;
        }
      }

      metaMaskAuth({
        ...res,
      })
        .then((resp: any) => {
          if (resp.data.data) {
            const createDate: Date = new Date(res.data.data.create_dt);
            const currentDate: Date = new Date();
            const timeDifference: number =
              currentDate.getTime() - createDate.getTime();
            const differenceInMinutes = timeDifference / (1000 * 60);
            if (differenceInMinutes <= 1) {
              registrationGoogleManagerHelper(
                resp.data.data.id,
                resp.data.data.register_country,
                'Meta Mask'
              );
            }

            changeUserAuthorizationFunction(true);
            changeUserAccessToken(resp.data.data.access_token);
            changeUserInfo(resp.data.data);
            setLocalStorageValue('userInfo', JSON.stringify(resp.data.data));
            setLocalStorageValue('isAuthorized', true);
            setLocalStorageValue(
              'token_expire_at',
              resp.data.data.access_token.expire_at
            );
            setLocalStorageValue('userId', resp.data.data.player.id);
            setLocalStorageValue('token', resp.data.data.access_token.token);
            onClose();
          }
        })
        .catch(() => {
          enqueueSnackbar(t('canNotConnectMetamask'), {
            variant: 'error',
          });
        });
    } catch (err: any) {
      enqueueSnackbar(err, {
        variant: 'error',
      });
    }
  };

  return (
    <span className={className} onClick={() => onConnect()}>
      <MetaMaskAuthIcon /> <span>{t('metamask')}</span>
    </span>
  );
};

const mapStateToProps = () => ({});

const dispatchToProps = (dispatch: any) => ({
  changeUserAccessToken: bindActionCreators(changeAccessToken, dispatch),
  changeUserInfo: bindActionCreators(changeUserInfo, dispatch),
  changeUserAuthorizationFunction: bindActionCreators(
    changeUserAuthorization,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(MetamaskAuthContainer);
