import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { IPlayerAccountInfo } from '../../../containers/Settings/SecurityContainer';
import WarningIcon from '../../../api/ui/svgIcons/NotificationIcons/WarningIcon';
import SuccessIcon from '../../../api/ui/svgIcons/NotificationIcons/SuccessIcon';
import { useEffect, useState } from 'react';
import PersonalModal from './PersonalModal';
import IdentityModal from './IdentityModal';
import AddressModal from './AddressModal';
import { getCountries } from '../../../api/services/Country.api';
import { ICountry } from '../../../types/common.type';
import { CONFIRMED, NOT_UPLOADED, PENDING } from './constants';
import { isMobile } from 'react-device-detect';
import SubpageHeader from '../SubpageHeader';
import SettingsButton from '../SettingsButton';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: isMobile ? 0 : '12px',
    border: isMobile ? '' : '1px solid #1F2937',
    width: '100%',
    background: '#111827',
    boxSizing: 'border-box',
    position: isMobile ? 'fixed' : 'static',
  },
  body: {
    margin: isMobile ? '16px 8px' : '16px',
    background: '#0D121D',
    borderRadius: '8px',
    display: 'flex',
    padding: isMobile ? '16px' : '16px 8px',
    gap: '16px',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: isMobile ? 'space-between' : 'center',
    color: '#fff',
    flexDirection: isMobile ? 'column' : 'row',
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: '14px',
    gap: '8px',
    color: '#fff',
    padding: isMobile ? '0 8px' : '0 16px',

    '& div:last-child': {
      color: '#6B7280',
    },
  },
  edit: {
    display: 'flex',
    alignItems: isMobile ? 'space-between' : 'center',
    gap: 24,
    flexDirection: isMobile ? 'column' : 'row',
  },
  verify: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    paddingLeft: '8px',
  },
}));

const PersonalVerification = ({ playerInfo, onClose }: IPlayerAccountInfo) => {
  const [openPersonalModal, setOpenPersonalModal] = useState(false);
  const [openIdentityModal, setOpenIdentityModal] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [countries, setCountries] = useState<ICountry[]>([]);

  const classes = useStyles();
  const { t } = useTranslation();
  const { player } = playerInfo;

  const openPersonalModalHandler = () => {
    setOpenPersonalModal(true);
  };

  const closePersonalModalHandler = () => {
    setOpenPersonalModal(false);
  };
  const openIdentityModalHandler = () => {
    setOpenIdentityModal(true);
  };

  const closeIdentityModalHandler = () => {
    setOpenIdentityModal(false);
  };

  const openAddressModalHandler = () => {
    setOpenAddressModal(true);
  };

  const closeAddressModalHandler = () => {
    setOpenAddressModal(false);
  };

  useEffect(() => {
    getCountries().then((res: any) => {
      setCountries(res.data.data);
    });
  }, []);

  if (!playerInfo.player || Object.keys(playerInfo.player).length === 0)
    return null;

  return (
    <div className={classes.root}>
      <SubpageHeader onClose={onClose} heading={t('personalVerification')} />
      <div
        style={{
          height: isMobile ? 'calc(100vh - 150px)' : 'auto',
          overflow: 'scroll',
        }}
      >
        <Box
          style={{
            background: '#FFFFFF0D',
            width: '100%',
            height: '1px',
          }}
        />
        <Box className={classes.body}>
          <Box className={classes.contact}>
            <div>{t('personalInformation')}</div>
            <div>{t('level')} 1</div>
          </Box>
          <Box className={classes.edit}>
            <SettingsButton
              action={openPersonalModalHandler}
              buttonText={t('verify')}
            />
            {openPersonalModal && (
              <PersonalModal
                isOpen={openPersonalModal}
                onClose={closePersonalModalHandler}
                countries={countries}
                playerInfo={playerInfo}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.body}>
          <Box className={classes.contact}>
            <div>{t('proofOfIdentity')}</div>
            <div>{t('level')} 2</div>
          </Box>
          <Box className={classes.edit}>
            <Box className={classes.verify}>
              {player.kyc_status === CONFIRMED ? (
                <SuccessIcon size={16} />
              ) : (
                <WarningIcon
                  size={16}
                  fill={player.kyc_status === PENDING ? '#ECC53A' : '#9197A3'}
                  color={'#0D121D'}
                />
              )}
              <div
                style={{
                  color:
                    player.kyc_status === PENDING
                      ? '#ECC53A'
                      : player.kyc_status === CONFIRMED
                        ? '#84CC16'
                        : '#9197A3',
                }}
              >
                {player.kyc_status === PENDING && t('verificationInProgress')}
                {player.kyc_status === CONFIRMED && t('verified')}
                {player.kyc_status === NOT_UPLOADED &&
                  t('requiresVerification')}
              </div>
            </Box>
            {player.kyc_status === NOT_UPLOADED && (
              <SettingsButton
                action={openIdentityModalHandler}
                buttonText={t('verify')}
              />
            )}

            {openIdentityModal && (
              <IdentityModal
                isOpen={openIdentityModal}
                onClose={closeIdentityModalHandler}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.body}>
          <Box className={classes.contact}>
            <div>{t('proofOfAddress')}</div>
            <div>{t('level')} 3</div>
          </Box>
          <Box className={classes.edit}>
            <Box className={classes.verify}>
              {player.address_proof_status === CONFIRMED ? (
                <SuccessIcon size={16} />
              ) : (
                <WarningIcon
                  size={16}
                  fill={
                    player.address_proof_status === PENDING
                      ? '#ECC53A'
                      : '#9197A3'
                  }
                  color={'#0D121D'}
                />
              )}
              <div
                style={{
                  color:
                    player.address_proof_status === PENDING
                      ? '#ECC53A'
                      : player.address_proof_status === CONFIRMED
                        ? '#84CC16'
                        : '#9197A3',
                }}
              >
                {player.address_proof_status === PENDING &&
                  t('verificationInProgress')}
                {player.address_proof_status === CONFIRMED && t('verified')}
                {player.address_proof_status === NOT_UPLOADED &&
                  t('requiresVerification')}
              </div>
            </Box>

            {player.address_proof_status === NOT_UPLOADED && (
              <SettingsButton
                action={openAddressModalHandler}
                buttonText={t('verify')}
              />
            )}
            {openAddressModal && (
              <AddressModal
                isOpen={openAddressModal}
                onClose={closeAddressModalHandler}
              />
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};
export default PersonalVerification;
