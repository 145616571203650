import { Box, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoginContainer from '../../containers/Login/LoginContainer';
import RegistrationContainer from '../../containers/Registration/RegistrationContainer';
import GoogleAuthIcon from '../../api/ui/svgIcons/GoogleAuthIcon';
import { getBaseUrl } from '../../api/baseUrl';
import MetamaskAuthContainer from '../../containers/MetamaskAuth/MetamaskAuthContainer';
import { isMobile } from 'react-device-detect';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  root: {
    background: '#111827',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
    minHeight: '0px',
    padding: isMobile ? '16px' : '24px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    '& .MuiTabPanel-root': {
      padding: '0px',
      marginTop: isMobile ? '24px' : '32px',
    },
  },
  tabContainer: {
    borderRadius: isMobile ? '8px' : '16px',
    background: '#0D121D',
    display: 'flex',
    padding: '4px',
    gap: '4px',
    alignItems: 'flex-start',
    width: isMobile ? '100%' : 'fit-content',

    '& .MuiTabs-root': {
      minHeight: '0px',
      width: isMobile && '100%',
    },

    '& .MuiButtonBase-root': {
      color: '#9CA3AF',
      fontSize: isMobile ? '12px' : '16px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0px',
      textAlign: 'left',
      minHeight: '0px',
      flex: isMobile && '1',
      height: isMobile ? '30px' : '38px',
      border: 'none',
    },
  },
  tab: {
    zIndex: '2',
    position: 'relative',
    borderRadius: isMobile ? '8px' : '12px',
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    minWidth: '104px',
    width: 'fit-content',
  },
  directlyJoin: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: '#FFFFFF80',
    textTransform: 'uppercase',
  },
  externalAuthBtn: {
    cursor: 'pointer',
    background: '#FFFFFF1A',
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    transition: 'background 0.3s',
    gap: '16px',
    '&:hover': {
      background: 'rgb(31 41 55)',
    },
    '& span': {
      color: '#fff',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
  close: {
    cursor: 'pointer',
    zIndex: '2',
    width: '24px',
    height: '24px',
    background: '#0D121D',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: '#fff',
      width: '12px',
      height: '12px',
    },
  },
}));

interface IAuthorizationContent {
  onClose: () => void;
  isRegistrationOpen: boolean;
  setIsOpenResetPasswordModal: (isOpen: boolean) => void;
}

const AuthorizationContent = ({
  onClose,
  isRegistrationOpen,
  setIsOpenResetPasswordModal,
}: IAuthorizationContent) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState('login');

  useEffect(() => {
    setValue(isRegistrationOpen ? 'register' : 'login');
  }, [isRegistrationOpen]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onGoogleAuth = (event: SyntheticEvent): void => {
    event.preventDefault();
    const baseUrl = getBaseUrl() + '/auth/social/google';
    const url = new URL(baseUrl);
    const params = new URLSearchParams();

    const stagData = localStorage.getItem('stag');
    if (stagData) {
      const convertStagData = JSON.parse(stagData);
      const stagDate = new Date(convertStagData.date);
      stagDate.setDate(stagDate.getDate() + 7);
      if (stagDate > new Date()) {
        params.append('stag', convertStagData.code);
      }
    }
    url.search = params.toString();
    window.location.replace(url.toString());
  };

  return (
    <Box className={classes.root}>
      {isMobile && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          marginBottom={'24px'}
          padding={'0px 8px'}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'8px'}
            maxWidth={'240px'}
          >
            <span
              style={{
                color: '#fff',
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 700,
              }}
            >
              {t('welcomeToAirbet')}
            </span>
            <span
              style={{
                color: '#FFFFFF80',
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: '600',
              }}
            >
              {t('welcomeToAirbetDescription')}
            </span>
          </Box>
          <Box>
            <span className={classes.close} onClick={() => onClose()}>
              <CloseIcon />
            </span>
          </Box>
        </Box>
      )}
      <Box
        height={isMobile ? 'fit-content' : '100%'}
        display={'flex'}
        flexDirection={'column'}
      >
        <TabContext value={value}>
          <Box className={classes.tabContainer}>
            <TabList
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  background: '#1F2937',
                  height: '100%',
                  borderRadius: isMobile ? '8px' : '12px',
                },
              }}
            >
              <Tab
                label={t('login')}
                value="login"
                className={classes.tab}
                disableRipple
              />
              <Tab
                label={t('register')}
                value="register"
                className={classes.tab}
                disableRipple
              />
            </TabList>
          </Box>
          <TabPanel
            value="login"
            style={{ height: isMobile ? 'fit-content' : '100%' }}
          >
            <LoginContainer
              onClose={onClose}
              setIsOpenResetPasswordModal={setIsOpenResetPasswordModal}
            />
          </TabPanel>
          <TabPanel
            value="register"
            style={{ height: isMobile ? 'fit-content' : '100%' }}
          >
            <RegistrationContainer onClose={onClose} />
          </TabPanel>
        </TabContext>
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'column'}
        gap={'16px'}
        alignItems={'center'}
        justifyContent={'center'}
        marginTop={'16px'}
      >
        <span className={classes.directlyJoin}>{t('directlyJoin')}</span>
        <Box display={'flex'} gap={'8px'} width={'100%'}>
          <span
            className={classes.externalAuthBtn}
            onClick={(event: SyntheticEvent) => onGoogleAuth(event)}
          >
            <GoogleAuthIcon /> <span>{t('google')}</span>
          </span>
          <MetamaskAuthContainer
            className={classes.externalAuthBtn}
            onClose={onClose}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthorizationContent;
