const ShowIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.78772 8C1.41473 4.58651 4.40537 2 8.00016 2C11.5949 2 14.5856 4.58651 15.2126 8C14.5856 11.4135 11.5949 14 8.00016 14C4.40537 14 1.41473 11.4135 0.78772 8ZM8.00016 11.3333C9.84109 11.3333 11.3335 9.84093 11.3335 8C11.3335 6.15905 9.84109 4.66667 8.00016 4.66667C6.15919 4.66667 4.6668 6.15905 4.6668 8C4.6668 9.84093 6.15919 11.3333 8.00016 11.3333ZM8.00016 10C6.89556 10 6.00013 9.1046 6.00013 8C6.00013 6.8954 6.89556 6 8.00016 6C9.10469 6 10.0002 6.8954 10.0002 8C10.0002 9.1046 9.10469 10 8.00016 10Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default ShowIcon;
