import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { KeyboardArrowLeft } from '@mui/icons-material';
import WalletIcon from '../../api/ui/svgIcons/WalletIcon';

const useStyles = makeStyles(() => ({
  header: {
    margin: isMobile ? '0' : '16px 0px',
    padding: isMobile ? '16px 8px' : '8px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: isMobile ? '#0D121D' : '#111827',
    '& path': {
      fill: isMobile ? '#6B7280' : '#fff',
    },
    '& span': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

interface ISubpageHeader {
  onClose: () => void;
  heading: string;
}

const SubpageHeader = ({ onClose, heading }: ISubpageHeader) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      {isMobile ? (
        <KeyboardArrowLeft style={{ fontSize: '20px' }} onClick={onClose} />
      ) : (
        <WalletIcon size={20} />
      )}
      <span>{heading}</span>
    </div>
  );
};

export default SubpageHeader;
