import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { IBlog } from '../../types/common.type';
import { useTranslation } from 'react-i18next';
import BlogCard from './BlogCard';
import { getBlogsByCategory } from '../../api/services/Blog.api';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  header: {
    padding: '32px 20px',
    maxWidth: '1160px',
    margin: '0 auto',
    background: '#0D121D',
  },
  headerText: {
    fontSize: isMobile ? '18px' : '24px',
    fontWeight: 600,
    lineHeight: '48px',
    color: '#fff',
    paddingLeft: '20px',
  },
  containerFluid: {
    width: '100%',
    margin: '0 auto',
    backgroundColor: '#0D121D',
  },
  blogsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '40px',
    gap: '20px',
    maxWidth: '1160px',
    margin: '0 auto',
    padding: '0 16px',
  },
}));

interface IRelatedTopicsProps {
  blog: Partial<IBlog>;
  selectBlog: (slug: string) => void;
}

const RelatedTopics = ({ blog, selectBlog }: IRelatedTopicsProps) => {
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  const classes = useStyles({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!blog.category) return;
    getBlogsByCategory(blog.category).then((res) => {
      setRelatedBlogs(res.data.data);
    });
  }, [blog]);

  return (
    <Box className={classes.containerFluid}>
      <div className={classes.header}>
        <span className={classes.headerText}>{t('related_Topics')}</span>
      </div>
      <div className={classes.blogsContainer}>
        {relatedBlogs.map((blog: any) => (
          <BlogCard
            key={blog.id}
            {...blog}
            onSelectBlog={() => selectBlog(blog.slug)}
          />
        ))}

        <Box width={'374px'} />
        <Box width={'374px'} />
        <Box width={'374px'} />
      </div>
    </Box>
  );
};

export default RelatedTopics;
