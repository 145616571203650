import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import { getBaseUrl } from './baseUrl';

const instance: AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
