import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { IStaticPage } from '../../types/common.type';

const useStyles = makeStyles((theme: any) => ({
  root: {
    ...theme.general.accountBody,
  },
  header: {
    padding: '16px 24px 0 24px',
    '& span': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '24px',
    },
  },
  body: {
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '& >div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      '& div': {
        color: '#fff',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        opacity: 0.92,
      },
      '& span': {
        color: '#9CA3AF',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '24px',
        opacity: 0.92,
      },
      '& p': {
        color: '#9CA3AF',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '24px',
        opacity: 0.92,
      },
      '& ul': {
        paddingLeft: '24px',
      },
      '& ul li': {
        color: '#9CA3AF',
      },
    },
  },
}));

const Policy = (data: IStaticPage) => {
  const classes = useStyles();

  const parseHtml = () => {
    const content = data.content;
    const elements = { __html: content };

    return <div dangerouslySetInnerHTML={elements} />;
  };

  return (
    <Box className={classes.root}>
      <div className={classes.header}>
        <span>{data.title}</span>
      </div>
      <Box className={classes.body}>{parseHtml()}</Box>
    </Box>
  );
};

export default Policy;
