import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { IBlog } from '../../types/common.type';
import BlogBannerCard from './BlogBannerCard';
import { Pagination } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  swiperContainer: {
    maxWidth: '1160px',
    margin: isMobile ? '0 auto' : '48px auto',
    padding: isMobile ? '0 16px' : '0px 24px',
  },
  swiper: {},
  '@global': {
    '.swiper-pagination-bullet': {
      backgroundColor: '#fff',
    },
  },
}));

interface BlogBannerProps {
  blogs: IBlog[];
  onSelectBlog: (slug: string) => void;
}

const BlogBanner = ({ blogs, onSelectBlog }: BlogBannerProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.swiperContainer}>
      <Swiper
        spaceBetween={5}
        slidesPerView={1}
        className={classes.swiper}
        pagination={{ clickable: true }}
        modules={[Pagination]}
      >
        {blogs.map((blog: IBlog) => (
          <SwiperSlide key={blog.id}>
            <BlogBannerCard
              {...blog}
              onSelectBlog={() => onSelectBlog(blog.slug ? blog.slug : '')}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default BlogBanner;
