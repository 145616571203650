const BaseballSportIcon = () => {
  return (
    <svg
      data-editor-id="navbarIcon"
      data-cy="sport-baseball"
      width="20"
      height="20"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.3364 17.857C10.9592 19.4673 9.3453 21.3545 7.67699 23.3055C7.44693 23.6676 6.98367 23.8221 6.58243 23.6506C6.20094 23.4875 6.02748 23.5082 5.87719 23.6585C5.68069 23.855 5.72794 24.1351 6.14789 24.555L7.44304 25.8502C7.86531 26.2725 8.14105 26.3194 8.33958 26.1209C8.49913 25.9613 8.52241 25.7945 8.37381 25.4376C8.21384 25.0533 8.35098 24.6154 8.68003 24.379L8.67821 24.3769L14.1343 19.6549L12.3364 17.857ZM12.904 17.1934L14.7943 19.0836L16.3435 17.7429C18.4727 15.9001 18.4727 15.9001 18.8516 15.5753L25.9709 8.42998C26.2808 8.11891 26.2706 7.58676 25.9257 7.24185L24.6945 6.01066C24.3496 5.66575 23.8184 5.65653 23.5085 5.96759L16.4162 13.0859C16.4215 13.0804 15.0002 14.7423 12.904 17.1934ZM10.1863 25.3748C10.3188 26.1154 10.1193 26.8035 9.57077 27.3521C8.56458 28.3583 7.21854 28.1291 6.19135 27.1019L4.8962 25.8067C3.87092 24.7814 3.64296 23.4303 4.646 22.4273C5.19722 21.8761 5.8933 21.6862 6.64648 21.8318C13.5021 13.8145 15.1404 11.8994 15.185 11.8547L22.2773 4.7364C23.2762 3.73386 24.9085 3.7622 25.9235 4.77722L27.1547 6.00842C28.1697 7.02344 28.201 8.65862 27.2021 9.66117C22.8514 14.0278 22.8514 14.0278 20.0358 16.8503L10.1863 25.3748ZM9.09553 14.0688C7.41269 14.0688 6.04848 12.7046 6.04848 11.0217C6.04848 9.33891 7.41269 7.9747 9.09553 7.9747C10.7784 7.9747 12.1426 9.33891 12.1426 11.0217C12.1426 12.7046 10.7784 14.0688 9.09553 14.0688ZM9.09553 13.1982C10.2976 13.1982 11.272 12.2238 11.272 11.0217C11.272 9.81972 10.2976 8.84529 9.09553 8.84529C7.8935 8.84529 6.91907 9.81972 6.91907 11.0217C6.91907 12.2238 7.8935 13.1982 9.09553 13.1982Z"></path>
    </svg>
  );
};

export default BaseballSportIcon;
