import instance from '../axios';
import { isMobile } from 'react-device-detect';

export const getGameCategories = () => {
  return instance.get('/categories');
};

export const getProviders = () => {
  return instance.get('/providers');
};

export const getGames = (params: any) => {
  return instance.get(`/game/list?${new URLSearchParams(params).toString()}`);
};

export const getRecentGame = () => {
  return instance.get(`/last_games_played`);
};

export const getFavoriteGame = () => {
  return instance.get(`/game/favourite/list`);
};

export const addGameToFavourites = (id: number) => {
  return instance.post(`/game/favourite/add`, {
    id,
  });
};

export const removeGameFromFavourites = (id: number) => {
  return instance.post(`/game/favourite/remove`, {
    id,
  });
};

export const getGameInfoBySlug = (slug: string) => {
  return instance.get(`/game/by_slug/${slug} `);
};

export const getDemoGameUrl = (gameId: string) => {
  return instance.get(`/game/generate_demo_url/${gameId}`);
};

export const getRealGameUrl = (gameId: string) => {
  return instance.get(`/game/generate_url/${gameId}`);
};

export const getTrendingGames = () => {
  return instance.get(
    `/game/trending?device=${isMobile ? 'mobile' : 'desktop'}`
  );
};

export const getRecommendedGames = () => {
  return instance.get(`/game/recommended`);
};
