import React from 'react';
import { ISvgIcon } from '../../../types/common.type';

const AccountIconForMobile = ({
  size = 20,
  className,
  pathClassName,
  fill,
}: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33329 13.3333H12.6666V14.6667H3.33329V13.3333ZM7.99996 12C5.05444 12 2.66663 9.61221 2.66663 6.66668C2.66663 3.72116 5.05444 1.33334 7.99996 1.33334C10.9455 1.33334 13.3333 3.72116 13.3333 6.66668C13.3333 9.61221 10.9455 12 7.99996 12Z"
        fill={fill}
        className={pathClassName}
      />
    </svg>
  );
};

export default AccountIconForMobile;
