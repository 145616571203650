import PromotionItemPage from './PromotionItemPage';
import { useEffect, useState } from 'react';
import { IPromotionDataType } from '../PromotionContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { getPromotionBySlug } from '../../../api/services/Promotion.api';
import { AppRoutePath } from '../../../util/appRoutePath';

const PromotionItemContainer = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [promotion, setPromotion] = useState<IPromotionDataType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [dateInfo, setDateInfo] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } | null>(null);

  const calculateTimeLeft = (endDate: any) => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateInfo((currentDateInfo) => {
        if (!currentDateInfo) return null;

        let { days, hours, minutes, seconds } = currentDateInfo;
        seconds--;
        if (seconds < 0) {
          minutes--;
          seconds = 59;
          if (minutes < 0) {
            hours--;
            minutes = 59;
            if (hours < 0) {
              days--;
              hours = 23;
              if (days < 0) {
                clearInterval(intervalId);
                return null; // Stop the countdown when it's over
              }
            }
          }
        }

        return { days, hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (slug)
      getPromotionBySlug(slug)
        .then((resp: any) => {
          if (resp && resp.data.data) {
            setPromotion(resp.data.data);
          } else {
            navigate(AppRoutePath.PROMOTION());
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
  }, []);

  useEffect(() => {
    if (promotion) {
      let timeLeft: any = null;
      if (new Date(promotion.start_date) > new Date()) {
        timeLeft = calculateTimeLeft(promotion.start_date);
        setDateInfo(timeLeft);
      } else if (new Date(promotion.end_date) > new Date()) {
        timeLeft = calculateTimeLeft(promotion.end_date);
        setDateInfo(timeLeft);
      } else {
        setDateInfo(null);
      }
    }
  }, [promotion]);

  return (
    <PromotionItemPage
      promotion={promotion}
      loading={loading}
      dateInfo={dateInfo}
    />
  );
};

export default PromotionItemContainer;
