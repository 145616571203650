import { ISvgIcon } from '../../../types/common.type';

const AgeIcon = ({ className }: ISvgIcon) => {
  return (
    <svg
      width="38"
      height="18"
      viewBox="0 0 38 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.39322 1.16V17H5.72922V4.184L2.00922 6.584L0.785218 4.592L5.94522 1.16H8.39322ZM14.5989 2C15.6229 1.264 17.0149 0.896 18.7749 0.896C20.5349 0.896 21.9349 1.264 22.9749 2C24.0309 2.72 24.5589 3.76 24.5589 5.12C24.5589 6.08 24.4069 6.808 24.1029 7.304C23.8149 7.784 23.2629 8.28 22.4469 8.792C23.2629 9.32 23.8549 9.864 24.2229 10.424C24.6069 10.968 24.7989 11.728 24.7989 12.704C24.7989 14.304 24.2469 15.464 23.1429 16.184C22.0389 16.904 20.5829 17.264 18.7749 17.264C14.8069 17.264 12.8229 15.832 12.8229 12.968C12.8229 11.896 13.0069 11.072 13.3749 10.496C13.7429 9.92 14.3269 9.352 15.1269 8.792C14.3749 8.296 13.8469 7.784 13.5429 7.256C13.2389 6.712 13.0869 5.992 13.0869 5.096C13.0869 3.752 13.5909 2.72 14.5989 2ZM15.6789 12.392C15.6789 14.04 16.7189 14.864 18.7989 14.864C20.8949 14.864 21.9429 14.024 21.9429 12.344C21.9429 11.24 21.2709 10.48 19.9269 10.064H17.6229C16.3269 10.48 15.6789 11.256 15.6789 12.392ZM21.7029 5.504C21.7029 4.032 20.7429 3.296 18.8229 3.296C16.9029 3.296 15.9429 4.032 15.9429 5.504C15.9429 6.464 16.5029 7.224 17.6229 7.784H19.9269C21.1109 7.224 21.7029 6.464 21.7029 5.504ZM26.7806 12.152V9.8H31.0526V5.504H33.4046V9.8H37.7246V12.152H33.4046V16.496H31.0526V12.152H26.7806Z"
        fill="#4B5563"
      />
    </svg>
  );
};

export default AgeIcon;
