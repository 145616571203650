import * as types from '../constants/settingConstants';

export const changeSettings = (data: any) => ({
  type: types.CHANGE_SETTINGS,
  payload: data,
});

export const generalTermsOfServices = (data: any) => ({
  type: types.TERMS_OF_SERVICES,
  payload: data,
});
