import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Affiliate = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const stag = url.searchParams.get('stag');
    if (stag) {
      const data = {
        date: new Date(),
        code: stag,
      };
      localStorage.setItem('stag', JSON.stringify(data));
    }
    navigate('/');
  }, []);
  return <div />;
};

export default Affiliate;
