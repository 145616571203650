const AmericanFootballSportIcon = () => {
  return (
    <svg
      className="bt2259"
      data-editor-id="expandedNavbarIcon"
      data-cy="sport-americanfootball"
      width="20"
      height="20"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 16V17.7143H26.7054L26.8734 17.7309C27.5163 17.8594 27.9835 18.404 27.9835 19.0662C27.9945 22.9079 28 25.028 28 25.4286C28 26.5434 27.0697 27.4433 26.0197 27.1005C25.4527 26.9154 24.2398 26.5012 23.2657 26.1493C22.567 25.897 21.9864 25.676 21.5562 25.4958C21.3001 25.3885 21.0966 25.2954 20.9427 25.2132C20.8393 25.1579 20.7528 25.1051 20.6753 25.0458C20.4365 24.8632 20.2857 24.6784 20.2857 24.2993V22H20.2148C19.8038 24.6745 17.6559 26.2857 14.2857 26.2857C11.8324 26.2857 9.84145 25.0024 8.36996 22.5364H7.00161C6.73261 22.5364 6.47922 22.4101 6.31725 22.1954C4.85621 20.258 4 17.628 4 15.1429C4 8.92833 8.92653 4 15.1429 4C21.4843 4 26.4776 8.54421 26.4776 14.6481C26.4776 15.3938 25.8708 16 25.1252 16H22ZM5.71429 15.1429C5.71429 17.1169 6.35564 19.2207 7.44204 20.8221H8.86857C9.18325 20.8221 9.47263 20.9946 9.6224 21.2713C10.8346 23.5112 12.3664 24.5714 14.2857 24.5714C17.1018 24.5714 18.5293 23.321 18.5716 21.1264C18.5806 20.6595 18.9616 20.2857 19.4286 20.2857H21.1429C21.6162 20.2857 22 20.6695 22 21.1429V23.8204C22.065 23.8494 22.1381 23.8809 22.2186 23.9147C22.6171 24.0816 23.1741 24.2936 23.8481 24.537C24.3911 24.7331 24.9845 24.9406 25.5821 25.1447C25.8404 25.2329 26.0932 25.3182 26.2857 25.3826C26.2854 24.9024 26.2802 22.9164 26.2703 19.4286H21.1429C20.6695 19.4286 20.2857 19.0448 20.2857 18.5714V15.1429C20.2857 14.6695 20.6695 14.2857 21.1429 14.2857H24.7562C24.5636 9.34993 20.4428 5.71429 15.1429 5.71429C9.87344 5.71429 5.71429 9.87497 5.71429 15.1429ZM14.2857 17.2857C15.4775 17.2857 16.4286 18.2368 16.4286 19.4286C16.4286 20.6204 15.4775 21.5714 14.2857 21.5714C13.0939 21.5714 12.1429 20.6204 12.1429 19.4286C12.1429 18.2368 13.0939 17.2857 14.2857 17.2857ZM14.2857 18.1429C13.5673 18.1429 13 18.7102 13 19.4286C13 20.147 13.5673 20.7143 14.2857 20.7143C15.0041 20.7143 15.5714 20.147 15.5714 19.4286C15.5714 18.7102 15.0041 18.1429 14.2857 18.1429Z"></path>
    </svg>
  );
};

export default AmericanFootballSportIcon;
