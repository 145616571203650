import React, { FC, useEffect, useState } from 'react';
import { Box, Pagination } from '@mui/material';

interface AirbetPaginationProps {
  initialPage: number;
  rowsPerPage: number;
  totalAmount: number;
  onPageChange: (page: number) => void;
}

const AirbetPagination: FC<AirbetPaginationProps> = ({
  initialPage,
  rowsPerPage,
  totalAmount,
  onPageChange,
}) => {
  const [activePage, setActivePage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);

  useEffect(() => {
    setActivePage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    setLimit(totalAmount);
  }, [totalAmount]);

  const pageChangeHandler = (e: React.ChangeEvent<any>, page: number) => {
    setActivePage(page);
    onPageChange(page);
  };

  return (
    <Box
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      sx={{ margin: '20px 0px' }}
    >
      <Pagination
        count={Math.ceil(limit / rowsPerPage)}
        shape={'rounded'}
        sx={{
          '& .MuiPaginationItem-root': {
            color: '#9CA3AF',
            '&:hover': {
              backgroundColor: 'rgb(31 41 55)',
            },
          },
          '& .Mui-selected': {
            color: '#FFF',
            backgroundColor: 'rgb(31 41 55) !important',
          },
          '& .MuiPaginationItem-ellipsis': {
            pointerEvents: 'none',
          },
        }}
        page={activePage}
        onChange={(e, value) => pageChangeHandler(e, value)}
        hidePrevButton={activePage === 1}
        hideNextButton={
          totalAmount === 0 || activePage === Math.ceil(limit / rowsPerPage)
        }
      />
    </Box>
  );
};

export default AirbetPagination;
