import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';

interface ICustomButton {
  children: ReactNode;
  style?: object;
  onClick?: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    height: '32px',
    padding: '4px, 16px, 4px, 8px',
    borderRadius: '8px',
    gap: '6px',
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'background 0.3s, box-shadow 0.3s',
    border: '1px solid rgba(255, 255, 255, 0.10)',
    background: '#65A30D',
    boxShadow: '0px 2px 8px 0px rgba(101, 163, 13, 0.30)',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: '#FFF',
    width: isMobile ? '32px' : '',
    minWidth: '0px',
    '&:hover': {
      background: '#4D7C0F',
      boxShadow: '0px 2px 8px 0px rgba(101, 163, 13, 0.30)',
    },
    '&:active': {
      background: '#3F6212',
      boxShadow: '0px 2px 8px 0px rgba(101, 163, 13, 0.30)',
    },
  },
}));
const DepositButton = ({ children, style, onClick }: ICustomButton) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.root}
      disableRipple
      style={style}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default DepositButton;
