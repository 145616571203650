import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CustomMinimalButton from '../../common/Buttons/CustomMinimalButton';

interface ILoadMoreGames {
  currentGamesCount: number;
  totalGamesCount: number;
  loadMoreLoading: boolean;
  loadMoreGames: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    marginTop: '40px',
  },
  content: {
    width: '160px',
  },
  progressContainer: {
    background: '#1F2937',
    width: '100%',
    height: '4px',
    borderRadius: '8px',
  },
  progress: {
    width: '0%',
    height: '4px',
    borderRadius: '8px',
    background: '#2563EB',
    boxShadow: 'box-shadow: 0px 2px 8px 0px #2563EB4D',
    transition: 'width 1s ease-in-out',
  },
  text: {
    color: '#6B7280',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
  },
}));

const LoadMoreGames = ({
  currentGamesCount,
  totalGamesCount,
  loadMoreLoading,
  loadMoreGames,
}: ILoadMoreGames) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const text = t('displayingGames', {
    loadedGameCount: currentGamesCount,
    maxGameCount: totalGamesCount,
  });

  const parts = text.split(/(\d+)/);

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.progressContainer}>
          <Box
            className={classes.progress}
            style={{
              width:
                currentGamesCount === 0 || currentGamesCount === 0
                  ? '0%'
                  : `${(currentGamesCount / totalGamesCount) * 100}%`,
            }}
          />
        </Box>
        <Box
          margin={'8px 0px 24px 0px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <span className={classes.text}>
            {parts.map((part: any, index: number) =>
              /\d+/.test(part) ? (
                <span key={index} style={{ color: '#FFFFFF' }}>
                  {part}
                </span>
              ) : (
                <span key={index}>{part}</span>
              )
            )}
          </span>
        </Box>
        {totalGamesCount !== currentGamesCount && (
          <CustomMinimalButton
            style={{
              textTransform: 'uppercase',
            }}
            loading={loadMoreLoading}
            onClick={() => loadMoreGames()}
          >
            {t('loadMore')}
          </CustomMinimalButton>
        )}
      </Box>
    </Box>
  );
};

export default LoadMoreGames;
