interface IVerificationIcon {
  size?: number;
  pathClassName?: string;
  fill: string;
}

export const VerificationIcon = ({
  size = 16,
  pathClassName,
  fill,
}: IVerificationIcon) => {
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33366 2.66663C0.965472 2.66663 0.666992 2.96511 0.666992 3.33329V12.6666C0.666992 13.0348 0.965472 13.3333 1.33366 13.3333H14.667C15.0352 13.3333 15.3337 13.0348 15.3337 12.6666V3.33329C15.3337 2.96511 15.0352 2.66663 14.667 2.66663H1.33366ZM6.00038 6.66663C6.00038 6.29844 5.7019 5.99996 5.33371 5.99996C4.96552 5.99996 4.66705 6.29844 4.66705 6.66663C4.66705 7.03483 4.96552 7.33329 5.33371 7.33329C5.7019 7.33329 6.00038 7.03483 6.00038 6.66663ZM7.33372 6.66663C7.33372 7.77123 6.43828 8.66663 5.33371 8.66663C4.22914 8.66663 3.33371 7.77123 3.33371 6.66663C3.33371 5.56206 4.22914 4.66663 5.33371 4.66663C6.43828 4.66663 7.33372 5.56206 7.33372 6.66663ZM3.68516 11.35L2.74235 10.4072C3.40516 9.74443 4.32274 9.33329 5.33507 9.33329C6.34741 9.33329 7.26499 9.74443 7.92779 10.4072L6.98499 11.35C6.56201 10.9271 5.97959 10.6666 5.33507 10.6666C4.69056 10.6666 4.10814 10.9271 3.68516 11.35ZM13.4717 7.13803L10.8051 9.80469L10.3337 10.2761L9.86226 9.80469L8.19559 8.13803L9.13839 7.19523L10.3337 8.39049L12.5289 6.19522L13.4717 7.13803Z"
        fill={fill}
        className={pathClassName}
      />
    </svg>
  );
};
