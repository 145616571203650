import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IProvider } from '../../types/Game.type';
import { getBaseUrlStatic } from '../../api/baseUrl';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';

interface IProviderCardItem {
  provider: IProvider;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '180px',
    height: '158px',
    maxHeight: '158px',
    maxWidth: '180px',
    minWidth: '0px',
    minHeight: '0px',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderRadius: '8px',
    background: '#111827',
    transition: 'background 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgb(32 41 58)',
    },
  },
  imgContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    minWidth: '0px',
    minHeight: '0px',
  },
  title: {
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: '12px',
    color: '#fff',
  },
  gameCount: {
    lineHeight: '16px',
    fontWeight: 600,
    fontSize: '12px',
    color: '#6B7280',
    textTransform: 'uppercase',
  },
}));
const ProviderCardItem = ({ provider }: IProviderCardItem) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const imgUrl = getBaseUrlStatic() + (provider.img || '');

  const onProviderCardClick = () => {
    navigate(`${AppRoutePath.GAMELIST()}?provider=` + provider.slug);
  };

  return (
    <Box className={classes.root} onClick={() => onProviderCardClick()}>
      <Box className={classes.imgContent}>
        <img
          src={imgUrl}
          alt={provider.title}
          style={{
            minWidth: '0px',
            minHeight: '0px',
          }}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        minHeight={'40px'}
      >
        <span className={classes.title}>{provider.title}</span>
        <span className={classes.gameCount}>
          {provider.games_count} {t('games')}
        </span>
      </Box>
    </Box>
  );
};

export default ProviderCardItem;
