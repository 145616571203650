import { ChangeEvent, useEffect, useState } from 'react';
import { IGameSchema } from '../../types/Game.type';
import { getGames } from '../../api/services/Game.api';
import { isMobile, isMobile as isMobileDevice } from 'react-device-detect';
import SearchIcon from '../../api/ui/svgIcons/SearchIcon';
import CustomInput from '../common/Inputs/CustomInput';
import { Box, CircularProgress } from '@mui/material';
import GameCard from '../GameCard/GameCard';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bodyForMobile: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
  },
  secondaryText: {
    color: '#9CA3AF',
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '14px',
  },
  primaryText: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
  },
  innerGameBox: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxHeight: '420px',
    overflow: 'auto',
    paddingTop: '20px',
  },
  innerGameBoxMobile: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    marginTop: '20px',
    justifyContent: 'center',
    height: '100vh',
  },
}));

const CasinoPageGlobalSearch = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [games, setGames] = useState<IGameSchema[] | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!open) {
      setSearchValue('');
      setGames(null);
    }
  }, [open]);

  useEffect(() => {
    if (searchValue.trim().length >= 3) {
      onLoadGame();
    } else {
      setGames(null);
    }
  }, [searchValue]);

  const onLoadGame = (): void => {
    setLoading(true);
    getGames({
      device: isMobileDevice ? 'mobile' : 'desktop',
      search_name: searchValue,
    })
      .then((resp: any) => {
        const result = resp.data.data.games.data;
        setGames(result.length > 0 ? resp.data.data.games.data : null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={isMobile ? '0px' : '24px'}
      boxSizing={'border-box'}
    >
      <CustomInput
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onSearchChange(event.target.value)
        }
        secondary={true}
        startAdornment={<SearchIcon pathClassName={'search-icon'} />}
        value={searchValue}
      />
      <Box className={!isMobile ? classes.body : classes.bodyForMobile}>
        {loading ? (
          <Box marginTop={'25px'}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {searchValue.length < 3 ? (
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              ></Box>
            ) : games ? (
              <Box
                className={
                  !isMobile ? classes.innerGameBox : classes.innerGameBoxMobile
                }
              >
                {games.map((game: IGameSchema, index: number) => (
                  <Box key={index}>
                    <GameCard
                      gameName={game.game_title}
                      gameProvider={game.provider}
                      backgroundImageUrl={game.image || ''}
                      isFavorite={
                        false
                        /* !!favourite.find(
                         (item: IGameSchema) => item.id === game.id
                       )*/
                      }
                      isAvailable={!game.coming_soon && !game.is_restricted}
                      comingSoon={!!game.coming_soon}
                      removeFromFavorite={
                        () => {}
                        // removeFromFavoriteGameList(String(game.id))
                      }
                      addToFavorites={() => {
                        /*    addToFavoriteGameList(String(game.id));*/
                      }}
                      startPlaying={() => navigate(`/games/${game.slug}`)}
                      isMobile={isMobile}
                      agregator_image={game.agregator_image}
                      gameId={game.game_id}
                      categorySlug={''}
                      index={index}
                    />
                  </Box>
                ))}
                {Array.from({
                  length: 7,
                }).map((_, index) => (
                  <Box key={`${index}_empty_card`} width={'150px'} />
                ))}
              </Box>
            ) : (
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'8px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span className={classes.primaryText}>{t('noResult')}</span>
                <span className={classes.secondaryText}>
                  {t('noResultInSearch')}
                </span>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default CasinoPageGlobalSearch;
