import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  timerBox: {
    display: 'flex',
    '& div': {
      flex: 1,
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 700,
    },
  },
}));

interface ITimerFinished {
  minutes: number;
  seconds: number;
}

const ResendTimer = ({ minutes, seconds }: ITimerFinished) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.timerBox}>
      <div
        style={{
          textTransform: 'uppercase',
        }}
      >
        {t('resendCode')}
        {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
      </div>
    </Box>
  );
};

export default ResendTimer;
