import React from 'react';
import { Box, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Grid } from '@giphy/react-components';
import { getGiphySdkApiKey } from '../../../api/baseUrl';
import GifIcon from '../../../api/ui/svgIcons/GifIcon';

interface GiphyProps {
  sendMessage: (message: string) => void;
}

const Giphy: React.FC<GiphyProps> = ({ sendMessage }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const gf = new GiphyFetch(getGiphySdkApiKey() || '');

  const fetchGifs = (offset: number) => gf.trending({ offset, limit: 10 });

  const handleGifSelect = (gif: any, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    sendMessage(gif.images.fixed_height_small.url);
    setAnchorEl(false);
  };

  const closeBonusPopover = () => {
    setAnchorEl(null);
  };

  const openBonusPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        aria-describedby={id}
        onClick={(event) => openBonusPopover(event as any)}
        sx={{
          display: 'flex',
          cursor: 'pointer',
          marginTop: '4px',
          '& path': {
            transition: 'fill 0.3s',
          },
          '&:hover': {
            '& path': {
              fill: '#fff',
            },
          },
        }}
      >
        <GifIcon fill={'#6B7280'} />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeBonusPopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: '#111827',
            border: '1px solid #1F2937',
            marginTop: '-10px',
            width: '200px',
            height: '306px',
            borderRadius: '8px',
            boxShadow: '0px 16px 32px 0px #0000001A',
            padding: '4px',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box
          sx={{
            border: '1px solid #1F2937',
            background: '#111827',
            boxShadow: '0px -16px 32px 0px rgba(0, 0, 0, 0.25)',
            maxHeight: 300,
            overflowY: 'auto',
            // padding: 4,
            maxWidth: 200,
          }}
        >
          <div style={{ minHeight: 272, maxHeight: 272, overflowY: 'auto' }}>
            <Grid
              width={200}
              columns={2}
              gutter={6}
              fetchGifs={fetchGifs}
              onGifClick={handleGifSelect}
              className="custom-giphy"
            />
          </div>
        </Box>
        <p style={{ color: 'white', alignItems: 'center' }}>
          {t('poweredByGiphy')}
        </p>
      </Popover>
    </>
  );
};

export default Giphy;
