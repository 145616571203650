import React from 'react';

type Props = {
  size?: number;
  className?: string;
};

const FlagGerman: React.FC<Props> = ({
  size = 20,
  className = 'rounded-full',
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      fill="none"
      viewBox="0 0 20 21"
    >
      <g mask="url(#mask0_41_1465)">
        <path d="M24.2986 0H-0.795532V23.7972H24.2986V0Z" fill="#050807" />
        <path
          d="M24.2986 7.95496H-0.795532V23.7925H24.2986V7.95496Z"
          fill="#F50D39"
        />
        <path
          d="M24.2986 15.8376H-0.795532V24.9496H24.2986V15.8376Z"
          fill="#FFDC00"
        />
      </g>
    </svg>
  );
};

export default FlagGerman;
