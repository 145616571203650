import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getBaseUrlStatic } from '../../api/baseUrl';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import { isMobile } from 'react-device-detect';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface IUserProfileHeader {
  username: string;
  userId: string;
  img: string | null;
  onClickProfile?: () => void;
  stage: 'DEFAULT' | 'TIP' | 'EDIT';
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    padding: '8px',
    borderRadius: '8px',
    background: '#0D121D',
    border: '1px solid',
    borderColor: 'transparent',
    transition: 'background 0.3s ease-in-out, border-color 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      background: '#111827',
      borderColor: '#1F2937',
    },
  },
  rootMobail: {
    display: 'flex',
    width: '100%',
    height: '72px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    padding: '16px',
    background: '#0D121D',
    borderBottom: '1px solid #FFFFFF0D',
    borderColor: 'transparent',
    transition: 'background 0.3s ease-in-out, border-color 0.3s ease-in-out',
    '&:hover': {
      background: '#111827',
      borderColor: '#1F2937',
    },
  },
  img: {
    width: '40px',
    height: '40px',
  },
  username: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    maxWidth: '150px',
    overflow: 'hidden',
  },
  userId: {
    color: '#6B7280',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  arrowIcon: {
    width: '16px',
    height: '16px',
    color: '#fff',
    cursor: 'pointer',
  },
  arrowIconMobile: {
    width: '20px',
    height: '20px',
    color: '#fff',
    cursor: 'pointer',
  },
}));

const UserProfileHeader = ({
  username,
  userId,
  img,
  stage,
  onClickProfile,
}: IUserProfileHeader) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      className={!isMobile ? classes.root : classes.rootMobail}
      onClick={() => onClickProfile && onClickProfile()}
    >
      <Box display={'flex'} gap={'16px'} alignItems={'center'}>
        <Box className={classes.img}>
          {img ? (
            <img
              src={
                img
                  ? img.includes('google')
                    ? img
                    : getBaseUrlStatic() + img
                  : '/imgs/DefaultAvatars/Avatar.png'
              }
              alt={'user_profile_img'}
              width={'40px'}
              height={'40px'}
              style={{
                borderRadius: '100%',
              }}
            />
          ) : (
            <img
              src={`/imgs/DefaultAvatars/Avatar.png`}
              alt={'user_profile_img'}
              width={'40px'}
              height={'40px'}
              style={{
                borderRadius: '100%',
              }}
            />
          )}
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          <span className={classes.username}>{username}</span>
          <span className={classes.userId}>
            {t('userId')} {userId}
          </span>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        {stage === 'DEFAULT' &&
          (!isMobile ? (
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          ) : (
            <KeyboardArrowRightIcon className={classes.arrowIconMobile} />
          ))}
        {stage === 'EDIT' && (
          <CustomMinimalButton
            style={{
              textTransform: 'uppercase',
            }}
          >
            {t('edit')}
          </CustomMinimalButton>
        )}
      </Box>
    </Box>
  );
};

export default UserProfileHeader;
