import { IPromotionDataType } from './PromotionContainer';
import { useTranslation } from 'react-i18next';
import {
  getFormattedDateWithText,
  getNameByType,
  getStyleByType,
} from './helper/PromotionHelper';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { getBaseUrlStatic } from '../../api/baseUrl';

interface IPromotionPageItem {
  promotion: IPromotionDataType;
}

const PromotionPageItem = ({ promotion }: IPromotionPageItem) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        boxSizing: 'border-box',
        width: '100%',
        height: isMobile ? '416px' : '208px',
        background: '#111827',
        borderRadius: '12px',
        flexDirection: isMobile ? 'column' : 'row',
        userSelect: 'text',
      }}
    >
      <img
        src={getBaseUrlStatic() + promotion.image}
        alt={'promotion img'}
        style={{
          width: isMobile ? '100%' : '377px',
          height: '208px',
          maxHeight: '208px',
          borderBottomLeftRadius: isMobile ? '0px' : '12px',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: isMobile ? '12px' : '0px',
          userSelect: 'none',
        }}
      />
      <div
        style={{
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          padding: '16px 24px',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '16px',
            }}
          >
            <div
              style={{
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '16px',
                ...getStyleByType(promotion),
              }}
            >
              {getNameByType(promotion)}
            </div>
            <div
              style={{
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '16px',
                background: '#1F2937',
                color: '#fff',
              }}
            >
              {getFormattedDateWithText(promotion)}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '8px',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                color: '#fff',
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '32px',
              }}
            >
              {promotion.title}
            </span>
            <span
              style={{
                color: '#9CA3AF',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '24px',
                overflow: 'hidden',
                maxHeight: '28px',
              }}
            >
              {promotion.description}
            </span>
          </div>
        </div>
        <div
          style={{
            textTransform: 'uppercase',
            cursor: 'pointer',
            color: '#fff',
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '24px',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid #1F2937',
            padding: '4px 16px',
            width: 'fit-content',
          }}
          onClick={() => navigate(`/promotion/${promotion.slug}`)}
        >
          {t('viewPromotion')}
        </div>
      </div>
    </div>
  );
};

export default PromotionPageItem;
