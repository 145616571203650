import { useEffect } from 'react';
import { emailVerificationConfirm } from '../../../api/services/Settings.api';
import { AppRoutePath } from '../../../util/appRoutePath';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const EmailVerificationPage = () => {
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id') || '';
  const hash = queryParams.get('hash') || '';
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const emailVerificationConfirmRequest = () => {
    emailVerificationConfirm(id, hash)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar(t('verifyEmailSuccessfully'), {
            variant: 'success',
          });
        }
      })
      .catch(() => {})
      .finally(() => navigate(AppRoutePath.ACCOUNT_INFO()));
  };

  useEffect(() => {
    emailVerificationConfirmRequest();
  }, []);

  return <div data-testid="empty-div" />;
};

export default EmailVerificationPage;
