import { makeStyles } from '@mui/styles';
import ChatFooter from './ChatFooter';
import { IMessageDataType } from '../../types/Chat.type';
import ChatBody from './ChatBody';
import ChatHeader from './ChatHeader';
import {
  changeGlobalUserLoginModalOpen,
  changeGlobalUserRegistrationModalOpen,
} from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import { useRef, useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
}));

interface IChat {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  isUserAuthenticated: boolean;
  isChatEnabled: boolean;
  needToVerifyEmail: boolean;
  minWager: boolean;
  chatLoading: boolean;
  selectedRoom: string;
  onChangeRoom: (room: string) => void;
  isChatAdmin: boolean;
  chatData: IMessageDataType[];
  sendMessage: any;
  showHideMessage: (messageId: string, action: string) => void;
}

const Chat = ({
  isOpen,
  setIsOpen,
  isUserAuthenticated,
  chatLoading,
  onChangeRoom,
  isChatAdmin,
  chatData,
  sendMessage,
  showHideMessage,
}: IChat) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const textareaRef = useRef<any>(null);
  const [message, setMessage] = useState<string>('');

  const onCallUserByName = (userName: string) => {
    setMessage(`${message} @${userName}`);
  };

  const openLogin = () => {
    dispatch(changeGlobalUserLoginModalOpen(true));
  };

  const openRegistration = () => {
    dispatch(changeGlobalUserRegistrationModalOpen(true));
  };

  return (
    <div
      className={classes.root}
      style={{
        width: isOpen ? '100%' : '0%',
        opacity: chatLoading || !isOpen ? 0 : 1,
        transition: 'opacity 1s ease-in-out',
      }}
    >
      <ChatHeader onChangeRoom={onChangeRoom} setIsOpen={setIsOpen} />
      <ChatBody
        chatData={chatData}
        isChatAdmin={isChatAdmin}
        isOpen={isOpen}
        showHideMessage={showHideMessage}
        setMessage={onCallUserByName}
      />
      <ChatFooter
        isUserAuthorized={isUserAuthenticated}
        openLogin={() => openLogin()}
        openRegistration={() => openRegistration()}
        sendMessage={sendMessage}
        textareaRef={textareaRef}
        message={message}
        setMessage={setMessage}
      />
    </div>
  );
};

export default Chat;
