import CustomModal from '../../common/Modal/CustomModal';
import { Box } from '@mui/material';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { changePassword } from '../../../api/services/Settings.api';
import { useSnackbar } from 'notistack';
import ModalHeader from '../ModalHeader';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#9CA3AF',
    fontWeight: 600,
    padding: '0px 32px',
  },
  passwordModal: {
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    color: '#fff',
    minWidth: isMobile ? '0px' : '416px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },
  inputContainer: {
    display: 'flex',
    gap: '4px',
    width: '100%',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    paddingLeft: '16px',

    alignItems: 'center',
  },
  input: {
    border: 'none',
    backgroundColor: '#0D121D',
    outline: 'none',
    color: '#fff',
    paddingLeft: '16px',

    margin: '4px 16px 4px 0px',
    height: '48px',
    borderRadius: '8px',
    flex: '1',
  },
  note: {
    fontSize: '12px',
    color: '#4B5563',
    fontWeight: 600,
    padding: '0 32px',
  },
  customButtonBox: {
    display: 'flex',
    margin: '16px',
    '& :first-child': {
      flex: 1,
    },
  },
}));

interface IPasswordModal {
  isOpen: boolean;
  onClose: () => void;
  havePassword: number | null;
}

const PasswordModal = ({ isOpen, onClose, havePassword }: IPasswordModal) => {
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const verifyIsAllowed = useMemo(() => {
    if (havePassword) {
      return !currentPassword || !newPassword || !repeatPassword;
    }
    return !newPassword || !repeatPassword;
  }, [currentPassword, newPassword, repeatPassword]);

  const changePasswordHandler = () => {
    changePasswordRequest();
  };

  const changePasswordRequest = () => {
    setLoading(true);
    const body =
      havePassword === 1
        ? {
            password: currentPassword,
            new_password: newPassword,
            repeat_password: repeatPassword,
          }
        : {
            new_password: newPassword,
            repeat_password: repeatPassword,
          };
    changePassword(body)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar(t(res.data.data.message), {
            variant: 'success',
          });
          onClose();
        }
      })
      .catch((err) => {
        const error = err.response.data.data.error;
        if (typeof error === 'string') {
          enqueueSnackbar(t(error), {
            variant: 'error',
          });
          return;
        }
        if (Array.isArray(error.password)) {
          enqueueSnackbar(t(error.password[0]), {
            variant: 'error',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const children = (
    <Box className={classes.passwordModal}>
      <ModalHeader onClose={onClose} heading={t('updatePasswordHeading')} />
      {havePassword === 1 && (
        <Box marginTop={'16px'}>
          <label htmlFor="current" className={classes.title}>
            {t('currentPassword')}
          </label>
          <div style={{ display: 'flex' }}>
            <div className={classes.inputContainer}>
              <input
                type="password"
                value={currentPassword}
                className={classes.input}
                onChange={(e) => setCurrentPassword(e.target.value)}
                id="current"
              />
            </div>
          </div>
        </Box>
      )}
      <Box marginTop={'16px'}>
        <label htmlFor="new" className={classes.title}>
          {t('newPassword')}
        </label>
        <div style={{ display: 'flex' }}>
          <div className={classes.inputContainer}>
            <input
              type="password"
              value={newPassword}
              className={classes.input}
              onChange={(e) => setNewPassword(e.target.value)}
              id="new"
            />
          </div>
        </div>
      </Box>
      <Box marginTop={'16px'}>
        <label htmlFor="repeat" className={classes.title}>
          {t('repeatPassword')}
        </label>
        <div style={{ display: 'flex' }}>
          <div className={classes.inputContainer}>
            <input
              type="password"
              value={repeatPassword}
              className={classes.input}
              onChange={(e) => setRepeatPassword(e.target.value)}
              id="repeat"
            />
          </div>
        </div>
      </Box>
      <Box className={classes.customButtonBox}>
        <CustomPrimaryButton
          onClick={changePasswordHandler}
          loading={loading}
          disabled={verifyIsAllowed}
        >
          <div>{t('update')}</div>
        </CustomPrimaryButton>
      </Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};

export default PasswordModal;
