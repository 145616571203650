import CustomModal from '../../common/Modal/CustomModal';
import { Box } from '@mui/material';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import ResendTimer from './ResendTimer';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import {
  phoneCode,
  phoneVerification,
} from '../../../api/services/Settings.api';
import ModalHeader from '../ModalHeader';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#9CA3AF',
    fontWeight: 600,
    padding: '0px 32px',
  },
  contactModal: {
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    color: '#fff',
    minWidth: isMobile ? '0px' : '416px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },
  inputContainer: {
    display: 'flex',
    gap: '4px',
    width: '100%',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    paddingLeft: '16px',

    alignItems: 'center',
    margin: '4px 16px 0px 0px',
  },
  input: {
    border: 'none',
    backgroundColor: '#0D121D',
    outline: 'none',
    color: '#fff',
    paddingLeft: '16px',
    height: '48px',
    borderRadius: '8px',
    flex: '1',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  note: {
    fontSize: '12px',
    color: '#4B5563',
    fontWeight: 600,
    padding: '0 32px',
    marginTop: '8px',
  },
  customButtonBox: {
    display: 'flex',
    margin: '8px 16px 16px 16px',
    '& :first-child': {
      flex: 1,
    },
  },
  countryCodes: {
    display: 'flex',
    color: '#67738A',
    justifyContent: 'space-between',
    gap: '50px',
  },
  timer: {
    textAlign: 'center',
    padding: '16px 8px 8px 8px',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    '& >span': {
      cursor: 'pointer',
    },
  },
}));

interface IPhoneModal {
  isOpen: boolean;
  onClose: () => void;
  seconds: number;
  minutes: number;
  timerIsOn: boolean;
  timerOnHandler: () => void;
}

const PhoneConfirmModal = ({
  isOpen,
  onClose,
  seconds,
  minutes,
  timerIsOn,
  timerOnHandler,
}: IPhoneModal) => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState<string>('');

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const sendCodeHandler = () => {
    timerOnHandler();
    phonePinCodeRequest();
  };
  const phonePinCodeRequest = () => {
    phoneCode().then((res: any) => {
      if (res.data.success) {
        enqueueSnackbar(t('codeHasSent'), {
          variant: 'success',
        });
        setPin('');
      }
    });
  };

  const confirmVerify = () => {
    if (pin.length !== 6) {
      return;
    }
    setLoading(true);
    phoneVerification({ pin: pin })
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar(res.data.data.message, {
            variant: 'error',
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.data.error, {
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const children = (
    <Box className={classes.contactModal}>
      <ModalHeader onClose={onClose} heading={t('confirmPhone')} />
      <Box marginTop={'16px'}>
        <div className={classes.title} style={{}}>
          <div> {t('digitCode')}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classes.inputContainer}>
            <input
              className={classes.input}
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.note} style={{}}>
          {t('verificationCodeIsSent')}
        </div>
      </Box>
      <Box className={classes.timer}>
        {timerIsOn ? (
          <ResendTimer seconds={seconds} minutes={minutes} />
        ) : (
          <span onClick={sendCodeHandler}>{t('sendCode')}</span>
        )}
      </Box>
      <Box className={classes.customButtonBox}>
        <CustomPrimaryButton
          disabled={!(pin.length === 6)}
          onClick={confirmVerify}
          loading={loading}
          style={{
            textTransform: 'upperCase',
          }}
        >
          <div>{t('confirm')}</div>
        </CustomPrimaryButton>
      </Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};

export default PhoneConfirmModal;
