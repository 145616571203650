import GameContainer from './GameContainer';

const InstantGameContainer = () => {
  return (
    <GameContainer
      categorySlugName={'instant'}
      pageName={'airbetMenu.instantGames'}
    />
  );
};

export default InstantGameContainer;
