const applicationTheme = () => {
  return {
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'rgb(31 41 55)',
            padding: '6px 12px 6px 12px',
            borderRadius: '4px',
          },
          arrow: {
            color: 'rgb(31 41 55)',
            marginTop: '2px',
            marginLeft: '1px',
          },
        },
      },
    },
    general: {
      pageHeader: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
        background: '#111827',
        boxShadow: '0px 2px 4px 0px rgba(3, 7, 18, 0.50)',
        height: '80px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
      },
      pageHeaderTitle: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '48px',
        color: '#fff',
      },
      accountBody: {
        borderRadius: '12px',
        border: '1px solid #1F2937',
        background: '#111827',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
      },
    },
  };
};

export default applicationTheme;
