import { Checkbox } from '@mui/material';
import { SyntheticEvent } from 'react';

interface ICustomCheckBox {
  onChange?: (event: SyntheticEvent) => void;
  checked?: boolean;
}

const CustomCheckBox = ({ onChange, checked }: ICustomCheckBox) => {
  return (
    <Checkbox
      checked={checked}
      disableRipple
      size={'small'}
      onChange={(event: SyntheticEvent) => onChange && onChange(event)}
      sx={{
        padding: '0px',
        color: '#1F2937',
        background: '#0D121D',
        borderRadius: '4px',
        width: '14px',
        height: '14px',
        '&.Mui-checked': {
          color: 'rgb(132 204 22)',
        },
      }}
    />
  );
};

export default CustomCheckBox;
