import React, { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import UserProfileHeader from '../../UserProfile/UserProfileHeader';
import { AppRoutePath } from '../../../util/appRoutePath';
import SettingIcon from '../../../api/ui/svgIcons/SettingIcon';
import { VerificationIcon } from '../../../api/ui/svgIcons/VerificationIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccountInfoIcon from '../../../api/ui/svgIcons/AccountInfoIcon';
import PeferencesIcon from '../../../api/ui/svgIcons/PeferencesIcon';
import LogOutIcon from '../../../api/ui/svgIcons/LogOutIcon';
import CustomModal from '../../common/Modal/CustomModal';
import { SignOut } from '../../../api/services/Auth.api';
import { setLocalStorageValue } from '../../../util/appUtil';
import { bindActionCreators } from 'redux';
import {
  changeAccessToken,
  changeUserAuthorization,
  changeUserProfileInfo,
  clearUserInfo,
} from '../../../redux/actions/userActions';
import { connect } from 'react-redux';
import { IAccessToken, IUserInfo } from '../../../types/common.type';
import { isMobile } from 'react-device-detect';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    background: '#0D121D',
  },
  listContainerItem: {
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    borderRadius: '10px',
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'background 0.3s ease-in-out',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    '& span:hover': {
      color: '#fff !important',
    },
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '8px',
    background: '#111827',
    boxSizing: 'border-box',
    marginTop: '16px',
  },
  icon: {
    transition: 'fill 0.3s ease-in-out',
  },
  signOutContainer: {
    width: '364px',
    height: '239px',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '10px',
    gap: '8px',
  },
  logOutBtn: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '12px',
    padding: '8px 24px',
    background: '#111827',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& span': {
      fontSize: '14px',
      fontWeight: 700,
      color: '#fff',
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
  },
}));

interface SettingMobilePageProps {
  userInfo: IUserInfo;
  changeUserAuthorizationFunction: (isAuthorized: boolean) => void;
  changeUserAccessToken: (data: IAccessToken) => void;
}

const AccountMobilePage: FC<SettingMobilePageProps> = ({
  userInfo,
  changeUserAuthorizationFunction,
  changeUserAccessToken,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [settingsType, setSettingsType] = useState('');
  const [openLogOutModal, setOpenLogOutModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleItemClick = (route: string, type: string) => {
    if (type === 'log-out') {
      setOpenLogOutModal(true);
    } else {
      setSettingsType(type);
      navigate(route);
    }
  };

  const onLogOut = () => {
    setLoading(true);
    SignOut()
      .then(() => {
        setOpenLogOutModal(false);
        changeUserAuthorizationFunction(false);
        changeUserAccessToken({
          expire_at: '',
          token: '',
        });
        clearUserInfo();
        setLocalStorageValue('isAuthorized', false);
        setLocalStorageValue('token_expire_at', '');
        setLocalStorageValue('userId', '');
        setLocalStorageValue('token', '');
        setLocalStorageValue('userInfo', '');
        navigate(AppRoutePath.HOME());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderListItem = (
    route: string = '',
    type: string = '',
    IconComponent: FC<any> = () => null,
    label?: string
  ) => (
    <div
      className={classes.listContainerItem}
      onClick={() => handleItemClick(route, type)}
      style={{
        background: settingsType === type ? '#0D121D' : '',
      }}
    >
      <Box style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
        <IconComponent
          pathClassName={classes.icon}
          size={16}
          fill={settingsType === type ? '#fff' : '#9CA3AF'}
        />
        <Typography
          component="span"
          style={{
            fontWeight: 600,
            transition: 'color 0.3s ease-in-out',
            color: settingsType === type ? '#fff' : '#9CA3AF',
            fontSize: '14px',
          }}
        >
          {t(label || '')}
        </Typography>
      </Box>
      {label !== 'logOut' && (
        <KeyboardArrowRightIcon
          sx={{ color: '#FFF', width: '20px', height: '20px' }}
        />
      )}
    </div>
  );

  const goToTheLobbyPage = () => {
    navigate('/');
  };

  return (
    <>
      {isMobile ? (
        <Box className={classes.root}>
          <UserProfileHeader
            username={userInfo.player.username}
            userId={String(userInfo.player.id)}
            img={userInfo.player.avatar}
            stage={'DEFAULT'}
          />
          <Box style={{ paddingRight: '8px', paddingLeft: '8px' }}>
            <div className={classes.listContainer}>
              {renderListItem(
                AppRoutePath.ACCOUNT_INFO(),
                'account-info',
                AccountInfoIcon,
                'accountInfo'
              )}
              {renderListItem(
                AppRoutePath.SECURITY(),
                'security',
                SettingIcon,
                'security'
              )}
              {renderListItem(
                AppRoutePath.PREFERENCES(),
                'preferences',
                PeferencesIcon,
                'preferences'
              )}
              {renderListItem(
                AppRoutePath.VERIFICATION(),
                'verification',
                VerificationIcon,
                'personalVerification'
              )}
              {renderListItem(
                AppRoutePath.LOGOUT(),
                'log-out',
                LogOutIcon,
                'logOut'
              )}
            </div>
          </Box>

          <CustomModal
            open={openLogOutModal}
            onClose={() => setOpenLogOutModal(false)}
          >
            <Box className={classes.signOutContainer}>
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 600,
                  color: '#fff',
                  padding: '0px 16px',
                }}
              >
                {t('logOut')}
              </span>
              <span
                style={{
                  fontWeight: 500,
                  color: '#fff',
                  marginTop: '16px',
                  padding: '0px 16px',
                  marginBottom: '16px',
                }}
              >
                {t('areYouSureLogOut')}
              </span>
              <Box
                className={classes.logOutBtn}
                onClick={() => onLogOut()}
                style={{}}
              >
                {loading ? (
                  <CircularProgress style={{ width: '24px', height: '24px' }} />
                ) : (
                  <span>{t('yesSignOut')}</span>
                )}
              </Box>
              <Box
                className={classes.logOutBtn}
                onClick={() => setOpenLogOutModal(false)}
              >
                <span>{t('cancel')}</span>
              </Box>
            </Box>
          </CustomModal>
        </Box>
      ) : (
        goToTheLobbyPage()
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.user.playerInfo,
  ranksInfo: state.user.ranksInfo,
});

const dispatchToProps = (dispatch: any) => ({
  changeUserAccessToken: bindActionCreators(changeAccessToken, dispatch),
  clearUserInfo: bindActionCreators(clearUserInfo, dispatch),
  changeUserAuthorizationFunction: bindActionCreators(
    changeUserAuthorization,
    dispatch
  ),
  changeUserProfileInfo: bindActionCreators(changeUserProfileInfo, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(AccountMobilePage);
