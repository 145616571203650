import CustomModal from '../../common/Modal/CustomModal';
import { Box } from '@mui/material';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { changeEmail } from '../../../api/services/Settings.api';
import { useSnackbar } from 'notistack';
import ModalHeader from '../ModalHeader';
import { CheckEmailValidation } from '../../../util/appUtil';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#9CA3AF',
    fontWeight: 600,
    padding: '0px 32px',
  },
  contactModal: {
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    color: '#fff',
    minWidth: isMobile ? '0px' : '416px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },

  inputContainer: {
    display: 'flex',
    gap: '4px',
    width: '100%',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    paddingLeft: '16px',

    alignItems: 'center',
  },
  input: {
    border: 'none',
    backgroundColor: '#0D121D',
    outline: 'none',
    color: '#fff',
    paddingLeft: '16px',
    margin: '4px 16px 0px 0px',
    height: '48px',
    borderRadius: '8px',
    flex: '1',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  note: {
    fontSize: '12px',
    lineHeight: '24px',
    color: '#4B5563',
    fontWeight: 600,
    padding: '0 32px',
    marginTop: '8px',
  },
  customButtonBox: {
    display: 'flex',
    margin: '32px 16px 16px 16px',
    '& :first-child': {
      flex: 1,
    },
  },
  countryCodes: {
    display: 'flex',
    color: '#67738A',
    justifyContent: 'space-between',
    gap: '50px',
  },
}));

interface IEmailModal {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  onChangeEmail: (email: string) => void;
  setVerifiedFalse: () => void;
}

const EmailModal = ({
  isOpen,
  onClose,
  email,
  onChangeEmail,
  setVerifiedFalse,
}: IEmailModal) => {
  const [loading, setLoading] = useState(false);

  const [currentEmail, setCurrentEmail] = useState(email || '');

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const emailConfirmHandler = () => {
    changeEmailRequest();
  };

  const emailIsValid = useMemo(() => {
    return CheckEmailValidation(currentEmail);
  }, [currentEmail]);

  const changeEmailRequest = () => {
    if (currentEmail === email) {
      enqueueSnackbar(t('emailDifferent'), {
        variant: 'warning',
      });
      return;
    }
    setLoading(true);
    changeEmail({ email: currentEmail })
      .then((res) => {
        if (res.data.success) {
          setVerifiedFalse();
          onChangeEmail(currentEmail);
          onClose();
          enqueueSnackbar(res.data.data.message, {
            variant: 'success',
          });
        }
      })
      .catch((err) => {
        if (typeof err.response.data.data.error === 'string') {
          enqueueSnackbar(err.response.data.data.error, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(err.response.data.data.error.email[0], {
            variant: 'error',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const children = (
    <Box className={classes.contactModal}>
      <ModalHeader onClose={onClose} heading={t('updateEmail')} />
      <Box marginTop={'16px'}>
        <div className={classes.title}>
          <div> {t('email')}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classes.inputContainer}>
            <input
              className={classes.input}
              type="email"
              value={currentEmail}
              onChange={(e) => setCurrentEmail(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.note}>{t('enterEmail')}</div>
      </Box>
      <Box className={classes.customButtonBox}>
        <CustomPrimaryButton
          disabled={!emailIsValid}
          onClick={emailConfirmHandler}
          loading={loading}
          style={{
            textTransform: 'upperCase',
          }}
        >
          <div>{t('update')}</div>
        </CustomPrimaryButton>
      </Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};

export default EmailModal;
