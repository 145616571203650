const BonusWheelDiamondIcon = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_478_8220)">
        <path
          d="M5.99952 6.62451L11.9995 6.62451L5.99952 0.640206L5.99952 6.62451Z"
          fill="url(#paint0_linear_478_8220)"
        />
        <path
          d="M6 6.62434L6 12.6086L8.27714e-06 6.62434L6 6.62434Z"
          fill="url(#paint1_linear_478_8220)"
        />
        <path
          d="M6 6.62444L6 0.640137L7.77204e-06 6.62444L6 6.62444Z"
          fill="url(#paint2_linear_478_8220)"
        />
        <path
          d="M5.99952 6.62451L11.9995 6.62451L5.99952 12.6088L5.99952 6.62451Z"
          fill="url(#paint3_linear_478_8220)"
        />
        <rect
          width="4.23709"
          height="4.23709"
          transform="matrix(-0.708032 0.70618 -0.708032 -0.706181 9 6.62439)"
          fill="url(#paint4_linear_478_8220)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.08887 6.62439L6.00015 9.70503L2.91144 6.62439L6.00015 3.54375L9.08887 6.62439ZM8.91143 6.62439L6.00015 3.72072L3.08887 6.62439L6.00015 9.52806L8.91143 6.62439Z"
          fill="url(#paint5_linear_478_8220)"
        />
        <path
          d="M8.76861 3.91369C10.4001 4.75817 11.1217 5.97798 11.4981 6.60352L11.9688 6.60352L11.3413 5.97798L6.82343 1.44281L6.82343 1.56792C7.30032 2.79398 6.53061 3.52794 6.10184 3.72603L6.66657 4.28902C6.64565 3.86157 7.12415 3.06248 8.76861 3.91369Z"
          fill="#FFEE93"
        />
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.69361 5.50485C5.99104 4.86615 5.05653 4.47667 4.03078 4.47667C2.35119 4.47667 0.916251 5.52091 0.343583 6.99368L5.45908 12.0934C6.93641 11.5225 7.98389 10.092 7.98389 8.41757C7.98389 7.39499 7.5932 6.46337 6.95252 5.76296L6.69361 5.50485Z"
          fill="#FFD074"
        />
        <path
          d="M5.09748 8.79294L5.9132 9.60615C4.31175 9.36053 5.29345 11.4456 5.96959 12.5775L3.34054 9.98147L0.736513 7.3542L3.18368 8.8555C5.61829 10.3318 5.46351 9.43934 5.09748 8.79294Z"
          fill="url(#paint6_linear_478_8220)"
        />
        <g filter="url(#filter1_f_478_8220)">
          <path
            d="M5.976 10.8572L5.90682 9.67507L4.72105 9.6061L5.90682 9.53713L5.976 8.35502L6.04519 9.53713L7.23096 9.6061L6.04519 9.67507L5.976 10.8572Z"
            fill="white"
          />
        </g>
        <path
          d="M5.976 10.8572L5.90682 9.67507L4.72105 9.6061L5.90682 9.53713L5.976 8.35502L6.04519 9.53713L7.23096 9.6061L6.04519 9.67507L5.976 10.8572Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_478_8220"
          x="0"
          y="0.640015"
          width="12"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_478_8220"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_478_8220"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_478_8220"
          x="3.18653"
          y="6.82032"
          width="5.57909"
          height="5.57152"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.76733"
            result="effect1_foregroundBlur_478_8220"
          />
        </filter>
        <linearGradient
          id="paint0_linear_478_8220"
          x1="6.28948"
          y1="4.78958"
          x2="7.99556"
          y2="2.83353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE665" />
          <stop offset="1" stopColor="#FFC164" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_478_8220"
          x1="-1.74178"
          y1="5.10219"
          x2="0.898828"
          y2="12.8569"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.14601" stopColor="#FFDF8E" />
          <stop offset="1" stopColor="#FFFCA8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_478_8220"
          x1="0.472582"
          y1="7.08102"
          x2="2.51073"
          y2="-1.10119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E7919" />
          <stop offset="1" stopColor="#F2D955" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_478_8220"
          x1="4.91536"
          y1="12.6934"
          x2="12.2077"
          y2="11.0786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFDF8" />
          <stop offset="1" stopColor="#E1BC69" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_478_8220"
          x1="-0.914128"
          y1="1.72854"
          x2="5.2535"
          y2="1.44509"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0203454" stopColor="#997525" />
          <stop offset="0.349209" stopColor="#CBA349" />
          <stop offset="1" stopColor="#FFFDF8" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_478_8220"
          x1="5.97636"
          y1="9.73123"
          x2="5.97365"
          y2="4.00754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFFD7" />
          <stop offset="0.176669" stopColor="#FFF09E" />
          <stop offset="0.432173" stopColor="#FFCB66" />
          <stop offset="0.685688" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_478_8220"
          x1="3.52879"
          y1="9.2621"
          x2="5.9756"
          y2="9.6397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFFD3" />
          <stop offset="0.603219" stopColor="#FFFDD3" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BonusWheelDiamondIcon;
