import React from 'react';
import { ISvgIcon } from '../../../types/common.type';

const LobbyIconForMobile = ({
  size = 16,
  className,
  pathClassName,
  fill,
}: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14 2C14.3682 2 14.6667 2.29848 14.6667 2.66667V13.3333C14.6667 13.7015 14.3682 14 14 14H2.00004C1.63185 14 1.33337 13.7015 1.33337 13.3333V2.66667C1.33337 2.29848 1.63185 2 2.00004 2H14ZM12.6667 4H3.33337V5.33333H12.6667V4Z"
        fill={fill}
        className={pathClassName}
      />
    </svg>
  );
};

export default LobbyIconForMobile;
