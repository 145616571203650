import { makeStyles } from '@mui/styles';
import { MouseEventHandler, ReactNode } from 'react';
import { isMobile } from 'react-device-detect';

interface IHeaderButtonContainer {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const useStyles = makeStyles(() => ({
  root: {
    background: '#1F2937',
    width: isMobile ? '32px' : '40px',
    height: isMobile ? '32px' : '40px',
    borderRadius: '12px',
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background 0.3s',
    '&:hover': {
      background: '#374151',
    },
    '&:active': {
      background: '#374151',
    },
  },
}));

const HeaderButtonContainer = ({
  children,
  onClick,
}: IHeaderButtonContainer) => {
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={onClick}>
      {children}
    </div>
  );
};

export default HeaderButtonContainer;
