import Blog from '../../components/Blogs/Blog';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { IBlog } from '../../types/common.type';
import { getBlogList } from '../../api/services/Blog.api';
import { bindActionCreators } from 'redux';
import { changeBlog } from '../../redux/actions/blogAction';
import { connect } from 'react-redux';

interface BlogContainerProps {
  blogs: IBlog[];
  changeBlog: (blogs: IBlog[]) => void;
  lang: string;
}

const BlogContainer = ({ blogs, changeBlog, lang }: BlogContainerProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [bannerBlogs, setBannerBlogs] = useState<IBlog[]>([]);
  const [filteredByLang, setFilterByLang] = useState<IBlog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const filterBlogsByLang = (blogs: IBlog[]) => {
    setFilterByLang(blogs.filter((blog: IBlog) => blog.lang === lang));
  };
  const filterBannerBlogs = (blogs: IBlog[]) => {
    setBannerBlogs(blogs.filter((blog: IBlog) => blog.is_banner === 1));
  };

  useEffect(() => {
    setLoading(true);
    if (blogs.length) {
      setLoading(false);
      return;
    }
    getBlogList()
      .then((res) => {
        changeBlog(res.data.data.reverse());
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    filterBlogsByLang(blogs);
  }, [blogs, lang]);

  useEffect(() => {
    filterBannerBlogs(filteredByLang);
  }, [filteredByLang]);

  return (
    <Blog blogs={filteredByLang} bannerBlogs={bannerBlogs} loading={loading} />
  );
};

const mapStateToProps = (state: any) => ({
  blogs: state.blogs.blogItems,
  lang: state.user.language,
});

const mapDispatchToProps = (dispatch: any) => ({
  changeBlog: bindActionCreators(changeBlog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogContainer);
