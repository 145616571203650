import React, { FC } from 'react';
import { Box } from '@mui/material';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import { isMobile } from 'react-device-detect';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '24px',
  },
  buttonsInnerContainer: {
    width: isMobile ? 'auto' : '176px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  circleButton: {
    width: '6px',
    height: '6px',
    borderRadius: '50%', // Use borderRadius to make it a circle
    backgroundColor: 'red', // Example background color for the circle
    cursor: 'pointer',
  },
  circleButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    margin: isMobile ? '0px 6px' : '0px',
  },
}));

interface RankSystemPaginatioinProps {
  handleNextRank: () => void;
  handlePrevRank: () => void;
  currentPage: number;
  pageSize: number;
  changeRankPage: (index: number) => void;
}

const RankSystemPaginatioin: FC<RankSystemPaginatioinProps> = ({
  handleNextRank,
  handlePrevRank,
  currentPage,
  changeRankPage,
}) => {
  const classes = useStyles();

  const PaginationCircleButton: FC<{ index: number }> = ({ index }) => {
    return (
      <Box
        className={classes.circleButton}
        onClick={() => changeRankPage(index)}
        style={{ background: currentPage === index ? '#FFF' : '#FFFFFF40' }}
      ></Box>
    );
  };

  return (
    <Box className={classes.buttonsContainer}>
      <Box className={classes.buttonsInnerContainer}>
        <CustomMinimalButton
          style={{
            padding: isMobile ? '6px' : '10px',
            height: isMobile ? '32px' : '40px',
            minWidth: '0px',
            width: isMobile ? '32px' : '40px',
          }}
          onClick={handlePrevRank}
          disabled={currentPage === 0}
        >
          <span
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <KeyboardArrowLeftIcon />
          </span>
        </CustomMinimalButton>

        <Box className={classes.circleButtonWrapper}>
          {[...Array(isMobile ? 12 : 4)].map((_, index) => (
            <PaginationCircleButton key={index} index={index} />
          ))}
        </Box>

        <CustomMinimalButton
          style={{
            padding: isMobile ? '6px' : '10px',
            height: isMobile ? '32px' : '40px',
            minWidth: '0px',
            width: isMobile ? '32px' : '40px',
          }}
          onClick={handleNextRank}
          disabled={currentPage >= (isMobile ? 11 : 3)}
        >
          <span
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <KeyboardArrowRightIcon />
          </span>
        </CustomMinimalButton>
      </Box>
    </Box>
  );
};

export default RankSystemPaginatioin;
