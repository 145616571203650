const PromotionCountDownIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2451 3.97869L13.2141 3.00977L14.1569 3.95258L13.1879 4.9215C14.009 5.94795 14.5 7.24996 14.5 8.66663C14.5 11.9804 11.8137 14.6666 8.5 14.6666C5.18629 14.6666 2.5 11.9804 2.5 8.66663C2.5 5.35292 5.18629 2.66663 8.5 2.66663C9.91667 2.66663 11.2187 3.15761 12.2451 3.97869ZM7.83333 5.33329V9.33329H9.16667V5.33329H7.83333ZM5.83333 0.666626H11.1667V1.99996H5.83333V0.666626Z"
        fill="white"
      />
    </svg>
  );
};

export default PromotionCountDownIcon;
