import { ISvgIcon } from '../../../../types/common.type';

const EthereumIcon = ({ className }: ISvgIcon) => {
  return (
    <svg
      width="107"
      height="32"
      viewBox="0 0 107 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M36.8028 13.6373H35.6754V11.7451C35.6763 11.6111 35.6481 11.4785 35.5929 11.3564C35.5376 11.2343 35.4566 11.1257 35.3554 11.0379C35.2502 10.9485 35.1269 10.8831 34.9938 10.8462C34.8608 10.8094 34.7214 10.802 34.5852 10.8245C34.3685 10.8625 34.1724 10.9765 34.0321 11.1459C33.8917 11.3154 33.8163 11.5293 33.8194 11.7493V13.6331H32.7602C32.5146 13.6364 32.2801 13.7355 32.1065 13.9092C31.933 14.083 31.8341 14.3176 31.8311 14.5632C31.8298 14.6856 31.853 14.807 31.8992 14.9203C31.9454 15.0336 32.0138 15.1365 32.1004 15.223C32.1869 15.3095 32.2898 15.3779 32.4031 15.4242C32.5164 15.4704 32.6378 15.4936 32.7602 15.4923H33.8194V21.6885C33.8197 21.935 33.9176 22.1712 34.0917 22.3456C34.2659 22.5199 34.502 22.6181 34.7484 22.6187C34.994 22.6154 35.2285 22.5163 35.4021 22.3425C35.5756 22.1688 35.6745 21.9341 35.6775 21.6885V15.4965H36.805C36.9274 15.498 37.0489 15.4749 37.1624 15.4287C37.2758 15.3826 37.3788 15.3142 37.4655 15.2277C37.5521 15.1411 37.6206 15.0381 37.6669 14.9248C37.7132 14.8114 37.7364 14.6899 37.7351 14.5675C37.735 14.4452 37.7107 14.3241 37.6638 14.2112C37.6169 14.0983 37.5482 13.9957 37.4616 13.9093C37.375 13.823 37.2723 13.7545 37.1593 13.7078C37.0462 13.6611 36.9251 13.6372 36.8028 13.6373Z"
        fill="#374151"
      />
      <path
        d="M25.2114 13.4806H25.2039C22.8146 13.4806 20.8711 15.5595 20.8711 18.1153C20.8711 20.671 22.8146 22.7499 25.2039 22.7499C26.8039 22.7499 28.2407 21.9851 28.9468 20.7542L28.9628 20.7233C29.0604 20.5408 29.0949 20.3311 29.061 20.127C29.0357 19.9756 28.9729 19.833 28.8782 19.7122C28.7836 19.5915 28.6601 19.4964 28.5191 19.4358C28.3182 19.3489 28.093 19.3354 27.8832 19.3978C27.6733 19.4602 27.4921 19.5945 27.3714 19.7771C26.838 20.5419 26.1682 20.8822 25.2082 20.8822C24.0412 20.8822 23.2263 20.0193 22.8999 19.0401H28.6247C28.8738 19.0403 29.1129 18.9418 29.2895 18.766C29.4661 18.5902 29.5657 18.3516 29.5666 18.1025C29.5559 15.551 27.605 13.4806 25.2114 13.4806ZM22.8999 17.1819C23.2252 16.2027 24.038 15.3387 25.2114 15.3387C26.357 15.3387 27.1996 16.1921 27.525 17.1819H22.8999Z"
        fill="#374151"
      />
      <path
        d="M44.3921 13.5019C43.6371 13.5019 42.9017 13.7417 42.2918 14.1867V8.11205C42.2916 7.86573 42.1936 7.62959 42.0194 7.45541C41.8452 7.28124 41.6091 7.18327 41.3628 7.18298C41.1172 7.18602 40.8826 7.28485 40.7088 7.4584C40.5351 7.63195 40.436 7.8665 40.4326 8.11205V21.6534C40.4317 21.7875 40.4598 21.9203 40.515 22.0426C40.5703 22.1649 40.6513 22.2737 40.7526 22.3617C40.8579 22.4509 40.9813 22.5162 41.1143 22.553C41.2472 22.5898 41.3866 22.5973 41.5228 22.575C41.7397 22.537 41.9359 22.4229 42.0763 22.2532C42.2167 22.0835 42.2919 21.8693 42.2886 21.6491V18.5558C42.2886 18.543 42.2886 18.4811 42.279 18.4086C42.279 18.3585 42.2684 18.3019 42.2673 18.2795V18.2411C42.2065 17.3035 42.4497 16.4683 42.935 15.9521C43.2977 15.5649 43.7884 15.3675 44.3942 15.3675C45.5836 15.3675 46.1254 16.4939 46.1254 17.5403V21.6534C46.124 21.7758 46.1471 21.8973 46.1932 22.0108C46.2394 22.1242 46.3078 22.2272 46.3943 22.3139C46.4809 22.4005 46.5838 22.469 46.6972 22.5153C46.8106 22.5616 46.9321 22.5848 47.0545 22.5835C47.3009 22.583 47.5371 22.4847 47.7112 22.3104C47.8854 22.1361 47.9833 21.8998 47.9836 21.6534V17.5329C47.9857 15.5787 46.727 13.5019 44.3921 13.5019Z"
        fill="#374151"
      />
      <path
        d="M87.9313 13.7238L87.911 13.7174C87.7733 13.6759 87.6278 13.6674 87.4863 13.6926C87.3447 13.7178 87.211 13.776 87.0961 13.8625C86.9792 13.9489 86.8841 14.0614 86.8182 14.191C86.7523 14.3206 86.7174 14.4637 86.7164 14.6091V17.248C86.7164 18.96 86.4796 20.9248 84.6374 20.9248C83.4481 20.9248 82.9062 19.7995 82.9062 18.7531V14.6134C82.906 14.3671 82.808 14.1309 82.6338 13.9567C82.4596 13.7826 82.2235 13.6846 81.9772 13.6843C81.7318 13.6876 81.4974 13.7866 81.3239 13.9601C81.1504 14.1336 81.0514 14.368 81.0481 14.6134V18.7574C81.0481 19.7974 81.3681 20.7734 81.9526 21.5073C82.2346 21.8591 82.5834 22.1517 82.979 22.3679C83.3746 22.5842 83.8092 22.72 84.2575 22.7674C84.7059 22.8148 85.1593 22.7729 85.5914 22.6441C86.0235 22.5154 86.4258 22.3023 86.775 22.0171C86.8392 22.1972 86.9579 22.3528 87.1147 22.4621C87.2715 22.5715 87.4585 22.6292 87.6497 22.6273C87.8962 22.627 88.1325 22.5288 88.3067 22.3544C88.4809 22.18 88.5788 21.9436 88.5788 21.6971V14.6262C88.5791 14.4269 88.5168 14.2325 88.4006 14.0706C88.2844 13.9086 88.1202 13.7873 87.9313 13.7238Z"
        fill="#374151"
      />
      <path
        d="M55.2273 13.4806H55.2199C52.8305 13.4806 50.886 15.5595 50.886 18.1153C50.886 20.671 52.8305 22.7499 55.2199 22.7499C56.8199 22.7499 58.2566 21.9851 58.9628 20.7542L58.9788 20.7233C59.0764 20.5408 59.1109 20.3311 59.0769 20.127C59.0517 19.9756 58.9889 19.833 58.8942 19.7122C58.7995 19.5915 58.676 19.4964 58.535 19.4358C58.3341 19.3489 58.109 19.3354 57.8991 19.3978C57.6893 19.4602 57.5081 19.5945 57.3873 19.7771C56.854 20.5419 56.1841 20.8822 55.2241 20.8822C54.0572 20.8822 53.2423 20.0193 52.9159 19.0401H58.6396C58.8888 19.0403 59.128 18.9418 59.3048 18.7661C59.4815 18.5903 59.5814 18.3517 59.5825 18.1025C59.5772 15.551 57.6241 13.4806 55.2273 13.4806ZM52.9159 17.1819C53.2423 16.2017 54.0572 15.3387 55.2241 15.3387C56.3697 15.3387 57.2124 16.1921 57.5377 17.1819H52.9159Z"
        fill="#374151"
      />
      <path
        d="M101.35 13.5018C100.793 13.5032 100.244 13.6331 99.7453 13.8814C99.2467 14.1297 98.8121 14.4896 98.4753 14.9333C98.1591 14.4878 97.7397 14.1254 97.2531 13.8771C96.7665 13.6287 96.227 13.5018 95.6807 13.5072C94.8996 13.5061 94.1394 13.7596 93.5153 14.2293C93.4603 14.09 93.372 13.9662 93.2583 13.8688C93.1531 13.7794 93.0297 13.714 92.8967 13.6771C92.7637 13.6403 92.6243 13.6329 92.4881 13.6554C92.2714 13.6934 92.0753 13.8074 91.935 13.9768C91.7946 14.1463 91.7192 14.3602 91.7223 14.5802V21.6533C91.7212 21.7875 91.7493 21.9203 91.8045 22.0425C91.8598 22.1648 91.9409 22.2737 92.0423 22.3616C92.2131 22.506 92.4298 22.5847 92.6535 22.5834C92.7056 22.5834 92.7577 22.5791 92.8092 22.5706C93.0275 22.5342 93.2255 22.4208 93.3673 22.2509C93.5092 22.0811 93.5855 21.866 93.5825 21.6448V18.6026C93.5939 18.5202 93.5939 18.4366 93.5825 18.3541V18.3466C93.5441 17.2405 93.7777 16.3914 94.2588 15.8933C94.6033 15.537 95.0695 15.36 95.6828 15.36C97.0492 15.36 97.414 16.7104 97.414 17.5072V21.6533C97.4126 21.7758 97.4356 21.8973 97.4818 22.0107C97.528 22.1241 97.5963 22.2272 97.6829 22.3138C97.7694 22.4004 97.8724 22.4689 97.9858 22.5152C98.0991 22.5615 98.2206 22.5847 98.3431 22.5834C98.5896 22.5831 98.8259 22.485 99.0001 22.3106C99.1743 22.1362 99.2721 21.8998 99.2721 21.6533V18.4533C99.2721 17.3866 99.7073 15.36 101.352 15.36C102.717 15.36 103.082 16.7104 103.082 17.5072V21.6533C103.081 21.7758 103.104 21.8973 103.15 22.0107C103.196 22.1241 103.265 22.2272 103.351 22.3138C103.438 22.4004 103.541 22.4689 103.654 22.5152C103.767 22.5615 103.889 22.5847 104.011 22.5834C104.258 22.5831 104.494 22.4851 104.669 22.3107C104.843 22.1363 104.941 21.8999 104.941 21.6533V17.5072C104.944 15.5658 103.684 13.5018 101.35 13.5018Z"
        fill="#374151"
      />
      <path
        d="M73.7926 13.4806H73.7841C71.3948 13.4806 69.4513 15.5595 69.4513 18.1153C69.4513 20.671 71.3948 22.7499 73.7841 22.7499C75.3841 22.7499 76.822 21.9851 77.527 20.7542L77.5441 20.7233C77.6417 20.5408 77.6762 20.3311 77.6422 20.127C77.617 19.9756 77.5542 19.833 77.4595 19.7122C77.3648 19.5915 77.2413 19.4964 77.1004 19.4358C76.8994 19.3489 76.6743 19.3354 76.4645 19.3978C76.2546 19.4602 76.0734 19.5945 75.9526 19.7771C75.4193 20.5419 74.7494 20.8822 73.7884 20.8822C72.6225 20.8822 71.8076 20.0193 71.4812 19.0401H77.2006C77.4499 19.0403 77.6891 18.9418 77.8658 18.7661C78.0426 18.5903 78.1424 18.3517 78.1436 18.1025C78.1382 15.551 76.1862 13.4806 73.7926 13.4806ZM71.4812 17.1819C71.8076 16.2017 72.6225 15.3387 73.7884 15.3387C74.935 15.3387 75.7777 16.1921 76.103 17.1819H71.4812Z"
        fill="#374151"
      />
      <path
        d="M66.5585 13.6416C65.7799 13.6416 65.0513 13.9681 64.4551 14.4342C64.4228 14.214 64.3127 14.0126 64.1447 13.8667C64.0395 13.7774 63.9161 13.7121 63.7831 13.6753C63.6501 13.6384 63.5107 13.631 63.3746 13.6534C63.1577 13.6914 62.9615 13.8053 62.821 13.9747C62.6805 14.1442 62.6048 14.3581 62.6076 14.5782V21.6278C62.6066 21.7506 62.6301 21.8723 62.6768 21.9859C62.7234 22.0995 62.7923 22.2026 62.8793 22.2892C62.9663 22.3759 63.0698 22.4443 63.1836 22.4904C63.2974 22.5365 63.4192 22.5595 63.542 22.5579C63.5952 22.5579 63.6484 22.5536 63.701 22.5451C63.9179 22.5071 64.1141 22.393 64.2545 22.2233C64.3948 22.0536 64.4701 21.8394 64.4668 21.6192V18.5259C64.4668 17.712 64.6801 15.7867 66.6961 15.4944C66.9279 15.4691 67.1417 15.3572 67.2947 15.1813C67.4477 15.0053 67.5288 14.7782 67.5217 14.5451C67.5217 14.4275 67.4979 14.3111 67.4517 14.203C67.4055 14.0948 67.3379 13.9971 67.253 13.9158C67.0664 13.7368 66.817 13.6383 66.5585 13.6416Z"
        fill="#374151"
      />
      <path
        d="M9.53237 22.2955L2.22571 18.0587L9.53237 28.175L16.838 18.0587L9.53237 22.3008V22.2955Z"
        fill="#374151"
      />
      <path
        d="M16.838 16.6997L9.53237 4.79468L2.22571 16.6997L9.53237 20.9419L16.838 16.6997Z"
        fill="#374151"
      />
    </svg>
  );
};

export default EthereumIcon;
