import RankWrapperInRankSystemPage from './RankWrapperInRankSystemPage';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RankSystemHowItWorks from './RankSystemHowItWorks';
import RankFAQ from './RankFAQ';
import { isMobile } from 'react-device-detect';
import RankSystemHeaderPart from './RankSystemHeaderPart';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: '#0D121D',
  },
  divider: {
    background: 'rgba(255, 255, 255, 0.05)',
    height: '1px',
    width: '100%',
    boxSizing: 'border-box',
    marginTop: isMobile ? '32px' : '48px',
    marginBottom: isMobile ? '32px' : '48px',
  },
}));
const RankSystemPage = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box
        style={{
          width: '100%',
          background:
            'linear-gradient(180deg, #030712 0%, rgba(3, 7, 18, 0) 100%)',
        }}
      >
        <Box
          maxWidth={'1048px'}
          width={'100%'}
          margin={'0px auto'}
          boxSizing={'border-box'}
          style={{
            paddingLeft: !isMobile ? '24px' : ' 12px',
            paddingRight: !isMobile ? '24px' : ' 12px',
          }}
        >
          <RankSystemHeaderPart />
        </Box>
      </Box>
      <Box className={classes.divider} />
      <Box
        maxWidth={'1030px'}
        width={'100%'}
        margin={'0px auto'}
        boxSizing={'border-box'}
        style={{
          paddingLeft: !isMobile ? '24px' : ' 12px',
          paddingRight: !isMobile ? '24px' : ' 12px',
        }}
      >
        <RankWrapperInRankSystemPage />
      </Box>
      <Box className={classes.divider} />
      <Box
        maxWidth={'1048px'}
        width={'100%'}
        margin={'0px auto'}
        boxSizing={'border-box'}
        style={{
          paddingLeft: !isMobile ? '24px' : ' 12px',
          paddingRight: !isMobile ? '24px' : ' 12px',
        }}
      >
        <RankSystemHowItWorks />
      </Box>
      <Box className={classes.divider} />
      <Box
        maxWidth={'1048px'}
        width={'100%'}
        margin={'0px auto'}
        boxSizing={'border-box'}
        style={{
          paddingLeft: !isMobile ? '24px' : ' 12px',
          paddingRight: !isMobile ? '24px' : ' 12px',
        }}
      >
        <RankFAQ />
      </Box>
    </Box>
  );
};

export default RankSystemPage;
