import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '48px',
    gap: '20px',
    maxWidth: '1160px',
    minWidth: '0px',
    margin: '0 auto',
    padding: isMobile ? '0 16px' : '0px 24px',
    justifyContent: 'center',
  },
  skeletonWrapper: {
    position: 'relative',
  },
  skeleton: {
    width: !isMobile ? '370px' : '350px',
    height: '481px',
    backgroundColor: 'rgb(43 54 80 / 23%)',
    borderRadius: '12px',
  },
  imageSkeleton: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    backgroundColor: 'rgb(43 60 80 / 75%)',
    width: !isMobile ? '370px' : '350px',
    height: '206px',
    borderRadius: '12px 12px 0px 0px',
  },
  innerBox: {
    position: 'absolute',
    bottom: 25,
    left: '24px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}));

const BlogTabsSkeleton = () => {
  const classes = useStyles();

  const tabSkeleton = () => {
    return (
      <Box className={classes.skeletonWrapper}>
        <Skeleton variant="rectangular" className={classes.skeleton} />
        <Skeleton variant="rectangular" className={classes.imageSkeleton} />
        <Box className={classes.innerBox}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Skeleton
              variant="text"
              sx={{
                fontSize: '24px',
                backgroundColor: 'rgb(43 60 80 / 75%)',
                width: '50px',
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: '24px',
                backgroundColor: 'rgb(43 60 80 / 75%)',
                width: '80px',
              }}
            />
          </Box>

          <Skeleton
            variant="text"
            sx={{
              fontSize: '24px',
              backgroundColor: 'rgb(43 60 80 / 75%)',
              width: !isMobile ? '250px' : '270px',
            }}
          />

          <Skeleton
            variant="text"
            sx={{
              fontSize: '24px',
              backgroundColor: 'rgb(43 60 80 / 75%)',
              width: !isMobile ? '200px' : '200px',
            }}
          />

          <Skeleton
            variant="text"
            sx={{
              fontSize: '24px',
              backgroundColor: 'rgb(43 60 80 / 75%)',
              width: !isMobile ? '250px' : '270px',
            }}
          />

          <Skeleton
            variant="text"
            sx={{
              fontSize: '24px',
              backgroundColor: 'rgb(43 60 80 / 75%)',
              width: '140px',
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      {Array.from({
        length: 6,
      }).map(() => tabSkeleton())}
    </div>
  );
};

export default BlogTabsSkeleton;
