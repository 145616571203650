import { useTranslation } from 'react-i18next';
import Container from '../common/BoxContainer/Container';
import CustomInput from '../common/Inputs/CustomInput';
import { ChangeEvent } from 'react';
import SearchIcon from '../../api/ui/svgIcons/SearchIcon';
import { Box } from '@mui/material';
import NoGameResult from '../GamesPage/common/NoGameResult';
import { IProvider } from '../../types/Game.type';
import { makeStyles } from '@mui/styles';
import ProviderCardItem from './ProviderCardItem';

interface IProviderPage {
  currentSearchValue: string;
  onChangeSearchValue: (value: string) => void;
  totalResult: number;
  onClearParams: () => void;
  loading: boolean;
  providers: IProvider[];
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  header: {
    ...theme.general.pageHeader,
    padding: '0px 20px',
  },
  headerText: {
    ...theme.general.pageHeaderTitle,
  },
  filterContainer: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
    alignItems: 'center',
    gap: '32px',
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column',
      gap: '16px',
    },

    '& .search-icon': {
      fill: '#6B7280',
    },
  },
  body: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '32px 20px 0px 20px',
    flexDirection: 'column',
    gap: '40px',
  },
}));

const Provider = ({
  currentSearchValue,
  onChangeSearchValue,
  totalResult,
  onClearParams,
  providers,
  loading,
}: IProviderPage) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Container>
          <span className={classes.headerText}>{t('providers')}</span>
        </Container>
      </div>
      <Container>
        <div className={classes.body}>
          <div className={classes.filterContainer}>
            <CustomInput
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onChangeSearchValue(event.target.value)
              }
              secondary={true}
              startAdornment={<SearchIcon pathClassName={'search-icon'} />}
              value={currentSearchValue}
            />
          </div>

          {totalResult === 0 && !loading ? (
            <NoGameResult onClearFilter={onClearParams} />
          ) : (
            <Box>
              <Box
                style={{
                  opacity: loading ? 0.3 : 1,
                  transition: 'opacity 0.3s ease-int-out',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    gap: '16px',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {providers.map((provider: IProvider, index: number) => (
                    <Box key={index}>
                      <ProviderCardItem provider={provider} />
                    </Box>
                  ))}
                  {Array.from({
                    length: 7,
                  }).map((_, index) => (
                    <Box key={`${index}_empty_card`} width={'180px'} />
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Provider;
