import CustomModal from '../../common/Modal/CustomModal';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { useState } from 'react';
import { sendFiles } from '../../../api/services/Settings.api';
import { useSnackbar } from 'notistack';
import ModalHeader from '../ModalHeader';
import IdentityItem from './IdentityItem';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  AddressModal: {
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    color: '#fff',
    minWidth: isMobile ? '0px' : '528px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },
  customButtonBox: {
    display: 'flex',
    margin: '32px 16px 16px',
    '& :first-child': {
      flex: 1,
    },
  },
}));

interface IAddressModal {
  isOpen: boolean;
  onClose: () => void;
}

const identityModal = ({ isOpen, onClose }: IAddressModal) => {
  const [loading, setLoading] = useState(false);
  const [addressDocument, setAddressDocument] = useState<File | null>(null);

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const removeAddressDocument = () => {
    setAddressDocument(null);
  };
  const attachDocument = (e: any) => {
    const file = e.target.files?.[0];
    setAddressDocument(file);
  };

  const handleVerify = () => {
    setLoading(true);
    const formData = new FormData();
    if (addressDocument) {
      formData.append('kyc_address', addressDocument);
    }
    sendFiles(formData)
      .then((res: any) => {
        enqueueSnackbar(res.data.data.message, {
          variant: 'success',
        });
        onClose();
      })
      .catch((err: any) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const children = (
    <Box className={classes.AddressModal}>
      <ModalHeader onClose={onClose} heading={t('AddressVerification')} />
      <IdentityItem
        name={t('AddressVerification')}
        onAttach={attachDocument}
        onRemove={removeAddressDocument}
        attachedDocument={addressDocument}
        description={t('proofOfResidence')}
      />
      <Box className={classes.customButtonBox}>
        <CustomPrimaryButton
          disabled={addressDocument === null}
          onClick={handleVerify}
          loading={loading}
          style={{
            textTransform: 'uppercase',
          }}
        >
          <div>{t('verify')}</div>
        </CustomPrimaryButton>
      </Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};

export default identityModal;
