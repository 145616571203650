import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RankBeginYourJourneyIcon from '../../api/ui/svgIcons/RankBeginYourJurneyIcon';
import RankPlayAndProgresIcon from '../../api/ui/svgIcons/RankPlayAndProgresIcon';
import RankClimbTheRanksIcon from '../../api/ui/svgIcons/RankClimbTheRanksIcon';
import RankReapTheRewardsIcon from '../../api/ui/svgIcons/RankReapTheRewardsIcon';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  root: {},
  titleContainer: {
    padding: '0px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    color: '#fff',
    marginBottom: isMobile ? '32px' : '48px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  bodyContainer: {
    borderRadius: '8px',
    background: '#111827',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    padding: '12px 16px 16px 16px',
    alignItems: isMobile ? 'flex-start' : 'center',
    gap: '24px',
    flexDirection: isMobile ? 'column' : 'row',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    boxSizing: 'border-box',
    width: '100%',
  },
  detailsTitle: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  detailsDescription: {
    color: '#FFFFFF80',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
  },
}));

const RankSystemHowItWorks = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const arr = [
    {
      title: t('rank.beginYourJourney'),
      description: t('rank.beginYourJourneyDescription'),
      icon: <RankBeginYourJourneyIcon />,
    },
    {
      title: t('rank.PlayProgress'),
      description: t('rank.PlayProgressDescription'),
      icon: <RankPlayAndProgresIcon />,
    },
    {
      title: t('rank.ClimbTheRanks'),
      description: t('rank.ClimbTheRanksDescription'),
      icon: <RankClimbTheRanksIcon />,
    },
    {
      title: t('rank.ReapTheRewards'),
      description: t('rank.ReapTheRewardsDescription'),
      icon: <RankReapTheRewardsIcon />,
    },
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <span className={classes.title}>{t('rankSystemHowItWorksTitle')}</span>
        <span className={classes.description}>
          {t('rankSystemHowItWorksDescription')}
        </span>
      </Box>

      <Box
        style={{
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {arr.map((item: any, i: number) => {
          return (
            <Box className={classes.bodyContainer} key={i}>
              <Box className={classes.icon}>{item.icon}</Box>
              <Box className={classes.details}>
                <span className={classes.detailsTitle}>{item.title}</span>
                <span className={classes.detailsDescription}>
                  {item.description}
                </span>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RankSystemHowItWorks;
