import GameContainer from './GameContainer';

const LotteryGameContainer = () => {
  return (
    <GameContainer
      categorySlugName={'lottery'}
      pageName={'airbetMenu.lottery'}
    />
  );
};

export default LotteryGameContainer;
