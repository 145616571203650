import { ISvgIcon } from '../../../../types/common.type';

const LiteCoinIcon = ({ className }: ISvgIcon) => {
  return (
    <svg
      width="107"
      height="32"
      viewBox="0 0 107 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.7898 6.43201C21.6823 6.4318 23.5322 6.99277 25.1058 8.04399C26.6794 9.0952 27.9059 10.5894 28.6303 12.3378C29.3546 14.0861 29.5442 16.0099 29.1752 17.866C28.8061 19.7221 27.8949 21.427 26.5569 22.7652C25.2188 24.1034 23.514 25.0148 21.6579 25.3841C19.8019 25.7534 17.878 25.5639 16.1296 24.8398C14.3812 24.1156 12.8869 22.8893 11.8355 21.3158C10.7841 19.7423 10.2229 17.8924 10.2229 16C10.2229 13.4626 11.2308 11.0291 13.0249 9.23479C14.819 7.44047 17.2524 6.43229 19.7898 6.43201ZM18.273 10.1152L16.785 15.7387L15.76 16.1355L15.2672 17.9808L16.2922 17.5936L15.4389 20.8107H24.5909L25.2192 18.4235H19.712L20.3402 16.0843L21.345 15.7067L21.8378 13.8613L20.832 14.2485L21.9242 10.112H18.273V10.1152Z"
        fill="#374151"
      />
      <path
        d="M33.5072 21.8421L36.0107 9.88904H38.464L36.3851 19.84H42.4651L42.0491 21.8378L33.5072 21.8421Z"
        fill="#374151"
      />
      <path
        d="M45.44 13.1829H47.776L45.9627 21.8421H43.6267L45.44 13.1829ZM46.1248 9.88904H48.4651L48.0246 12.0085H45.68L46.1248 9.88904Z"
        fill="#374151"
      />
      <path
        d="M48.8534 14.9194L49.2118 13.1829H50.3531L50.639 11.7962L53.3217 10.1824L52.6934 13.1829H54.1206L53.7601 14.9194H52.3254L51.567 18.5461C51.4319 19.2046 51.364 19.5797 51.3633 19.6714C51.3592 19.7498 51.3725 19.8281 51.4022 19.9008C51.4319 19.9734 51.4772 20.0386 51.535 20.0917C51.6491 20.192 51.855 20.2421 52.1547 20.2421C52.2614 20.2421 52.5163 20.224 52.9291 20.1856L52.5622 21.9221C52.1541 22.0041 51.7389 22.0452 51.3227 22.0448C50.4964 22.0448 49.8959 21.8855 49.5211 21.5669C49.1464 21.2483 48.9593 20.8071 48.9601 20.2432C48.9601 19.9772 49.0607 19.3632 49.2619 18.401L49.9873 14.9194H48.8534Z"
        fill="#374151"
      />
      <path
        d="M62.256 18.24H56.3893C56.3893 18.3328 56.3808 18.4032 56.3808 18.4533C56.3808 19.0293 56.5454 19.494 56.8747 19.8475C57.025 20.0174 57.2102 20.153 57.4176 20.2451C57.6251 20.3371 57.8499 20.3834 58.0768 20.3808C58.854 20.3808 59.4585 19.9783 59.8901 19.1733L61.9872 19.52C61.5797 20.3627 61.035 20.9945 60.3531 21.4155C59.6631 21.8391 58.8672 22.0584 58.0576 22.048C56.8935 22.048 55.9477 21.6796 55.2203 20.9429C54.4928 20.2062 54.1287 19.232 54.128 18.0203C54.1115 16.8877 54.4568 15.7794 55.1136 14.8565C56.011 13.6064 57.291 12.9813 58.9536 12.9813C60.0124 12.9813 60.8548 13.3088 61.4805 13.9637C62.1063 14.6187 62.4188 15.536 62.4181 16.7157C62.4242 17.2284 62.3698 17.74 62.256 18.24ZM60.2837 16.8107C60.2891 16.704 60.2912 16.6293 60.2912 16.5749C60.2912 15.9349 60.1472 15.4539 59.8592 15.1317C59.7133 14.9717 59.534 14.8456 59.334 14.7625C59.1341 14.6794 58.9182 14.6412 58.7019 14.6507C58.4597 14.6515 58.22 14.7002 57.9967 14.7939C57.7734 14.8876 57.5708 15.0245 57.4005 15.1968C57.0172 15.5602 56.7581 16.0981 56.6229 16.8107H60.2837Z"
        fill="#374151"
      />
      <path
        d="M68.9834 18.5803L71.2533 18.9472C70.8792 19.9584 70.3249 20.7275 69.5903 21.2544C68.8462 21.7848 67.9515 22.0622 67.0378 22.0459C65.9349 22.0459 65.0599 21.717 64.4127 21.0592C63.7656 20.4014 63.4435 19.4973 63.4463 18.3467C63.4423 17.4252 63.643 16.5144 64.0341 15.68C64.4119 14.858 65.0285 14.1689 65.8037 13.7024C66.583 13.2239 67.4812 12.974 68.3957 12.9814C69.4176 12.9814 70.2328 13.2477 70.8416 13.7803C71.4503 14.3129 71.793 15.0141 71.8698 15.8838L69.6437 16.1206C69.5896 15.6363 69.4414 15.2807 69.1989 15.0539C68.9399 14.8191 68.5988 14.696 68.2496 14.7115C67.7812 14.7176 67.3316 14.8961 66.9866 15.2128C66.5955 15.5499 66.2951 16.0541 66.0853 16.7254C65.8862 17.3353 65.7804 17.9718 65.7717 18.6134C65.7717 19.1246 65.8968 19.5186 66.1472 19.7952C66.2634 19.9291 66.4076 20.0357 66.5696 20.1076C66.7317 20.1795 66.9075 20.2149 67.0847 20.2112C67.4818 20.2027 67.8641 20.059 68.1685 19.8038C68.5184 19.5321 68.79 19.1243 68.9834 18.5803Z"
        fill="#374151"
      />
      <path
        d="M72.7467 18.2464C72.7467 16.6421 73.199 15.3621 74.1035 14.4064C75.008 13.4506 76.197 12.9738 77.6704 12.976C78.9504 12.976 79.9481 13.3276 80.6635 14.0309C81.3789 14.7342 81.7376 15.6814 81.7398 16.8725C81.7637 18.2108 81.2836 19.5091 80.3947 20.5098C79.4916 21.5338 78.2887 22.0458 76.7862 22.0458C76.0343 22.0605 75.2902 21.8925 74.6176 21.5562C74.0126 21.2547 73.5185 20.7692 73.2064 20.1696C72.8997 19.5752 72.7419 18.9152 72.7467 18.2464ZM79.4155 16.5749C79.4155 16.0202 79.2537 15.5758 78.9302 15.2416C78.7682 15.0752 78.5732 14.9446 78.3577 14.8583C78.1422 14.7719 77.911 14.7317 77.679 14.7402C77.1897 14.7344 76.7138 14.9001 76.3339 15.2085C75.9392 15.5228 75.6324 15.9768 75.4134 16.5706C75.2095 17.0949 75.099 17.6509 75.087 18.2133C75.087 18.8334 75.2555 19.3255 75.5926 19.6896C75.7527 19.8667 75.9492 20.0072 76.1687 20.1015C76.3881 20.1957 76.6253 20.2415 76.864 20.2357C77.504 20.2357 78.0423 19.9612 78.479 19.4122C79.1012 18.6343 79.4119 17.6885 79.4112 16.5749H79.4155Z"
        fill="#374151"
      />
      <path
        d="M84.4096 13.1829H86.7499L84.9366 21.8421H82.5995L84.4096 13.1829ZM85.0944 9.88904H87.4347L86.9942 12.0085H84.6539L85.0944 9.88904Z"
        fill="#374151"
      />
      <path
        d="M89.0816 13.1829H91.2992L91.071 14.3072C91.5334 13.8889 92.0572 13.5439 92.624 13.2842C93.1052 13.0806 93.6226 12.9768 94.1451 12.9792C94.8562 12.9792 95.4173 13.1829 95.8283 13.5904C96.2393 13.9978 96.4445 14.5386 96.4437 15.2128C96.4437 15.5164 96.3542 16.0928 96.175 16.9418L95.1563 21.8421H92.816L93.8432 16.9248C93.9961 16.1909 94.0722 15.7536 94.0715 15.6128C94.0785 15.4832 94.0586 15.3536 94.0131 15.232C93.9675 15.1105 93.8974 14.9997 93.807 14.9066C93.7068 14.8175 93.59 14.7491 93.4634 14.7051C93.3367 14.6612 93.2026 14.6427 93.0688 14.6506C92.7261 14.6506 92.3456 14.7946 91.9275 15.0826C91.5067 15.3728 91.1671 15.7657 90.9408 16.224C90.7666 16.561 90.5707 17.2487 90.3531 18.2869L89.6118 21.8421H87.2715L89.0816 13.1829Z"
        fill="#374151"
      />
    </svg>
  );
};

export default LiteCoinIcon;
