import { ISvgIcon } from '../../../types/common.type';

const SettingIcon = ({
  size = 16,
  className,
  pathClassName,
  fill = '#9CA3AF',
}: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.63605 1.47375C7.52511 1.28765 8.45384 1.28177 9.36331 1.47192C9.48131 2.24495 9.93657 2.9593 10.6665 3.38071C11.3964 3.80211 12.2427 3.83925 12.9711 3.55489C13.5906 4.24747 14.0498 5.05471 14.3332 5.9177C13.7235 6.40641 13.3332 7.15735 13.3332 7.99949C13.3332 8.84215 13.724 9.59349 14.3342 10.0822C14.1934 10.5091 14.007 10.9282 13.7733 11.3328C13.5396 11.7376 13.27 12.1085 12.9706 12.444C12.2423 12.1598 11.3962 12.197 10.6665 12.6183C9.93717 13.0394 9.48204 13.753 9.36357 14.5253C8.47457 14.7114 7.54584 14.7172 6.63633 14.5271C6.51836 13.7541 6.06305 13.0397 5.33315 12.6183C4.60327 12.1969 3.75697 12.1598 3.02852 12.4442C2.40909 11.7516 1.94981 10.9443 1.66647 10.0813C2.27612 9.59262 2.66649 8.84162 2.66649 7.99949C2.66649 7.15689 2.27567 6.40553 1.66541 5.91685C1.80626 5.4899 1.99267 5.07088 2.22632 4.66617C2.45998 4.26147 2.72966 3.89053 3.02898 3.55507C3.75733 3.83923 4.60342 3.80202 5.33315 3.38071C6.06247 2.95963 6.51764 2.24609 6.63605 1.47375ZM7.99984 9.99949C9.10437 9.99949 9.99984 9.10409 9.99984 7.99949C9.99984 6.89495 9.10437 5.99951 7.99984 5.99951C6.89524 5.99951 5.99982 6.89495 5.99982 7.99949C5.99982 9.10409 6.89524 9.99949 7.99984 9.99949Z"
        fill={fill}
        className={pathClassName}
      />
    </svg>
  );
};

export default SettingIcon;
