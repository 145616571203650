import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { makeStyles } from '@mui/styles';
import { IBlog } from '../../types/common.type';
import AirbetPagination from '../common/Pagination/AirbetPagination';
import BlogCard from './BlogCard';
import { isMobile } from 'react-device-detect';
import { scrollToTop } from '../../util/appUtil';

const useStyles = makeStyles(() => ({
  blogsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '48px',
    gap: '20px',
    maxWidth: '1160px',
    minWidth: '0px',
    margin: '0 auto',
    padding: isMobile ? '0 16px' : '0px 24px',
    justifyContent: 'center',
  },
  tabList: {
    '& .MuiTabs-scroller': {
      overflow: 'scroll !important',
    },
  },
}));

interface TabsProps {
  blogs: IBlog[];
  tabs: string[];
  onSelectBlog: (slug: string) => void;
}

export default function Tabs({ blogs, tabs, onSelectBlog }: TabsProps) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);

  useEffect(() => {
    scrollToTop();
  }, [activePage]);

  const classes = useStyles();

  const handleTabHandler = (event: React.SyntheticEvent, newValue: number) => {
    setActivePage(1);
    setActiveTab(newValue);
  };
  const rowsPerPage = 6;

  const filteredBlogsByCategory = useMemo(() => {
    if (activeTab === 0) return blogs;
    return blogs.filter((blog: IBlog) => blog.category === tabs[activeTab]);
  }, [blogs, activeTab]);

  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 auto',
      }}
    >
      <TabContext value={activeTab}>
        <Box
          sx={{
            maxWidth: '1160px',
            minWidth: '0px',
            margin: '0 auto',
            overflow: 'scroll',
          }}
        >
          <TabList
            onChange={handleTabHandler}
            className={classes.tabList}
            sx={{}}
          >
            {tabs.map((tab: string, index: number) => (
              <Tab
                key={tab}
                label={tab}
                value={index}
                disableRipple
                sx={{
                  flex: 1,
                  color: '#9CA3AF',
                  fontWeight: 600,
                  lineHeight: '24px',
                  fontSize: '14px',
                  '&.Mui-selected': {
                    color: '#fff',
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        <div style={{ height: '1px', backgroundColor: '#1F2937' }} />
        <div className={classes.blogsContainer}>
          {filteredBlogsByCategory
            .slice(
              (activePage - 1) * rowsPerPage,
              (activePage - 1) * rowsPerPage + rowsPerPage
            )
            .map((blog: any) => (
              <BlogCard
                key={blog.id}
                {...blog}
                onSelectBlog={() => onSelectBlog(blog.slug)}
              />
            ))}

          <Box width={'374px'} />
          <Box width={'374px'} />
          <Box width={'374px'} />
        </div>
        <AirbetPagination
          initialPage={activePage}
          rowsPerPage={rowsPerPage}
          totalAmount={filteredBlogsByCategory.length}
          onPageChange={(page: number) => setActivePage(page)}
        />
      </TabContext>
    </Box>
  );
}
