import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ILeaderboard } from '../../types/LeaderBoard.type';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  leaderBoard: {
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    background: 'linear-gradient(92.35deg, #2E271B 0.37%, #161F32 98.56%)',
    cursor: 'pointer',
    width: '100%',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '0px',
    boxSizing: 'border-box',
    paddingTop: '8px',
    paddingBottom: '8px',
    height: '52px',
  },
  '@keyframes leaderBoardAnimation': {
    '0%': {
      opacity: 0,
    },
    '99%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  leaderBoardDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '12px',
    width: '100%',
    opacity: 1,
    animation: 'leaderBoardAnimation 0.2s ease',
    gap: '4px',
    overflow: 'hidden',
  },
  leaderBoardSubName: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  leaderBoardCountDownTimer: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
});

interface LeaderBoardBannerProps {
  isSidebarOpen: boolean;
  leaderBoard: ILeaderboard;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
}

const LeaderBoardBanner: FC<LeaderBoardBannerProps> = ({
  isSidebarOpen,
  leaderBoard,
  setIsSidebarOpen,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isStartingTimer, setIsStartingTimer] = useState<boolean>(false);

  const getTimeLeft = () => {
    const dataEnd = new Date(leaderBoard.end_date);
    const COUNTDOWN_TARGET_UTC = new Date(
      Date.UTC(
        dataEnd.getFullYear(),
        dataEnd.getMonth(),
        dataEnd.getDate(),
        dataEnd.getHours(),
        dataEnd.getMinutes(),
        dataEnd.getSeconds()
      )
    ).getTime();

    const now = new Date();
    const NOW_UTC = new Date(
      Date.UTC(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds()
      )
    ).getTime();
    const totalTimeLeft = COUNTDOWN_TARGET_UTC - NOW_UTC;
    const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
    const seconds = Math.floor((totalTimeLeft / 1000) % 60);
    if (!days && !hours && !minutes && !seconds) {
      setIsStartingTimer(false);
    }
    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(getTimeLeft);

  useEffect(() => {
    if (isStartingTimer) {
      const timer = setInterval(() => {
        setTimeLeft(getTimeLeft());
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [isStartingTimer, leaderBoard]);

  useEffect(() => {
    if (
      new Date() < new Date(leaderBoard.end_date) &&
      new Date() > new Date(leaderBoard.start_date)
    ) {
      setIsStartingTimer(true);
    } else {
      setIsStartingTimer(false);
    }
  }, [leaderBoard]);

  const showUpcomingOrFinishedTime = () => {
    if (new Date() > new Date(leaderBoard.start_date)) {
      return t('finished').toUpperCase();
    } else {
      return t('upcoming').toUpperCase();
    }
  };

  return (
    <div
      className={classes.leaderBoard}
      onClick={() => {
        if (isMobile) setIsSidebarOpen(false);
        navigate(`/leaderBoards/${leaderBoard.slug}`);
      }}
    >
      <img
        src={'/imgs/leaderBoard.png'}
        alt={'Bonus Wheel'}
        style={{
          marginLeft: '7px',
        }}
      />
      {isSidebarOpen && (
        <div className={classes.leaderBoardDescriptionContainer}>
          <span className={classes.leaderBoardSubName}>
            {leaderBoard.leaderboardinfos !== undefined &&
              leaderBoard.leaderboardinfos.name}
          </span>
          <span className={classes.leaderBoardCountDownTimer}>
            {isStartingTimer
              ? `${timeLeft.days > 9 ? timeLeft.days : `0${timeLeft.days}`}:${timeLeft.hours > 9 ? timeLeft.hours : `0${timeLeft.hours}`}:${timeLeft.minutes > 9 ? timeLeft.minutes : `0${timeLeft.minutes}`}:${timeLeft.seconds > 9 ? timeLeft.seconds : `0${timeLeft.seconds}`}`
              : showUpcomingOrFinishedTime()}
          </span>
        </div>
      )}
    </div>
  );
};

export default LeaderBoardBanner;
