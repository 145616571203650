import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IGameSchema } from '../../types/Game.type';
import GameCard from '../GameCard/GameCard';
import { isMobile } from 'react-device-detect';
import { bindActionCreators } from 'redux';
import {
  addGameToFavourite,
  removeGameFromFavourite,
} from '../../redux/actions/gameActions';
import { connect } from 'react-redux';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import { useNavigate } from 'react-router-dom';

interface ILobbyTabItemSlots {
  games: IGameSchema[];
  favourite: IGameSchema[];
  addToFavoriteGameList: (gameId: string) => void;
  removeFromFavoriteGameList: (gameId: string) => void;
  linkName: string;
  linkSrc: string;
  slugName?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minWidth: '0px',
    boxSizing: 'border-box',
    marginTop: '32px',
  },
}));
const LobbyTabItem = ({
  games,
  removeFromFavoriteGameList,
  addToFavoriteGameList,
  favourite,
  linkName,
  linkSrc,
  slugName,
}: ILobbyTabItemSlots) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.root}>
      <Container>
        <Box
          style={{
            gap: '16px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {games.map((game: IGameSchema, index: number) => (
            <Box key={index}>
              <GameCard
                gameProvider={game.provider}
                gameName={game.game_title}
                backgroundImageUrl={game.image || ''}
                isFavorite={
                  !!favourite.find((item: IGameSchema) => item.id === game.id)
                }
                isAvailable={!game.coming_soon && !game.is_restricted}
                comingSoon={!!game.coming_soon}
                addToFavorites={() => {
                  addToFavoriteGameList(String(game.id));
                }}
                removeFromFavorite={() =>
                  removeFromFavoriteGameList(String(game.id))
                }
                startPlaying={() => navigate(`/games/${game.slug}`)}
                isMobile={isMobile}
                agregator_image={game.agregator_image}
                gameId={game.game_id}
                categorySlug={slugName ? slugName : ''}
                index={index}
              />
            </Box>
          ))}
          {Array.from({
            length: 7,
          }).map((_, index) => (
            <Box key={`${index}_empty_card`} width={'150px'} />
          ))}
        </Box>
        <Box style={{}}>
          <CustomMinimalButton onClick={() => navigate(linkSrc)}>
            {linkName}
          </CustomMinimalButton>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  favourite: state.game.favourite,
});
const dispatchToProps = (dispatch: any) => ({
  addToFavoriteGameList: bindActionCreators(addGameToFavourite, dispatch),
  removeFromFavoriteGameList: bindActionCreators(
    removeGameFromFavourite,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(LobbyTabItem);
