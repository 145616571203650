import { ISvgIcon } from '../../../types/common.type';

const LockIcon = ({ size = 16, className, pathClassName }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.3235 8.1398H20.3235C20.8758 8.1398 21.3235 8.58752 21.3235 9.1398V21.1398C21.3235 21.6921 20.8758 22.1398 20.3235 22.1398H4.32349C3.77121 22.1398 3.32349 21.6921 3.32349 21.1398V9.1398C3.32349 8.58752 3.77121 8.1398 4.32349 8.1398H6.32349V7.1398C6.32349 3.82609 9.00978 1.1398 12.3235 1.1398C15.6372 1.1398 18.3235 3.82609 18.3235 7.1398V8.1398ZM11.3235 15.8722V18.1398H13.3235V15.8722C13.9213 15.5264 14.3235 14.8801 14.3235 14.1398C14.3235 13.0352 13.4281 12.1398 12.3235 12.1398C11.2189 12.1398 10.3235 13.0352 10.3235 14.1398C10.3235 14.8801 10.7257 15.5264 11.3235 15.8722ZM16.3235 8.1398V7.1398C16.3235 4.93066 14.5326 3.1398 12.3235 3.1398C10.1143 3.1398 8.32349 4.93066 8.32349 7.1398V8.1398H16.3235Z"
        fill="white"
        fillOpacity="0.25"
        className={pathClassName}
      />
    </svg>
  );
};

export default LockIcon;
