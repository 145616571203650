import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  getLocalStorageValue,
  registrationGoogleManagerHelper,
  setLocalStorageValue,
} from '../../util/appUtil';
import { getUserInfo } from '../../api/services/User.api';
import { bindActionCreators } from 'redux';
import {
  changeAccessToken,
  changeUserAuthorization,
  changeUserInfo,
} from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { IAccessToken, IUserInfo } from '../../types/common.type';
import { AppRoutePath } from '../../util/appRoutePath';

interface IUGoogleAuthContainer {
  changeUserAuthorizationFunction: (isAuthorized: boolean) => void;
  changeUserAccessToken: (data: IAccessToken) => void;
  changeUserInfo: (data: IUserInfo) => void;
}

const GoogleAuthContainer = ({
  changeUserAuthorizationFunction,
  changeUserAccessToken,
  changeUserInfo,
}: IUGoogleAuthContainer) => {
  const navigate = useNavigate();
  const { access_token } = useParams();

  useEffect(() => {
    if (access_token) {
      setLocalStorageValue('token', access_token);
      getUserInfo()
        .then((resp: any) => {
          const createDate: Date = new Date(resp.data.data.create_dt);
          const currentDate: Date = new Date();
          const timeDifference: number =
            currentDate.getTime() - createDate.getTime();
          const differenceInMinutes = timeDifference / (1000 * 60);
          if (differenceInMinutes <= 1) {
            registrationGoogleManagerHelper(
              resp.data.data.id,
              resp.data.data.register_country,
              'Google'
            );
          }
          if (resp.data.data) {
            const now = new Date();
            const access_tokenInfo: IAccessToken = {
              token: access_token,
              expire_at: new Date(now.getTime() + 60 * 60 * 1000).toISOString(),
            };
            const data: IUserInfo = {
              access_Token: {
                ...access_tokenInfo,
              },
              player: resp.data.data,
            };
            changeUserAuthorizationFunction(true);
            changeUserAccessToken(access_tokenInfo);
            changeUserInfo(data);
            setLocalStorageValue('userInfo', JSON.stringify(data));
            setLocalStorageValue('isAuthorized', true);
            setLocalStorageValue('token_expire_at', access_tokenInfo.expire_at);
            setLocalStorageValue('userId', resp.data.data.id);
            setLocalStorageValue('token', access_tokenInfo.token);

            if (getLocalStorageValue('lastOpenedPage', '') === '/sports') {
              navigate(AppRoutePath.SPORT());
            } else {
              navigate(AppRoutePath.HOME());
            }
          }
        })
        .catch(() => {});
    }
  }, [access_token]);

  return <div />;
};

const mapStateToProps = () => ({});

const dispatchToProps = (dispatch: any) => ({
  changeUserAccessToken: bindActionCreators(changeAccessToken, dispatch),
  changeUserInfo: bindActionCreators(changeUserInfo, dispatch),
  changeUserAuthorizationFunction: bindActionCreators(
    changeUserAuthorization,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(GoogleAuthContainer);
