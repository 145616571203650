import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { isMobile } from 'react-device-detect';

const LeaderBoardPlayerPositionSkeleton = () => {
  const skeletonArray = Array.from({ length: 10 }, (v, i) => i);

  return (
    <>
      {skeletonArray.map((index) => (
        <Box key={index} style={{ width: '100%', marginBottom: '60px' }}>
          <div style={{ position: 'relative' }}>
            <Skeleton
              variant="rounded"
              height={60}
              style={{
                width: '100%',
                position: 'absolute',
                zIndex: 1,
              }}
            />

            <Skeleton
              variant="circular"
              width={24}
              height={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '0%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgb(43 54 80 / 70%)',
                zIndex: 2,
                marginTop: '30px',
                marginLeft: '20px',
              }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: '1rem' }}
              width={!isMobile ? '100px' : '80px'}
              style={{
                position: 'absolute',
                top: '50%',
                left: '15%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgb(43 54 80 / 70%)',
                zIndex: 2,
                marginTop: '30px',
                marginLeft: !isMobile ? '20px' : '25px',
              }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: '1rem' }}
              width={!isMobile ? '130px' : '115px'}
              style={{
                position: 'absolute',
                top: '50%',
                left: '47%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgb(43 54 80 / 70%)',
                zIndex: 2,
                marginTop: '30px',
                marginLeft: !isMobile ? '20px' : '13px',
              }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: '1rem' }}
              width={!isMobile ? '115px' : '120px'}
              style={{
                position: 'absolute',
                top: '50%',
                left: '80%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgb(43 54 80 / 70%)',
                zIndex: 2,
                marginTop: '30px',
                marginLeft: !isMobile ? '30px' : '15px',
              }}
            />
          </div>
        </Box>
      ))}
    </>
  );
};

export default LeaderBoardPlayerPositionSkeleton;
