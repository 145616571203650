import { ISvgIcon } from '../../../types/common.type';

const QuestionMarkIcon = ({
  size = 16,
  className,
  pathClassName,
  fill,
}: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00001C1.3335 4.31811 4.31826 1.33334 8.00016 1.33334C11.682 1.33334 14.6668 4.31811 14.6668 8.00001C14.6668 11.6819 11.682 14.6667 8.00016 14.6667ZM7.3335 10V11.3333H8.66683V10H7.3335ZM8.66683 8.90341C9.63063 8.61654 10.3335 7.72368 10.3335 6.66668C10.3335 5.37801 9.28883 4.33334 8.00016 4.33334C6.86816 4.33334 5.92441 5.13947 5.7117 6.20898L7.01936 6.47052C7.11056 6.01216 7.51503 5.66668 8.00016 5.66668C8.55243 5.66668 9.00016 6.11439 9.00016 6.66668C9.00016 7.21894 8.55243 7.66668 8.00016 7.66668C7.63196 7.66668 7.3335 7.96514 7.3335 8.33334V9.33334H8.66683V8.90341Z"
        fill={fill}
        className={pathClassName}
      />
    </svg>
  );
};

export default QuestionMarkIcon;
