import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import LiveSportIcon from '../../api/ui/svgIcons/Sport/LiveSportIcon';
import SoccerSportIcon from '../../api/ui/svgIcons/Sport/SoccerSportIcon';
import TennisSportIcon from '../../api/ui/svgIcons/Sport/TennisSportIcon';
import BasketballSportIcon from '../../api/ui/svgIcons/Sport/BasketballSportIcon';
import CricketSportIcon from '../../api/ui/svgIcons/Sport/CricketSportIcon';
import AmericanFootballSportIcon from '../../api/ui/svgIcons/Sport/AmericanFootballSportIcon';
import IceHockeySportIcon from '../../api/ui/svgIcons/Sport/IceHockeySportIcon';
import BaseballSportIcon from '../../api/ui/svgIcons/Sport/BaseballSportIcon';
import HandballSportIcon from '../../api/ui/svgIcons/Sport/HandballSportIcon';
import { Box } from '@mui/material';
import { AppRoutePath } from '../../util/appRoutePath';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Swiper, SwiperSlide } from 'swiper/react';
import SportCardItem from './SportCardItem';
import StarIcon from '@mui/icons-material/Star';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    minWidth: '0px',
    marginTop: isMobile ? '24px' : '48px',
    gap: '6px',
  },
  headerContainer: {
    padding: isMobile ? '0px' : '0px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerContainerLeftSide: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  headerContainerTitle: {
    color: '#fff',
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    lineHeight: '24px',
  },
  headerContainerSeeAll: {
    marginLeft: '8px',
    color: '#84CC16',
    fontSize: isMobile ? '12px' : '16px',
    fontWeight: '600',
    lineHeight: '24px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  buttonsContainer: {
    gap: isMobile ? '8px' : '16px',
    display: 'flex',
  },
  gameContainerSwiper: {},
}));

export interface sportSwiperData {
  text: string;
  icon: any;
  link: string;
}

const SportSwiper = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const swiperRef = useRef<any>(null);

  const [isAtBeginning, setIsAtBeginning] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [visibleSlidesCount, setVisibleSlidesCount] = useState(0);
  const [partiallyVisibleSlides, setPartiallyVisibleSlides] = useState(
    new Set<number>()
  );

  const sportsData: sportSwiperData[] = [
    {
      icon: LiveSportIcon,
      link: '2Flive',
      text: 'betBy.live',
    },
    {
      icon: SoccerSportIcon,
      link: '2Fsoccer-1',
      text: 'betBy.soccer',
    },
    {
      icon: TennisSportIcon,
      link: '2Ftennis-5',
      text: 'betBy.tennis',
    },
    {
      icon: BasketballSportIcon,
      link: '2Fbasketball-2',
      text: 'betBy.basketball',
    },
    {
      icon: CricketSportIcon,
      link: '2Fcricket-21',
      text: 'betBy.cricket',
    },
    {
      icon: AmericanFootballSportIcon,
      link: '2Famerican-football-16',
      text: 'betBy.americanFootball',
    },
    {
      icon: IceHockeySportIcon,
      link: '2Fice-hockey-4',
      text: 'betBy.iceHockey',
    },
    {
      icon: BaseballSportIcon,
      link: '2Fbaseball-3',
      text: 'betBy.baseball',
    },
    {
      icon: HandballSportIcon,
      link: '2Fhandball-6',
      text: 'betBy.handball',
    },
  ];

  useEffect(() => {
    const updateButtonStates = () => {
      if (swiperRef.current) {
        setIsAtEnd(swiperRef.current.isEnd);
        setIsAtBeginning(swiperRef.current.isBeginning);
      }
    };

    updateButtonStates();

    const swiperInstance = swiperRef.current;
    swiperInstance.on('slideChange', updateButtonStates);

    return () => {
      swiperInstance.off('slideChange', updateButtonStates);
    };
  }, []);

  useEffect(() => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on('slideChangeTransitionEnd', updateSlideVisibility);
      swiperInstance.on('init', updateSlideVisibility);
    }
    updateSlideVisibility();
    return () => {
      if (swiperInstance) {
        swiperInstance.off('slideChangeTransitionEnd', updateSlideVisibility);
        swiperInstance.off('init', updateSlideVisibility);
      }
    };
  }, []);

  useEffect(() => {
    const updateSlideVisibility = () => {
      const swiper = swiperRef.current;
      if (!swiper || !swiper.slides) return;

      const newPartiallyVisibleSlides = new Set<number>();
      swiper.slides.forEach((slide: HTMLElement, index: number) => {
        const slideRect = slide.getBoundingClientRect();
        const swiperRect = swiper.el.getBoundingClientRect();

        const isPartiallyVisible =
          slideRect.left < swiperRect.left ||
          slideRect.right > swiperRect.right;

        if (isPartiallyVisible) {
          newPartiallyVisibleSlides.add(index);
        }
      });

      setPartiallyVisibleSlides(newPartiallyVisibleSlides);
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on('init', updateSlideVisibility);
      swiperInstance.on('slideChangeTransitionEnd', updateSlideVisibility);

      updateSlideVisibility();
    }

    window.addEventListener('resize', updateSlideVisibility);

    return () => {
      if (swiperInstance) {
        swiperInstance.off('init', updateSlideVisibility);
        swiperInstance.off('slideChangeTransitionEnd', updateSlideVisibility);
        window.removeEventListener('resize', updateSlideVisibility);
      }
    };
  }, []);

  useEffect(() => {
    const handleResizeOrInit = () => {
      const swiper = swiperRef.current;
      if (swiper) {
        swiper.update(); // Make sure Swiper updates its internal state
        updateVisibleSlidesCount(swiper);
      }
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on('init', handleResizeOrInit);
    }
    window.addEventListener('resize', handleResizeOrInit);

    // Initial calculation
    if (swiperInstance) {
      handleResizeOrInit();
    }

    return () => {
      window.removeEventListener('resize', handleResizeOrInit);
      if (swiperInstance) {
        swiperInstance.off('init', handleResizeOrInit);
      }
    };
  }, []);

  const updateVisibleSlidesCount = (swiper: any) => {
    if (!swiper || !swiper.slides.length || !swiper.slides[0]) return;

    // Assuming swiper.slides[0] exists and has uniform width across all slides
    const slideWidth =
      swiper.slides[0].offsetWidth + swiper.params.spaceBetween;
    const count = Math.floor(swiper.width / slideWidth);
    setVisibleSlidesCount(count);
  };

  const updateSlideVisibility = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;

    const newPartiallyVisibleSlides = new Set<number>();
    swiper.slides.forEach((slide: HTMLElement, index: number) => {
      const slideLeftEdge = slide.offsetLeft;
      const slideRightEdge = slideLeftEdge + slide.offsetWidth;
      const swiperVisibleLeftEdge = swiper.translate;
      const swiperVisibleRightEdge = swiperVisibleLeftEdge + swiper.width;

      if (
        slideLeftEdge < swiperVisibleLeftEdge ||
        slideRightEdge > swiperVisibleRightEdge
      ) {
        newPartiallyVisibleSlides.add(index);
      }
    });

    setPartiallyVisibleSlides(newPartiallyVisibleSlides);
  };

  const goLeft = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    const newIndex = Math.max(currentIndex - visibleSlidesCount, 0);
    swiper.slideTo(newIndex);
  };

  const goRight = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    const slidesCount = swiper.slides.length;
    const newIndex = Math.min(
      currentIndex + visibleSlidesCount,
      slidesCount - visibleSlidesCount
    );
    swiper.slideTo(newIndex);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        <Box className={classes.headerContainerLeftSide}>
          <StarIcon
            style={{
              color: '#9CA3AF',
              width: '16px',
              height: '16px',
            }}
          />
          <span className={classes.headerContainerTitle}>
            {t('airbetMenu.sports')}
          </span>
          <span
            className={classes.headerContainerSeeAll}
            onClick={() => navigate(AppRoutePath.PROVIDER_LIST_PAGE())}
          >
            {t('goToSports')}
          </span>
        </Box>
        <Box className={classes.buttonsContainer}>
          <CustomMinimalButton
            style={{
              minWidth: '0px',
              width: isMobile ? '32px' : '40px',
              padding: isMobile ? '6px' : '10px',
              height: isMobile ? '32px' : '40px',
            }}
            onClick={() => goLeft()}
            disabled={isAtBeginning}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowLeftIcon />
            </span>
          </CustomMinimalButton>
          <CustomMinimalButton
            style={{
              padding: isMobile ? '6px' : '10px',
              height: isMobile ? '32px' : '40px',
              minWidth: '0px',
              width: isMobile ? '32px' : '40px',
            }}
            onClick={() => goRight()}
            disabled={isAtEnd}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowRightIcon />
            </span>
          </CustomMinimalButton>
        </Box>
      </Box>

      <Box className={classes.gameContainerSwiper}>
        <Swiper
          spaceBetween={isMobile ? 8 : 12}
          slidesPerView={'auto'}
          onSlideChange={() => {}}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          style={{
            paddingTop: '10px',
          }}
          speed={500}
        >
          {sportsData.map((sport: sportSwiperData, index: number) => {
            const isPartiallyVisible = partiallyVisibleSlides.has(index);
            return (
              <SwiperSlide
                style={{
                  color: '#fff',
                  minWidth: '0px',
                  width: 'fit-content',
                  transition: 'opacity 0.2s ease-in-out',
                  opacity: isPartiallyVisible ? 0.1 : 1,
                }}
                key={index}
              >
                <Box key={index}>
                  <SportCardItem sport={sport} />
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};

export default SportSwiper;
