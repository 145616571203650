import { Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { getBaseUrlStatic } from '../../api/baseUrl';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { useRef, useState } from 'react';
import {
  getUserInfo,
  updateUserProfilePicture,
} from '../../api/services/User.api';
import { useSnackbar } from 'notistack';
import { IUserInfo } from '../../types/common.type';
import { getLocalStorageValue, setLocalStorageValue } from '../../util/appUtil';
import { bindActionCreators } from 'redux';
import {
  changeUserInfo,
  changeUserProfileInfo,
} from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { IUserProfileInfo } from '../../util/UserProfileHelper';

interface IUserProfileChangeImg {
  avatar: string | null;
  setStage: (stage: number) => void;
  onClose: () => void;
  changeUserInfo: (data: IUserInfo) => void;
  changeUserProfileInfo: (data: IUserProfileInfo) => void;
  userProfileInformation: IUserProfileInfo;
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '12px',
    border: '1px solid #1F2937',
    background: '#111827',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.50)',
    width: '426px',
  },
  header: {
    padding: '16px',
    borderBottom: '1px solid #1F2937',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    width: '100%',
    '& .MuiSvgIcon-root': {
      color: '#6B7280',
      width: '20px',
      height: '20px',
    },
  },
  headerText: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  body: {
    borderBottom: '1px solid #1F2937',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
    height: '184px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
  img: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
  },
  goBack: {
    '& .MuiSvgIcon-root': {
      color: '#6B7280',
      width: '16px',
      height: '16px',
    },
    cursor: 'pointer',
  },
  defaultAvatarsContainer: {
    background: '#0D121D',
    padding: '8px 16px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '& img': {
      cursor: 'pointer',
    },
  },
  defaultAvatarsTitle: {
    color: '#9CA3AF',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
  },
  defaultAvatars: {
    display: 'flex',
    gap: '24px',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  upload: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
}));

const UserProfileChangeImg = ({
  setStage,
  onClose,
  avatar,
  changeUserInfo,
  changeUserProfileInfo,
  userProfileInformation,
}: IUserProfileChangeImg) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [img, setImg] = useState<string | null>(null);
  const fileInputRef = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file && file.type.match(/^image\/(png|jpe?g|svg)$/)) {
      setImg(URL.createObjectURL(file));
      fileInputRef.current.file = file;
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const convertUrlToFile = async (imageUrl: string, fileName: string) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  };

  const handleImageClick = async (src: string) => {
    const file = await convertUrlToFile(src, 'avatar.png');
    fileInputRef.current.file = file;
    setImg(URL.createObjectURL(file));
  };

  const updateAvatar = () => {
    if (fileInputRef.current.file) {
      const formData = new FormData();
      formData.append('avatar', fileInputRef.current.file);
      setLoading(true);
      updateUserProfilePicture(formData)
        .then(() => {
          getUserInfo()
            .then((resp: any) => {
              const token = getLocalStorageValue('token', '');
              const isTokenExpired = getLocalStorageValue(
                'token_expire_at',
                new Date()
              );
              if (resp.data.data) {
                const data: IUserInfo = {
                  access_Token: {
                    token: token,
                    expire_at: isTokenExpired,
                  },
                  player: resp.data.data,
                };

                changeUserProfileInfo({
                  ...userProfileInformation,
                  avatar: resp.data.data.avatar,
                });

                changeUserInfo(data);
                setLocalStorageValue('userInfo', JSON.stringify(data));
                enqueueSnackbar(t('playerProfileUpdatedSuccess'), {
                  variant: 'success',
                });
                setStage(0);
              }
            })
            .catch(() => {})
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => {});
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          <Box
            display={'flex'}
            alignItems={'center'}
            className={classes.goBack}
            onClick={() => setStage(1)}
          >
            <ArrowBackIosIcon />
          </Box>
          <span className={classes.headerText}>{t('myProfile')}</span>
        </Box>
        <Box
          onClick={() => onClose()}
          display={'flex'}
          alignItems={'center'}
          style={{
            cursor: 'pointer',
          }}
          sx={{
            '& .MuiSvgIcon-root': {
              color: '#6B7280',
            },
          }}
        >
          <CloseIcon />
        </Box>
      </Box>
      <Box className={classes.body}>
        <Box className={classes.img}>
          {img ? (
            <img src={img} alt={'user_profile_img'} />
          ) : (
            <>
              {avatar ? (
                <img
                  src={getBaseUrlStatic() + avatar}
                  alt={'user_profile_img'}
                />
              ) : (
                <img
                  src={`/imgs/DefaultAvatars/Avatar.png`}
                  alt={'user_profile_img'}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      <Box margin={'16px'} className={classes.defaultAvatarsContainer}>
        <span className={classes.defaultAvatarsTitle}>
          {t('defaultAvatars')}
        </span>
        <Box className={classes.defaultAvatars}>
          <img
            alt={'default avatar'}
            src={'/imgs/DefaultAvatars/Avatar.png'}
            width={'48px'}
            onClick={() => handleImageClick('/imgs/DefaultAvatars/Avatar.png')}
          />
          <img
            alt={'default avatar'}
            src={'/imgs/DefaultAvatars/avatar1.png'}
            onClick={() => handleImageClick('/imgs/DefaultAvatars/avatar1.png')}
          />
          <img
            alt={'default avatar'}
            src={'/imgs/DefaultAvatars/avatar2.png'}
            onClick={() => handleImageClick('/imgs/DefaultAvatars/avatar2.png')}
          />
          <img
            alt={'default avatar'}
            src={'/imgs/DefaultAvatars/avatar3.png'}
            onClick={() => handleImageClick('/imgs/DefaultAvatars/avatar3.png')}
          />
          <img
            alt={'default avatar'}
            src={'/imgs/DefaultAvatars/avatar4.png'}
            onClick={() => handleImageClick('/imgs/DefaultAvatars/avatar4.png')}
          />
          <img
            alt={'default avatar'}
            src={'/imgs/DefaultAvatars/avatar5.png'}
            onClick={() => handleImageClick('/imgs/DefaultAvatars/avatar5.png')}
          />
          <img
            alt={'default avatar'}
            src={'/imgs/DefaultAvatars/avatar6.png'}
            onClick={() => handleImageClick('/imgs/DefaultAvatars/avatar6.png')}
          />
        </Box>
      </Box>
      <Box
        padding={'16px'}
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
        boxSizing={'border-box'}
      >
        <span className={classes.upload} onClick={() => handleUploadClick()}>
          {t('upload')}
        </span>
        <Box flex={1}>
          <CustomPrimaryButton
            style={{
              textTransform: 'uppercase',
              flex: 1,
              width: '100%',
            }}
            onClick={() => updateAvatar()}
            loading={loading}
          >
            {t('update')}
          </CustomPrimaryButton>
        </Box>
      </Box>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  userProfileInformation: state.user.userProfileInformation,
});

const dispatchToProps = (dispatch: any) => ({
  changeUserInfo: bindActionCreators(changeUserInfo, dispatch),
  changeUserProfileInfo: bindActionCreators(changeUserProfileInfo, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(UserProfileChangeImg);
