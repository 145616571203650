import React, { FC, useEffect, useState } from 'react';
import { getLeaderBoards } from '../../api/services/LeaderBoards';
import LeaderBoardBanner from '../../components/LeaderBoards/LeaderBoardBanner';
import { ILeaderboard, ILeaderBoardInfo } from '../../types/LeaderBoard.type';
import { getUserLanguage } from '../../util/appUtil';
import { connect } from 'react-redux';

interface LeaderBoardsBannerContainerProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  language: string;
}

const LeaderBoardsBannerContainer: FC<LeaderBoardsBannerContainerProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  language,
}) => {
  const [leaderBoards, setLeaderBoards] = useState<ILeaderboard[]>([]);

  useEffect(() => {
    loadLeaderBoards();
  }, [language]);

  const loadLeaderBoards = () => {
    getLeaderBoards({ page: 1, per_page: 50 })
      .then((res) => {
        const userLangCode = getUserLanguage();
        const respData = res.data.data.data;
        const leaderBoardData = respData.map((item: ILeaderboard) => ({
          ...item,
          leaderboardinfos:
            Array.isArray(item.leaderboardinfos) &&
            item.leaderboardinfos.find(
              (leaderboardinfo: ILeaderBoardInfo) =>
                userLangCode === leaderboardinfo.lang
            ),
        }));
        setLeaderBoards(leaderBoardData);
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <>
      {leaderBoards.map((leaderBoard: ILeaderboard) => (
        <LeaderBoardBanner
          key={leaderBoard.id}
          isSidebarOpen={isSidebarOpen}
          leaderBoard={leaderBoard}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      ))}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  language: state.user.language,
});

const dispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  dispatchToProps
)(LeaderBoardsBannerContainer);
