import CustomModal from '../../common/Modal/CustomModal';
import { Box, SelectChangeEvent } from '@mui/material';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { changePhone, phoneCode } from '../../../api/services/Settings.api';
import { useSnackbar } from 'notistack';
import { PHONE_COUNTRY_CODES } from '../../Account/constants';
import CustomSettingsSelect from '../CustomSettingsSelect';
import ModalHeader from '../ModalHeader';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#9CA3AF',
    fontWeight: 600,
    padding: '0px 32px',
  },
  contactModal: {
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    color: '#fff',
    minWidth: isMobile ? '0px' : '416px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },
  inputContainer: {
    display: 'flex',
    gap: '4px',
    width: '100%',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    paddingLeft: '16px',
    alignItems: 'center',
    margin: '4px 16px 0px 0px',
  },
  input: {
    border: 'none',
    backgroundColor: '#0D121D',
    outline: 'none',
    color: '#fff',
    paddingLeft: '16px',
    height: '48px',
    borderRadius: '8px',
    flex: '1',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
  note: {
    fontSize: '12px',
    lineHeight: '24px',
    color: '#4B5563',
    fontWeight: 600,
    padding: '0 32px',
    marginTop: '8px',
  },
  customButtonBox: {
    display: 'flex',
    margin: '32px 16px 16px 16px',
    '& :first-child': {
      flex: 1,
    },
  },
  country: {
    display: 'flex',
    color: '#67738A',
    justifyContent: 'space-between',
    gap: '50px',
  },
  countryName: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    textAlign: 'left',
  },
}));

interface IPhoneModal {
  isOpen: boolean;
  onClose: () => void;
  onChangePhone: (phoneNumber: string) => void;
  phoneNumber: string;
}

const PhoneUpdateModal = ({
  isOpen,
  onClose,
  onChangePhone,
  phoneNumber,
}: IPhoneModal) => {
  const [loading, setLoading] = useState(false);

  const [countryCode, setCountryCode] = useState(
    phoneNumber?.split('-')[0] || ''
  );

  const [currentPhone, setCurrentPhone] = useState(
    phoneNumber?.split('-')[1] || ''
  );
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const updatePhoneHandler = () => {
    if (!currentPhone) {
      enqueueSnackbar(t('EnterPhoneNumber'), {
        variant: 'warning',
      });
      return;
    }
    if (!countryCode) {
      enqueueSnackbar(t('ChooseCountryCode'), {
        variant: 'warning',
      });
      return;
    }

    changePhoneRequest();
  };

  const changePhoneRequest = () => {
    setLoading(true);
    const phoneNumber = countryCode + '-' + currentPhone;
    changePhone({ phone: phoneNumber })
      .then((res) => {
        if (res.data.success) {
          onChangePhone(phoneNumber);
          phoneCode().then((resPin: any) => {
            if (resPin.data.success) {
              enqueueSnackbar(res.data.data.message, {
                variant: 'success',
              });
              onClose();
            }
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.data.error, {
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };
  const countryCodesData = () => {
    return PHONE_COUNTRY_CODES.map((country) => {
      return {
        ...country,
        value: country.dial_code,
        renderer: () => (
          <Box width={'256px'}>
            <div className={classes.country}>
              <div className={classes.countryName}>{country.name}</div>
              <div>{country.dial_code}</div>
            </div>
          </Box>
        ),
      };
    });
  };

  const onChangeCountryCode = (e: SelectChangeEvent) => {
    setCountryCode(e.target.value as string);
  };

  const children = (
    <Box className={classes.contactModal}>
      <ModalHeader onClose={onClose} heading={t('updatePhone')} />
      <Box marginTop={'16px'}>
        <div className={classes.title}>
          <div> {t('phone')}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classes.inputContainer}>
            <Box width={'82px'}>
              <div>
                <CustomSettingsSelect
                  onChange={onChangeCountryCode}
                  value={countryCode}
                  data={countryCodesData()}
                  separated={true}
                />
              </div>
            </Box>
            <input
              className={classes.input}
              type="number"
              value={currentPhone}
              placeholder={t('EnterPhoneNumber')}
              onChange={(e) => setCurrentPhone(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.note}>{t('enterPhone')}</div>
      </Box>

      <Box className={classes.customButtonBox}>
        <CustomPrimaryButton onClick={updatePhoneHandler} loading={loading}>
          <div>{t('update')}</div>
        </CustomPrimaryButton>
      </Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};

export default PhoneUpdateModal;
