import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useState } from 'react';
import PasswordModal from './PasswordModal';
import { IPlayerAccountInfo } from '../../../containers/Settings/SecurityContainer';
import AuthenticationModal from './AuthenticationModal';
import { isMobile } from 'react-device-detect';
import SubpageHeader from '../SubpageHeader';
import SecurityDescription from './SecurityDescription';

const useStyles = makeStyles(() => ({
  root: {
    border: isMobile ? '' : '1px solid #1F2937',
    background: '#111827',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: isMobile ? 0 : '12px',
    position: isMobile ? 'fixed' : 'static',
  },
  body: {
    margin: isMobile ? '16px 8px' : '16px',
    background: '#0D121D',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: isMobile ? 'space-between' : 'center',
    gap: '16px',
    padding: isMobile ? '16px' : '16px 8px',
    flexDirection: isMobile ? 'column' : 'row',
  },
}));

const Security = ({ playerInfo, onClose }: IPlayerAccountInfo) => {
  const [openAuthenticationModal, setOpenAuthenticationModal] =
    useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);

  const openPasswordModalHandler = () => {
    setOpenPasswordModal(true);
  };

  const closePasswordModalHandler = () => {
    setOpenPasswordModal(false);
  };

  const openAuthenticationModalHandler = () => {
    setOpenAuthenticationModal(true);
  };

  const closeAuthenticationModalHandler = () => {
    setOpenAuthenticationModal(false);
  };

  if (!playerInfo.player || Object.keys(playerInfo.player).length === 0)
    return null;

  return (
    <div className={classes.root}>
      <SubpageHeader onClose={onClose} heading={t('security')} />
      <div
        style={{
          height: isMobile ? 'calc(100vh - 100px)' : 'auto',
          overflow: 'scroll',
        }}
      >
        <Box style={{ background: '#FFFFFF0D', height: '1px' }} />
        <Box className={classes.body}>
          <SecurityDescription
            onOpen={openPasswordModalHandler}
            securityType={t('password')}
            description={t('updatePassword')}
            buttonText={t('update')}
          />
          {openPasswordModal && (
            <PasswordModal
              isOpen={openPasswordModal}
              onClose={closePasswordModalHandler}
              havePassword={playerInfo.player.have_password}
            />
          )}
        </Box>
        <Box className={classes.body}>
          <SecurityDescription
            onOpen={openAuthenticationModalHandler}
            securityType={t('twoFactorAuthentication')}
            description={t('twoFactorAuthenticationEnable')}
            buttonText={t('enable')}
            disabled={playerInfo.player.google_2fa_is_active === 1}
          />
          {openAuthenticationModal && (
            <AuthenticationModal
              isOpen={openAuthenticationModal}
              onClose={closeAuthenticationModalHandler}
            />
          )}
        </Box>
      </div>
    </div>
  );
};

export default Security;
