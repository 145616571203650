import CustomGameFilterSelect from '../../common/Game/CustomGameFilterSelect';
import { useTranslation } from 'react-i18next';
import { Box, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SortIcon from '../../../api/ui/svgIcons/SortIcon';
import PopularIcon from '../../../api/ui/svgIcons/PopularIcon';
import FeaturedIcon from '../../../api/ui/svgIcons/FeaturedIcon';

interface ICustomSortBySelect {
  selectedSortByValue: string;
  onChangeSortBy: (selectedItem: string) => void;
}

const useStyles = makeStyles(() => ({
  renderItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& span': {
      color: '#9CA3AF',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
  selectedItem: {
    '& span': {
      color: '#fff',
      transition: 'color 0.3 ease-int-out',
    },
    '& path': {
      fill: '#84CC16',
      transition: 'fill 0.3 ease-int-out',
    },
  },
}));

const CustomSortBySelect = ({
  selectedSortByValue,
  onChangeSortBy,
}: ICustomSortBySelect) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const selectData = [
    {
      renderer: () => (
        <Box
          className={`${classes.renderItem} ${selectedSortByValue === 'a-z' && classes.selectedItem}`}
        >
          <SortIcon /> <span>{'A-Z'}</span>
        </Box>
      ),
      value: 'a-z',
    },
    {
      renderer: () => (
        <Box
          className={`${classes.renderItem} ${selectedSortByValue === 'z-a' && classes.selectedItem}`}
        >
          <SortIcon /> <span>{'Z-A'}</span>
        </Box>
      ),
      value: 'z-a',
    },
    {
      renderer: () => (
        <Box
          className={`${classes.renderItem} ${selectedSortByValue === 'popular' && classes.selectedItem}`}
        >
          <PopularIcon /> <span>{t('popular')}</span>
        </Box>
      ),
      value: 'popular',
    },
    {
      renderer: () => (
        <Box
          className={`${classes.renderItem} ${selectedSortByValue === 'trending' && classes.selectedItem}`}
        >
          <FeaturedIcon /> <span>{t('featured')}</span>
        </Box>
      ),
      value: 'trending',
    },
  ];

  const returnNames = () => {
    if (selectedSortByValue === 'a-z' || selectedSortByValue === 'z-a') {
      return selectedSortByValue.toUpperCase();
    }

    if (selectedSortByValue === 'trending') {
      return t('featured');
    }

    return t('popular');
  };

  return (
    <CustomGameFilterSelect
      value={selectedSortByValue}
      onChange={(event: SelectChangeEvent) => {
        onChangeSortBy(event.target.value);
      }}
      data={selectData}
      label={t('sortBy')}
      secondLabel={returnNames()}
      minWith={170}
    />
  );
};

export default CustomSortBySelect;
