import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RankExclusiveAccessIcon from '../../api/ui/svgIcons/RankExclusiveAccessIcon';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: isMobile ? '32px' : '48px',
    display: 'flex',
    boxSizing: 'border-box',
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    background:
      'linear-gradient(90deg, rgba(192, 139, 9, 0.20) 0%, rgba(178, 140, 47, 0.07) 100%), #0D121D',
    padding: '12px 16px 16px 16px',
    alignItems: isMobile ? 'flex-start' : 'center',
    gap: '24px',
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    gap: '8px',
  },
}));

const RankExclusiveAccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <RankExclusiveAccessIcon />
      <Box className={classes.body}>
        <span
          style={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '24px',
            color: '#fff',
          }}
        >
          {t('rank.ExclusiveAccess')}
        </span>
        <span
          style={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '24px',
            background: 'linear-gradient(0deg, #D0BA8C 0%, #FFF 100%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {t('rank.VIPHost')}
        </span>
        <span
          style={{
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            color: 'rgba(255, 255, 255, 0.50)',
          }}
        >
          {t('rank.ExclusiveAccessDescription')}
        </span>
      </Box>
    </Box>
  );
};

export default RankExclusiveAccess;
