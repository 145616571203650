import { connect } from 'react-redux';
import Policies from '../../components/Policies/Policies';
import { useEffect, useState } from 'react';
import { IStaticPage } from '../../types/common.type';
import { getStaticPages } from '../../api/services/Policies.api';
import { useSnackbar } from 'notistack';

interface PoliciesContainerProps {
  lang: string;
}

const PoliciesContainer = ({ lang }: PoliciesContainerProps) => {
  const [staticPages, setStaticPages] = useState<IStaticPage[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getStaticPages(lang)
      .then((res) => {
        setStaticPages(res.data.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.data.data.message, {
          variant: 'error',
        });
      });
  }, [lang]);

  return <Policies staticPages={staticPages} />;
};

const mapStateToProps = (state: any) => ({
  lang: state.user.language,
});

export default connect(mapStateToProps)(PoliciesContainer);
