import { IAction } from '../../types/common.type';
import {
  ADD_GAME_TO_FAVOURITE,
  CHANGE_GAME_CATEGORIES,
  CHANGE_INIT_FAVOURITE_GAME,
  CHANGE_PROVIDERS,
  REMOVE_GAME_TO_FAVOURITE,
} from '../constants/gameConstants';

const initialState = {
  categories: [],
  providers: [],
  favourite: [],
};

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case CHANGE_GAME_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case CHANGE_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
      };
    case CHANGE_INIT_FAVOURITE_GAME:
      return {
        ...state,
        favourite: action.payload,
      };
    case REMOVE_GAME_TO_FAVOURITE:
      return {
        ...state,
        favourite: action.payload,
      };
    case ADD_GAME_TO_FAVOURITE:
      return {
        ...state,
        favourite: action.payload,
      };
    default:
      return state;
  }
}
