import React, { useEffect, useState } from 'react';
import ResetPasswordPage from '../../components/ResetPassword/ResetPasswordPage';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import { resetPassword } from '../../api/services/Auth.api';
import { enqueueSnackbar } from 'notistack';

export interface IPassword {
  password: string;
  password_confirmation: string;
  token?: string | null;
  email?: string | null;
}

const ResetPasswordContainer = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const [password, setPassword] = useState<IPassword>({
    password: '',
    password_confirmation: '',
  });

  useEffect(() => {
    if (!token || !email) {
      navigate(AppRoutePath.HOME());
    }
  }, []);

  const onSubmit = () => {
    const submitData = { ...password, token, email };
    resetPassword(submitData)
      .then((resp) => {
        if (resp && resp.data && !resp.data.success) {
          enqueueSnackbar(resp.data.message, { variant: 'error' });
        } else {
          navigate(AppRoutePath.HOME());
          enqueueSnackbar(resp.data.message, { variant: 'success' });
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <ResetPasswordPage
      password={password}
      setPassword={setPassword}
      onSubmit={onSubmit}
    />
  );
};

export default ResetPasswordContainer;
