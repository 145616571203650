import CustomModal from '../../common/Modal/CustomModal';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ModalHeader from '../ModalHeader';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  enable2FAGoogle,
  verify2FACode,
} from '../../../api/services/Settings.api';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  modalBody: {
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    color: '#fff',
    minWidth: isMobile ? '0px' : '416px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#9CA3AF',
    fontWeight: 600,
    padding: '0px 32px',
  },
  inputContainer: {
    display: 'flex',
    gap: '4px',
    width: '100%',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    alignItems: 'center',
    margin: '4px 16px',
    borderRadius: '8px',
    backgroundColor: '#0D121D',
  },
  input: {
    border: 'none',
    backgroundColor: '#0D121D',
    outline: 'none',
    color: '#fff',
    paddingLeft: '16px',
    height: '48px',
    borderRadius: '8px',
    flex: '1',
  },
  copy: {
    padding: '0px 8px',
    borderRadius: '8px',
    marginRight: '16px',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#84CC16',
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  qrBox: {
    padding: '16px 16px 0px 16px',
  },
  qrWrapper: {
    padding: '6px 6px 0px 6px',
    backgroundColor: '#fff',
    borderRadius: '3px',
    '& svg': {
      width: '128px',
      height: '128px',
    },
  },
  qrContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '8px 8px 40px 8px',
    borderBottom: '1px solid #FFFFFF0D',
  },
  qrText: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    color: '#9CA3AF',
    textAlign: 'center',
  },
  customButtonBox: {
    display: 'flex',
    margin: '32px 16px 0px 16px',
    '& :first-child': {
      flex: 1,
    },
  },
}));

interface IAuthenticationModal {
  isOpen: boolean;
  onClose: () => void;
}

const AuthenticationModal = ({ isOpen, onClose }: IAuthenticationModal) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [passwordBoxIsOpen, setPasswordBoxIsOpen] = useState(true);
  const [qrImage, setQrImage] = useState(null);
  const [secretKey, setSecretKey] = useState('');
  const [showDigitInput, setShowDigitInput] = useState(false);
  const [pin, setPin] = useState('');

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const copySecretKey = () => {
    enqueueSnackbar(t('keyIsCopied'), {
      variant: 'success',
    });
    return navigator.clipboard.writeText(secretKey);
  };
  const enableGoogleRequest = () => {
    setLoading(true);
    enable2FAGoogle({ password: password })
      .then((res) => {
        if (res.data.success) {
          setPasswordBoxIsOpen(false);
          setQrImage(res.data.data.qr_image);
          setSecretKey(res.data.data.secret);
        }
      })
      .catch((err) => {
        enqueueSnackbar(t(err.response.data.data.error), {
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const verifyRequest = () => {
    setLoading(true);
    verify2FACode({ '2fa_code': pin })
      .then((res) => {
        onClose();
        enqueueSnackbar(t(res.data.data.message), {
          variant: 'success',
        });
      })
      .catch((err) => {
        if (err.response.data.data) {
          enqueueSnackbar(t(err.response.data.data.message), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(t(err.response.data.errors['2fa_code'][0]), {
            variant: 'error',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const children = (
    <Box className={classes.modalBody}>
      <ModalHeader onClose={onClose} heading={t('enable2FA')} />
      {(passwordBoxIsOpen || showDigitInput) && (
        <Box marginTop={'16px'}>
          <label htmlFor="2fa" className={classes.title}>
            {t(passwordBoxIsOpen ? 'enterPassword' : 'digitCode')}
          </label>
          <div style={{ display: 'flex' }}>
            <div className={classes.inputContainer}>
              <input
                type={passwordBoxIsOpen ? 'password' : 'text'}
                value={passwordBoxIsOpen ? password : pin}
                className={classes.input}
                onChange={
                  passwordBoxIsOpen
                    ? (e) => setPassword(e.target.value)
                    : (e) => setPin(e.target.value)
                }
                id="2fa"
                data-testid="2fa"
              />
            </div>
          </div>
        </Box>
      )}
      {qrImage && !showDigitInput && (
        <>
          <Box className={classes.qrBox}>
            <div className={classes.qrContent}>
              <div
                className={classes.qrWrapper}
                dangerouslySetInnerHTML={{ __html: qrImage }}
              />
              <div className={classes.qrText}>
                {t('scanQrCodeWithGoogleAuthenticator')}
              </div>
            </div>
          </Box>
          <Box marginTop={'16px'}>
            <div className={classes.title}>{t('securityKey')}</div>
            <div style={{ display: 'flex' }}>
              <div className={classes.inputContainer}>
                <input
                  type="text"
                  value={secretKey}
                  className={classes.input}
                  disabled={true}
                />
                <div className={classes.copy} onClick={copySecretKey}>
                  {t('copy')}
                </div>
              </div>
            </div>
          </Box>
        </>
      )}
      <Box className={classes.customButtonBox}>
        <CustomPrimaryButton
          loading={loading}
          onClick={
            passwordBoxIsOpen
              ? enableGoogleRequest
              : showDigitInput
                ? verifyRequest
                : () => setShowDigitInput(true)
          }
          disabled={showDigitInput ? pin.length !== 6 : password === ''}
          style={{
            textTransform: 'upperCase',
          }}
        >
          {showDigitInput ? t('confirm') : t('next')}
        </CustomPrimaryButton>
      </Box>
      <Box className={classes.qrBox}></Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};
export default AuthenticationModal;
