import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { KeyboardArrowLeft } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  modalHeader: {
    display: 'flex',
    justifyContent: isMobile ? 'flex-start' : 'space-between',
    alignItems: 'center',
    padding: isMobile ? '16px 8px' : '16px',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 600,
    borderBottom: '1px solid #FFFFFF0D',
    gap: '10px',
    backgroundColor: isMobile ? '#0D121D' : '',
  },
  icon: {
    display: 'flex',
    cursor: 'pointer',
    color: '#6B7280',
  },
}));

interface IModalHeader {
  onClose: () => void;
  heading: string;
}

const ModalHeader = ({ onClose, heading }: IModalHeader) => {
  const classes = useStyles();

  return (
    <div className={classes.modalHeader}>
      {isMobile && (
        <KeyboardArrowLeft
          onClick={onClose}
          style={{ fontSize: '20px' }}
          className={classes.icon}
        />
      )}
      <div>{heading}</div>
      {!isMobile && (
        <div onClick={onClose} className={classes.icon}>
          <CloseIcon sx={{ width: '16px', color: '#6B7280' }} />
        </div>
      )}
    </div>
  );
};

export default ModalHeader;
