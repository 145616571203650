import { ISvgIcon } from '../../../types/common.type';

const ArrowIcon = ({ size = 16, className, pathClassName }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8 6.66667L12 10.6667L4 10.6667L8 6.66667Z"
        fill="#9CA3AF"
        className={pathClassName}
      />
    </svg>
  );
};

export default ArrowIcon;
