import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import CustomSecondaryButton from '../common/Buttons/CustomSecondaryButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import EmojiPickerComponent from '../Emojy/EmojiPickerComponent';
import Giphy from './Giphy/Giphy';

const useStyles = makeStyles(() => ({
  root: {
    border: '0px solid rgba(255, 255, 255, 0.05)',
    background: '#0D121D',
    boxShadow: '0px 2px 4px 0px rgba(3, 7, 18, 0.50)',
    padding: '12px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  authorizedContainer: {
    display: 'flex',
    boxSizing: 'border-box',
    gap: '16px',
    flexDirection: 'column',
  },
  unAuthorizedContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 16px',
    background: '#030712',
    borderRadius: '12px',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  loginToMessage: {
    color: '#6B7280',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  unAuthorizedContainerForButtons: {
    display: 'flex',
    gap: '8px',
  },
  ruleContainer: {
    padding: '0px 16px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  rules: {
    color: '#9CA3AF',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  inputContainer: {
    borderRadius: '12px',
    border: '2px solid',
    background: '#030712',
    boxShadow: '0px 1px 2px 0px #030712',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'border-color 0.3s ease-in-out',
    borderColor: '#1F2937',
    paddingRight: '5px',
    '&:hover': {
      borderColor: '#1F2937',
    },
  },
  messageTextCount: {
    color: '#6B7280',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  input: {
    width: '100%',
    height: '26px',
    maxHeight: '150px',
    lineHeight: '24px',
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    resize: 'none',
    overflowY: 'auto',
    padding: '8px 8px',
  },
}));

interface IChatFooter {
  isUserAuthorized: boolean;
  openLogin: () => void;
  openRegistration: () => void;
  sendMessage: any;
  textareaRef: any;
  message: string;
  setMessage: (info: string) => void;
}

const ChatFooter = ({
  isUserAuthorized,
  openLogin,
  openRegistration,
  sendMessage,
  textareaRef,
  message,
  setMessage,
}: IChatFooter) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const maxTextWith: number = 200;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const newHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${newHeight - 16}px`;
    }
  }, [message]);

  const onEmojyChange = (event: any) => {
    setMessage(`${message}${event.emoji}`);
  };

  const handleKeyDown = (e: any) => {
    if (
      e.key === 'Enter' &&
      !e.shiftKey &&
      message &&
      message.trim().length <= 200
    ) {
      e.preventDefault();
      sendMessage(message);
      setMessage('');
    }
  };

  return (
    <Box className={classes.root}>
      {isUserAuthorized ? (
        <Box className={classes.authorizedContainer}>
          <Box
            className={classes.inputContainer}
            style={{
              borderColor: isFocused ? '#3B82F6' : '',
            }}
          >
            <textarea
              ref={textareaRef}
              className={classes.input}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onKeyDown={handleKeyDown}
            />
            <Box
              display={'flex'}
              alignItems={'center'}
              alignContent={'flex-start'}
              height={'100%'}
              boxSizing={'border-box'}
              flexWrap={'wrap'}
              width={'44px'}
              marginTop={'20px'}
            >
              <Giphy sendMessage={sendMessage} />
              <EmojiPickerComponent onEmojyChange={onEmojyChange} />
            </Box>
          </Box>
          <Box className={classes.ruleContainer}>
            <span className={classes.rules}>{t('rules')}</span>
            <Box display={'flex'} alignItems={'center'} gap={'16px'}>
              <span className={classes.messageTextCount}>
                {message.trim().length}/{maxTextWith}
              </span>
              <CustomPrimaryButton
                onClick={() => {
                  sendMessage(message);
                  setMessage('');
                }}
                style={{
                  textTransform: 'uppercase',
                  padding: '4px 8px',
                }}
                disabled={message.trim().length > 200}
              >
                {t('send')}
              </CustomPrimaryButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.unAuthorizedContainer}>
          <span className={classes.loginToMessage}>{t('loginToMessage')}</span>
          <Box className={classes.unAuthorizedContainerForButtons}>
            <CustomSecondaryButton
              onClick={() => openLogin()}
              style={{
                padding: '3px 8px',
              }}
            >
              {t('login')}
            </CustomSecondaryButton>
            <CustomPrimaryButton
              onClick={() => openRegistration()}
              style={{
                padding: '3px 8px',
              }}
            >
              {t('header.joinNow')}
            </CustomPrimaryButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatFooter;
