import React, { FC } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Container from '../common/BoxContainer/Container';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  flexContainer: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
      height: '56px',
    },
    '& .MuiButtonBase-root': {
      flex: 1,
      minHeight: '0px',
      minWidth: '0px',
      padding: '12px 0px 12px 0px',
      '& span': {
        transition: 'color 0.2s ease',
        color: '#9CA3AF',
      },
      '& :hover': {
        '& span': {
          color: '#fff !important',
        },
      },
    },
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  menuText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    '&:hover': {
      color: '#FFF',
    },
  },
}));

interface AirbetSwitcherProps {
  onChange: (
    selectedTab: 'all' | 'inprogress' | 'upcoming' | 'archived'
  ) => void;
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const PromotionSwitcher: FC<AirbetSwitcherProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState<
    'all' | 'inprogress' | 'upcoming' | 'archived'
  >('inprogress');

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: 'all' | 'inprogress' | 'upcoming' | 'archived'
  ) => {
    onChange(newValue);
    setValue(newValue);
  };

  return (
    <Box
      style={{
        background: '#111827',
      }}
    >
      <Container>
        <Tabs
          TabIndicatorProps={{
            sx: {
              height: '2px',
              bgcolor: '#1D4ED8',
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.flexContainer}
        >
          <Tab
            label={
              <span
                className={`${classes.menuText} ${classes.tabLabel}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span
                  style={{ color: value === 'inprogress' ? '#FFF' : '#9CA3AF' }}
                >
                  {t('inprogress')}
                </span>
              </span>
            }
            {...a11yProps(1)}
            disableRipple
            value={'inprogress'}
          />

          <Tab
            label={
              <span
                className={`${classes.menuText} ${classes.tabLabel}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span
                  style={{ color: value === 'upcoming' ? '#FFF' : '#9CA3AF' }}
                >
                  {t('upcoming')}
                </span>
              </span>
            }
            {...a11yProps(2)}
            disableRipple
            value={'upcoming'}
          />

          <Tab
            label={
              <span
                className={`${classes.menuText} ${classes.tabLabel}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span
                  style={{ color: value === 'archived' ? '#FFF' : '#9CA3AF' }}
                >
                  {t('archived')}
                </span>
              </span>
            }
            {...a11yProps(3)}
            disableRipple
            value={'archived'}
          />
          <Tab
            label={
              <span className={`${classes.menuText} ${classes.tabLabel}`}>
                <span style={{ color: value === 'all' ? '#FFF' : '#9CA3AF' }}>
                  {t('all')}
                </span>
              </span>
            }
            {...a11yProps(0)}
            disableRipple
            value={'all'}
          />
        </Tabs>
      </Container>
    </Box>
  );
};

export default PromotionSwitcher;
