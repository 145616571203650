import { IPromotionDataType } from './PromotionContainer';
import { useTranslation } from 'react-i18next';
import PromotionPageItem from './PromotionPageItem';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Container from '../common/BoxContainer/Container';
import PromotionSwitcher from './PromotionSwitcher';
import AirbetPagination from '../common/Pagination/AirbetPagination';
import { Box } from '@mui/material';
import { scrollToTop } from '../../util/appUtil';
import PromotionPageItemSkeleton from './PromotionPageItemSkeleton';

interface IPromotionPage {
  promotions: IPromotionDataType[];
  loading: boolean;
  selectedType: string;
  setSelectedType: (
    type: 'all' | 'inprogress' | 'upcoming' | 'archived'
  ) => void;
}

const PromotionPage = ({
  promotions,
  setSelectedType,
  loading,
  selectedType,
}: IPromotionPage) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState<number>(1);
  const rowsPerPage: number = 10;

  useEffect(() => {
    scrollToTop();
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);
  }, [selectedType]);

  return (
    <div style={{ width: '100%', background: '#0D121D' }}>
      <div
        style={{
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          background:
            'linear-gradient(180deg, #030712 0%, rgba(3, 7, 18, 0.00) 100%)',
          height: '320px',
          gap: '8px',
          userSelect: 'none',
          margin: '0px auto',
        }}
      >
        <span
          style={{
            color: '#FFF',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
          }}
        >
          AirBet
        </span>
        <span
          style={{
            fontSize: '40px',
            fontWeight: 600,
            lineHeight: '32px',
            background: 'linear-gradient(180deg, #FFF 0%, #B3C7FD 100%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {t('airbetMenu.promotions')}
        </span>
        <span
          style={{
            color: '#9CA3AF',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
          }}
        >
          {t('promotionPageBannerDescription')}
        </span>
      </div>
      <PromotionSwitcher
        onChange={(type: 'all' | 'inprogress' | 'upcoming' | 'archived') =>
          setSelectedType(type)
        }
      />
      {loading && (
        <Container>
          <PromotionPageItemSkeleton />
        </Container>
      )}
      <Box style={{ display: loading ? 'none' : '' }}>
        <Container>
          <div
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              flexDirection: 'column',
              gap: '19px',
              padding: isMobile ? '0px 8px' : '0px 24px',
              marginTop: '32px',
              marginBottom: '32px',
            }}
          >
            {promotions
              .slice(
                (activePage - 1) * rowsPerPage,
                (activePage - 1) * rowsPerPage + rowsPerPage
              )
              .map((promotion: IPromotionDataType, index: number) => {
                return <PromotionPageItem promotion={promotion} key={index} />;
              })}
          </div>
          <AirbetPagination
            initialPage={activePage}
            rowsPerPage={rowsPerPage}
            totalAmount={promotions.length}
            onPageChange={(page: number) => setActivePage(page)}
          />
        </Container>
      </Box>
    </div>
  );
};

export default PromotionPage;
