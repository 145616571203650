import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  listContainerItem: {
    display: 'flex',
    padding: isMobile ? '0' : '8px',
    gap: '8px',
    borderRadius: '10px',
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'background 0.3s ease-in-out',
    '& span': {
      color: '#9CA3AF',
    },
    '&:hover': {
      '& span': {
        color: '#fff',
      },
    },
  },
  listContainerItemText: {
    transition: 'color .3s ease-in-out',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

interface IListContainerItem {
  active: boolean;
  text: string;
  slug: string;
}

const ListContainerItem = ({ active, text, slug }: IListContainerItem) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div
      className={classes.listContainerItem}
      onClick={() => navigate(`/policies/${slug}`)}
      style={{
        background: active && !isMobile ? '#0D121D' : '',
        color: active ? '#fff' : '',
      }}
    >
      <span
        className={classes.listContainerItemText}
        style={{
          color: active ? '#fff' : '',
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default ListContainerItem;
