import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Container from '../common/BoxContainer/Container';
import WalletIcon from '../../api/ui/svgIcons/WalletIcon';
import DepositIcon from '../../api/ui/svgIcons/DepositIcon';
import WithdrawIcon from '../../api/ui/svgIcons/WithdrawIcon';
import TransactionIcon from '../../api/ui/svgIcons/TransactionIcon';
import BuyCryptoIcon from '../../api/ui/svgIcons/BuyCryptoIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import { Box } from '@mui/material';
import DepositContainer from '../../containers/Account/DepositContainer';
import WithdrawContainer from '../../containers/Account/WithdrawContainer';
import BuyCryptoContainer from '../../containers/Account/BuyCryptoContainer';
import TransactionContainer from '../../containers/Account/TransactionContainer';
import WalletContainer from '../../containers/Account/WalletContainer';
import { isMobile } from 'react-device-detect';
import WalletMobileWrapper from './Mobile/WalletMobileWrapper';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    ...theme.general.pageHeader,
    padding: '0px 20px',
  },
  headerText: {
    ...theme.general.pageHeaderTitle,
  },
  body: {
    padding: '40px 20px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    overflow: 'hidden',
  },
  bodyContent: {
    display: 'flex',
    gap: '32px',
  },
  listContainer: {
    display: 'flex',
    width: '272px',
    padding: '8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '12px',
    border: '1px solid #1F2937',
    background: '#111827',
    height: '250px',
    minHeight: '0px',
    maxHeight: '250px',
    boxSizing: 'border-box',
  },
  listContainerItem: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '10px',
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'background 0.3s ease-in-out',
    '& span': {
      color: '#9CA3AF',
    },
    '&:hover': {
      '& span': {
        color: '#fff',
      },
    },
  },
  listContainerItemText: {
    transition: 'color 0.3s ease-in-out',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

const Account = () => {
  const classes = useStyles();
  const { accountType } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return isMobile ? (
    <Box width={'100%'} height={'100%'} boxSizing={'border-box'}>
      {accountType === 'deposit' && (
        <WalletMobileWrapper title={t('deposit')}>
          <DepositContainer />
        </WalletMobileWrapper>
      )}
      {accountType === 'withdraw' && (
        <WalletMobileWrapper title={t('withdraw')}>
          <WithdrawContainer />
        </WalletMobileWrapper>
      )}
      {accountType === 'buycrypto' && (
        <WalletMobileWrapper title={t('buyCrypto')}>
          <BuyCryptoContainer />
        </WalletMobileWrapper>
      )}
      {accountType === 'transactions' && (
        <WalletMobileWrapper title={t('transactions')}>
          <TransactionContainer />
        </WalletMobileWrapper>
      )}
    </Box>
  ) : (
    <div className={classes.root}>
      <div className={classes.header}>
        <Container>
          <span className={classes.headerText}>{t('wallet')}</span>
        </Container>
      </div>
      <div className={classes.body}>
        <Container>
          <div className={classes.bodyContent}>
            <div className={classes.listContainer}>
              <div
                className={classes.listContainerItem}
                onClick={() => navigate(AppRoutePath.WALLET())}
                style={{
                  background: accountType === 'wallet' ? '#0D121D' : '',
                }}
              >
                <WalletIcon />
                <span
                  className={classes.listContainerItemText}
                  style={{ color: accountType === 'wallet' ? '#fff' : '' }}
                >
                  {t('wallet')}
                </span>
              </div>
              <div
                className={classes.listContainerItem}
                onClick={() => navigate(AppRoutePath.DEPOSIT())}
                style={{
                  background: accountType === 'deposit' ? '#0D121D' : '',
                }}
              >
                <DepositIcon />
                <span
                  className={classes.listContainerItemText}
                  style={{ color: accountType === 'deposit' ? '#fff' : '' }}
                >
                  {t('deposit')}
                </span>
              </div>
              <div
                className={classes.listContainerItem}
                onClick={() => navigate(AppRoutePath.WITHDRAW())}
                style={{
                  background: accountType === 'withdraw' ? '#0D121D' : '',
                }}
              >
                <WithdrawIcon />
                <span
                  className={classes.listContainerItemText}
                  style={{ color: accountType === 'withdraw' ? '#fff' : '' }}
                >
                  {t('withdraw')}
                </span>
              </div>
              <div
                className={classes.listContainerItem}
                onClick={() => navigate(AppRoutePath.BUYCRYPTO())}
                style={{
                  background: accountType === 'buycrypto' ? '#0D121D' : '',
                }}
              >
                <BuyCryptoIcon />
                <span
                  className={classes.listContainerItemText}
                  style={{ color: accountType === 'buycrypto' ? '#fff' : '' }}
                >
                  {t('buyCrypto')}
                </span>
              </div>
              <div
                className={classes.listContainerItem}
                onClick={() => navigate(AppRoutePath.TRANSACTIONS())}
                style={{
                  background: accountType === 'transactions' ? '#0D121D' : '',
                }}
              >
                <TransactionIcon />
                <span
                  className={classes.listContainerItemText}
                  style={{
                    color: accountType === 'transactions' ? '#fff' : '',
                  }}
                >
                  {t('transactions')}
                </span>
              </div>
            </div>
            <Box width={'100%'} height={'100%'} boxSizing={'border-box'}>
              {accountType === 'wallet' && <WalletContainer />}
              {accountType === 'deposit' && <DepositContainer />}
              {accountType === 'withdraw' && <WithdrawContainer />}
              {accountType === 'buycrypto' && <BuyCryptoContainer />}
              {accountType === 'transactions' && <TransactionContainer />}
            </Box>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Account;
