const HideIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_501_3786)">
        <path
          d="M3.01361 3.95642L0.929066 1.87189L1.87188 0.929077L15.0712 14.1284L14.1284 15.0712L11.9218 12.8646C10.7876 13.5837 9.44249 14.0002 8.00016 14.0002C4.40537 14.0002 1.41473 11.4136 0.78772 8.00018C1.07884 6.41526 1.87951 5.00864 3.01361 3.95642ZM9.83843 10.7812L8.86243 9.80524C8.60129 9.93018 8.30889 10.0002 8.00016 10.0002C6.89556 10.0002 6.00013 9.10471 6.00013 8.00018C6.00013 7.69138 6.07011 7.39898 6.19505 7.13784L5.21907 6.16189C4.87005 6.68884 4.6668 7.32078 4.6668 8.00018C4.6668 9.84111 6.15919 11.3335 8.00016 11.3335C8.67949 11.3335 9.31143 11.1302 9.83843 10.7812ZM5.31627 2.50681C6.14747 2.17977 7.05283 2.00015 8.00016 2.00015C11.5949 2.00015 14.5856 4.58666 15.2126 8.00018C15.0044 9.13324 14.5358 10.1752 13.8712 11.0617L11.298 8.48851C11.3214 8.32911 11.3335 8.16604 11.3335 8.00018C11.3335 6.1592 9.84109 4.66682 8.00016 4.66682C7.83423 4.66682 7.67116 4.67893 7.51176 4.70233L5.31627 2.50681Z"
          fill="#9CA3AF"
        />
      </g>
      <defs>
        <clipPath id="clip0_501_3786">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HideIcon;
