import React, { FC } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import LobbyIconForMobile from '../../api/ui/svgIcons/LobbyIconForMobile';
import { makeStyles } from '@mui/styles';
import Container from '../common/BoxContainer/Container';
import SlotIcon from '../../api/ui/svgIcons/SlotIcon';
import LiveCasinoIcon from '../../api/ui/svgIcons/LiveCasinoIcon';
import TableGameIcon from '../../api/ui/svgIcons/TableGameIcon';
import GameShowIcon from '../../api/ui/svgIcons/GameShowIcon';
import AirbetOriginalMenuIcon from '../../api/ui/svgIcons/AirbetOriginalMenuIcon';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  flexContainer: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
      height: '56px',
    },
    '& .MuiButtonBase-root': {
      flex: 1,
      minHeight: '0px',
      minWidth: '0px',
      padding: '12px 0px 12px 0px',
      '& span': {
        transition: 'color 0.2s ease',
        color: '#9CA3AF',
      },
      '& :hover': {
        '& span': {
          color: '#fff !important',
        },
      },
    },
  },
  menuText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    '&:hover': {
      color: '#FFF',
    },
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

interface AirbetSwitcherProps {
  onChange: (selectedTab: string) => void;
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const AirbetSwitcher: FC<AirbetSwitcherProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState('lobby');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Box
      style={{
        background: '#111827',
      }}
    >
      <Container>
        <Tabs
          TabIndicatorProps={{
            sx: {
              bgcolor: '#1D4ED8',
              height: '2px',
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.flexContainer}
        >
          <Tab
            label={
              <span className={`${classes.menuText} ${classes.tabLabel}`}>
                <LobbyIconForMobile
                  size={16}
                  fill={value === 'lobby' ? '#FFF' : '#9CA3AF'}
                />
                <span style={{ color: value === 'lobby' ? '#FFF' : '#9CA3AF' }}>
                  {t('airbetMenu.lobby')}
                </span>
              </span>
            }
            {...a11yProps(0)}
            disableRipple
            value={'lobby'}
          />

          <Tab
            label={
              <span
                className={`${classes.menuText} ${classes.tabLabel}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <SlotIcon
                  size={16}
                  fill={value === 'slots' ? '#FFF' : '#9CA3AF'}
                />
                <span style={{ color: value === 'slots' ? '#FFF' : '#9CA3AF' }}>
                  {t('airbetMenu.slots')}
                </span>
              </span>
            }
            {...a11yProps(1)}
            disableRipple
            value={'slots'}
          />

          <Tab
            label={
              <span
                className={`${classes.menuText} ${classes.tabLabel}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <LiveCasinoIcon
                  size={16}
                  fill={value === 'liveCasino' ? '#FFF' : '#9CA3AF'}
                />
                <span
                  style={{ color: value === 'liveCasino' ? '#FFF' : '#9CA3AF' }}
                >
                  {t('airbetMenu.liveCasino')}
                </span>
              </span>
            }
            {...a11yProps(2)}
            disableRipple
            value={'liveCasino'}
          />

          <Tab
            label={
              <span
                className={`${classes.menuText} ${classes.tabLabel}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <TableGameIcon
                  size={16}
                  fill={value === 'tableGames' ? '#FFF' : '#9CA3AF'}
                />
                <span
                  style={{ color: value === 'tableGames' ? '#FFF' : '#9CA3AF' }}
                >
                  {t('airbetMenu.tableGames')}
                </span>
              </span>
            }
            {...a11yProps(3)}
            disableRipple
            value={'tableGames'}
          />

          <Tab
            label={
              <span
                className={`${classes.menuText} ${classes.tabLabel}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <GameShowIcon
                  size={20}
                  fill={value === 'gameShows' ? '#FFF' : '#9CA3AF'}
                />
                <span
                  style={{ color: value === 'gameShows' ? '#FFF' : '#9CA3AF' }}
                >
                  {t('airbetMenu.gameShows')}
                </span>
              </span>
            }
            {...a11yProps(4)}
            disableRipple
            value={'gameShows'}
          />

          <Tab
            label={
              <span
                className={`${classes.menuText} ${classes.tabLabel}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <AirbetOriginalMenuIcon
                  size={20}
                  fill={value === 'originals' ? '#FFF' : '#9CA3AF'}
                />
                <span
                  style={{ color: value === 'originals' ? '#FFF' : '#9CA3AF' }}
                >
                  {t('originals')}
                </span>
              </span>
            }
            {...a11yProps(4)}
            disableRipple
            value={'originals'}
          />
        </Tabs>
      </Container>
    </Box>
  );
};

export default AirbetSwitcher;
