const CricketSportIcon = () => {
  return (
    <svg
      className="bt2259"
      data-editor-id="expandedNavbarIcon"
      data-cy="sport-cricket"
      width="20"
      height="20"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.8967 18.8639L13.1361 20.1033L13.1724 20.067C13.9726 19.2668 15.1951 19.0684 16.2073 19.5745L16.4262 19.684C16.7636 19.8527 17.1711 19.7866 17.4378 19.5198L25.2204 11.7372C26.5895 10.3682 26.5895 8.14859 25.2204 6.77956C23.8514 5.41054 21.6318 5.41054 20.2628 6.77956L12.4802 14.5622C12.2134 14.8289 12.1473 15.2364 12.316 15.5738L12.4255 15.7927C12.9316 16.8049 12.7332 18.0274 11.933 18.8276L11.8967 18.8639ZM11.277 19.4836L9.80088 20.9597C9.14153 21.6191 8.16624 21.8493 7.28162 21.5544C7.02684 21.4695 6.74595 21.5358 6.55605 21.7257L6.00949 22.2723C5.66724 22.6145 5.66724 23.1694 6.00949 23.5117L8.48832 25.9905C8.83058 26.3328 9.38548 26.3328 9.72774 25.9905L10.2743 25.4439C10.4642 25.254 10.5305 24.9732 10.4456 24.7184C10.1507 23.8338 10.3809 22.8585 11.0403 22.1991L12.5164 20.723L11.277 19.4836ZM7.47068 10.5069C5.7766 10.5069 4.40328 9.13361 4.40328 7.43954C4.40328 5.74546 5.7766 4.37214 7.47068 4.37214C9.16475 4.37214 10.5381 5.74546 10.5381 7.43954C10.5381 9.13361 9.16475 10.5069 7.47068 10.5069ZM7.47068 9.63053C8.68073 9.63053 9.66168 8.64959 9.66168 7.43954C9.66168 6.22948 8.68073 5.24854 7.47068 5.24854C6.26062 5.24854 5.27968 6.22948 5.27968 7.43954C5.27968 8.64959 6.26062 9.63053 7.47068 9.63053ZM26.4599 5.54015C28.5134 7.59368 28.5134 10.9231 26.4599 12.9766L18.6773 20.7592C17.877 21.5595 16.6545 21.7578 15.6423 21.2517L15.4234 21.1423C15.086 20.9736 14.6785 21.0397 14.4118 21.3065L12.2797 23.4385C12.0898 23.6284 12.0235 23.9093 12.1084 24.1641C12.4033 25.0487 12.1731 26.024 11.5137 26.6834L10.9672 27.2299C9.94039 28.2567 8.27567 28.2567 7.24891 27.2299L4.77007 24.7511C3.74331 23.7243 3.74331 22.0596 4.77007 21.0328L5.31664 20.4863C5.97599 19.8269 6.95128 19.5967 7.8359 19.8916C8.09068 19.9765 8.37157 19.9102 8.56147 19.7203L10.6935 17.5882C10.9603 17.3215 11.0264 16.914 10.8577 16.5766L10.7483 16.3577C10.2422 15.3455 10.4405 14.123 11.2408 13.3227L19.0234 5.54015C21.0769 3.48662 24.4063 3.48662 26.4599 5.54015Z"></path>
    </svg>
  );
};

export default CricketSportIcon;
