import * as React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    padding: '4px 16px',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
    border: '2px solid #1F2937',
    borderRadius: '12px',
  },
}));

interface IBlogButton {
  action: () => void;
  buttonText: string;
}

const BlogButton = ({ action, buttonText }: IBlogButton) => {
  const classes = useStyles({});

  return (
    <>
      <div onClick={action} className={classes.button}>
        <span>{buttonText}</span>
      </div>
    </>
  );
};

export default BlogButton;
