import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import PlayButton from '../common/GameCard/PlayButton';
import { Box } from '@mui/material';
import FavouriteIcon from '../../api/ui/svgIcons/FavouriteIcon';
import { useTranslation } from 'react-i18next';
import { getBaseUrlStatic } from '../../api/baseUrl';
import { connect } from 'react-redux';
import { updateGamePlayerNumber } from '../../util/appUtil';

interface IGameCard {
  gameName: string;
  gameProvider: string;
  backgroundImageUrl: string;
  isFavorite: boolean;
  isAvailable: boolean;
  comingSoon: boolean;
  addToFavorites: () => void;
  removeFromFavorite: () => void;
  startPlaying: () => void;
  isMobile: boolean;
  agregator_image?: string;
  gameCardStyle?: any;
  hideActions?: boolean;
  isUserAuthorized: boolean;
  gameId: string;
  categorySlug?: string;
  index?: number;
}

const useStyles = makeStyles(() => ({
  wrapper: {},
  root: {
    position: 'relative',
    borderRadius: '8px',
    width: '150px',
    height: '200px',
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    transition: 'transform 0.3s ease',

    '&:hover': {
      transform: 'translateY(-10px)',
    },
    '& >div': {
      textAlign: 'center',
    },
  },
  mobileDevice: {
    width: '104px',
    height: '139px',
    '&:hover': {
      transform: 'none',
    },
    border: 'none',
  },

  info: {
    boxSizing: 'border-box',
    width: '100%',
    position: 'absolute',
    top: '-1px',
    height: '101%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '8px',
    border: '1px solid rgba(255, 255, 255, 0.10)',
    background:
      'linear-gradient(180deg, rgba(3, 7, 18, 0.65) 0%, #030712 100%)',
    opacity: 0,
    borderRadius: '8px',
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 1,
    },
  },
  notAvailable: {
    background:
      'linear-gradient(180deg, rgba(3, 7, 18, 0.85) 0%, #030712 100%)',
  },
  favoriteIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    flexGrow: 3,

    '& svg': {
      borderRadius: '50%',
      padding: '8px',
      fill: '#fff',
      transition: 'backgroundColor 0.1s ease-in-out, fill 0.3s ease-in-out',
      backgroundColor: '#ffffff1a',
      '&:hover': {
        backgroundColor: '#0D121D',
      },
    },

    '& .is-favorite': {
      backgroundColor: '#450E0A',
      fill: 'red',
      '&:hover': {
        backgroundColor: '#450E0A',
      },
    },
    '& .dark': {
      fill: '#fff',
    },
  },

  playButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 199,
  },
  gameDescriptions: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    flexGrow: 5,
    padding: '0 14px',
    '& div:first-child': {
      fontWeight: 700,
      lineHeight: '24px',
      color: '#fff',
    },
    '& div:last-child': {
      fontWeight: 600,
      lineHeight: '16px',
      color: '#FFFFFF80',
    },
  },
  playerNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginTop: '8px',
    marginLeft: '2px',
  },
  circle: {
    background: '#A3E635',
    width: '4px',
    height: '4px',
    borderRadius: '100%',
    boxShadow: '0px 0px 10px rgba(163, 230, 53, 0.5)',
    animation: '$pulse 1s infinite alternate',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0px 0px 10px rgba(163, 230, 53, 0.5)',
    },
    '100%': {
      transform: 'scale(2)',
      boxShadow: '0px 0px 20px rgba(163, 230, 53, 0.8)',
    },
  },
  numbers: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  playing: {
    color: '#9CA3AF',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
}));

const GameCard = ({
  gameName,
  gameProvider,
  backgroundImageUrl,
  isFavorite,
  isAvailable,
  comingSoon,
  addToFavorites,
  startPlaying,
  isMobile,
  agregator_image,
  removeFromFavorite,
  gameCardStyle,
  hideActions = false,
  isUserAuthorized,
  gameId,
  categorySlug,
  index,
}: IGameCard) => {
  const classes = useStyles({});
  const [isFav, setIsFav] = useState<boolean>(false);
  const [playerNumber, setPlayerNumber] = useState(0);

  useEffect(() => {
    if (gameId) {
      let minNum = 1;
      let maxNum = 15;

      switch (categorySlug) {
        case 'slots':
          if (index && index <= 7) {
            minNum = 5;
            maxNum = 150;
          }
          break;
        case 'live':
          if (index && index <= 7) {
            minNum = 2;
            maxNum = 30;
          } else {
            minNum = 1;
            maxNum = 10;
          }
          break;
        case 'instant':
          if (index && index <= 7) {
            minNum = 2;
            maxNum = 20;
          } else {
            minNum = 0;
            maxNum = 10;
          }
          break;
        case 'table':
          if (index && index <= 7) {
            minNum = 2;
            maxNum = 20;
          } else {
            minNum = 0;
            maxNum = 10;
          }
          break;
        case 'game-show':
          if (index && index <= 7) {
            minNum = 2;
            maxNum = 25;
          } else {
            minNum = 0;
            maxNum = 10;
          }
          break;
        default:
          if (index && index <= 7) {
            minNum = 3;
            maxNum = 30;
          }
          break;
      }

      const playerNumber = updateGamePlayerNumber(
        gameId,
        Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum
      );
      setPlayerNumber(
        playerNumber
          ? playerNumber
          : Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum
      );
    }
  }, [gameId]);

  useEffect(() => {
    setIsFav(isFavorite);
  }, [isFavorite]);
  const { t } = useTranslation();
  const background = {
    backgroundImage: backgroundImageUrl
      ? `url(${getBaseUrlStatic() + '/' + (backgroundImageUrl[0] === '/' ? backgroundImageUrl.substring(1) : backgroundImageUrl)})`
          .replace(' ', '%20')
          .replace(/`/g, '') // Replace backticks
          .replace(/ /g, '%20') // Replace spaces
          .replace(/'/g, '%27')
      : `${agregator_image}`,
  };

  const addToFavoritesHandler = () => {
    addToFavorites();
    setIsFav(!isFav);
  };

  const removeFromFavoritesHandler = () => {
    removeFromFavorite();
    setIsFav(!isFav);
  };
  return (
    <>
      <Box
        className={`${classes.root} ${isMobile && classes.mobileDevice}`}
        style={{ ...background, ...gameCardStyle }}
        onClick={isMobile ? startPlaying : () => {}}
      >
        {!isMobile && (
          <Box
            className={`${classes.info} ${!isAvailable && classes.notAvailable}}`}
            onClick={startPlaying}
          >
            {isUserAuthorized && !hideActions && (
              <Box
                className={classes.favoriteIcon}
                onClick={(event: any) => {
                  event.stopPropagation();
                  isFav
                    ? removeFromFavoritesHandler()
                    : addToFavoritesHandler();
                }}
              >
                <FavouriteIcon className={isFav ? 'is-favorite' : 'dark'} />
              </Box>
            )}
            {!isUserAuthorized && <Box className={classes.favoriteIcon} />}
            {!hideActions && isAvailable && (
              <Box className={classes.playButton}>
                <PlayButton onClick={startPlaying} />
              </Box>
            )}
            {!hideActions && (
              <Box className={classes.gameDescriptions}>
                {isAvailable ? (
                  <>
                    <div>{gameName}</div>
                    <div>{gameProvider}</div>
                  </>
                ) : (
                  <>
                    <div>
                      {comingSoon ? t('comingSoon') : t('notAvailable')}
                    </div>
                    <div>
                      {comingSoon
                        ? t('comingSoonDescription')
                        : t('notAvailableDescription')}
                    </div>
                  </>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
      <div className={classes.playerNumberContainer}>
        <span className={classes.circle} />
        <span className={classes.numbers}>{playerNumber}</span>
        <span className={classes.playing}>{t('playing')}</span>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  isUserAuthorized: state.user.isUserAuthorized,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(GameCard);
