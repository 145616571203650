import React, { FC, useState } from 'react';
import { Box, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles(() => ({
  rootHeader: {
    width: '100%',
    minHeight: '72px',
    maxHeight: '72px',
    background: '#0D121D',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid#FFFFFF0D',
    borderBottom: '1px solid #FFFFFF0D',
    boxShadow: '0px 2px 4px 0px #03071280',
  },
  wrapperBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '36px',
    width: '100%',
    padding: '0px 20px',
  },
  popoverBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'auto',
    height: '36px',
    background: '#111827',
    borderRadius: '8px',
    padding: '0px 10px',
    cursor: 'pointer',
    border: '1px solid #111827',
    '&:hover': {
      border: '1px solid #1F2937',
      transition: 'border-color 0.3s ease-in-out',
    },
  },
  popoverBoxOpen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '112px',
    height: '36px',
    background: '#1F2937',
    borderRadius: '8px',
    padding: '0px 10px',
    cursor: 'pointer',
    border: '1px solid #111827',
    '&:hover': {
      border: '1px solid #1F2937',
      transition: 'border-color 0.3s ease-in-out',
    },
  },
  popoverContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  activeRoomName: {
    boxSizing: 'border-box',
    paddingRight: '8px',
    background: '#0D121D',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '112px',
    height: '36px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  popoverList: {
    boxSizing: 'border-box',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '112px',
    height: '36px',
    background: '#111827',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      background: '#0D121D',
      color: '#FFFFFF',
      transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
    },
  },
  listWrapper: {
    padding: '7px 8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

interface ChatHeaderProps {
  onChangeRoom: (value: string) => void;
  setIsOpen: (isOpen: boolean) => void;
}

const ChatHeader: FC<ChatHeaderProps> = ({ onChangeRoom, setIsOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchor, setAnchor] = useState(null);
  const [roomName, setRoomName] = useState<string>('general');

  const roomArray = [
    { name: t('general'), value: 'general' },
    { name: t('english'), value: 'english' },
    { name: t('sport'), value: 'sport' },
  ];

  const openPopover = (event: any) => {
    setAnchor(event.currentTarget);
  };

  const closePopover = () => {
    setAnchor(null);
  };

  const onChange = (roomValue: string) => {
    setRoomName(roomValue);
    onChangeRoom(roomValue);
    setAnchor(null);
  };

  return (
    <Box className={classes.rootHeader}>
      <Box className={classes.wrapperBox}>
        <Box className={classes.popoverContentWrapper}>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '24px',
              marginRight: '15px',
            }}
          >
            {t('chat')}
          </Typography>
          <Box onClick={openPopover}>
            <Box
              //className={!anchor ? classes.popoverBox : classes.popoverBoxOpen}
              className={classes.popoverBox}
            >
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: '12px',
                  lineHeight: '16px',
                  textTransform: 'uppercase',
                }}
              >
                {t(roomName)}
              </Typography>
              {false && (
                <KeyboardArrowDownIcon
                  style={{
                    rotate: anchor ? '-180deg' : '',
                    transition: 'rotate 0.3s ease-in-out',
                  }}
                />
              )}
            </Box>
          </Box>

          <Popover
            open={false && Boolean(anchor)}
            anchorEl={anchor}
            onClose={closePopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            PaperProps={{
              style: {
                marginTop: '50px',
                width: '128px',
                background: '#111827',
                color: '#6B7280',
                borderRadius: '8px',
              },
            }}
          >
            <Box className={classes.listWrapper}>
              {roomArray.map((item: any, index: number) => {
                return (
                  <Box
                    className={
                      item.value === roomName
                        ? classes.activeRoomName
                        : classes.popoverList
                    }
                    key={index}
                    onClick={() => onChange(item.value)}
                  >
                    <Typography
                      style={{
                        marginLeft: '8px',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: '16px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t(item.name)}
                    </Typography>
                    <ChevronRightIcon />
                  </Box>
                );
              })}
            </Box>
          </Popover>
        </Box>
        <CloseIcon
          sx={{ fontSize: '18px', cursor: 'pointer' }}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default ChatHeader;
