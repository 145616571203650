import React, { useState } from 'react';
import Picker from 'emoji-picker-react';
import EmojyOpenIcon from '../../api/ui/svgIcons/EmojyOpenIcon';
import { Box, Popover } from '@mui/material';

interface IEmojiPickerComponent {
  onEmojyChange: (emojy: any) => void;
}

const EmojiPickerComponent = ({ onEmojyChange }: IEmojiPickerComponent) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const handleEmojiIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'emoji-picker-popover' : undefined;

  const onEmojiClick = (event: any) => {
    onEmojyChange(event);
  };

  return (
    <div>
      <Box
        onClick={handleEmojiIconClick}
        sx={{
          display: 'flex',
          cursor: 'pointer',
          '& path': {
            transition: 'fill 0.3s',
          },
          '&:hover': {
            '& path': {
              fill: '#fff',
            },
          },
        }}
      >
        <EmojyOpenIcon />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            overflow: 'hidden',
          },
        }}
      >
        <Box>
          <Picker
            width={280}
            onEmojiClick={onEmojiClick}
            style={{
              minHeight: '450px',
            }}
          />
        </Box>
      </Popover>
    </div>
  );
};

export default EmojiPickerComponent;
