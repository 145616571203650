import CustomModal from '../../common/Modal/CustomModal';
import { Box } from '@mui/material';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ModalHeader from '../ModalHeader';
import { changeSystemLanguage } from '../../../api/services/Settings.api';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  contactModal: {
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    color: '#fff',
    minWidth: isMobile ? '0px' : '416px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },

  customButtonBox: {
    display: 'flex',
    margin: '16px',
    '& :first-child': {
      flex: 1,
    },
  },
  langContainer: {
    display: 'flex',
    padding: '16px',
    lineHeight: '24px',
    gap: '10px',
    fontWeight: 600,
    paddingLeft: '16px',
    flexDirection: isMobile ? 'column' : 'row',
    backgroundColor: isMobile ? '#0D121D' : '',
    margin: isMobile ? '8px' : '',
    borderRadius: '8px',
  },
  lang: {
    padding: isMobile ? '16px' : '16px 30px',
    cursor: 'pointer',
    flex: '1',
    borderRadius: '8px',
    border: isMobile ? '1px solid #0D121D' : '1px solid #111827',
    userSelect: 'none',
    transition: 'all 0.3s ease-in-out',
    color: '#9CA3AF',
    textAlign: 'center',
  },
  activeLang: {
    backgroundColor: '#030712',
    boxSizing: 'border-box',
    border: '1px solid #1D4ED8',
    color: '#fff',
    transition: 'all 0.3s ease-in-out',
  },
}));

interface IPreferencesModal {
  isOpen: boolean;
  onClose: () => void;
  activeLangIndex: number | null;
  onChangeLanguage: (index: number) => void;
  languages: string[];
  langs: string[];
}

const PreferencesModal = ({
  isOpen,
  onClose,
  activeLangIndex,
  onChangeLanguage,
  languages,
  langs,
}: IPreferencesModal) => {
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(activeLangIndex);
  const classes = useStyles();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const changeLanguageHandler = (index: number) => {
    setCurrentIndex(index);
  };
  const changeLanguageRequest = () => {
    setLoading(true);

    if (currentIndex !== null) {
      onChangeLanguage(currentIndex);
    }
    changeSystemLanguage({
      lang: currentIndex !== null ? langs[currentIndex] : '',
    })
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar(res.data.data.message, {
            variant: 'success',
          });
          onClose();
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const children = (
    <Box className={classes.contactModal}>
      <ModalHeader onClose={onClose} heading={t('systemLanguage')} />
      <Box marginTop={'16px'} className={classes.langContainer}>
        {languages.map((lang, index) => (
          <div
            key={lang}
            className={`${classes.lang} ${index === currentIndex ? `${classes.activeLang}` : ''}`}
            onClick={() => changeLanguageHandler(index)}
            data-testid="lang"
          >
            {lang}
          </div>
        ))}
      </Box>
      <Box className={classes.customButtonBox}>
        <CustomPrimaryButton
          onClick={changeLanguageRequest}
          loading={loading}
          style={{
            textTransform: 'upperCase',
          }}
          disabled={activeLangIndex === currentIndex}
        >
          <div>{t('save')}</div>
        </CustomPrimaryButton>
      </Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};

export default PreferencesModal;
