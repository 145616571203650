const EmojyOpenIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6015 7.06508C14.2961 7.02221 13.9839 7.00001 13.6666 7.00001C11.4935 7.00001 9.56359 8.04028 8.34738 9.64734C8.23332 9.65914 8.11745 9.66521 7.99992 9.66521C7.14578 9.66521 6.36803 9.34481 5.77786 8.81654L4.88864 9.81008C5.61239 10.4579 6.54086 10.8835 7.56432 10.9784C7.20145 11.8011 6.99992 12.7108 6.99992 13.6667C6.99992 13.984 7.02212 14.2961 7.06498 14.6016C3.82572 14.147 1.33325 11.3646 1.33325 8.00001C1.33325 4.31811 4.31802 1.33334 7.99992 1.33334C11.3645 1.33334 14.1469 3.82581 14.6015 7.06508ZM14.5804 8.41134C14.2835 8.36008 13.9782 8.33334 13.6666 8.33334C11.8437 8.33334 10.2341 9.24728 9.27112 10.645C8.67952 11.5037 8.33325 12.5437 8.33325 13.6667C8.33325 13.9783 8.35998 14.2836 8.41125 14.5805L14.5804 8.41134ZM5.66658 7.66668C6.21887 7.66668 6.66658 7.21894 6.66658 6.66668C6.66658 6.11439 6.21887 5.66668 5.66658 5.66668C5.1143 5.66668 4.66658 6.11439 4.66658 6.66668C4.66658 7.21894 5.1143 7.66668 5.66658 7.66668ZM10.3333 7.66668C10.8855 7.66668 11.3333 7.21894 11.3333 6.66668C11.3333 6.11439 10.8855 5.66668 10.3333 5.66668C9.78098 5.66668 9.33325 6.11439 9.33325 6.66668C9.33325 7.21894 9.78098 7.66668 10.3333 7.66668Z"
        fill="#6B7280"
      />
    </svg>
  );
};

export default EmojyOpenIcon;
