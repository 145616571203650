import React, { FC } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';

const useStyles = makeStyles(() => ({
  BonusDashboardButton: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    padding: '8px 16px',
    width: '100%',
    cursor: 'pointer',
    '& span': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#6B7280',
      transition: 'color 0.3s',
    },
    '&:hover': {
      '& span': {
        color: '#FFFFFF',
      },
      '& .MuiSvgIcon-root': {
        color: '#FFFFFF',
      },
    },
  },
}));

interface BonusDashboardButtonProps {
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

const BonusDashboardButton: FC<BonusDashboardButtonProps> = ({
  setAnchorEl,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={classes.BonusDashboardButton}
      onClick={() => {
        navigate(AppRoutePath.BONUS());
        setAnchorEl(null);
      }}
    >
      <span>{t('bonusDashboard')}</span>
      <KeyboardArrowRightIcon
        sx={{
          width: '20px',
          height: '20px',
          color: '#6B7280',
          transition: 'color 0.3s',
        }}
      />
    </Box>
  );
};

export default BonusDashboardButton;
