import React, { useEffect, useState } from 'react';
import BonusesPage from '../../components/Bonus/BonusesPage/BonusesPage';
import { IRakeBackOrCashBack } from '../../types/Bonus.type';
import { getCashBacks, getRakeBacks } from '../../api/services/Bonus.api';

const BonusesContainer = () => {
  const [rakeBacks, setRakeBacks] = useState<IRakeBackOrCashBack[]>([]);
  const [cashBacks, setCashBacks] = useState<IRakeBackOrCashBack[]>([]);

  useEffect(() => {
    initRakeBack();
    initCashBack();
  }, []);

  const initRakeBack = () => {
    getRakeBacks()
      .then((resp: any) => {
        if (resp.data.data) {
          setRakeBacks(resp.data.data);
        }
      })
      .catch(() => {});
  };

  const initCashBack = () => {
    getCashBacks()
      .then((resp: any) => {
        if (resp.data.data) {
          setCashBacks(resp.data.data);
        }
      })
      .catch(() => {});
  };

  return (
    <BonusesPage
      rakeBacks={rakeBacks}
      cashBacks={cashBacks}
      initRakeBack={initRakeBack}
      initCashBack={initCashBack}
    />
  );
};

export default BonusesContainer;
