import * as types from '../constants/gameConstants';
import {
  ADD_GAME_TO_FAVOURITE,
  REMOVE_GAME_TO_FAVOURITE,
} from '../constants/gameConstants';
import { ICategory, IGameSchema, IProvider } from '../../types/Game.type';
import {
  addGameToFavourites,
  removeGameFromFavourites,
} from '../../api/services/Game.api';
import { enqueueSnackbar } from 'notistack';
import { t } from 'i18next';

export const changeGameCategories = (data: ICategory[]) => ({
  type: types.CHANGE_GAME_CATEGORIES,
  payload: data,
});

export const changeProviders = (data: IProvider[]) => ({
  type: types.CHANGE_PROVIDERS,
  payload: data,
});

export const changeInitFavouriteGame = (data: IGameSchema[]) => ({
  type: types.CHANGE_INIT_FAVOURITE_GAME,
  payload: data,
});

export const addGameToFavourite = (id: string) => {
  return (dispatch: any) => {
    addGameToFavourites(Number(id))
      .then((resp: any) => {
        dispatch({
          type: ADD_GAME_TO_FAVOURITE,
          payload: resp.data.data,
        });
        enqueueSnackbar(t('addGameToFavourite'), {
          variant: 'success',
        });
      })
      .catch(() => {});
  };
};

export const removeGameFromFavourite = (id: string) => {
  return (dispatch: any) => {
    removeGameFromFavourites(Number(id))
      .then((resp: any) => {
        dispatch({
          type: REMOVE_GAME_TO_FAVOURITE,
          payload: resp.data.data,
        });
        enqueueSnackbar(t('removeGameFromFavourite'), {
          variant: 'success',
        });
      })
      .catch(() => {});
  };
};
