import React, { FC, useEffect, useRef, useState } from 'react';
import { RANKS_SYSTEM } from '../../util/UserProfileHelper';
import RankSystemPaginatioin from './RankSystemPaginatioin';
import RankSystemCard from './RankSystemCard';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import RankExclusiveAccess from './RankExclusiveAccess';
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import { connect } from 'react-redux';
import { IRankInfo } from '../../types/common.type';

const useStyles = makeStyles(() => ({
  rankHeaderTitleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '48px',
    '& :nth-child(1)': {
      fontWeight: 600,
      fontSize: !isMobile ? '32px' : '24px',
      lineHeight: '32px',
      color: '#D9D9D9',
    },
    '& :nth-child(2)': {
      marginTop: '16px',
      maxWidth: '640px',
      fontWeight: 400,
      fontSize: !isMobile ? '16px' : '14px',
      lineHeight: '24px',
      color: '#FFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
}));

interface RankWrapperInRankSystemPageProps {
  ranksInfo: IRankInfo[];
}

const RankWrapperInRankSystemPage: FC<RankWrapperInRankSystemPageProps> = ({
  ranksInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [mainRanksInfo, setMainRanksInfo] = useState<IRankInfo[]>([]);

  const pageSize: number = 3;
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const combinedArray = ranksInfo.map((item) => {
      const rankSystemItem = RANKS_SYSTEM.find(
        (rankItem) => rankItem.name === item.name
      );

      if (rankSystemItem) {
        return {
          ...item,
          background: rankSystemItem.background,
          img: rankSystemItem.img,
          imgStyle: rankSystemItem.imgStyle,
          height: rankSystemItem.height,
          progressStyle: rankSystemItem.progressStyle,
          index: rankSystemItem.index,
        };
      }

      return null;
    });

    const filteredCombinedArray = combinedArray.filter(
      (item) => item !== null
    ) as IRankInfo[]; // Type assertion to IRankInfo[]

    setMainRanksInfo(filteredCombinedArray);
  }, [ranksInfo]);

  const handleNextRank = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(
        swiperRef.current.swiper.activeIndex + (isMobile ? 1 : 3)
      );
    }
  };

  const handlePrevRank = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(
        swiperRef.current.swiper.activeIndex - (isMobile ? 1 : 3)
      );
    }
  };

  const changeRankPage = (index: number) => {
    if (isMobile) {
      setCurrentPage(index);
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideTo(index + 1);
      }
    } else {
      setCurrentPage(index);
      if (swiperRef.current && swiperRef.current.swiper) {
        switch (index) {
          case 1:
            swiperRef.current.swiper.slideTo(3);
            break;
          case 2:
            swiperRef.current.swiper.slideTo(6);
            break;
          case 3:
            swiperRef.current.swiper.slideTo(9);
            break;
          default:
            swiperRef.current.swiper.slideTo(0);
        }
      }
    }
  };

  const onSlideChange = () => {
    if (isMobile) {
      setCurrentPage(swiperRef.current.swiper.activeIndex);
      return;
    }

    const currentIndex: number = swiperRef.current.swiper.activeIndex;
    if (currentIndex < 3) {
      setCurrentPage(0);
    } else if (currentIndex > 5 && currentIndex > 8) {
      setCurrentPage(3);
    } else if (currentIndex > 2 && currentIndex < 6) {
      setCurrentPage(1);
    } else {
      setCurrentPage(2);
    }
  };

  return (
    <Box>
      <Box className={classes.rankHeaderTitleWrapper}>
        <span
          style={{
            textAlign: 'center',
          }}
        >
          {t('yourPathToGlory12Ranks150Levels')}
        </span>
        <Box>{t('ranksSecondInfoTitle')}</Box>
      </Box>
      <Box>
        <Swiper
          spaceBetween={8}
          slidesPerView={isMobile ? 1 : 3}
          ref={swiperRef}
          onSlideChange={onSlideChange}
          speed={1000}
        >
          {mainRanksInfo.map((rank, index) => (
            <SwiperSlide
              key={index}
              style={{
                width: isMobile ? '100%' : '322px',
                minWidth: isMobile ? '100%' : '322px',
                maxWidth: isMobile ? '100%' : '322px',
              }}
            >
              <RankSystemCard
                key={index}
                rankInfo={rank}
                index={index}
                currentPage={currentPage}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <RankSystemPaginatioin
        handleNextRank={handleNextRank}
        handlePrevRank={handlePrevRank}
        currentPage={currentPage}
        pageSize={pageSize}
        changeRankPage={changeRankPage}
      />
      <RankExclusiveAccess />
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  ranksInfo: state.user.ranksInfo,
});

const dispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  dispatchToProps
)(RankWrapperInRankSystemPage);
