import { IAction } from '../../types/common.type';
import { CHANGE_BLOGS } from '../constants/blogConstants';

const initialState = {
  blogItems: [],
};

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case CHANGE_BLOGS:
      return {
        ...state,
        blogItems: action.payload,
      };
    default:
      return state;
  }
}
