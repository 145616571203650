import AccountInfo from '../../components/Settings/AccountInfo/AccountInfo';
import { connect } from 'react-redux';
import { IUserInfo } from '../../types/common.type';
import { bindActionCreators } from 'redux';
import { changeEmail, changePhone } from '../../redux/actions/userActions';

export interface IPlayerAccountInfo {
  playerInfo: IUserInfo;
  changeEmail: (email: string) => void;
  changePhone: (phoneNumber: string) => void;
  onClose: () => void;
}

const AccountInfoContainer = ({
  playerInfo,
  changeEmail,
  changePhone,
  onClose,
}: IPlayerAccountInfo) => {
  return (
    <AccountInfo
      playerInfo={playerInfo}
      changeEmail={changeEmail}
      changePhone={changePhone}
      onClose={onClose}
    />
  );
};

const mapStateToProps = (state: any) => ({
  playerInfo: state.user.playerInfo,
});

const dispatchToProps = (dispatch: any) => ({
  changeEmail: bindActionCreators(changeEmail, dispatch),
  changePhone: bindActionCreators(changePhone, dispatch),
});
export default connect(mapStateToProps, dispatchToProps)(AccountInfoContainer);
