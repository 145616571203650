import React, { FC, useEffect, useState } from 'react';
import PlayGamePage from '../../components/PlayGamePage/PlayGamePage';
import {
  getDemoGameUrl,
  getGameInfoBySlug,
  getRealGameUrl,
  getRecommendedGames,
} from '../../api/services/Game.api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IGameSchema } from '../../types/Game.type';
import { bindActionCreators } from 'redux';
import {
  addGameToFavourite,
  removeGameFromFavourite,
} from '../../redux/actions/gameActions';

interface PlayGameContainerProps {
  isUserAuthenticated: boolean;
  addToFavoriteGameList: (gameId: string) => void;
  removeFromFavoriteGameList: (gameId: string) => void;
  favourite: IGameSchema[];
}

const PlayGameContainer: FC<PlayGameContainerProps> = ({
  isUserAuthenticated,
  addToFavoriteGameList,
  removeFromFavoriteGameList,
  favourite,
}) => {
  const { slug } = useParams();
  const [gameType, setGameType] = useState<null | string>(null);
  const [gameInfo, setGameInfo] = useState<null | IGameSchema>(null);
  const [gameUrl, setGameUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [recommendedGames, setRecommendedGames] = useState<IGameSchema[]>([]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
    if (slug) {
      getInfoBySlug(slug);
    }

    onLoadRecommendedGame();
  }, [slug]);

  useEffect(() => {
    if (isUserAuthenticated) {
      setGameType('real');
    } else {
      setGameType(null);
      setLoading(false);
    }
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (gameType !== null && gameInfo !== null) {
      setLoading(true);
      if (gameType === 'real') {
        loadRealGameUrl(gameInfo['game_id']);
      } else {
        loadDemoGameUrl(gameInfo['game_id']);
      }
    }
  }, [gameType, gameInfo]);

  const getInfoBySlug = (gameName: string) => {
    setLoading(true);
    getGameInfoBySlug(gameName)
      .then((resp) => {
        if (resp.data.data) {
          setGameInfo(resp.data.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const loadDemoGameUrl = (gameId: string) => {
    getDemoGameUrl(gameId)
      .then((resp) => {
        if (!resp.data.data.is_restricted) {
          setGameUrl(resp.data.data.url);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const loadRealGameUrl = (gameId: string) => {
    getRealGameUrl(gameId)
      .then((resp) => {
        if (!resp.data.data.is_restricted) {
          setGameUrl(resp.data.data.url);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onLoadRecommendedGame = () => {
    getRecommendedGames().then((resp: any) => {
      const games = resp.data.data;
      if (games) {
        setRecommendedGames(games);
      }
    });
  };

  return (
    <PlayGamePage
      gameUrl={gameUrl}
      loading={loading}
      gameInfo={gameInfo}
      isUserAuthenticated={isUserAuthenticated}
      setGameType={setGameType}
      gameType={gameType}
      addToFavoriteGameList={addToFavoriteGameList}
      removeFromFavoriteGameList={removeFromFavoriteGameList}
      favourite={favourite}
      recommendedGames={recommendedGames}
    />
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: state.user.isUserAuthorized,
  favourite: state.game.favourite,
});

const dispatchToProps = (dispatch: any) => ({
  addToFavoriteGameList: bindActionCreators(addGameToFavourite, dispatch),
  removeFromFavoriteGameList: bindActionCreators(
    removeGameFromFavourite,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(PlayGameContainer);
