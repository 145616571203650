export const CheckEmailValidation = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const getLocalStorageValue = (key: string, defaultValue: any) => {
  const currValue = window.localStorage.getItem(key);
  return currValue !== null && currValue !== undefined
    ? currValue
    : defaultValue;
};

export const setLocalStorageValue = (key: string, value: any) => {
  if (key) {
    window.localStorage.setItem(key, value);
  }
};

export const getCookieValue = (key: string): any => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${key.replace(/([.$?*|{}()[\]\\+^])/g, '\\$1')}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const CurrenciesNameWithMemo: string[] = ['BNB', 'XRP'];

export const getUserLanguage = () => {
  return getLocalStorageValue('language', 'en');
};

export const SettingsKeys = {
  chatMinimumWagerKey: 'chat_min_wager_amount',
  chatEnable: 'chat_is_enabled',
  chatEmailVerification: 'chat_is_allow_email_not_virified',
  KYCIsConfirmed: 'withdraw_player_with_kyc',
  KYCIsNotConfirmed: 'withdraw_player_with_out_kyc',
};

export const scrollToTop = () => {
  const element = document.getElementById('airbet-body');
  if (element) {
    element.scrollTop = 0;
  }
};

export const getRandomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  return '#' + red.toString(16) + green.toString(16) + blue.toString(16);
};

export const updateGamePlayerNumber = (gameId: string, players: number) => {
  try {
    let games = getLocalStorageValue('gamePlayersNumbers', null);
    games = games ? JSON.parse(games) : null;

    if (!games) {
      setLocalStorageValue(
        'gamePlayersNumbers',
        JSON.stringify([{ [gameId]: players }])
      );
      return players;
    } else {
      const updatePlayersNumberDate = getLocalStorageValue(
        'updatePlayersNumberDate',
        false
      );
      const findGame = games.find((item: any) => item[gameId]);

      if (updatePlayersNumberDate === 'true' && findGame) {
        games[gameId] = players;
        setLocalStorageValue('gamePlayersNumbers', JSON.stringify([...games]));
        return players;
      } else {
        if (!findGame) {
          setLocalStorageValue(
            'gamePlayersNumbers',
            JSON.stringify([...games, { [gameId]: players }])
          );
          return players;
        } else {
          return findGame[gameId];
        }
      }
    }
  } catch (error) {
    /* empty */
  }
};

export const registrationGoogleManagerHelper = (
  id: string,
  country: string,
  type: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'user-registration',
    id,
    country,
    type,
  });
};

export const firstDepositGoogleManagerHelper = (
  id: string,
  country: string,
  amount: number
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'first-deposit',
    id,
    country,
    amount,
  });
};

export const generalDepositGoogleManagerHelper = (
  id: string,
  country: string,
  amount: number
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'general-deposit',
    id,
    country,
    amount,
  });
};
