import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  changeGlobalUserRegistrationModalOpen,
  globalBonusWheelModalOpen,
} from '../../redux/actions/userActions';
import { IUserInfo } from '../../types/common.type';

const useStyles = makeStyles({
  bonusWheel: {
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    background: 'linear-gradient(92deg, #392355 0.37%, #270355 98.56%)',
    cursor: 'pointer',
    width: '100%',
    height: '48px',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '0px',
    minHeight: '48px',
    boxSizing: 'border-box',
  },
  bonusWheelDescriptionContainer: {
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
    width: '100%',
    marginRight: '8px',
    opacity: 1,
    animation: '$bonusWheelAnimation 0.2s ease',
  },
  bonusWheelDescriptionLeft: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  bonusWheelDescriptionRight: {
    color: '#FFFFFF80',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  '@keyframes bonusWheelAnimation': {
    '0%': {
      opacity: 0,
    },
    '99%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
});

interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

interface BonusWheelBannerProps {
  isUserAuthenticated: boolean;
  isSidebarOpen: boolean;
  playerInfo: IUserInfo;
}

const BonusWheelBanner: FC<BonusWheelBannerProps> = ({
  isUserAuthenticated,
  isSidebarOpen,
  playerInfo,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isAvailable, setIsAvailable] = useState<boolean>(false);

  const calculateTimeLeft = (): TimeLeft => {
    const targetDateTimeString = playerInfo.player.can_spin_after;
    const targetDateTime = new Date(targetDateTimeString);
    const targetUtcTimestamp =
      targetDateTime.getTime() - targetDateTime.getTimezoneOffset() * 60000;
    const nowUtcTimestamp = Date.now();
    const difference = targetUtcTimestamp - nowUtcTimestamp;
    if (difference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    return { hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(() => calculateTimeLeft());

  useEffect(() => {
    if (isUserAuthenticated) {
      if (
        playerInfo.player.wheel_spins === 1 &&
        remainingTime(playerInfo.player.can_spin_after)
      ) {
        setIsAvailable(false);
        const timer = setInterval(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
      } else {
        setIsAvailable(true);
      }
    }
  }, [playerInfo]);

  useEffect(() => {
    if (
      timeLeft.hours === 0 &&
      timeLeft.minutes === 0 &&
      timeLeft.seconds === 0
    ) {
      setIsAvailable(true);
    }
  }, [timeLeft]);

  const remainingTime = (can_spin_after: string | null) => {
    if (can_spin_after === null) return false;

    const serverTime = new Date(can_spin_after);
    const targetServerTime =
      serverTime.getTime() - serverTime.getTimezoneOffset() * 60000;

    const now = new Date();
    const nowUtc = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
      )
    );

    return targetServerTime > nowUtc.getTime();
  };

  const { hours, minutes, seconds } = timeLeft;

  return (
    <div
      className={classes.bonusWheel}
      onClick={() =>
        dispatch(
          isUserAuthenticated
            ? globalBonusWheelModalOpen(true)
            : changeGlobalUserRegistrationModalOpen(true)
        )
      }
    >
      <img
        src={'/imgs/bonusWheel.png'}
        alt={'Bonus Wheel'}
        style={{
          marginLeft: '3px',
        }}
      />
      {isSidebarOpen && (
        <div className={classes.bonusWheelDescriptionContainer}>
          <span className={classes.bonusWheelDescriptionLeft}>
            {t('airbetMenu.bonusWheel')}
          </span>
          <span className={classes.bonusWheelDescriptionRight}>
            {isUserAuthenticated && (
              <>
                {isAvailable && t('available')}
                {!isAvailable &&
                  !isNaN(hours) &&
                  `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`}
              </>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  playerInfo: state.user.playerInfo,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(BonusWheelBanner);
