import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  root: {},
  titleContainer: {
    padding: '0px 16px',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    gap: '8px',
    marginBottom: isMobile ? '32px' : '48px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  description: {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
  },
  bodyContainer: {
    borderRadius: '8px',
    background: '#111827',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    padding: '12px 16px 16px 16px',
    alignItems: 'center',
    gap: '24px',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    boxSizing: 'border-box',
    width: '100%',
  },
  detailsTitle: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  detailsDescription: {
    color: '#FFFFFF80',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
  },
}));

const RankFAQ = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const arr = [
    {
      title: t('rank.whatAreRanks'),
      description: t('rank.whatAreRanksDescription'),
    },
    {
      title: t('rank.WhatAreLevels'),
      description: t('rank.WhatAreLevelsDescription'),
    },
    {
      title: t('rank.WhatISVIPHost'),
      description: t('rank.WhatISVIPHostDescription'),
    },
    {
      title: t('rank.HowDoILevelUp'),
      description: t('rank.HowDoILevelUpDescription'),
    },
    {
      title: t('rank.CanIGetHigherRank'),
      description: t('rank.CanIGetHigherRankDescription'),
    },
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <span className={classes.title}>{t('rank.FAQTitle')}</span>
        <span className={classes.description}>{t('rank.FAQDescription')}</span>
      </Box>

      <Box
        style={{
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {arr.map((item: any, i: number) => {
          return (
            <Box className={classes.bodyContainer} key={i}>
              <Box className={classes.details}>
                <span className={classes.detailsTitle}>{item.title}</span>
                <span className={classes.detailsDescription}>
                  {item.description}
                </span>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RankFAQ;
