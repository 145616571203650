import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, SelectChangeEvent } from '@mui/material';
import WithdrawIcon from '../../api/ui/svgIcons/WithdrawIcon';
import {
  ICurrency,
  ICurrencyNetworksId,
  INetwork,
} from '../../types/common.type';
import CustomSelect from '../common/Select/CustomSelect';
import { getBaseUrlStatic } from '../../api/baseUrl';
import CustomInput from '../common/Inputs/CustomInput';
import React, { ChangeEvent } from 'react';
import { CurrenciesNameWithMemo } from '../../util/appUtil';
import MinimumInfoAmountIcon from '../../api/ui/svgIcons/MinimumInfoAmountIcon';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { isMobile } from 'react-device-detect';

interface IWithdraw {
  currencies: ICurrency[];
  network: INetwork[];
  selectedCurrency: ICurrency | null;
  selectedNetwork: INetwork | null;
  address: string | null;
  setAddress: (address: string | null) => void;
  amount: number | null;
  setAmount: (amount: number | null) => void;
  fee: {
    total_withdraw_amount: string;
    withdraw_amount: string;
    fee: string;
  } | null;
  memo: string | null;
  setMemo: (memo: string | null) => void;
  feeLoading: boolean;
  getFilteredNetworks: any;
  setSelectedNetwork: any;
  setSelectedCurrency: any;
  confirmLoading: boolean;
  onConfirm: () => void;
  getFeeResult: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    ...theme.general.accountBody,
  },
  rootMobile: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    background: '#111827',
  },
  header: {
    margin: '16px 0px',
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& path': {
      fill: '#fff',
    },
    '& span': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  itemName: {
    color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
  },
  calculationContainer: {
    background: '#0D121D',
    padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: isMobile ? 'column' : 'row',
  },
  calculationItem: {
    display: 'flex',
    flexDirection: isMobile ? 'row' : 'column',
    flex: 1,
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiCircularProgress-root': {
      width: '12px !important',
      height: '12px !important',
    },
  },
  calculationHeaderText: {
    color: '#6B7280',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  calculationBodyText: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    height: '24px',
  },
  minimumAmountContainer: {
    background: '#FFFFFF0D',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  minimumAmountContainerText: {
    color: '#FFFFFF80',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  minimumAmountContainerAmount: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
  },
  minimumAmountContainerAmountText: {
    color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
}));

const Withdraw = ({
  network,
  currencies,
  selectedNetwork,
  selectedCurrency,
  getFilteredNetworks,
  setSelectedNetwork,
  setSelectedCurrency,
  amount,
  setAmount,
  setAddress,
  fee,
  feeLoading,
  memo,
  setMemo,
  address,
  onConfirm,
  confirmLoading,
  getFeeResult,
}: IWithdraw) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const networkRendererData = () => {
    if (selectedCurrency) {
      return getFilteredNetworks(selectedCurrency, network).map(
        (item: INetwork) => {
          return {
            ...item,
            value: item.id,
            renderer: () => (
              <span className={classes.itemName}>{item.name}</span>
            ),
          };
        }
      );
    }

    return [];
  };

  const currencyRendererData = () => {
    return currencies.map((item: ICurrency) => {
      return {
        ...item,
        value: item.name,
        renderer: () => (
          <Box
            display={'flex'}
            gap={'8px'}
            alignItems={'center'}
            height={'24px'}
          >
            {item.logo && (
              <img
                src={getBaseUrlStatic() + item.logo}
                alt={item.name}
                width={'24px'}
                height={'24px'}
              />
            )}
            <span className={classes.itemName}>{item.name}</span>
          </Box>
        ),
      };
    });
  };

  const onChangeNetwork = (value: string): void => {
    const findNetwork: INetwork | undefined = network.find(
      (item: INetwork) => item.id === Number(value)
    );
    if (findNetwork) {
      setSelectedNetwork({ ...findNetwork });
      setMemo(null);
    }
  };

  const onChangeCurrency = (value: string): void => {
    const findCurrency: ICurrency | undefined = currencies.find(
      (item: ICurrency) => item.name === value
    );
    if (findCurrency) {
      setSelectedNetwork(null);
      setSelectedCurrency(findCurrency);
      setMemo(null);
    }
  };

  const getMinimumAmount = () => {
    if (selectedCurrency && selectedNetwork) {
      return selectedCurrency.networks_id.find(
        (item: ICurrencyNetworksId) =>
          item.coin_network_id === selectedNetwork.id
      )?.min_amount_usd;
    }

    return '';
  };

  const onConfirmDisable = () => {
    return (
      !(selectedCurrency && selectedNetwork && fee && address) ||
      Boolean(
        fee &&
          amount &&
          Number(fee.fee) + Number(getMinimumAmount()) > Number(amount)
      )
    );
  };

  const onSelectNetworkAuto = (value: string) => {
    const findCurrency: ICurrency | undefined = currencies.find(
      (item: ICurrency) => item.name === value
    );

    const networks = getFilteredNetworks(findCurrency, network).map(
      (item: INetwork) => {
        return {
          ...item,
          value: item.id,
          renderer: () => <span className={classes.itemName}>{item.name}</span>,
        };
      }
    );

    if (networks && networks.length > 0) {
      onChangeNetwork(String(networks[0].value));
    }
  };

  return (
    <div className={isMobile ? classes.rootMobile : classes.root}>
      {!isMobile && (
        <div className={classes.header}>
          <WithdrawIcon size={20} />
          <span>{t('withdraw')}</span>
        </div>
      )}
      {!isMobile && (
        <Box
          style={{
            background: '#FFFFFF0D',
            width: '100%',
            height: '1px',
          }}
        />
      )}
      <Box
        padding={isMobile ? '16px 8px' : '16px'}
        display={'flex'}
        flexDirection={'column'}
        gap={'24px'}
      >
        <Box
          gap={'24px'}
          display={'flex'}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          {currencies && (
            <CustomSelect
              onChange={(event: SelectChangeEvent) => {
                onChangeCurrency(event.target.value as string);
                onSelectNetworkAuto(event.target.value as string);
              }}
              value={selectedCurrency?.name || ''}
              label={t('currency')}
              data={currencyRendererData()}
            />
          )}

          {network && (
            <CustomSelect
              onChange={(event: SelectChangeEvent) =>
                onChangeNetwork(event.target.value as string)
              }
              value={selectedNetwork ? String(selectedNetwork?.id) : ''}
              label={t('network')}
              data={networkRendererData()}
            />
          )}
        </Box>
        {selectedNetwork &&
          selectedCurrency &&
          CurrenciesNameWithMemo.includes(selectedCurrency.name) && (
            <Box gap={'24px'} display={'flex'}>
              <CustomInput
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setMemo(event.target.value)
                }
                label={t('memo')}
                placeholder={t('memo')}
                defaultValue={memo ? memo : ''}
              />
            </Box>
          )}
        {selectedCurrency && selectedNetwork && (
          <Box className={classes.minimumAmountContainer}>
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <MinimumInfoAmountIcon />
              <span className={classes.minimumAmountContainerAmount}>
                {getMinimumAmount()}
              </span>
              <span className={classes.minimumAmountContainerAmountText}>
                {t('minimumWithdraw')}
              </span>
            </Box>
            {!isMobile && (
              <Box className={classes.minimumAmountContainerText}>
                {t('minimumWithdrawDescription')}
              </Box>
            )}
          </Box>
        )}

        <Box
          gap={'24px'}
          display={'flex'}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <CustomInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setAddress(event.target.value)
            }
            label={t('withdrawalAddress')}
            placeholder={t('enterAddress')}
            onBlur={() => amount && amount > 0 && getFeeResult()}
          />
          <CustomInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setAmount(Number(event.target.value))
            }
            label={t('amount')}
            type={'number'}
            value={String(amount) || ''}
            placeholder={'0.00'}
          />
        </Box>

        <Box
          gap={'24px'}
          display={'flex'}
          className={classes.calculationContainer}
        >
          <Box className={classes.calculationItem}>
            <span className={classes.calculationHeaderText}>
              {t('withdrawAmount')}
            </span>
            <span className={classes.calculationBodyText}>
              {feeLoading ? (
                <CircularProgress />
              ) : fee?.fee && fee?.total_withdraw_amount ? (
                Number(fee?.withdraw_amount)
              ) : (
                ''
              )}
            </span>
          </Box>
          <Box className={classes.calculationItem}>
            <span className={classes.calculationHeaderText}>{t('fee')}</span>
            <span className={classes.calculationBodyText}>
              {feeLoading ? <CircularProgress /> : fee?.fee}
            </span>
          </Box>
          <Box className={classes.calculationItem}>
            <span className={classes.calculationHeaderText}>
              {t('totalWithdraw')}
            </span>
            <span className={classes.calculationBodyText}>
              {feeLoading ? <CircularProgress /> : fee?.total_withdraw_amount}
            </span>
          </Box>
        </Box>
        <Box display={'flex'} width={'100%'}>
          <CustomPrimaryButton
            style={{
              textTransform: 'uppercase',
              width: isMobile ? '100%' : '',
            }}
            disabled={onConfirmDisable()}
            onClick={() => onConfirm()}
            loading={confirmLoading}
            fullWidth={isMobile ? '100%' : 'auto'}
          >
            {t('confirm')}
          </CustomPrimaryButton>
        </Box>
      </Box>
    </div>
  );
};

export default Withdraw;
