import Chat from '../../components/Chat/Chat';
import { connect } from 'react-redux';
import { SettingsKeys } from '../../util/appUtil';
import { useEffect, useState } from 'react';
import { chatSocket } from '../../api/airbet.socket';
import { IMessageDataType } from '../../types/Chat.type';

interface IChatContainer {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  isUserAuthenticated: boolean;
  settings: [];
  userInfo: any;
}

const ChatContainer = ({
  isOpen,
  setIsOpen,
  isUserAuthenticated,
  settings,
  userInfo,
}: IChatContainer) => {
  const [chatLoading, setChatLoading] = useState<boolean>(true);
  const [selectedRoom, setSelectedRoom] = useState<string>('general');
  const [chatData, setChatData] = useState<IMessageDataType[]>([]);

  const isChatAdmin =
    isUserAuthenticated &&
    userInfo['player'] &&
    Boolean(userInfo['player']['is_chat_admin']);

  useEffect(() => {
    setChatLoading(false);
  }, []);

  useEffect(() => {
    const cleanUpGeneralChatSocket = generalChatSocket();
    const cleanUpPreviousMessagesSocket = previousMessagesSocket();
    const cleanUpNewMessageSocket = newMessageSocket();
    const cleanUpdatedMessageSocket = updatedMessageSocket();

    return () => {
      cleanUpGeneralChatSocket();
      cleanUpPreviousMessagesSocket();
      cleanUpNewMessageSocket();
      cleanUpdatedMessageSocket();
    };
  }, []);

  useEffect(() => {
    if (selectedRoom) {
      if (userInfo['access_Token'] && userInfo['access_Token']['token']) {
        chatSocket.emit('authenticateAndJoin', {
          token: userInfo['access_Token']['token'],
          room: selectedRoom,
        });
      } else {
        chatSocket.emit('authenticateAndJoin', {});
      }
    }
  }, [selectedRoom, userInfo]);

  const previousMessagesSocket = () => {
    const handler = (previousMessages: any): void => {
      setChatData(previousMessages);
    };

    chatSocket.on('previousMessages', handler);

    return () => {
      chatSocket.off('previousMessages', handler);
    };
  };

  const newMessageSocket = () => {
    const handler = (message: IMessageDataType) => {
      setChatData((prev: IMessageDataType[]) =>
        prev.length > 200 ? [...prev.slice(1), message] : [...prev, message]
      );
    };

    chatSocket.on('newMessage', handler);

    return () => {
      chatSocket.off('newMessage', handler);
    };
  };

  const generalChatSocket = () => {
    const handler = (): void => {};
    chatSocket.on('general', handler);
    return () => {
      chatSocket.off('general', handler);
    };
  };

  const updatedMessageSocket = () => {
    const handler = (updatedMessage: IMessageDataType): void => {
      setChatData((prev) =>
        prev.map((message: IMessageDataType) => {
          return message._id === updatedMessage._id ? updatedMessage : message;
        })
      );
    };

    chatSocket.on('showHideMessage', handler);

    return () => {
      chatSocket.off('showHideMessage', handler);
    };
  };

  const onChangeRoom = (room: string) => {
    setSelectedRoom(room);
  };

  const isChatEnabled: boolean = Boolean(
    settings.find((item: any) => item.key === SettingsKeys.chatEnable)
  );

  const needToVerifyEmail: boolean = Boolean(
    settings.find(
      (item: any) => item.key === SettingsKeys.chatEmailVerification
    )
  );

  const minWager: boolean = Boolean(
    settings.find((item: any) => item.key === SettingsKeys.chatMinimumWagerKey)
  );

  const sendMessage = (messageText: string) => {
    if (messageText.trim()) {
      chatSocket.emit('sendMessage', {
        token: userInfo['access_Token']
          ? userInfo['access_Token']['token']
          : userInfo['access_token']['token'],
        room: selectedRoom,
        type: 'message',
        message: messageText,
      });
    }
  };

  const showHideMessage = (messageId: string, action: string) => {
    if (messageId) {
      chatSocket.emit('showHideMessage', {
        token: userInfo['access_Token']['token'],
        room: selectedRoom,
        message_id: messageId,
        action: action,
      });
    }
  };

  return (
    <Chat
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isUserAuthenticated={isUserAuthenticated}
      isChatEnabled={isChatEnabled}
      needToVerifyEmail={needToVerifyEmail}
      minWager={minWager}
      chatLoading={chatLoading}
      selectedRoom={selectedRoom}
      onChangeRoom={onChangeRoom}
      isChatAdmin={isChatAdmin}
      chatData={chatData}
      sendMessage={sendMessage}
      showHideMessage={showHideMessage}
    />
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.user.playerInfo,
  isUserAuthenticated: state.user.isUserAuthorized,
  settings: state.settings.generalSetting,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(ChatContainer);
