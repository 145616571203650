import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { claimDepositBonuses } from '../../../api/services/Bonus.api';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '8px',
    padding: '15px 16px',
    border: '1px solid #FFFFFF0D',
    gap: '16px',
  },
  title: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#FFFFFF',
  },
  text: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#FFFFFF80',
    marginTop: '8px',
  },
  button: {
    width: '100%',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '8px',
    borderTop: '1px solid #FFFFFF1A',
    background: '#5568E4',
    userSelect: 'none',
    cursor: 'pointer',
    justifyItems: 'center',
    boxShadow: '0px 2px 8px 0px #5568E44D',
    gap: '6px',
  },
  buttonCountdown: {
    width: '100%',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '8px',
    background: '#FFFFFF0D',
    userSelect: 'none',
    cursor: 'pointer',
    justifyItems: 'center',
    gap: '6px',
  },
  buttonText: {
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.20)',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
}));

interface DepositBonusItemProps {
  depositBonus: any;
  initGetAllDepositBonus: () => void;
}

const DepositBonusItem: FC<DepositBonusItemProps> = ({
  depositBonus,
  initGetAllDepositBonus,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [arr, setArr] = useState<number[]>([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const wagering_coefficient = depositBonus['unusedBonuses']
      ? depositBonus['wagering_coefficient']
      : 0;
    const res: number[] = [];
    const itemNumber: number = depositBonus.unusedBonuses
      ? wagering_coefficient / depositBonus.split_number
      : depositBonus.wager_amount /
        depositBonus.init_amount /
        depositBonus.split_number;
    for (let i = 0; i <= depositBonus.split_number; i++) {
      res.push(itemNumber * i);
    }
    setArr(res);
  }, [depositBonus]);

  const InfoContainer = ({ keyText, value }: any) => {
    return (
      <Box
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          background: '#FFFFFF0D',
          padding: '8px',
          borderRadius: '5px',
          gap: '8px',
        }}
      >
        <span
          style={{
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: '#FFFFFF80',
          }}
        >
          {keyText}
        </span>
        <span
          style={{
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            color: '#FFFFFF',
          }}
        >
          {value}
        </span>
      </Box>
    );
  };

  const DepositBonusButton = (type: string) => {
    switch (type) {
      case 'type1':
        return (
          <div className={classes.button}>
            <>
              <KeyboardDoubleArrowRightIcon
                sx={{ width: '16px', height: '16px', color: '#FFF' }}
              />
              <span className={classes.buttonText}>{t('claim')}</span>
            </>
          </div>
        );
      case 'type2':
        return (
          <div
            className={classes.button}
            style={{ background: '#FFFFFF1A', boxShadow: 'none' }}
          >
            <>
              <WatchLaterIcon
                sx={{ width: '16px', height: '16px', color: '#FFF' }}
              />
              <span className={classes.buttonText}>
                {t('bonusCampaignExpired')}
              </span>
            </>
          </div>
        );
      case 'type3':
        return (
          <div
            className={classes.button}
            style={{
              background: '#65A30D',
              boxShadow: '0px 2px 8px 0px #65A30D4D',
            }}
          >
            <>
              <AddIcon sx={{ width: '16px', height: '16px', color: '#FFF' }} />
              <span className={classes.buttonText}>{t('deposit')}</span>
            </>
          </div>
        );
      case 'type4':
        return (
          <div
            className={classes.button}
            style={{ background: '#FFFFFF1A', boxShadow: 'none' }}
          >
            <>
              <DoneIcon sx={{ width: '16px', height: '16px', color: '#FFF' }} />
              <span className={classes.buttonText}>
                {t('youHaveFullyClaimedBonus')}
              </span>
            </>
          </div>
        );
    }
  };

  const totalBonus = depositBonus['unusedBonuses']
    ? depositBonus.amount
    : depositBonus.init_amount;

  const isBonusExpired = () => {
    const expirationTimeUTC = Date.parse(depositBonus.expire_at);
    const currentTimeUTC = Date.now();
    return expirationTimeUTC < currentTimeUTC;
  };

  const isFullyClaimed = () => {
    return (
      depositBonus.wagered_amount === depositBonus.wager_amount &&
      !depositBonus.can_claim &&
      !depositBonus.unusedBonuses
    );
  };

  const getContainerStyles = () => {
    if (isBonusExpired()) {
      return {
        background: '#0D121D',
      };
    }

    if (isFullyClaimed()) {
      return {
        background:
          'linear-gradient(180deg, rgba(59, 76, 199, 0.20) 0%, rgba(59, 76, 199, 0.00) 100%), #0D121D',
      };
    }

    if (!(depositBonus.can_claim > 0)) {
      return {
        background: '#111827',
      };
    } else {
      return {
        background:
          'linear-gradient(180deg, rgba(59, 76, 199, 0.20) 0%, rgba(59, 76, 199, 0.00) 100%), #0D121D',
      };
    }
  };

  const getButtonType = () => {
    if (isBonusExpired()) {
      return 'type2';
    }

    if (isFullyClaimed()) {
      return 'type4';
    }

    if (!(depositBonus.can_claim > 0)) {
      return 'type3';
    } else {
      return 'type1';
    }
  };

  const getProgressBarItemStyle = (index: number, arrLength: number) => {
    const style = {
      justifyContent: 'center',
    };

    if (Math.ceil(arr.length / 2) === index + 1) {
      style.justifyContent = 'center';
    }
    if (Math.ceil(arr.length / 2) < index + 1) {
      style.justifyContent = 'flex-end';
    }
    if (Math.ceil(arr.length / 2) > index + 1) {
      style.justifyContent = 'flex-start';
    }

    if (arrLength === 2 && index === 0) style.justifyContent = 'flex-start';
    if (index + 1 === arr.length) style.justifyContent = 'flex-end';

    return style;
  };

  const claim = () => {
    claimDepositBonuses({ player_bonus_id: depositBonus.id })
      .then((resp: any) => {
        if (resp.error) {
          enqueueSnackbar(resp.error.data.data.error, {
            variant: 'error',
          });
        } else {
          if (resp.success || resp.data.success) {
            enqueueSnackbar(t('bonusClaimedSuccess'), {
              variant: 'success',
            });
            initGetAllDepositBonus();
          }
        }
      })
      .catch(() => {});
  };

  const onClickBonus = () => {
    if (!isBonusExpired() && !isFullyClaimed()) {
      if (!(depositBonus.can_claim > 0)) {
        navigate('/account/deposit');
      } else {
        claim();
      }
    }
  };

  return (
    <Box
      sx={{
        background:
          'linear-gradient(180deg, rgba(59, 76, 199, 0.20) 0%, rgba(59, 76, 199, 0.00) 100%), #0D121D',
        borderRadius: '8px',
        minWidth: '300px',
        flex: '1 0 calc(33.333% - 16px)',
        maxWidth: 'calc(33.333% - 16px)',

        '@media (max-width: 1433px)': {
          flexBasis: 'calc(50% - 8px)',
          maxWidth: 'calc(50% - 8px)',
        },

        '@media (max-width: 890px)': {
          flexBasis: '100%',
          maxWidth: '100%',
        },
      }}
      style={{
        ...getContainerStyles(),
      }}
    >
      <Box className={classes.root}>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <span className={classes.title}>{t(depositBonus.take_on)}</span>
          <span className={classes.text}>{t('depositBonusItemText')}</span>
        </Box>
        {/* progress bar here */}
        <Box
          style={{
            height: '28px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              width: '100%',
              background: '#FFFFFF1A',
              height: '4px',
              display: 'flex',
            }}
          >
            <div
              style={{
                width: isBonusExpired()
                  ? 0
                  : `${(100 * depositBonus.wagered_amount) / depositBonus.wager_amount}%`,
                boxShadow:
                  depositBonus.wagered_amount === depositBonus.wager_amount
                    ? '0px 0px 8px 0px #4E7C11'
                    : '0px 0px 8px 0px #5568E4',
                height: '4px',
                background:
                  depositBonus.wagered_amount === depositBonus.wager_amount
                    ? '#84CC16'
                    : '#6682EE',
              }}
            />
            {!isBonusExpired() && (
              <div
                style={{
                  width: '4px',
                  boxShadow: '0px 0px 8px 0px #5568E4',
                  height: '4px',
                  background: '#fff',
                }}
              />
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {arr.map((item: number, index: number) => {
              return (
                <div
                  key={item}
                  style={{
                    flex: 1,
                    fontSize: '12px',
                    lineHeight: '12px',
                    fontWeight: 400,
                    display: 'flex',
                    color: isBonusExpired()
                      ? '#FFFFFF80'
                      : depositBonus.split_claimed
                        ? '#FFFFFF80'
                        : '#FFF',
                    ...getProgressBarItemStyle(index, arr.length),
                  }}
                >
                  {Math.ceil(item)}
                </div>
              );
            })}
          </div>
        </Box>
        <Box
          style={{
            display: 'flex',
            gap: '16px',
          }}
        >
          <InfoContainer
            keyText={
              depositBonus.is_percent ? t('possibleBonus') : t('totalBonus')
            }
            value={`${depositBonus.is_percent ? '%' : '$'} ${totalBonus}`}
          />
          <InfoContainer
            keyText={t('alreadyClaimed')}
            value={`$${depositBonus['unusedBonuses'] ? 0 : depositBonus.claimed_amount}`}
          />
          <InfoContainer
            keyText={t('readyToClaim')}
            value={`$${depositBonus['unusedBonuses'] ? 0 : depositBonus.can_claim}`}
          />
        </Box>
        <span onClick={() => onClickBonus()}>
          {DepositBonusButton(getButtonType())}
        </span>
      </Box>
    </Box>
  );
};

export default DepositBonusItem;
