import { makeStyles } from '@mui/styles';
import WalletIcon from '../../api/ui/svgIcons/WalletIcon';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import AirbetDepositIcon from '../../api/ui/svgIcons/AirbetDepositIcon';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import CustomSecondaryButton from '../common/Buttons/CustomSecondaryButton';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import { connect } from 'react-redux';
import { IUserInfo } from '../../types/common.type';

interface IWallet {
  playerInfo: IUserInfo;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    ...theme.general.accountBody,
  },
  header: {
    margin: '16px 0px',
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& path': {
      fill: '#fff',
    },
    '& span': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  body: {
    margin: '16px',
    background: '#0D121D',
    height: '72px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    justifyContent: 'space-between',
  },
}));

const Wallet = ({ playerInfo }: IWallet) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <WalletIcon size={20} />
        <span>{t('accountInfo')}</span>
      </div>
      <Box
        style={{
          background: '#FFFFFF0D',
          width: '100%',
          height: '1px',
        }}
      />
      <Box className={classes.body}>
        <Box display={'flex'} alignItems={'center'}>
          <AirbetDepositIcon size={24} />
          <span
            style={{
              fontSize: '24px',
              color: '#fff',
              fontWeight: 700,
              lineHeight: '24px',
              marginLeft: '8px',
            }}
          >
            {Number(playerInfo.player.balance).toFixed(2)}
          </span>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={'16px'}>
          <CustomPrimaryButton
            style={{
              textTransform: 'uppercase',
            }}
            onClick={() => navigate(AppRoutePath.DEPOSIT())}
          >
            {t('deposit')}
          </CustomPrimaryButton>
          <CustomSecondaryButton
            style={{
              textTransform: 'uppercase',
            }}
            onClick={() => navigate(AppRoutePath.WITHDRAW())}
          >
            {t('withdraw')}
          </CustomSecondaryButton>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  playerInfo: state.user.playerInfo,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(Wallet);
