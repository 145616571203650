import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { IMessageDataType } from '../../types/Chat.type';
import { makeStyles } from '@mui/styles';
import ChatMessage from './ChatMessage';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    width: '100%',
    height: '100%',
    background: '#030712',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'auto',
  },
  scrollDownButton: {
    position: 'fixed',
    bottom: '130px',
    right: '30px',
    width: '40px',
    height: '40px',
    backgroundColor: '#3c485c',
    borderRadius: '50%',
    zIndex: 1,
    '& .MuiIconButton-root': {
      color: '#FFF',
    },
  },
}));

interface ChatBodyProps {
  chatData: IMessageDataType[];
  isChatAdmin: boolean;
  isOpen: boolean;
  showHideMessage: (messageId: string, action: string) => void;
  setMessage: (info: string) => void;
}

const ChatBody: FC<ChatBodyProps> = ({
  chatData,
  isChatAdmin,
  isOpen,
  showHideMessage,
  setMessage,
}) => {
  const classes = useStyles();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showScrollDown, setShowScrollDown] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen && chatData.length > 0) {
      scrollToBottom('auto');
      setFirstLoad(false);
    }
  }, [isOpen, chatData]);

  const scrollToBottom = (behavior: 'auto' | 'smooth' | undefined) => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
        behavior: behavior ? behavior : firstLoad ? 'auto' : 'smooth',
      });
    }
  };

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;

    const scrollPositionFromBottom = scrollHeight - (scrollTop + clientHeight);

    setShowScrollDown(scrollPositionFromBottom > 200);
  };

  return (
    <Box className={classes.root} onScroll={onScroll} ref={scrollContainerRef}>
      {chatData.map((message: IMessageDataType) => (
        <ChatMessage
          key={message._id}
          message={message}
          isChatAdmin={isChatAdmin}
          showHideMessage={showHideMessage}
          setMessage={setMessage}
        />
      ))}

      {showScrollDown && isOpen && (
        <Box
          className={classes.scrollDownButton}
          onClick={() => scrollToBottom('smooth')}
        >
          <IconButton>
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ChatBody;
