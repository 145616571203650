import instance from '../axios';
import {
  IMetamaskAuth,
  IMetamaskNonce,
  ISignIn,
  ISignUp,
} from '../../types/Auth.type';
import { IPassword } from '../../containers/ResetPassword.tsx/ResetPasswordContainer';

export const SignIn = (body: ISignIn) => {
  return instance.post('/login', body);
};

export const getMetaMaskNonce = (body: IMetamaskNonce) => {
  return instance.post('/auth/metamask/nonce', {
    ...body,
  });
};

export const metaMaskAuth = (body: IMetamaskAuth) => {
  return instance.post('/auth/metamask', {
    ...body,
  });
};

export const SignUp = (body: ISignUp) => {
  return instance.post('/register', {
    ...body,
  });
};

export const SignOut = () => {
  return instance.post('/logout');
};

export const RefreshToken = () => {
  instance.defaults.withCredentials = true;
  return instance.post('/auth/refreshToken');
};

export const forgotPassword = (body: { email: string }) => {
  return instance.post('/forgot-password', {
    ...body,
  });
};

export const resetPassword = (body: IPassword) => {
  return instance.post('/reset-password', {
    ...body,
  });
};
