import React from 'react';

type Props = {
  size?: number;
  className?: string;
};

const FlagUnitedKingdom: React.FC<Props> = ({
  size = 24,
  className = 'rounded-full',
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      fill="none"
      viewBox="0 0 20 21"
    >
      <g mask="url(#mask0_41_1465)">
        <path
          d="M25.6304 0.385498H-5.35718V21.0419H25.6304V0.385498Z"
          fill="#004AAF"
        />
        <path
          d="M0.41322 19.3681L-2.41732 19.4689L-2.48259 17.5879L7.23413 10.606L-2.50003 3.63689L-2.41695 1.78547L0.407652 1.88042L9.90981 8.68344L19.4437 1.83297L22.2683 1.73804L22.3336 3.61912L12.602 10.6109L22.3636 17.5997L22.2805 19.4511L19.4559 19.3562L9.92618 12.5334L0.41322 19.3681Z"
          fill="#FAFAFC"
        />
        <path
          d="M14.0021 7.67208L12.1151 7.73735L20.3456 1.8033L22.2267 1.73804L14.0021 7.67208Z"
          fill="#F50D39"
        />
        <path
          d="M-0.571151 19.4215L-2.45818 19.4867L5.77234 13.5527L7.65937 13.4874L-0.571151 19.4215Z"
          fill="#F50D39"
        />
        <path
          d="M22.2384 19.4689L22.2799 18.2109L15.889 13.6358L14.0019 13.5705L22.2384 19.4689Z"
          fill="#F50D39"
        />
        <path
          d="M-2.50003 3.06132L-2.45849 1.8033L5.77796 7.70174L3.89687 7.63646L-2.50003 3.06132Z"
          fill="#F50D39"
        />
        <path
          d="M7.65421 -0.0478516H12.3718V7.54181H24.3045V13.8972H12.3718V21.4809H7.65421V13.8972H-4.28577V7.54181H7.65421V-0.0478516Z"
          fill="#FAFAFC"
        />
        <path
          d="M8.598 0.309326H11.4285V8.82472H24.3045V12.6344H11.4285V21.1556H8.598V12.6344H-4.28577V8.82472H8.598V0.309326Z"
          fill="#F50D39"
        />
      </g>
    </svg>
  );
};

export default FlagUnitedKingdom;
