import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import SuccessIcon from '../../../api/ui/svgIcons/NotificationIcons/SuccessIcon';
import WarningIcon from '../../../api/ui/svgIcons/NotificationIcons/WarningIcon';
import { useEffect, useState } from 'react';
import { IUserInfo } from '../../../types/common.type';
import PhoneUpdateModal from './PhoneUpdateModal';
import PhoneConfirmModal from './PhoneConfirmModal';
import { isMobile } from 'react-device-detect';
import SettingsButton from '../SettingsButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    padding: '16px',
    borderRadius: '8px',
    background: '#0D121D',
    border: '1px solid',
    borderColor: 'transparent',
    transition: 'background 0.3s ease-in-out, border-color 0.3s ease-in-out',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: isMobile ? 'space-between' : 'center',
    width: '100%',
    gap: '16px',
    overflow: 'hidden',
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '24px',
    fontWeight: 700,
    fontSize: '14px',
    overflow: 'hidden',
    maxWidth: '50%',

    '& div:first-child': {
      fontSize: '12px',
      color: '#6B7280',
      lineHeight: '16px',
      fontWeight: 600,
    },
  },
  verify: {
    display: 'flex',
    gap: '8px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    alignItems: 'center',
    flexWrap: 'wrap',

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  buttons: {
    display: 'flex',
    width: isMobile ? '100%' : 'auto',
    justifyContent: 'space-between',
    gap: '24px',
    '& div': {
      flex: 1,
    },
  },
}));

interface IPhoneInformation {
  playerInfo: IUserInfo;
  onChangePhone: (phoneNumber: string) => void;
}

const PhoneInformation = ({ playerInfo, onChangePhone }: IPhoneInformation) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [openConfirmPhoneModal, setOpenConfirmPhoneModal] = useState(false);

  const [seconds, setSeconds] = useState(30);
  const [minutes, setMinutes] = useState(1);
  const [timerIsOn, setTimerIsOn] = useState(false);

  const { player } = playerInfo;
  const openPhoneModalHandler = () => {
    setOpenPhoneModal(true);
  };
  const closePhoneModalHandler = () => {
    setOpenPhoneModal(false);
  };

  const timerOnHandler = () => {
    setTimerIsOn(true);
  };

  useEffect(() => {
    if (!timerIsOn) return;
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0) {
          setTimerIsOn(false);
          setMinutes(1);
          setSeconds(30);
          clearInterval(timer);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [minutes, seconds, timerIsOn]);

  return (
    <Box className={classes.root}>
      {openPhoneModal && (
        <PhoneUpdateModal
          isOpen={openPhoneModal}
          onClose={closePhoneModalHandler}
          onChangePhone={onChangePhone}
          phoneNumber={playerInfo.player.phone || ''}
        />
      )}

      {openConfirmPhoneModal && (
        <PhoneConfirmModal
          isOpen={openConfirmPhoneModal}
          onClose={() => setOpenConfirmPhoneModal(false)}
          minutes={minutes}
          seconds={seconds}
          timerIsOn={timerIsOn}
          timerOnHandler={timerOnHandler}
        />
      )}

      <Box className={classes.container}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          <Box className={classes.contact}>
            <div>{t('phone')}</div>
            <div
              style={{
                fontWeight: player.phone ? 700 : 600,
                fontSize: '14px',
                lineHeight: '24px',
              }}
            >
              {player.phone || t('addPhoneNumber')}
            </div>
          </Box>
          <Box className={classes.verify}>
            <div>
              {player.phone_verified ? (
                <SuccessIcon size={16} />
              ) : (
                <WarningIcon size={16} fill={'#9197A3'} color={'#0D121D'} />
              )}
            </div>
            <div
              style={{
                color: player.phone_verified ? '#84CC16' : '#9197A3',
              }}
            >
              {player.phone_verified
                ? t('verified')
                : t('requiresVerification')}
            </div>
          </Box>
        </div>
        <Box>
          <Box className={classes.buttons}>
            <SettingsButton
              action={openPhoneModalHandler}
              buttonText={t('edit')}
            />
            {!player.phone_verified && (
              <SettingsButton
                action={() => setOpenConfirmPhoneModal(true)}
                buttonText={t('verify')}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default PhoneInformation;
