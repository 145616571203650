const FeaturedIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00007 5.66668L9.41074 9.05834L13.0723 9.35194L10.2826 11.7417L11.1349 15.3147L8.00007 13.4L4.86518 15.3147L5.7175 11.7417L2.92773 9.35194L6.58935 9.05834L8.00007 5.66668ZM5.33337 1.33334V7.33334H4.00003V1.33334H5.33337ZM12.0001 1.33334V7.33334H10.6667V1.33334H12.0001ZM8.66673 1.33334V4.66668H7.3334V1.33334H8.66673Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default FeaturedIcon;
