import { ISvgIcon } from '../../../../types/common.type';

const WarningIcon = ({ className, size, fill, color }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="24" height="24" rx="12" fill={fill} />
      <path d="M11 17V15H13V17H11Z" fill={color} />
      <path d="M11 13V7H13V13H11Z" fill={color} />
    </svg>
  );
};

export default WarningIcon;
