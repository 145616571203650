import * as React from 'react';
import { makeStyles } from '@mui/styles';
import formatDate from './helpers';
import { useTranslation } from 'react-i18next';
import { getBaseUrlStatic } from '../../api/baseUrl';
import { IBlog } from '../../types/common.type';
import BlogButton from './BlogButton';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: '#111827',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  buttonContainer: {
    display: 'flex',
    gap: '8px',
    '& .category': {
      backgroundColor: '#3B82F6',
    },
  },

  button: {
    backgroundColor: '#1F2937',
    color: '#fff',
    padding: '4px 8px',
    fontSize: '12px',
    fontWeight: 600,
    border: 'none',
    borderRadius: '4px',
  },

  title: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '32px',
    marginTop: '24px',
    color: '#fff',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  message: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    marginTop: '8px',
    color: '#9CA3AF',
  },
  blogImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    '& img': {
      width: '100%',
      height: '206px',
      objectFit: 'cover',
    },
  },
}));

const BlogCard = ({
  category,
  create_dt,
  image,
  post,
  title,
  update_dt,
  onSelectBlog,
}: IBlog) => {
  const classes = useStyles({});
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        borderRadius: '12px',
        overflow: 'hidden',
        boxSizing: 'border-box',
        width: '370px',
        userSelect: 'text',
      }}
    >
      <div className={classes.root}>
        <div className={classes.blogImage}>
          <img src={getBaseUrlStatic() + '/' + image} alt={'blog img'} />
        </div>
        <div style={{ padding: '24px' }}>
          <div className={classes.buttonContainer}>
            <button className={`${classes.button} category`}>{category}</button>
            <button className={classes.button}>
              {formatDate(update_dt ? update_dt : create_dt)}
            </button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <div className={classes.title}>{title}</div>
            <div className={classes.message}>
              <div
                dangerouslySetInnerHTML={{
                  __html: post,
                }}
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                  height: '77px',
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', padding: '0 0 24px 24px' }}>
          <BlogButton
            action={onSelectBlog ? onSelectBlog : () => {}}
            buttonText={t('continue_reading')}
          />
        </div>
      </div>
    </div>
  );
};
export default BlogCard;
