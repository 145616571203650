import Transaction from '../../components/Account/Transaction';
import { useEffect, useState } from 'react';
import {
  registerUser,
  socket,
  useTransactionUpdated,
  useWithdrawUpdated,
} from '../../api/airbet.socket';
import { getLocalStorageValue } from '../../util/appUtil';
import {
  ITransactionDeposit,
  ITransactionWithdraw,
} from '../../types/common.type';

const TransactionContainer = () => {
  const [withdraw, setWithdraw] = useState<ITransactionWithdraw[]>([]);
  const [deposit, setDeposit] = useState<ITransactionDeposit[]>([]);
  const [withdrawLoading, setWithdrawLoading] = useState<boolean>(true);
  const [depositLoading, setDepositLoading] = useState<boolean>(true);
  const transactionSocket = useTransactionUpdated();
  const withdrawSocket = useWithdrawUpdated();

  useEffect(() => {
    const userId = getLocalStorageValue('userId', 0);
    const token = getLocalStorageValue('token', '');
    registerUser(token, userId);

    socket.emit('getTransactions', { token: token, userId: userId }, () => {});
    socket.emit(
      'getWithdrawRequests',
      { token: token, userId: userId },
      () => {}
    );

    return () => {
      socket.off('getWithdrawRequests');
      socket.off('getTransactions');
    };
  }, []);

  useEffect(() => {
    if (transactionSocket) {
      handleTransactionUpdate(transactionSocket);
    }
  }, [transactionSocket]);

  useEffect(() => {
    if (withdrawSocket) {
      handleWithdrawUpdate(withdrawSocket);
    }
  }, [withdrawSocket]);

  const handleWithdrawUpdate = (withdraw: ITransactionWithdraw[]) => {
    setWithdraw(withdraw);
    setWithdrawLoading(false);
  };

  const handleTransactionUpdate = (deposit: ITransactionDeposit[]) => {
    setDeposit(deposit);
    setDepositLoading(false);
  };

  const reorderData = (
    depositData: ITransactionDeposit[],
    withdrawData: ITransactionWithdraw[]
  ) => {
    return [...depositData, ...withdrawData].sort(
      (
        a: ITransactionWithdraw | ITransactionDeposit,
        b: ITransactionWithdraw | ITransactionDeposit
      ) => {
        const dateA = new Date(a.create_time).getTime();
        const dateB = new Date(b.create_time).getTime();
        return dateB - dateA;
      }
    );
  };

  const getTableData = (type: string) => {
    if (type === 'deposit') {
      return reorderData(deposit, []);
    } else if (type === 'withdraw') {
      return reorderData([], withdraw);
    } else {
      return reorderData(deposit, withdraw);
    }
  };

  return (
    <Transaction
      loading={withdrawLoading && depositLoading}
      getTableData={getTableData}
    />
  );
};

export default TransactionContainer;
