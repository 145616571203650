import { ISvgIcon } from '../../../types/common.type';

const SearchIcon = ({ size = 20, className, pathClassName }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.0262 13.8473L18.5951 17.4163L17.4166 18.5947L13.8477 15.0258C12.5644 16.0525 10.937 16.6667 9.16699 16.6667C5.02699 16.6667 1.66699 13.3067 1.66699 9.16667C1.66699 5.02667 5.02699 1.66667 9.16699 1.66667C13.307 1.66667 16.667 5.02667 16.667 9.16667C16.667 10.9367 16.0528 12.5641 15.0262 13.8473ZM13.3542 13.229C14.3732 12.1788 15.0003 10.7463 15.0003 9.16667C15.0003 5.94375 12.3899 3.33333 9.16699 3.33333C5.94408 3.33333 3.33366 5.94375 3.33366 9.16667C3.33366 12.3896 5.94408 15 9.16699 15C10.7467 15 12.1792 14.3729 13.2293 13.3539L13.3542 13.229Z"
        fill="white"
        className={pathClassName}
      />
    </svg>
  );
};

export default SearchIcon;
