import Withdraw from '../../components/Account/Withdraw';
import { useEffect, useState } from 'react';
import {
  ICurrency,
  ICurrencyNetworksId,
  INetwork,
} from '../../types/common.type';
import {
  calculateFee,
  getCurrencyList,
  getNetworkList,
  withdraw,
} from '../../api/services/Account.api';
import { useSnackbar } from 'notistack';

const WithdrawContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [currencies, setCurrencies] = useState<ICurrency[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency | null>(
    null
  );
  const [network, setNetworks] = useState<INetwork[]>([]);
  const [selectedNetwork, setSelectedNetwork] = useState<INetwork | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [feeLoading, setFeeLoading] = useState<boolean>(false);
  const [memo, setMemo] = useState<string | null>(null);
  const [fee, setFee] = useState<{
    total_withdraw_amount: string;
    withdraw_amount: string;
    fee: string;
  } | null>(null);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  useEffect(() => {
    getCurrencyList().then((resp: any) => {
      if (resp.data.data) {
        setCurrencies(resp.data.data);
        const currentCurrenciesData = resp.data.data.find(
          (item: any) => item.name === 'USDT'
        );

        if (resp.data.data.length > 0) {
          setSelectedCurrency(currentCurrenciesData);
        }

        getNetworkList().then((resp: any) => {
          if (resp.data.data) {
            const currentSelectedNetwork = getFilteredNetworks(
              currentCurrenciesData,
              resp.data.data
            );
            setNetworks(resp.data.data);
            if (currentSelectedNetwork && currentSelectedNetwork.length > 0) {
              setSelectedNetwork(currentSelectedNetwork[0]);
            }
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (selectedCurrency && selectedNetwork && address && amount) {
      getFeeResult();
    } else {
      setFee(null);
    }
  }, [selectedCurrency, selectedNetwork, amount]);

  const getFilteredNetworks = (
    selectedCurrency: ICurrency,
    networkData: INetwork[]
  ) => {
    const coinNetworkIds: number[] = selectedCurrency.networks_id.map(
      (item: ICurrencyNetworksId) => {
        return item.coin_network_id;
      }
    );
    if (networkData) {
      return networkData.filter((item: INetwork) =>
        coinNetworkIds.includes(item.id)
      );
    }
    return [];
  };

  const getFeeResult = () => {
    if (selectedCurrency && selectedNetwork) {
      const id: number | undefined = selectedCurrency.networks_id.find(
        (item: ICurrencyNetworksId) =>
          item.coin_network_id === selectedNetwork.id
      )?.coin_id;

      if (id) {
        setFeeLoading(true);
        calculateFee({
          coin_id: id,
          amount: amount,
        })
          .then((reps: any) => {
            if (reps.data.data) {
              setFee({
                fee: reps.data.data.fee,
                withdraw_amount: reps.data.data.withdraw_amount,
                total_withdraw_amount: reps.data.data.total_withdraw_amount,
              });
            }
          })
          .catch((error: any) => {
            if (error.response.data.data.error) {
              enqueueSnackbar(error.response.data.data.error, {
                variant: 'error',
              });
            }
          })
          .finally(() => {
            setFeeLoading(false);
          });
      }
    }
  };

  const onConfirm = () => {
    setConfirmLoading(true);

    if (selectedCurrency && selectedNetwork) {
      const id: number | undefined = selectedCurrency.networks_id.find(
        (item: ICurrencyNetworksId) =>
          item.coin_network_id === selectedNetwork.id
      )?.coin_id;

      const result: any = {
        amount: amount,
        address: address,
        coin_id: id,
      };

      if (memo) {
        result['tag'] = memo;
      }

      withdraw(result)
        .then((resp: any) => {
          if (resp.data.data.message) {
            enqueueSnackbar(resp.data.data.message, {
              variant: 'success',
            });
            setAmount(null);
          }
        })
        .catch(() => {})
        .finally(() => {
          setConfirmLoading(false);
        });
    }
  };

  return (
    <Withdraw
      network={network}
      selectedNetwork={selectedNetwork}
      currencies={currencies}
      selectedCurrency={selectedCurrency}
      getFilteredNetworks={getFilteredNetworks}
      setSelectedNetwork={setSelectedNetwork}
      setSelectedCurrency={setSelectedCurrency}
      address={address}
      setAddress={setAddress}
      amount={amount}
      setAmount={setAmount}
      fee={fee}
      feeLoading={feeLoading}
      memo={memo}
      setMemo={setMemo}
      confirmLoading={confirmLoading}
      onConfirm={onConfirm}
      getFeeResult={getFeeResult}
    />
  );
};

export default WithdrawContainer;
