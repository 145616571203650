const BonusRewardClaimedIcon = () => {
  return (
    <svg
      width="82"
      height="80"
      viewBox="0 0 82 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="40.25"
        y1="2.18557e-08"
        x2="40.25"
        y2="80"
        stroke="#4B5563"
        strokeDasharray="2 2"
      />
      <line
        x1="69.8272"
        y1="13.0086"
        x2="13.2586"
        y2="69.5771"
        stroke="#4B5563"
        strokeDasharray="2 2"
      />
      <line
        x1="81.25"
        y1="42"
        x2="1.25"
        y2="42"
        stroke="#4B5563"
        strokeDasharray="2 2"
      />
      <line
        x1="69.1201"
        y1="71.6985"
        x2="12.5515"
        y2="15.13"
        stroke="#4B5563"
        strokeDasharray="2 2"
      />
      <path
        d="M58.8612 29.3179L58.8625 29.3175L49.107 26L31.3914 26L21.1933 29.3175L30.6747 32.7936L49.3868 32.7953L49.3856 32.7936L56.511 30.18L58.8612 29.3179Z"
        fill="#A3E635"
      />
      <path
        d="M65.7494 35.7068L58.8612 29.3179L56.511 30.18L49.3868 32.7953L56.511 41.9589L65.7494 35.7068Z"
        fill="#BFF164"
      />
      <path
        d="M30.6747 32.7936L30.6742 32.7936L30.6737 32.7943L24.4831 41.9589H56.511L49.3868 32.7953L30.6747 32.7936Z"
        fill="#65A30C"
      />
      <path
        d="M30.6737 32.7943L21.1926 29.3175L14.7507 35.7068L24.4831 41.9589L30.6737 32.7943Z"
        fill="#84CC15"
      />
      <path
        d="M24.4831 41.9589L24.4828 41.959L40.2499 63.9992L41.5903 62.1257L56.511 41.9589H24.4831Z"
        fill="#4D7C0F"
      />
      <path
        d="M40.2501 63.9994L65.75 35.7064L65.7494 35.7068L56.511 41.9589L41.5903 62.1257L40.2501 63.9994Z"
        fill="#1B2D06"
      />
      <path
        d="M24.4828 41.959L14.75 35.7064L40.2464 63.9955L40.2495 63.9998L40.2498 63.9993L40.2499 63.9994L40.2505 64L40.2501 63.9994L40.2499 63.9992L24.4828 41.959Z"
        fill="#3F6212"
      />
      <path
        d="M5.36756 21.2372L7.78059 23.8183L11.2224 24.6175L8.6413 27.0305L7.84211 30.4723L5.42908 27.8912L1.98727 27.092L4.56837 24.679L5.36756 21.2372Z"
        fill="#4B5563"
      />
      <path
        d="M73.4791 52.1145L73.7566 55.2661L75.7429 57.7288L72.5912 58.0063L70.1286 59.9926L69.851 56.8409L67.8648 54.3783L71.0164 54.1007L73.4791 52.1145Z"
        fill="#4B5563"
      />
    </svg>
  );
};

export default BonusRewardClaimedIcon;
