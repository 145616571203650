import CustomModal from '../common/Modal/CustomModal';
import { makeStyles } from '@mui/styles';
import AirbetAuthorizationIcon from '../../api/ui/svgIcons/AirbetAuthorizationIcon';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import AuthorizationContent from './AuthorizationContent';
import { isMobile } from 'react-device-detect';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    width: isMobile ? '100%' : '888px',
    height: isMobile ? '100vh' : '660px',
    borderRadius: isMobile ? '0px' : '12px',
    display: 'flex',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  leftSideContainer: {
    minWidth: '360px',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: 'url(/imgs/loginOrRegistrationBanner.png)',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  icon: {
    position: 'absolute',
  },
  descriptionContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '240px',
  },
  primaryText: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    marginBottom: '8px',
  },
  secondaryText: {
    color: '#FFFFFF80',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: '2',
    width: '24px',
    height: '24px',
    background: '#0D121D',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '16px',
    top: '16px',
    '& .MuiSvgIcon-root': {
      color: '#fff',
      width: '9px',
      height: '9px',
    },
  },
  mobileModal: {
    position: 'fixed',
    width: '100%',
    background: 'red',
    zIndex: 99,
    top: 0,
  },
}));

interface IAuthorization {
  open: boolean;
  onClose: () => void;
  isRegistrationOpen: boolean;
  setIsOpenResetPasswordModal: (isOpen: boolean) => void;
}

const AuthorizationModal = ({
  open,
  onClose,
  isRegistrationOpen,
  setIsOpenResetPasswordModal,
}: IAuthorization) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getBody = () => {
    return (
      <div className={classes.root}>
        {!isMobile && (
          <div className={classes.leftSideContainer}>
            <span className={classes.close} onClick={() => onClose()}>
              <CloseIcon />
            </span>
            <div className={classes.icon}>
              <AirbetAuthorizationIcon />
            </div>
            <div className={classes.descriptionContainer}>
              <span className={classes.primaryText}>
                {t('welcomeToAirbet')}
              </span>
              <span className={classes.secondaryText}>
                {t('welcomeToAirbetDescription')}
              </span>
            </div>
          </div>
        )}
        <AuthorizationContent
          onClose={onClose}
          isRegistrationOpen={isRegistrationOpen}
          setIsOpenResetPasswordModal={setIsOpenResetPasswordModal}
        />
      </div>
    );
  };

  return isMobile ? (
    open && <Box className={classes.mobileModal}>{getBody()}</Box>
  ) : (
    <CustomModal
      open={open}
      onClose={() => onClose()}
      customStyle={{
        borderRadius: '0px',
      }}
    >
      {getBody()}
    </CustomModal>
  );
};

export default AuthorizationModal;
