import { combineReducers } from 'redux';
import userReducer from './reducers/userReducer';
import gameReducer from './reducers/gameReducer';
import blogReducer from './reducers/blogsReducer';
import settingReducer from './reducers/settingReducer';
import sportsReducer from './reducers/sportsReducer';

export const rootReducer: any = combineReducers({
  user: userReducer,
  game: gameReducer,
  blogs: blogReducer,
  settings: settingReducer,
  sports: sportsReducer,
});
