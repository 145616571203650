import Deposit from '../../components/Account/Deposit';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
  ICurrency,
  ICurrencyNetworksId,
  INetwork,
} from '../../types/common.type';
import {
  getCurrencyList,
  getNetworkList,
  getWallet,
} from '../../api/services/Account.api';

interface DepositContainerProps {
  hideHeader?: boolean;
}

const DepositContainer = ({ hideHeader }: DepositContainerProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [currencies, setCurrencies] = useState<ICurrency[]>([]);
  const [network, setNetworks] = useState<INetwork[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency | null>(
    null
  );
  const [selectedNetwork, setSelectedNetwork] = useState<INetwork | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [hasTag, setHasTag] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCurrencyList().then((resp: any) => {
      if (resp.data.data) {
        const currentCurrenciesData = resp.data.data;
        setCurrencies(resp.data.data);

        if (resp.data.data.length > 0) {
          const usdtIndex = resp.data.data.findIndex(
            (item: any) => item.name === 'USDT'
          );
          if (usdtIndex) {
            setSelectedCurrency(resp.data.data[usdtIndex]);
          } else {
            setSelectedCurrency(resp.data.data[0]);
          }
        }

        getNetworkList().then((resp: any) => {
          if (resp.data.data) {
            setNetworks(resp.data.data);

            const usdtIndex = currentCurrenciesData.findIndex(
              (item: any) => item.name === 'USDT'
            );

            const currentCur = usdtIndex
              ? currentCurrenciesData[usdtIndex]
              : currentCurrenciesData[0];

            const currentSelectedNetwork = getFilteredNetworks(
              currentCur,
              resp.data.data
            );

            if (currentSelectedNetwork && currentSelectedNetwork.length > 0) {
              const trc20Index = currentSelectedNetwork.findIndex(
                (item: any) => item.slug === 'tron-trc-20-token'
              );
              setSelectedNetwork(
                trc20Index && trc20Index !== -1
                  ? currentSelectedNetwork[trc20Index]
                  : currentSelectedNetwork[0]
              );
            }
          }
        });
      }
    });

    getNetworkList().then((resp: any) => {
      if (resp.data.data) {
        setNetworks(resp.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedCurrency && selectedNetwork) {
      const id: number | undefined = selectedCurrency.networks_id.find(
        (item: ICurrencyNetworksId) =>
          item.coin_network_id === selectedNetwork.id
      )?.coin_id;

      if (id) {
        setLoading(true);
        getWallet(String(id))
          .then((resp: any) => {
            if (resp.data.data) {
              setAddress(resp.data.data.address);
              setHasTag(resp.data.data.tag);
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data.data.error, {
              variant: 'error',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [selectedCurrency, selectedNetwork]);

  const getFilteredNetworks = (
    selectedCurrency: ICurrency,
    networkData: INetwork[]
  ) => {
    const coinNetworkIds: number[] = selectedCurrency.networks_id.map(
      (item: ICurrencyNetworksId) => {
        return item.coin_network_id;
      }
    );
    if (networkData) {
      return networkData.filter((item: INetwork) =>
        coinNetworkIds.includes(item.id)
      );
    }
    return [];
  };

  return (
    <Deposit
      currencies={currencies}
      network={network}
      address={address}
      loading={loading}
      selectedCurrency={selectedCurrency}
      selectedNetwork={selectedNetwork}
      getFilteredNetworks={getFilteredNetworks}
      setAddress={setAddress}
      setSelectedCurrency={setSelectedCurrency}
      setSelectedNetwork={setSelectedNetwork}
      hasTag={hasTag}
      setHasTag={setHasTag}
      hideHeader={hideHeader}
    />
  );
};

export default DepositContainer;
