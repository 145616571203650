const BonusNotEnoughWageredIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="39"
        y1="2.18557e-08"
        x2="39"
        y2="80"
        stroke="#4B5563"
        strokeDasharray="2 2"
      />
      <line
        x1="68.5772"
        y1="13.0086"
        x2="12.0086"
        y2="69.5771"
        stroke="#4B5563"
        strokeDasharray="2 2"
      />
      <line
        x1="80"
        y1="42"
        x2="-4.37114e-08"
        y2="42"
        stroke="#4B5563"
        strokeDasharray="2 2"
      />
      <line
        x1="67.8701"
        y1="71.6985"
        x2="11.3015"
        y2="15.13"
        stroke="#4B5563"
        strokeDasharray="2 2"
      />
      <g filter="url(#filter0_di_1455_12679)">
        <circle
          cx="38.75"
          cy="44.0936"
          r="20"
          fill="url(#paint0_linear_1455_12679)"
        />
        <circle
          cx="38.75"
          cy="44.0936"
          r="20"
          fill="url(#paint1_linear_1455_12679)"
          fillOpacity="0.05"
        />
        <circle
          cx="38.75"
          cy="44.0936"
          r="19.5"
          stroke="white"
          strokeOpacity="0.02"
        />
      </g>
      <g filter="url(#filter1_f_1455_12679)">
        <ellipse
          cx="27.6935"
          cy="36.2516"
          rx="4"
          ry="6"
          transform="rotate(40.6487 27.6935 36.2516)"
          fill="white"
          fillOpacity="0.35"
        />
      </g>
      <ellipse
        cx="38.75"
        cy="27.5936"
        rx="8"
        ry="1.5"
        fill="#010617"
        fillOpacity="0.4"
      />
      <path
        d="M50.7128 45.077C50.7651 44.5894 50.7597 44.0938 50.7128 43.6056C50.684 43.3043 50.6125 43.0085 50.5411 42.7152C49.7648 39.5368 48.3976 37.017 45.7966 35.1988C42.953 33.2109 39.7791 32.7805 36.4462 33.2896C34.1045 33.6475 32.0787 34.702 30.3321 36.3364C28.1748 38.3544 27.0207 40.8841 26.777 43.8318C26.5944 46.0387 27.3432 47.9417 28.8743 49.5128C30.1988 50.8717 31.8217 51.6852 33.6062 52.1894C33.8734 52.265 33.955 52.3745 33.958 52.6641C33.9754 54.6071 36.0775 55.2903 37.2441 54.7123C37.3348 54.6674 37.4777 54.6987 37.5858 54.7307C37.9454 54.8383 38.2931 55.0129 38.6587 55.0689C39.3132 55.1685 39.916 54.9576 40.4312 54.5954C40.6191 54.6809 40.7668 54.7756 40.9277 54.8174C42.5195 55.2282 44.0505 54.1958 43.9563 52.5214C43.9413 52.2527 44.0199 52.1334 44.2835 52.0535C45.3775 51.7215 46.4114 51.2455 47.3505 50.5753C48.8155 49.53 49.9137 48.1932 50.4192 46.4082C50.5429 45.9723 50.6648 45.5302 50.7134 45.0782L50.7128 45.077ZM43.9022 39.5375C44.5405 39.5178 45.1721 39.7588 45.7143 40.2347C47.3973 41.7135 47.5924 44.4018 46.1496 46.1463C45.0034 47.5322 43.1535 47.6687 41.8254 46.466C40.3465 45.1262 40.1184 42.5313 41.3384 40.8939C41.9629 40.0558 42.753 39.5301 43.9022 39.5381L43.9022 39.5375ZM30.6917 43.5219C30.6857 42.3561 30.9667 41.5088 31.5828 40.7242C32.7224 39.2724 34.7104 39.1132 35.9869 40.3712C37.5516 41.914 37.626 44.6349 36.1478 46.2717C34.9367 47.6122 33.0076 47.6325 31.7767 46.308C31.0262 45.5006 30.6851 44.5242 30.6923 43.5219L30.6917 43.5219ZM38.6479 50.5193C38.6479 50.5132 38.6467 50.5071 38.6461 50.5009C38.5272 50.4917 38.4066 50.4898 38.2889 50.4708C37.8001 50.3927 37.5173 50.1012 37.5119 49.6192C37.5041 48.912 37.7809 48.3094 38.2607 47.8151C38.5501 47.5169 38.8707 47.5064 39.2075 47.7536C39.6344 48.0659 39.9166 48.4921 40.0595 49.0092C40.2661 49.7581 39.9713 50.2795 39.2369 50.4523C39.0454 50.4972 38.8443 50.4978 38.6473 50.5187L38.6479 50.5193Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.75 22.0936H31.75V27.0936C31.75 27.6459 34.884 28.0936 38.75 28.0936C42.616 28.0936 45.75 27.6459 45.75 27.0936V22.0936Z"
        fill="url(#paint2_linear_1455_12679)"
      />
      <ellipse cx="38.75" cy="22.0936" rx="7" ry="1" fill="#A76E4A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.2102 19.0405C50.0435 19.5937 48.7068 19.4736 47.3358 19.0166C46.4189 18.7109 44.8721 18.3122 43.2922 18.1341C41.6412 17.9479 40.2682 18.0416 39.4811 18.4352C39.289 18.5312 39.2764 18.5933 39.2676 18.6367C39.2669 18.6397 39.2664 18.6427 39.2657 18.6455C39.2348 18.7839 39.2394 19.0573 39.3669 19.5195C39.4779 19.9218 39.6384 20.3241 39.8136 20.7636C39.8352 20.8176 39.857 20.8722 39.8789 20.9274C39.9713 21.1601 40.0738 21.4231 40.1514 21.6709C40.2198 21.8893 40.3207 22.2493 40.3096 22.6364L37.3108 22.5507C37.3087 22.6256 37.317 22.6709 37.317 22.6709C37.3169 22.6709 37.3111 22.6396 37.2886 22.5678C37.2483 22.4391 37.1844 22.2708 37.0907 22.0347C37.0711 21.9853 37.0503 21.9334 37.0285 21.879C36.8595 21.4566 36.6335 20.892 36.475 20.3176C36.299 19.6798 36.1482 18.8407 36.3379 17.9916C36.5486 17.0478 37.145 16.2491 38.1395 15.7519C39.7524 14.9454 41.8794 14.9558 43.6283 15.153C45.4483 15.3582 47.2015 15.8095 48.2845 16.1705C49.3171 16.5147 49.7606 16.4077 49.925 16.3298C50.0714 16.2604 50.3178 16.0617 50.5576 15.3597C50.7987 14.6535 50.9531 13.668 51.0659 12.3731C51.1406 11.5162 51.1923 10.5966 51.2485 9.59527C51.2772 9.08552 51.307 8.5546 51.3417 8L54.3358 8.18713C54.3041 8.69466 54.2756 9.20175 54.2475 9.70114C54.1894 10.7328 54.1332 11.7315 54.0546 12.6335C53.9382 13.9702 53.7617 15.2601 53.3966 16.3292C53.0301 17.4025 52.3948 18.4789 51.2102 19.0405Z"
        fill="#F9D97D"
      />
      <mask
        id="mask0_1455_12679"
        maskUnits="userSpaceOnUse"
        x="36"
        y="8"
        width="19"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.2102 19.0405C50.0435 19.5937 48.7068 19.4736 47.3358 19.0166C46.4189 18.7109 44.8721 18.3122 43.2922 18.1341C41.6412 17.9479 40.2682 18.0416 39.4811 18.4352C39.289 18.5312 39.2764 18.5933 39.2676 18.6367C39.2669 18.6397 39.2664 18.6427 39.2657 18.6455C39.2348 18.7839 39.2394 19.0573 39.3669 19.5195C39.4779 19.9218 39.6384 20.3241 39.8136 20.7636C39.8352 20.8176 39.857 20.8722 39.8789 20.9274C39.9713 21.1601 40.0738 21.4231 40.1514 21.6709C40.2198 21.8893 40.3207 22.2493 40.3096 22.6364L37.3108 22.5507C37.3087 22.6256 37.317 22.6709 37.317 22.6709C37.3169 22.6709 37.3111 22.6396 37.2886 22.5678C37.2483 22.4391 37.1844 22.2708 37.0907 22.0347C37.0711 21.9853 37.0503 21.9334 37.0285 21.879C36.8595 21.4566 36.6335 20.892 36.475 20.3176C36.299 19.6798 36.1482 18.8407 36.3379 17.9916C36.5486 17.0478 37.145 16.2491 38.1395 15.7519C39.7524 14.9454 41.8794 14.9558 43.6283 15.153C45.4483 15.3582 47.2015 15.8095 48.2845 16.1705C49.3171 16.5147 49.7606 16.4077 49.925 16.3298C50.0714 16.2604 50.3178 16.0617 50.5576 15.3597C50.7987 14.6535 50.9531 13.668 51.0659 12.3731C51.1406 11.5162 51.1923 10.5966 51.2485 9.59527C51.2772 9.08552 51.307 8.5546 51.3417 8L54.3358 8.18713C54.3041 8.69466 54.2756 9.20175 54.2475 9.70114C54.1894 10.7328 54.1332 11.7315 54.0546 12.6335C53.9382 13.9702 53.7617 15.2601 53.3966 16.3292C53.0301 17.4025 52.3948 18.4789 51.2102 19.0405Z"
          fill="#F9D97D"
        />
      </mask>
      <g mask="url(#mask0_1455_12679)">
        <path
          d="M49.25 7.09357L57.25 14.5936M48.25 11.5936L53.75 22.0936M43.25 13.0936C43.65 14.2936 46.4167 19.2602 47.75 21.5936M36.25 13.5936C36.25 13.9936 40.25 18.0936 42.25 20.0936"
          stroke="#C05421"
          strokeOpacity="0.31"
          strokeWidth="2"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.2102 19.0405C50.0435 19.5937 48.7068 19.4736 47.3358 19.0166C46.4189 18.7109 44.8721 18.3122 43.2922 18.1341C41.6412 17.9479 40.2682 18.0416 39.4811 18.4352C39.289 18.5312 39.2764 18.5933 39.2676 18.6367C39.2669 18.6397 39.2664 18.6427 39.2657 18.6455C39.2348 18.7839 39.2394 19.0573 39.3669 19.5195C39.4779 19.9218 39.6384 20.3241 39.8136 20.7636C39.8352 20.8176 39.857 20.8722 39.8789 20.9274C39.9713 21.1601 40.0738 21.4231 40.1514 21.6709C40.2198 21.8893 40.3207 22.2493 40.3096 22.6364L37.3108 22.5507C37.3087 22.6256 37.317 22.6709 37.317 22.6709C37.3169 22.6709 37.3111 22.6396 37.2886 22.5678C37.2483 22.4391 37.1844 22.2708 37.0907 22.0347C37.0711 21.9853 37.0503 21.9334 37.0285 21.879C36.8595 21.4566 36.6335 20.892 36.475 20.3176C36.299 19.6798 36.1482 18.8407 36.3379 17.9916C36.5486 17.0478 37.145 16.2491 38.1395 15.7519C39.7524 14.9454 41.8794 14.9558 43.6283 15.153C45.4483 15.3582 47.2015 15.8095 48.2845 16.1705C49.3171 16.5147 49.7606 16.4077 49.925 16.3298C50.0714 16.2604 50.3178 16.0617 50.5576 15.3597C50.7987 14.6535 50.9531 13.668 51.0659 12.3731C51.1406 11.5162 51.1923 10.5966 51.2485 9.59527C51.2772 9.08552 51.307 8.5546 51.3417 8L54.3358 8.18713C54.3041 8.69466 54.2756 9.20175 54.2475 9.70114C54.1894 10.7328 54.1332 11.7315 54.0546 12.6335C53.9382 13.9702 53.7617 15.2601 53.3966 16.3292C53.0301 17.4025 52.3948 18.4789 51.2102 19.0405Z"
        fill="url(#paint3_linear_1455_12679)"
        fillOpacity="0.6"
      />
      <defs>
        <filter
          id="filter0_di_1455_12679"
          x="14.75"
          y="24.0936"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1455_12679"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1455_12679"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_1455_12679"
          />
        </filter>
        <filter
          id="filter1_f_1455_12679"
          x="18.7449"
          y="27.0054"
          width="17.8972"
          height="18.4924"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_1455_12679"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1455_12679"
          x1="46.4919"
          y1="63.4484"
          x2="35.5242"
          y2="25.3839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#090E14" />
          <stop offset="0.255208" stopColor="#17212F" />
          <stop offset="1" stopColor="#2C3956" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1455_12679"
          x1="38.75"
          y1="32.8436"
          x2="38.75"
          y2="64.0936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1455_12679"
          x1="45.75"
          y1="24.5936"
          x2="31.75"
          y2="24.5936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="#2F130F" />
          <stop offset="0.171875" stopOpacity="#502820" />
          <stop offset="0.526042" stopOpacity="#946C51" />
          <stop offset="1" stopOpacity="#F4CD97" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1455_12679"
          x1="46.75"
          y1="22.0936"
          x2="42.25"
          y2="15.5936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BonusNotEnoughWageredIcon;
