import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Skeleton } from '@mui/material';

const useStyles = makeStyles(() => ({
  skeletonWrapper: {
    display: 'flex',
    width: '100%',
  },
  skeleton: {
    width: '100%',
    height: '220px',
    minHeight: '220px',
    maxHeight: '220px',
    borderRadius: '8px',
    background: 'rgb(17 24 39)',
  },
  innerBox: {
    height: '100%',
    width: '1300px',
    display: 'flex',
    margin: '0 auto',
    gap: '24px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

interface BannerSkeletonProps {
  slidePerView: number;
}

const BannerSkeleton: FC<BannerSkeletonProps> = ({ slidePerView }) => {
  const classes = useStyles();

  return (
    <Box className={classes.skeletonWrapper}>
      <Box className={classes.innerBox}>
        {Array.from({
          length: slidePerView,
        }).map((_, index) => (
          <Skeleton
            classes={{ root: classes.skeleton }}
            key={index}
            variant="rectangular"
            className={classes.skeleton}
          />
        ))}
      </Box>
    </Box>
  );
};

export default BannerSkeleton;
