import { ISvgIcon } from '../../../types/common.type';

const AirbetFireIcon = ({ size = 20 }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93832 17.145C5.67115 17.759 6.53903 18.1905 7.47082 18.4042C7.55999 18.4334 7.61999 18.3167 7.55999 18.2584C5.56415 16.3542 6.66082 14.2934 7.47082 13.3642C8.04832 12.7009 8.87082 11.6084 8.81082 10.1434C8.81082 9.99671 8.96082 9.87921 9.07915 9.96754C10.2117 10.5534 10.9858 11.8425 11.1942 12.8967C11.5225 12.575 11.6417 12.0767 11.6417 11.6375C11.6417 11.4909 11.82 11.3734 11.9692 11.4909C13.0417 12.4575 14.8883 15.7384 11.9092 18.3159C11.85 18.375 11.9092 18.4925 11.9692 18.4634C12.8725 18.2266 13.722 17.8186 14.4717 17.2617C19.3275 13.5117 16.1692 6.86171 14.1133 4.66504C13.8458 4.40087 13.3692 4.57671 13.3692 4.95754C13.3392 5.74921 13.1008 6.62754 12.4758 7.21337C11.9992 5.22171 10.4108 2.95921 8.14665 1.84587C7.84915 1.69921 7.49165 1.93337 7.52165 2.25587C7.57999 4.98087 5.80249 6.71587 4.25332 8.97171C2.88249 10.9925 1.98915 14.7134 4.93832 17.145Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default AirbetFireIcon;
