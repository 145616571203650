import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';

interface NoGameResult {
  onClearFilter: () => void;
}

const NoGameResult = ({ onClearFilter }: NoGameResult) => {
  const { t } = useTranslation();
  return (
    <Box
      display={'flex'}
      boxSizing={'border-box'}
      height={'200px'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={'8px'}
      flexDirection={'column'}
    >
      <span
        style={{
          color: '#fff',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
        }}
      >
        {t('noResult')}
      </span>
      <span
        style={{
          color: '#9CA3AF',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '24px',
        }}
      >
        {t('noResultInSearch')}
      </span>
      <CustomPrimaryButton
        style={{
          marginTop: '16px',
          textTransform: 'uppercase',
        }}
        onClick={() => onClearFilter()}
      >
        {t('clearSearch')}
      </CustomPrimaryButton>
    </Box>
  );
};

export default NoGameResult;
