import { Box, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

interface ICustomButton {
  children: ReactNode;
  style?: object;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: '7px 23px',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '8px',
    background: '#111827',
    color: ' #FFF !important',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    transition: 'background 0.3s, box-shadow 0.3s, opacity 0.3s',
    textTransform: 'none',
    '&:hover': {
      background: '#1F2937',
    },
    '&:active': {
      background: '#111827',
    },
  },
  loader: {
    position: 'absolute',
    color: '#fff',
    height: '16px',
  },
}));
const CustomMinimalButton = ({
  children,
  style,
  onClick,
  disabled = false,
  loading = false,
}: ICustomButton) => {
  const classes = useStyles({ disabled });
  return (
    <Box
      display="flex"
      position="relative"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        className={classes.root}
        disableRipple
        style={style}
        onClick={() => onClick && onClick()}
        disabled={disabled || loading}
        sx={{
          opacity: disabled || loading ? '0.3' : '',
        }}
      >
        <div
          style={{
            opacity: loading ? '0' : '',
          }}
        >
          {children}
        </div>
      </Button>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress size={16} color="inherit" />
        </div>
      )}
    </Box>
  );
};

export default CustomMinimalButton;
