import { ISvgIcon } from '../../../types/common.type';

const LiveSupportIcon = ({ size = 16, className, pathClassName }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.6667 11.3348C14.666 13.2487 13.3211 14.8484 11.5248 15.241L11.0995 13.965C11.9021 13.834 12.5843 13.3447 12.9766 12.6666H11.3333C10.5969 12.6666 10 12.0697 10 11.3333V8.66665C10 7.93025 10.5969 7.33331 11.3333 7.33331H13.2921C12.964 4.70244 10.7197 2.66665 8 2.66665C5.28025 2.66665 3.03599 4.70244 2.70793 7.33331H4.66667C5.40305 7.33331 6 7.93025 6 8.66665V11.3333C6 12.0697 5.40305 12.6666 4.66667 12.6666H2.66667C1.93029 12.6666 1.33333 12.0697 1.33333 11.3333V7.99998C1.33333 4.31808 4.3181 1.33331 8 1.33331C11.6819 1.33331 14.6667 4.31808 14.6667 7.99998V8.66578V8.66665V11.3333V11.3348Z"
        fill="#9CA3AF"
        className={pathClassName}
      />
    </svg>
  );
};

export default LiveSupportIcon;
