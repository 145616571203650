import CustomModal from '../common/Modal/CustomModal';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { IBonusClaimStatus } from '../../types/Bonus.type';
import { bindActionCreators } from 'redux';
import { changeBonusClaimModalStatusInfo } from '../../redux/actions/userActions';
import BonusRewardClaimedIcon from '../../api/ui/svgIcons/BonusRewardClaimedIcon';
import BonusNotEnoughWageredIcon from '../../api/ui/svgIcons/BonusNotEnoughWageredIcon';
import { useTranslation } from 'react-i18next';

interface IBonusClaimNotificationModal {
  bonusClaimModalStatusInfo: IBonusClaimStatus;
  changeBonusClaimModalStatusInfo: (info: IBonusClaimStatus) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '272px',
    height: '344px',
    borderRadius: '12px',
    border: '1px solid #1F2937;',
    background: '#111827',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.10)',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    gap: '32px',
    flexDirection: 'column',
  },
  primaryText: {
    color: '#FFF',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  secondaryText: {
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  descriptionText: {
    color: 'rgba(255, 255, 255, 0.50)',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'center',
  },
  button: {
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.10)',
    background: '#65A30D',
    boxShadow: '0px 2px 8px 0px rgba(101, 163, 13, 0.30)',

    display: 'flex',
    padding: '4px 16px 4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    alignSelf: 'stretch',
    cursor: 'pointer',
    userSelect: 'none',
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
  },
}));
const BonusClaimNotificationModal = ({
  bonusClaimModalStatusInfo,
  changeBonusClaimModalStatusInfo,
}: IBonusClaimNotificationModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <CustomModal
      open={bonusClaimModalStatusInfo.open}
      onClose={() =>
        changeBonusClaimModalStatusInfo({ open: false, success: false })
      }
    >
      <Box className={classes.root}>
        {bonusClaimModalStatusInfo.success ? (
          <BonusRewardClaimedIcon />
        ) : (
          <BonusNotEnoughWageredIcon />
        )}
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={'16px'}
          flexDirection={'column'}
        >
          <span
            className={
              bonusClaimModalStatusInfo.success
                ? classes.primaryText
                : classes.secondaryText
            }
          >
            {bonusClaimModalStatusInfo.success
              ? `$${bonusClaimModalStatusInfo.claimedAmount}`
              : t('MinimalRakeback')}
          </span>
          <span className={classes.secondaryText}>
            {bonusClaimModalStatusInfo.success
              ? t('rewardClaimed')
              : t('MinimalRakebackTerms')}
          </span>
        </Box>
        <span className={classes.descriptionText}>
          {bonusClaimModalStatusInfo.success
            ? t('bonusClaimedSuccessDescription')
            : t('bonusClaimedFailedDescription')}
        </span>
        <Box
          className={classes.button}
          onClick={() =>
            changeBonusClaimModalStatusInfo({ open: false, success: false })
          }
        >
          {bonusClaimModalStatusInfo.success
            ? t('continuePlaying')
            : t('startPlaying')}
        </Box>
      </Box>
    </CustomModal>
  );
};

const mapStateToProps = (state: any) => ({
  bonusClaimModalStatusInfo: state.user.bonusClaimModalStatusInfo,
});

const dispatchToProps = (dispatch: any) => ({
  changeBonusClaimModalStatusInfo: bindActionCreators(
    changeBonusClaimModalStatusInfo,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  dispatchToProps
)(BonusClaimNotificationModal);
