import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';

const useStyles = makeStyles(() => ({
  bonusModalWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    boxSizing: 'border-box',
  },
  grabBonusForFirstContainer: {
    margin: '16px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    background: '#FFFFFF1A',
    padding: '8px 12px',
    borderRadius: '8px',
    width: 'fit-content',
  },
  icon: {
    fill: '#fff',
  },
  grabBonusForFirstText: {
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  welcomeBonus: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxWidth: '260px',
  },
  welcomeBonusTitle: {
    color: '#FFFFFF',
    fontSize: '32px',
    lineHeight: '32px',
    fontWeight: '700',
  },
  welcomeBonusSubTitle: {
    color: '#FFFFFFBF',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '600',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
    background: '#111827',
    marginTop: '32px',
  },
  title: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '32px',
    textTransform: 'uppercase',
  },
  description: {
    color: '#FFFFFF80',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '32px',
    marginTop: '8px',
    marginBottom: '32px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '16px',
    flexDirection: isMobile ? 'column' : 'row',
  },
}));

interface WelcomeBonusContentProps {
  setOpenWelcomeBonus: (isOpen: boolean) => void;
}

const WelcomeBonusContent: FC<WelcomeBonusContentProps> = ({
  setOpenWelcomeBonus,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box className={classes.bonusModalWrapper}>
      <div
        style={{
          backgroundImage: isMobile
            ? 'url(/imgs/make_first_deposit_after_registration_mobile.png)'
            : 'url(/imgs/make_first_deposit_after_registration.png)',
          backgroundSize: 'cover',
          borderRadius: '8px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '320px',
        }}
      >
        <Box className={classes.grabBonusForFirstContainer}>
          <img
            src={'/imgs/grab_bonus.png'}
            alt={'bonus icon'}
            className={classes.icon}
          />
          <span className={classes.grabBonusForFirstText}>
            {t('grabBonusForFirstDeposit')}
          </span>
        </Box>
        <Box className={classes.welcomeBonus}>
          <span className={classes.welcomeBonusTitle}>{t('welcomeBonus')}</span>
          <span className={classes.welcomeBonusSubTitle}>
            {t('doubleYourLuck')}
          </span>
        </Box>
      </div>
      <Box className={classes.contentContainer}>
        <span className={classes.title}>{t('startJourneyAirbet')}</span>
        <span className={classes.description}>
          {t('startJourneyAirbetDescription')}
        </span>

        <Box className={classes.buttonContainer}>
          <CustomPrimaryButton
            style={{
              width: '100%',
              textTransform: 'uppercase',
            }}
            onClick={() => {
              navigate(AppRoutePath.DEPOSIT());
              setOpenWelcomeBonus(false);
            }}
          >
            {t('depositNow')}
          </CustomPrimaryButton>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenWelcomeBonus(false);
            }}
            style={{
              width: !isMobile ? '164px' : '',
              border: 'none',
              backgroundColor: 'transparent',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '24px',
              color: '#FFFFFF',
            }}
          >
            {t('remindMeLater')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default WelcomeBonusContent;
