import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ProviderIcon from '../../api/ui/svgIcons/ProviderIcon';
import { AppRoutePath } from '../../util/appRoutePath';
import { IProvider } from '../../types/Game.type';
import ProviderCardItem from './ProviderCardItem';
import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';

interface IProviderSwiper {
  providers: IProvider[];
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    minWidth: '0px',
    gap: '6px',
    marginTop: isMobile ? '24px' : '48px',
  },
  headerContainer: {
    padding: isMobile ? '0px' : '0px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerContainerLeftSide: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  headerContainerTitle: {
    color: '#fff',
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: '600',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  headerContainerSeeAll: {
    marginLeft: '8px',
    color: '#84CC16',
    fontSize: isMobile ? '12px' : '16px',
    fontWeight: '600',
    lineHeight: '24px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  buttonsContainer: {
    display: 'flex',
    gap: isMobile ? '8px' : '16px',
  },
  gameContainerSwiper: {},
}));

const ProviderSwiper = ({ providers }: IProviderSwiper) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const swiperRef = useRef<any>(null);

  const [isAtBeginning, setIsAtBeginning] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [partiallyVisibleSlides, setPartiallyVisibleSlides] = useState(
    new Set<number>()
  );
  const [visibleSlidesCount, setVisibleSlidesCount] = useState(0);

  useEffect(() => {
    const updateButtonStates = () => {
      if (swiperRef.current) {
        setIsAtBeginning(swiperRef.current.isBeginning);
        setIsAtEnd(swiperRef.current.isEnd);
      }
    };

    updateButtonStates();

    const swiperInstance = swiperRef.current;
    swiperInstance.on('slideChange', updateButtonStates);

    return () => {
      swiperInstance.off('slideChange', updateButtonStates);
    };
  }, [providers]);

  useEffect(() => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on('slideChangeTransitionEnd', updateSlideVisibility);
      swiperInstance.on('init', updateSlideVisibility);
    }

    updateSlideVisibility();

    return () => {
      if (swiperInstance) {
        swiperInstance.off('slideChangeTransitionEnd', updateSlideVisibility);
        swiperInstance.off('init', updateSlideVisibility);
      }
    };
  }, [providers]);

  useEffect(() => {
    const updateSlideVisibility = () => {
      const swiper = swiperRef.current;
      if (!swiper || !swiper.slides) return;

      const newPartiallyVisibleSlides = new Set<number>();
      swiper.slides.forEach((slide: HTMLElement, index: number) => {
        const slideRect = slide.getBoundingClientRect();
        const swiperRect = swiper.el.getBoundingClientRect();

        const isPartiallyVisible =
          slideRect.left < swiperRect.left ||
          slideRect.right > swiperRect.right;

        if (isPartiallyVisible) {
          newPartiallyVisibleSlides.add(index);
        }
      });

      setPartiallyVisibleSlides(newPartiallyVisibleSlides);
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on('init', updateSlideVisibility);
      swiperInstance.on('slideChangeTransitionEnd', updateSlideVisibility);

      updateSlideVisibility();
    }

    window.addEventListener('resize', updateSlideVisibility);

    return () => {
      if (swiperInstance) {
        swiperInstance.off('init', updateSlideVisibility);
        swiperInstance.off('slideChangeTransitionEnd', updateSlideVisibility);
        window.removeEventListener('resize', updateSlideVisibility);
      }
    };
  }, [providers]);

  useEffect(() => {
    const handleResizeOrInit = () => {
      const swiper = swiperRef.current;
      if (swiper) {
        swiper.update(); // Make sure Swiper updates its internal state
        updateVisibleSlidesCount(swiper);
      }
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on('init', handleResizeOrInit);
    }
    window.addEventListener('resize', handleResizeOrInit);

    // Initial calculation
    if (swiperInstance) {
      handleResizeOrInit();
    }

    return () => {
      window.removeEventListener('resize', handleResizeOrInit);
      if (swiperInstance) {
        swiperInstance.off('init', handleResizeOrInit);
      }
    };
  }, [providers]);

  const updateVisibleSlidesCount = (swiper: any) => {
    if (!swiper || !swiper.slides.length || !swiper.slides[0]) return;

    // Assuming swiper.slides[0] exists and has uniform width across all slides
    const slideWidth =
      swiper.slides[0].offsetWidth + swiper.params.spaceBetween;
    const count = Math.floor(swiper.width / slideWidth);
    setVisibleSlidesCount(count);
  };

  const updateSlideVisibility = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;

    const newPartiallyVisibleSlides = new Set<number>();
    swiper.slides.forEach((slide: HTMLElement, index: number) => {
      const slideLeftEdge = slide.offsetLeft;
      const slideRightEdge = slideLeftEdge + slide.offsetWidth;
      const swiperVisibleLeftEdge = swiper.translate;
      const swiperVisibleRightEdge = swiperVisibleLeftEdge + swiper.width;

      if (
        slideLeftEdge < swiperVisibleLeftEdge ||
        slideRightEdge > swiperVisibleRightEdge
      ) {
        newPartiallyVisibleSlides.add(index);
      }
    });

    setPartiallyVisibleSlides(newPartiallyVisibleSlides);
  };

  const goLeft = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    const newIndex = Math.max(currentIndex - visibleSlidesCount, 0);
    swiper.slideTo(newIndex);
  };

  const goRight = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    const slidesCount = swiper.slides.length;
    const newIndex = Math.min(
      currentIndex + visibleSlidesCount,
      slidesCount - visibleSlidesCount
    );
    swiper.slideTo(newIndex);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        <Box className={classes.headerContainerLeftSide}>
          <ProviderIcon />
          <span className={classes.headerContainerTitle}>{t('providers')}</span>
          <span
            className={classes.headerContainerSeeAll}
            onClick={() => navigate(AppRoutePath.PROVIDER_LIST_PAGE())}
          >
            {t('seeAll')}
          </span>
        </Box>
        <Box className={classes.buttonsContainer}>
          <CustomMinimalButton
            style={{
              minWidth: '0px',
              padding: isMobile ? '6px' : '10px',
              height: isMobile ? '32px' : '40px',
              width: isMobile ? '32px' : '40px',
            }}
            onClick={() => goLeft()}
            disabled={isAtBeginning}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowLeftIcon />
            </span>
          </CustomMinimalButton>
          <CustomMinimalButton
            style={{
              padding: isMobile ? '6px' : '10px',
              height: isMobile ? '32px' : '40px',
              minWidth: '0px',
              width: isMobile ? '32px' : '40px',
            }}
            onClick={() => goRight()}
            disabled={isAtEnd}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <KeyboardArrowRightIcon />
            </span>
          </CustomMinimalButton>
        </Box>
      </Box>

      <Box className={classes.gameContainerSwiper}>
        <Swiper
          spaceBetween={isMobile ? 8 : 12}
          slidesPerView={'auto'}
          onSlideChange={() => {}}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          style={{
            paddingTop: '10px',
          }}
          speed={500}
        >
          {providers.map((provider: IProvider, index: number) => {
            const isPartiallyVisible = partiallyVisibleSlides.has(index);
            return (
              <SwiperSlide
                style={{
                  color: '#fff',
                  minWidth: '0px',
                  width: 'fit-content',
                  transition: 'opacity 0.2s ease-in-out',
                  opacity: isPartiallyVisible ? 0.1 : 1,
                }}
                key={index}
              >
                <Box key={index}>
                  <ProviderCardItem provider={provider} />
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state: any) => ({
  providers: state.game.providers,
});
const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(ProviderSwiper);
