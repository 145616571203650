import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

interface ICustomGameFilterSelect {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  data: any[];
  label: string;
  minWith?: number;
  secondLabel?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
    boxSizing: 'border-box',
    '& .MuiOutlinedInput-input': {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
    },

    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '8px',
      border: '1px solid #0D121D',
      background: '#0D121D',
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
      transition: 'border-color 0.3s ease-in-out, background 0.3s ease-in-out',
      borderColor: '#1F2937',
      '&:hover': {
        background: '#111827',
      },
      '&.selectOpen': {
        background: '#111827',
      },
      '& fieldset': {
        border: 'none',
      },
      '& .MuiSvgIcon-root': {
        color: '#84CC16',
        transition: 'transform 0.3s ease-in-out',
      },
    },
  },
  paper: {
    padding: '0px',
    borderRadius: '8px',
    border: '1px solid #1F2937',
    background: '#111827',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.10)',
    marginTop: '8px',
    maxHeight: '400px',
    overflow: 'auto',

    '& .MuiList-root': {
      padding: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      alignItem: 'center',
      borderRadius: '10px',
      transition: 'background 0.2s ease-in-out, border-color 0.2s ease-in-out',
      padding: '8px',
      background: '#111827',
      border: '1px solid',
      borderColor: '#111827',
      '&:hover': {
        background: '#0D121D !important',
        borderColor: '#1F2937',
      },
      '&.Mui-selected': {
        backgroundColor: '#0D121D !important',
      },
    },
  },
  renderLabelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '0px',
    boxSizing: 'border-box',
    gap: '21px',
  },
  renderLabelContainerKey: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  renderLabelContainerValue: {
    color: '#84CC16',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
}));
const CustomGameFilterSelect = ({
  value,
  onChange,
  data = [],
  label,
  minWith,
  secondLabel,
}: ICustomGameFilterSelect) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const classes = useStyles();
  const openClass = isOpen ? 'selectOpen' : '';

  const onChangeSelect = (event: SelectChangeEvent) => {
    onChange(event);
  };

  return (
    <Box
      className={classes.root}
      style={{
        minWidth: minWith ? `${minWith}px` : 'auto',
      }}
    >
      <Select
        value={value}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        label={''}
        displayEmpty
        className={openClass}
        renderValue={() => {
          return (
            <Box className={classes.renderLabelContainer}>
              <span className={classes.renderLabelContainerKey}>{label}</span>
              <span className={classes.renderLabelContainerValue}>
                {secondLabel}
              </span>
            </Box>
          );
        }}
        onChange={onChangeSelect}
        MenuProps={{
          PaperProps: {
            className: classes.paper,
          },
        }}
      >
        {data.map((item: any, index: number) => {
          return (
            <MenuItem value={item.value} key={index}>
              {item.renderer()}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default CustomGameFilterSelect;
