import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import AirbetIcon from '../../api/ui/svgIcons/NotificationIcons/AirbetIcon';
import { IBetsTableItem } from '../../types/common.type';
import { useTranslation } from 'react-i18next';
import { RANKS } from '../../util/UserProfileHelper';
import { isMobile } from 'react-device-detect';
import BetTableIcon from '../../api/ui/svgIcons/BetTableIcon';

const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: '#0D121D',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 16px 24px 16px',
    color: '#fff',

    '& path': {
      fill: '#fff',
    },
    '& div': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: isMobile ? '14px' : '16px',
      lineHeight: '24px',
    },
  },
  transactionTypes: {
    '& span': {
      borderRadius: '8px',
      padding: '8px',
      cursor: 'pointer',
      color: '#9CA3AF',
      transition: 'all .3s ease-in-out',
      fontSize: isMobile ? '12px' : '14px',
    },
    '& span:hover': {
      color: '#fff',
    },
    '& .active': {
      backgroundColor: '#111827',
      color: '#fff',
    },
  },
  tableHeader: {
    display: 'flex',
    color: '#9CA3AF',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    backgroundColor: '#111827',
    borderRadius: '8px',
    '& div': {
      padding: '8px 16px',
    },
    '& div:last-child': {
      textAlign: 'right',
    },
  },
  tableBody: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    borderRadius: '8px',
  },

  transition: {
    transition: 'top 0.3s ease-in-out',
  },

  tableBodyContainer: {
    position: 'relative',
    height: '500px',
    overflow: 'hidden',
    marginTop: '8px',
    borderRadius: '8px',
  },
  gradient: {
    position: 'absolute',
    bottom: 0,
    background: `linear-gradient(180deg, #0D121D00 0%, #0D121D 100%)`,
    width: '100%',
    height: '82px',
    zIndex: '1',
  },
  row: {
    display: 'flex',
    backgroundColor: '#111827',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#9CA3AF',
    '& div': {
      display: 'flex',
      flex: '1',
      padding: '12px 16px',
      alignItems: 'center',
      gap: '8px',
      '& rect': {
        fill: '#374151',
      },
    },
    '& div:last-child': {
      justifyContent: 'flex-end',
      color: '#fff',
    },
    '& div:first-child': {
      color: '#fff',
      '& span': {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
      },
    },
  },
}));

interface IBetTable {
  tableData: IBetsTableItem[];
  tableHeader: string[];
  betTypes: string[];
}

const BetsTable = ({ tableData, tableHeader, betTypes }: IBetTable) => {
  const [activeBetsType, setActiveBetsType] = useState<string>('All Bets');
  const [top, setTop] = useState<number>(tableData.length * -40);
  const [filteredTableData, setFilterTableData] = useState(tableData);
  const [transitionIsOn, setTransitionIsOn] = useState(true);

  const classes = useStyles();
  const { t } = useTranslation();

  function moveFirstToLast() {
    const first10 = filteredTableData.slice(0, 10);
    const remaining = filteredTableData.slice(10);
    const nextArray = [...remaining, ...first10];

    return setFilterTableData(nextArray);
  }

  useEffect(() => {
    setTransitionIsOn(true);
    setTop((prevTop) => prevTop + 50);

    if (top === 0) {
      setTransitionIsOn(false);
      moveFirstToLast();
      setTop(tableData.length * -40);
    }
    if (tableData.length >= 99) {
      setTop(0);
    }
  }, [tableData]);

  useEffect(() => {
    switch (activeBetsType) {
      case 'High Rollers': {
        const filteredData = [...tableData].sort((a: any, b: any) => {
          return Number(a.bet) - Number(b.bet);
        });
        setFilterTableData(filteredData.reverse());
        break;
      }
      case 'Rare Wins': {
        const filteredData = [...tableData].sort((a: any, b: any) => {
          return Number(a.win) - Number(b.win);
        });
        setFilterTableData(filteredData.reverse());
        break;
      }
      default:
        setFilterTableData(tableData);
        break;
    }
  }, [activeBetsType, tableData]);

  const getRankImgSrc = (rankName: string) => {
    return RANKS.find(
      (item) => item.name.toLowerCase() === rankName.toLowerCase()
    )?.img;
  };

  return (
    <div className={classes.root}>
      <Box className={classes.header}>
        <div>
          <BetTableIcon size={isMobile ? 16 : 20} />
          <span
            style={{
              textTransform: 'uppercase',
            }}
          >
            {t('bets')}
          </span>
        </div>
        <div className={classes.transactionTypes}>
          {betTypes.map((type, index) => (
            <span
              key={index}
              onClick={() => setActiveBetsType(type)}
              className={`${activeBetsType === type ? 'active' : ''}`}
            >
              {type}
            </span>
          ))}
        </div>
      </Box>
      <Box>
        <Box className={classes.tableHeader}>
          {tableHeader.map((header: string, index: number) => {
            return (
              <Box key={index} flex={'1'}>
                {header}
              </Box>
            );
          })}
        </Box>
        <div className={classes.tableBodyContainer}>
          <div className={classes.gradient}></div>
          <div
            className={`${classes.tableBody}`}
            style={{
              top: top,
              transition: transitionIsOn ? 'top 0.5s ease-in-out' : '',
            }}
          >
            {filteredTableData.map((row: IBetsTableItem, index: number) => (
              <div key={index} className={`${classes.row}`}>
                <div>
                  <span>{row.game_name}</span>
                </div>
                <div>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <img
                      src={'/imgs/Rank/' + getRankImgSrc(row.player_rank.rank)}
                      alt={'rank img'}
                      width={'22px'}
                      height={'24px'}
                    />
                    {row.player_name}
                  </span>
                </div>
                {!isMobile && (
                  <div>
                    <span>{Number(row.bet).toFixed(2)}</span>
                  </div>
                )}
                {!isMobile && (
                  <div>
                    <span
                      style={{
                        color:
                          Number(Number(row.win).toFixed(2)) >= 25
                            ? '#84CC16'
                            : '',
                      }}
                    >
                      {row.multiplier}x
                    </span>
                  </div>
                )}
                <div>
                  <AirbetIcon size={16} className="amount" />
                  <span
                    style={{
                      color:
                        Number(Number(row.win).toFixed(2)) >= 25
                          ? '#84CC16'
                          : '',
                    }}
                  >
                    {Number(row.win).toFixed(2)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Box>
    </div>
  );
};

export default BetsTable;
