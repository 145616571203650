import { makeStyles } from '@mui/styles';
import Container from '../common/BoxContainer/Container';
import { useLocation, useParams } from 'react-router-dom';
import { Box, SelectChangeEvent } from '@mui/material';
import Policy from './Policy';
import { useEffect, useMemo, useState } from 'react';
import { scrollToTop } from '../../util/appUtil';
import ListContainerItem from './ListContainerItem';
import { isMobile } from 'react-device-detect';
import CustomPoliciesSelect from './CustomPoliciesSelect';
import { IStaticPage } from '../../types/common.type';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    ...theme.general.pageHeader,
    padding: '0px 20px',
  },
  headerText: {
    ...theme.general.pageHeaderTitle,
  },
  body: {
    padding: isMobile ? '16px' : '40px 20px',
    boxSizing: 'border-box',
    display: 'flex',
    overflow: 'hidden',
    userSelect: 'text',
  },
  bodyContent: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    gap: isMobile ? '16px' : '32px',
    alignItems: 'flex-start',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '272px',
    padding: '8px',
    gap: '8px',
    borderRadius: '12px',
    border: '1px solid #1F2937',
    backgroundColor: '#111827',
    boxSizing: 'border-box',
  },
}));

interface IPolicies {
  staticPages: IStaticPage[];
  generalTermsOfServices: any;
}

const Policies = ({ staticPages, generalTermsOfServices }: IPolicies) => {
  const [value, setValue] = useState<string>('');
  const classes = useStyles();
  const { policyType } = useParams();
  const location = useLocation();

  const policyData = useMemo(() => {
    return staticPages.find(
      (page: { slug: string }) => page.slug === policyType
    );
  }, [policyType, staticPages]);

  useEffect(() => {
    scrollToTop();
  }, [location]);

  const policies = generalTermsOfServices.map((item: any) => ({
    ...item,
    type: item.slug,
    text: item.title,
  }));

  const onChangeSelect = (e: SelectChangeEvent) => {
    setValue(e.target.value as string);
  };

  useEffect(() => {
    if (!policyData) return;
    setValue(policyData.title);
  }, [policyData]);

  const policiesData = () => {
    return policies.map((policy: any) => {
      return {
        ...policy,
        value: policy.title,
        renderer: () => (
          <div style={{ width: '100%' }}>
            <ListContainerItem
              active={policy.slug === policyType}
              text={policy.title}
              slug={policy.slug}
            />
          </div>
        ),
      };
    });
  };

  return (
    <div className={classes.root}>
      {!isMobile && (
        <div className={classes.header}>
          <Container>
            <span className={classes.headerText}>
              {policyData ? policyData.title : ''}
            </span>
          </Container>
        </div>
      )}
      <div className={classes.body}>
        <Container>
          <div className={classes.bodyContent}>
            {isMobile && (
              <div style={{ width: '272px', marginRight: '8px' }}>
                <CustomPoliciesSelect
                  value={value}
                  onChange={onChangeSelect}
                  data={policiesData()}
                />
              </div>
            )}
            {!isMobile && (
              <div className={classes.listContainer}>
                {generalTermsOfServices.map((policy: any) => (
                  <ListContainerItem
                    key={policy.id}
                    slug={policy.slug}
                    active={policy.slug === policyType}
                    text={policy.title}
                  />
                ))}
              </div>
            )}
            <Box width={'100%'} boxSizing={'border-box'}>
              {policyData && <Policy {...policyData} />}
            </Box>
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  generalTermsOfServices: state.settings.termsOfServices,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(Policies);
