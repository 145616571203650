import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { IRakeBackOrCashBack } from '../../types/Bonus.type';
import {
  CalculateBonusCountdownTime,
  GetBonusConvertedTime,
} from './BonusHelper';
import CountDownIcon from '../../api/ui/svgIcons/CountDownIcon';
import { claimCashBacks } from '../../api/services/Bonus.api';
import { changeBonusClaimModalStatusInfo } from '../../redux/actions/userActions';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '8px',
    padding: '15px 16px',
    border: '1px solid #FFFFFF0D',
  },
  button: {
    width: '100%',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '8px',
    borderTop: '1px solid #FFFFFF1A',
    background: '#65A30D',
    userSelect: 'none',
    cursor: 'pointer',
    justifyItems: 'center',
    boxShadow: '0px 2px 8px 0px #5568E44D',
    gap: '6px',
  },
  buttonCountdown: {
    width: '100%',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '8px',
    background: '#FFFFFF0D',
    userSelect: 'none',
    cursor: 'pointer',
    justifyItems: 'center',
    gap: '6px',
  },
  buttonText: {
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
    textShadow: '0px 2px 0px rgba(0, 0, 0, 0.20)',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  title: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#FFFFFF',
  },
  text: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#FFFFFF80',
    marginTop: '8px',
    marginBottom: '16px',
  },
}));

interface ICashback {
  cashBackInfo: IRakeBackOrCashBack;
  reloadData?: () => void;
}

const Cashback = ({ cashBackInfo, reloadData }: ICashback) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [countdown, setCountdown] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    setCountdown(CalculateBonusCountdownTime(cashBackInfo));
  }, [cashBackInfo]);

  useEffect(() => {
    const updateCountdown = () => {
      setCountdown((prevCountdown: any) => prevCountdown - 1000);
    };
    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  const handleClick = () => {
    if (countdown <= 0) {
      claimCashBacks({ player_cashback_id: cashBackInfo.id })
        .then((response: any) => {
          if (response.data.data.success) {
            if (response.data.data.amount === 0) {
              dispatch(
                changeBonusClaimModalStatusInfo({ open: true, success: false })
              );
            } else {
              dispatch(
                changeBonusClaimModalStatusInfo({
                  open: true,
                  success: true,
                  claimedAmount: response.data.data.amount,
                })
              );
            }
          } else {
            enqueueSnackbar(response.data.data.message, {
              variant: 'error',
            });
          }
        })
        .catch(() => {})
        .catch(() => {})
        .finally(() => {
          if (reloadData) {
            reloadData();
          }
        });
    }
  };

  const getNameBySlug = (slug: string) => {
    if (slug === 'daily') {
      return 'dailyCashback';
    }

    if (slug === 'weekly') {
      return 'weeklyCashback';
    }

    if (slug === 'monthly') {
      return 'monthlyCashback';
    }

    return '';
  };

  return (
    <Box
      style={{
        background:
          'linear-gradient(180deg, rgba(132, 204, 22, 0.20) 0%, rgba(132, 204, 22, 0.00) 100%), #0D121D',
        borderRadius: '8px',
      }}
    >
      <Box className={classes.root}>
        <span className={classes.title}>
          {' '}
          {t(getNameBySlug(cashBackInfo.slug))}
        </span>
        <span className={classes.text}>{t('cashbackText')}</span>
        <div
          className={countdown <= 0 ? classes.button : classes.buttonCountdown}
          onClick={handleClick}
        >
          {countdown <= 0 ? (
            <>
              <KeyboardDoubleArrowRightIcon
                sx={{ width: '16px', height: '16px', color: '#FFF' }}
              />
              <span className={classes.buttonText}>{t('claim')}</span>
            </>
          ) : (
            <>
              {' '}
              <CountDownIcon fill={'#84CC16'} />
              <span className={classes.buttonText}>
                {GetBonusConvertedTime(countdown)}
              </span>
            </>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default Cashback;
