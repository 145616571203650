import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme: any) => ({
  root: {
    maxWidth: '1160px',
    margin: isMobile ? '0 auto' : '48px auto',
    padding: isMobile ? '0 16px' : '0px 24px',
    position: 'relative',
  },
  skeleton: {
    width: '100%',
    height: '320px',
    backgroundColor: 'rgb(43 54 80 / 23%)',
    borderRadius: '12px',
  },
  innerBox: {
    position: 'absolute',
    top: '32px',
    left: !isMobile ? '56px' : '40px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  imgSkeleton: {
    position: 'absolute',
    top: 0,
    right: '24px',
    zIndex: 1,
    backgroundColor: 'rgb(43 60 80 / 75%)',
    width: '580px',
    height: '320px',
    borderRadius: '0px 12px 12px 0px',
    [theme.breakpoints.down(1140)]: {
      width: '400px',
    },
    [theme.breakpoints.down(950)]: {
      width: '300px',
    },
    [theme.breakpoints.down(850)]: {
      display: 'none',
    },
    [theme.breakpoints.down(1310)]: {
      width: '400px',
    },
    [theme.breakpoints.down(1130)]: {
      display: 'none',
    },
  },
}));

const BlogBannerSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Skeleton variant="rectangular" className={classes.skeleton} />
      <Box className={classes.innerBox}>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Skeleton
            variant="text"
            sx={{
              fontSize: '24px',
              backgroundColor: 'rgb(43 60 80 / 75%)',
              width: '50px',
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              fontSize: '24px',
              backgroundColor: 'rgb(43 60 80 / 75%)',
              width: '80px',
            }}
          />
        </Box>

        <Skeleton
          variant="text"
          sx={{
            fontSize: '24px',
            backgroundColor: 'rgb(43 60 80 / 75%)',
            width: !isMobile ? '400px' : '270px',
          }}
        />

        <Skeleton
          variant="text"
          sx={{
            fontSize: '24px',
            backgroundColor: 'rgb(43 60 80 / 75%)',
            width: !isMobile ? '300px' : '200px',
          }}
        />

        <Skeleton
          variant="text"
          sx={{
            fontSize: '24px',
            backgroundColor: 'rgb(43 60 80 / 75%)',
            width: !isMobile ? '400px' : '270px',
          }}
        />

        <Skeleton
          variant="text"
          sx={{
            fontSize: '24px',
            backgroundColor: 'rgb(43 60 80 / 75%)',
            width: '140px',
          }}
        />
      </Box>

      <Skeleton variant="rectangular" className={classes.imgSkeleton} />
    </div>
  );
};

export default BlogBannerSkeleton;
