import GameContainer from './GameContainer';

const AirbetOriginalContainer = () => {
  return (
    <GameContainer
      categorySlugName={'inhouse'}
      pageName={'airbetMenu.airbetOriginals'}
      hideGameProviderFilter={true}
      hideBlockedGameFilter={true}
      hideGameSortByFilter={true}
      isOriginalGame={true}
      showSwitcher={true}
    />
  );
};

export default AirbetOriginalContainer;
