import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import LeaderBoardsCountDown from './LeaderBoardsCountDown';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '306px',
    padding: '48px 0px 30px 0px',
    background: 'linear-gradient(180deg, #030712 0%, rgba(3, 7, 18, 0) 100%)',
  },
  container: {
    margin: '0 auto',
    maxWidth: '480px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '8px',
    position: 'relative',
    marginTop: '30px',
    '& :nth-child(1)': {
      position: 'absolute',
      left: '-90px',
    },
    '& :nth-child(2)': {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      background: 'rgba(255, 255, 255, 1)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textAlign: 'center',
      maxWidth: '200px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& :nth-child(3)': {
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: '32px',
      background: 'linear-gradient(180deg, #F7E6B1 0%, #BF8C4D 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textAlign: 'center',
      maxWidth: '250px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& :nth-child(4)': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      background: 'rgba(255, 255, 255, 0.5)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textAlign: 'center',
      width: '230px',
    },
    '& :nth-child(5)': {
      position: 'absolute',
      right: '-90px',
    },
  },
}));

interface LeaderBoardsCompetitionProps {
  startDate: string;
  endDate: string;
  name: string;
  subName: string;
  subSubName: string;
}

const LeaderBoardsCompetition: FC<LeaderBoardsCompetitionProps> = ({
  startDate,
  endDate,
  name,
  subName,
  subSubName,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <img
            src={'/imgs/LeaderBoards/competitionLeft.png'}
            alt="left-photo"
          />
          <span>{name}</span>
          <span>{subName}</span>
          <span>{subSubName}</span>
          <img
            src={'/imgs/LeaderBoards/competitionRight.png'}
            alt="right-photo"
          />
        </Box>

        <LeaderBoardsCountDown startDate={startDate} endDate={endDate} />
      </Box>
    </Box>
  );
};

export default LeaderBoardsCompetition;
