import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

interface LeaderBoardsCountDownProps {
  startDate: string;
  endDate: string;
}

const LeaderBoardsCountDown: FC<LeaderBoardsCountDownProps> = ({
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const [isStartingTimer, setIsStartingTimer] = useState<boolean>(false);

  const getTimeLeft = () => {
    const dataEnd = new Date(endDate);
    const COUNTDOWN_TARGET_UTC = new Date(
      Date.UTC(
        dataEnd.getFullYear(),
        dataEnd.getMonth(),
        dataEnd.getDate(),
        dataEnd.getHours(),
        dataEnd.getMinutes(),
        dataEnd.getSeconds()
      )
    ).getTime();

    const now = new Date();
    const NOW_UTC = new Date(
      Date.UTC(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds()
      )
    ).getTime();
    const totalTimeLeft = COUNTDOWN_TARGET_UTC - NOW_UTC;
    const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
    const seconds = Math.floor((totalTimeLeft / 1000) % 60);
    if (!days && !hours && !minutes && !seconds) {
      setIsStartingTimer(false);
    }
    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(getTimeLeft);

  useEffect(() => {
    if (isStartingTimer) {
      const timer = setInterval(() => {
        setTimeLeft(getTimeLeft());
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [isStartingTimer, startDate, endDate]);

  useEffect(() => {
    if (new Date() < new Date(endDate) && new Date() > new Date(startDate)) {
      setIsStartingTimer(true);
    } else {
      setIsStartingTimer(false);
    }
  }, [startDate, endDate]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px',
        marginTop: '70px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: isMobile ? '12px 10px' : '12px 0px',
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '8px',
          width: isMobile ? '60px' : '80px',
        }}
      >
        <span
          style={{
            color: isStartingTimer ? '#fff' : 'rgba(255, 255, 255, 0.50)',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isStartingTimer && timeLeft?.days
            ? timeLeft?.days > 9
              ? timeLeft.days
              : '0' + timeLeft.days
            : '00'}
        </span>
        <span
          style={{
            color: isStartingTimer ? '#fff' : 'rgba(255, 255, 255, 0.50)',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t('days')}
        </span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: isMobile ? '12px 10px' : '12px 0px',
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '8px',
          width: isMobile ? '60px' : '80px',
        }}
      >
        <span
          style={{
            color: isStartingTimer ? '#fff' : 'rgba(255, 255, 255, 0.50)',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isStartingTimer && timeLeft?.hours
            ? timeLeft?.hours > 9
              ? timeLeft.hours
              : '0' + timeLeft.hours
            : '00'}
        </span>
        <span
          style={{
            color: isStartingTimer ? '#fff' : 'rgba(255, 255, 255, 0.50)',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t('hours')}
        </span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: isMobile ? '12px 10px' : '12px 0px',
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '8px',
          width: isMobile ? '60px' : '80px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span
          style={{
            color: isStartingTimer ? '#fff' : 'rgba(255, 255, 255, 0.50)',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isStartingTimer && timeLeft?.minutes
            ? timeLeft?.minutes > 9
              ? timeLeft.minutes
              : '0' + timeLeft.minutes
            : '00'}
        </span>
        <span
          style={{
            color: isStartingTimer ? '#fff' : 'rgba(255, 255, 255, 0.50)',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
          }}
        >
          {t('minutes')}
        </span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: isMobile ? '12px 10px' : '12px 0px',
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '8px',
          width: isMobile ? '60px' : '80px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span
          style={{
            color: isStartingTimer ? '#fff' : 'rgba(255, 255, 255, 0.50)',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isStartingTimer && timeLeft?.seconds
            ? timeLeft?.seconds > 9
              ? timeLeft.seconds
              : '0' + timeLeft.seconds
            : '00'}
        </span>
        <span
          style={{
            color: isStartingTimer ? '#fff' : 'rgba(255, 255, 255, 0.50)',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
          }}
        >
          {t('seconds')}
        </span>
      </div>
    </div>
  );
};

export default LeaderBoardsCountDown;
