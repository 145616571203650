import React, { FC } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { connect, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { changeGlobalUserLoginModalOpen } from '../../redux/actions/userActions';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: !isMobile ? '64px' : '32px',
  },
  rankHeaderTitleWrapper: {
    marginBottom: '32px',
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '423px',
    '& :nth-child(1)': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#FFFFFF80',
      marginTop: '32px',
    },
    '& :nth-child(2)': {
      fontWeight: 600,
      fontSize: !isMobile ? '48px' : '24px',
      lineHeight: !isMobile ? '48px' : '32px',
      color: '#FFF',
      textAlign: 'center',
    },
    '& :nth-child(3)': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#FFFFFF',
      textAlign: 'center',
    },
  },
  cardsWrapper: {
    gap: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',
    flexWrap: 'wrap',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    background: '#111827',
    padding: '12px 16px 16px 16px',
    borderRadius: '8px',
    justifyContent: 'start',
    flex: 1,
    minWidth: '250px',
  },
  cardInfoWrapper: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',
    '& :nth-child(1)': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#DCE6FF',
    },
    '& :nth-child(2)': {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
      color: '#FFF',
    },
    '& :nth-child(3)': {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '20px',
      color: '#FFFFFF80',
    },
  },
}));

interface RankSystemHeaderPartProps {
  isUserAuthenticated: boolean;
}

const RankSystemHeaderPart: FC<RankSystemHeaderPartProps> = ({
  isUserAuthenticated,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const openLogin = () => {
    dispatch(changeGlobalUserLoginModalOpen(true));
  };

  const cardsItems = [
    {
      photo: (
        <img src={'/imgs/Rank/WelcomeToTheClub/Visual - 1.png'} alt={''} />
      ),
      title_1: t('ranks&Levels'),
      title_2: t('PlayMoreGainMore'),
      welcomeText: t('welcomeToTheClubText1'),
    },
    {
      photo: (
        <img src={'/imgs/Rank/WelcomeToTheClub/Visual - 2.png'} alt={''} />
      ),
      title_1: t('exclusivePromotions'),
      title_2: t('unlockTheExtraordinary'),
      welcomeText: t('welcomeToTheClubText2'),
    },
    {
      photo: (
        <img src={'/imgs/Rank/WelcomeToTheClub/Visual - 3.png'} alt={''} />
      ),
      title_1: t('increaseYourCashback'),
      title_2: t('earnUpto25'),
      welcomeText: t('welcomeToTheClubText3'),
    },
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.rankHeaderTitleWrapper}>
        <span>{t('airBetRankSystem')}</span>
        <span>{t('welcomeToTheClub')}</span>
        <span>{t('whereYourGainsEarnYouMoreThanJustWins')}</span>
      </Box>

      <Box className={classes.cardsWrapper}>
        {cardsItems.map((item, index) => (
          <Box key={index} className={classes.card}>
            <span> {item.photo}</span>
            <Box className={classes.cardInfoWrapper}>
              <span>{item.title_1}</span>
              <span>{item.title_2}</span>
              <span>{item.welcomeText}</span>
            </Box>
          </Box>
        ))}
      </Box>

      {!isUserAuthenticated && (
        <CustomPrimaryButton
          style={{
            width: '240px',
            textTransform: 'uppercase',
          }}
          onClick={() => {
            openLogin();
          }}
        >
          {t('header.joinNow')}
        </CustomPrimaryButton>
      )}
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: state.user.isUserAuthorized,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(RankSystemHeaderPart);
