import { useEffect, useState } from 'react';
import PromotionPage from './PromotionPage';
import { getPromotionList } from '../../api/services/Promotion.api';
import { connect } from 'react-redux';

export interface IPromotionDataType {
  id: number;
  start_date: string;
  end_date: string;
  title: string;
  image: string;
  slug: string;
  is_active: number;
  lang: string;
  description: string;
  post: string;
  modify_uid: number;
  create_dt: string;
  update_dt: string;
}

interface IPromotionContainer {
  language: string;
}

const PromotionContainer = ({ language }: IPromotionContainer) => {
  const [promotions, setPromotions] = useState<IPromotionDataType[]>([]);
  const [originalPromotions, setOriginalPromotions] = useState<
    IPromotionDataType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedType, setSelectedType] = useState<
    'inprogress' | 'upcoming' | 'archived' | 'all'
  >('inprogress');

  useEffect(() => {
    getPromotionList()
      .then((resp: any) => {
        if (resp && resp.data.data) {
          const sortedData = [...resp.data.data].sort(
            (a: IPromotionDataType, b: IPromotionDataType) => b.id - a.id
          );
          setOriginalPromotions([...sortedData]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    switch (selectedType) {
      case 'inprogress':
        setPromotions([
          ...originalPromotions.filter(
            (promotion: IPromotionDataType) =>
              new Date() > new Date(promotion.start_date) &&
              new Date() < new Date(promotion.end_date) &&
              promotion.lang === language
          ),
        ]);
        break;
      case 'upcoming':
        setPromotions([
          ...originalPromotions.filter(
            (promotion: IPromotionDataType) =>
              new Date() < new Date(promotion.start_date) &&
              promotion.lang === language
          ),
        ]);
        break;
      case 'archived':
        setPromotions([
          ...originalPromotions.filter(
            (promotion: IPromotionDataType) =>
              new Date() > new Date(promotion.end_date) &&
              promotion.lang === language
          ),
        ]);
        break;
      default:
        setPromotions([
          ...originalPromotions.filter(
            (promotion: IPromotionDataType) => promotion.lang === language
          ),
        ]);
        break;
    }
  }, [originalPromotions, selectedType, language]);

  return (
    <PromotionPage
      promotions={promotions}
      loading={loading}
      setSelectedType={setSelectedType}
      selectedType={selectedType}
    />
  );
};

const mapStateToProps = (state: any) => ({
  language: state.user.language,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(PromotionContainer);
