import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { getBaseUrlStatic } from '../../api/baseUrl';
import { RANKS_SYSTEM } from '../../util/UserProfileHelper';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '194px',
    width: '100%',
    background: '#111827',
    borderRadius: '16px',
    gap: '16px',
    padding: '16px',
    overflow: 'hidden',
    marginTop: '20px',
  },
  header: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  },
  title: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  circle: {
    background: '#A3E635',
    width: '4px',
    height: '4px',
    borderRadius: '100%',
    boxShadow: '0px 0px 10px rgba(163, 230, 53, 0.5)',
    animation: '$pulse 1s infinite alternate',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0px 0px 10px rgba(163, 230, 53, 0.5)',
    },
    '100%': {
      transform: 'scale(2)',
      boxShadow: '0px 0px 20px rgba(163, 230, 53, 0.8)',
    },
  },
  body: {
    display: 'flex',
    boxSizing: 'border-box',
    overflow: 'auto',
    gap: '16px',
    position: 'relative',
    scrollBehavior: 'smooth',
  },
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    transition: 'opacity 0.5s ease-in-out',
    cursor: 'pointer',
  },
  imgContainer: {
    borderRadius: '8px',
    width: '64px',
    height: '86px',
    boxSizing: 'border-box',
  },
  name: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#fff',
  },
  winNumber: {
    color: '#84CC16',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
}));

interface IRecentWins {
  data: [];
}

const RecentWins = ({ data }: IRecentWins) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const ref = useRef<any>(null);
  const navigate = useNavigate();

  const handleResize = () => {
    ref.current?.scrollTo({
      left: ref.current?.scrollWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [data]);

  const getImgUrl = (player: any): string | undefined => {
    const imgName = RANKS_SYSTEM.find(
      (item: any) =>
        item.name.toLowerCase() === player.player_rank.rank.toLowerCase()
    );
    return imgName?.img;
  };

  const getGameImg = (gameUrl: string): string => {
    if (gameUrl[0] === '/') return gameUrl;
    return '/' + gameUrl;
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.circle} />
        <span className={classes.title}>{t('recentWins')}</span>
      </div>
      <div className={classes.body} ref={ref}>
        {data &&
          data.map((item: any, index: number) => {
            return (
              <Box
                className={classes.containerItem}
                key={index}
                onClick={() => navigate(`/games/${item.slug}`)}
              >
                <Box className={classes.imgContainer}>
                  <img
                    src={getBaseUrlStatic() + getGameImg(item.image)}
                    alt={'game img'}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px',
                    }}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <img
                      width={'16px'}
                      src={`/imgs/Rank/${getImgUrl(item)}`}
                      alt={'rank'}
                    />
                    <span className={classes.name}>{item.player_name}</span>
                  </div>
                  <span className={classes.winNumber}>
                    ${Number(item.win).toFixed(2)}
                  </span>
                </Box>
              </Box>
            );
          })}
      </div>
    </div>
  );
};

export default RecentWins;
