import './App.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import ThemeWrapper from './containers/App/ThemeWrapper';
import ApplicationContainer from './containers/ApplicationContainer';
import '../src/locale/i18n';
import '@fontsource/titillium-web/200.css';
import '@fontsource/titillium-web/300.css';
import '@fontsource/titillium-web/400.css';
import '@fontsource/titillium-web/600.css';
import '@fontsource/titillium-web/700.css';
import '@fontsource/titillium-web/900.css';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

function App() {
  useEffect(() => {
    window.LiveChatWidget.on('ready', () => {
      if (isMobile) {
        const chat = document.getElementById('chat-widget-container');
        if (chat) {
          chat.style.visibility = 'hidden';
        }
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <ThemeWrapper>
        <ApplicationContainer />
      </ThemeWrapper>
    </Provider>
  );
}

export default App;
