import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  initialize,
  unauthorizedInitialize,
} from '../../api/services/BetBy.api';
import {
  changeGlobalDepositModal,
  changeGlobalUserLoginModalOpen,
  changeGlobalUserRegistrationModalOpen,
} from '../../redux/actions/userActions';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { getSportBookLibrarySrc } from '../../api/baseUrl';
import { Box } from '@mui/material';
import AirbetLogo from '../../api/ui/svgIcons/AirbetLogo';
import { CHANGE_SPORT_PAGE_URL } from '../../redux/constants/sportsConstants';

interface IBetBy {
  isUserAuthorized: boolean;
  language: string;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    animation: '$pulse infinite 2s ease-in-out',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

const BetBy = ({ isUserAuthorized, language }: IBetBy) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const [btInfo, setBtInfo] = useState<any>(null);
  const jwtToken = useRef<string | null>(null);
  const brandId = useRef<string | null>(null);

  useEffect(() => {
    if (window.LiveChatWidget) {
      window.LiveChatWidget.on('ready', () => {
        const chat = document.getElementById('chat-widget-container');
        if (chat) {
          chat.style.visibility = 'hidden';
        }
      });
    }

    return () => {
      const chat = document.getElementById('chat-widget-container');
      if (chat) {
        chat.style.visibility = 'visible';
      }
    };
  }, []);

  useEffect(() => {
    if (isUserAuthorized !== null && language) {
      setLoading(true);
      const initializeRendererInfo: any = {
        brand_id: '',
        token: null,
        onTokenExpired: () => onTokenExpiredFun(),
        themeName: 'default',
        lang: language,
        target: document.getElementById('betby'),
        stickyTop: 0,
        betSlipOffsetTop: 71,
        betSlipOffsetBottom: isMobile ? 60 : 0,
        betSlipZIndex: 0,
        onLogin: function () {
          onLoginChange();
        },
        onRegister: function () {
          onRegisterChange();
        },
        onSessionRefresh: function () {},
        onBetSlipStateChange: function () {},
        onRecharge: () => onRecharge(),
        onRouteChange: function (route: string) {
          onRouteChange(route);
        },
      };

      const script = document.createElement('script');
      script.src = getSportBookLibrarySrc() || '';
      script.async = true;
      let bt: any = '';

      if (isUserAuthorized) {
        if (bt) {
          bt.kill();
          setBtInfo(null);
        }
        initialize({
          lang: language,
          currency: 'USDT',
        })
          .then((resp: any) => {
            script.onload = () => {
              bt = new window.BTRenderer().initialize({
                ...initializeRendererInfo,
                token: resp.data.data.jwt_token,
                brand_id: resp.data.data.payload.iss,
              });
              jwtToken.current = resp.data.data.jwt_token;
              brandId.current = resp.data.data.payload.iss;
              setBtInfo(bt);
            };
            document.body.appendChild(script);
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      } else {
        if (bt) {
          bt.kill();
          setBtInfo(null);
        }
        unauthorizedInitialize()
          .then((resp: any) => {
            script.onload = () => {
              bt = new window.BTRenderer().initialize({
                ...initializeRendererInfo,
                brand_id: resp.data.data.iss,
              });
              setBtInfo(bt);
            };
            document.body.appendChild(script);
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      }

      const onTokenExpiredFun = () => {
        return initialize({
          lang: language,
          currency: 'USDT',
        }).then((resp) => {
          return resp.data.data.jwt_token;
        });
      };

      return () => {
        if (bt) {
          document.body.removeChild(script);
          bt.kill();
          setBtInfo(null);
        }
      };
    }
  }, [isUserAuthorized, language]);

  const onLoginChange = () => {
    dispatch(changeGlobalUserLoginModalOpen(true));
  };

  const onRegisterChange = () => {
    dispatch(changeGlobalUserRegistrationModalOpen(true));
  };

  const onRecharge = () => {
    dispatch(changeGlobalDepositModal(true));
  };

  useEffect(() => {
    if (!loading && btInfo) {
      const params = new URLSearchParams(location.search);
      const btPath = params.get('bt-path');
      if (btPath && window.BTRenderer) {
        btInfo.updateOptions({ url: btPath });
      }
    }
  }, [location, loading, btInfo]);

  const onRouteChange = (route: string) => {
    dispatch({
      type: CHANGE_SPORT_PAGE_URL,
      payload: route,
    });
  };

  return (
    <Box className={classes.root}>
      {loading && (
        <div className={classes.loadingContainer}>
          <AirbetLogo />
        </div>
      )}
      <div
        id="betby"
        style={{
          display: loading ? 'none' : '',
          overflow: 'auto',
          maxHeight: '100%',
        }}
      ></div>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthorized: state.user.isUserAuthorized,
  language: state.user.language,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(BetBy);
