import React, { FC, useState } from 'react';
import { IMessageDataType } from '../../types/Chat.type';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { getBaseUrlStatic } from '../../api/baseUrl';

const useStyles = makeStyles(() => ({
  root: {
    background: '#030712',
    width: '100%',
    display: 'flex',
    padding: '16px',
    boxSizing: 'border-box',
  },
  messageDeleted: {
    background: '#030712',
    width: '100%',
    display: 'flex',
    padding: '16px',
    boxSizing: 'border-box',
    opacity: 0.5,
  },
  MainIconBox: {
    display: 'flex',
    alignItems: 'start',
    paddingRight: '8px',
  },
  rightContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: '0px',
  },
  firstMainBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    boxSizing: 'border-box',
    minWidth: '0px',
    position: 'relative',
    '&:hover $alternateEmailIcon': {
      display: 'block',
    },
  },
  secondMainBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 8,
    borderRadius: '12px',
    background: '#111827',
    padding: '16px',
  },
  info: {
    display: 'flex',
    minWidth: '0px',
    gap: '8px',
    '& > span:first-child': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '24px',
      color: '#FFF',
      maxWidth: '100px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& > span:nth-child(2)': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '24px',
      color: '#6B7280',
    },
  },
  hideButtonBox: {
    display: 'flex',
    '& > :first-child': {
      display: 'none',
      width: '20px',
      height: '20px',
      color: '#6B7280',
      cursor: 'pointer',
      transition: 'color 0.3s',
      '&:hover': {
        color: '#FFF',
      },
    },
    '& > :nth-child(2)': {
      width: '20px',
      height: '20px',
      color: '#6B7280',
      cursor: 'pointer',
      transition: 'color 0.3s',
      '&:hover': {
        color: '#FFF',
        opacity: 0.5,
      },
    },
  },
  messageBox: {
    lineBreak: 'anywhere',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#9CA3AF',
  },
  iconBackground: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  alternateEmailIcon: {
    display: 'none',
  },
  mention: {
    color: '#4F46E5',
  },
}));

interface ChatMessageProps {
  message: IMessageDataType;
  isChatAdmin: boolean;
  showHideMessage: (messageId: string, action: string) => void;
  setMessage: (info: string) => void;
}

const ChatMessage: FC<ChatMessageProps> = ({
  message,
  isChatAdmin,
  showHideMessage,
  setMessage,
}) => {
  const classes = useStyles();
  const [hiddenMessage, setHiddenMessage] = useState<boolean>(!message.show);

  const isGiphy = message.data.message.includes('giphy.com');
  const dateString = message.updatedAt ? message.updatedAt : message.createdAt;
  const date = new Date(dateString);
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  });

  const backgroundImgUrl: string = !message.avatar
    ? 'url(imgs/DefaultAvatars/Avatar.png)'
    : message.avatar.includes('googleusercontent.com')
      ? `url(${message.avatar})`
      : `url(${getBaseUrlStatic()}/${message.avatar})`;

  const transformMessage = (message: string): any => {
    return message
      .replace(
        /@[^ ]+/g,
        (match: string) => ` <span style="color:#84CC16">${match}</span> `
      )
      .replace(/\[-(.*?)-\]/g, (match: string, codePoint: string) => {
        if (!isNaN(parseInt(codePoint, 16))) {
          return String.fromCodePoint(parseInt(codePoint, 16));
        } else {
          // If not a valid hexadecimal, return the original match
          return match;
        }
      });
  };

  return (
    <Box className={!hiddenMessage ? classes.root : classes.messageDeleted}>
      <Box className={classes.MainIconBox}>
        <div
          className={classes.iconBackground}
          style={{
            backgroundImage: backgroundImgUrl,
          }}
        />
      </Box>
      <Box className={classes.rightContentWrapper}>
        <Box className={classes.firstMainBox}>
          <Box className={classes.info}>
            <span>{message?.username}</span>
            <span>{formattedTime}</span>
          </Box>
          <Box className={classes.hideButtonBox}>
            <AlternateEmailIcon
              className={classes.alternateEmailIcon}
              onClick={() => setMessage(message?.username)}
            />
            {hiddenMessage && (
              <SettingsBackupRestoreIcon
                onClick={() => {
                  setHiddenMessage(false);
                  showHideMessage(message._id, 'show');
                }}
              />
            )}
            {isChatAdmin && !hiddenMessage && (
              <CloseIcon
                onClick={() => {
                  setHiddenMessage(true);
                  if (message._id) {
                    showHideMessage(message._id, 'hide');
                  }
                }}
              />
            )}
          </Box>
        </Box>

        {!isGiphy ? (
          <Box className={classes.secondMainBox}>
            <Box
              className={classes.messageBox}
              dangerouslySetInnerHTML={{
                __html: transformMessage(message?.data.message),
              }}
            />
          </Box>
        ) : (
          <img
            alt="giphy"
            src={message.data.message}
            className="rounded-[4px] min-h-[100px]"
            style={{ borderRadius: '4px' }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ChatMessage;
