import React from 'react';

type Props = {
  size?: number;
  className?: string;
};

const FlagSpanish: React.FC<Props> = ({
  size = 20,
  className = 'rounded-full',
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 1200 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1200" height="800" fill="#C60B1E" />
      <rect y="266.667" width="1200" height="266.667" fill="#FFC400" />
    </svg>
  );
};

export default FlagSpanish;
