import GameContainer from './GameContainer';

const GameShowContainer = () => {
  return (
    <GameContainer
      categorySlugName={'game-show'}
      pageName={'airbetMenu.gameShows'}
      showSwitcher={true}
    />
  );
};

export default GameShowContainer;
