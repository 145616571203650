import instance from '../axios';

export const getCurrencyList = () => {
  return instance.get('/currency');
};

export const getNetworkList = () => {
  return instance.get('/network');
};

export const getWallet = (coinId: string) => {
  return instance.get(`/currency/get_wallets/${coinId}`);
};

export const calculateFee = (params: any) => {
  return instance.get(`/transactions/withdraw/calculate`, {
    params,
  });
};

export const withdraw = (params: any) => {
  return instance.post(`/transactions/withdraw`, {
    ...params,
  });
};

export const getChangellyOffers = (params: any) => {
  return instance.post('/changelly/offers', {
    ...params,
  });
};

export const createOrder = (params: any) => {
  return instance.post('/changelly/create_order', {
    ...params,
  });
};
