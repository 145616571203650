import BlogDetails from '../../components/Blogs/BlogDetails';
import { IBlog } from '../../types/common.type';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getBlogBySlug } from '../../api/services/Blog.api';
import { enqueueSnackbar } from 'notistack';

const BlogDetailsContainer = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState<Partial<IBlog>>({});
  const navigate = useNavigate();

  const selectBlog = (slug: string) => {
    if (!slug) return;
    navigate('/blog/' + slug);
  };

  useEffect(() => {
    if (!slug) return;
    getBlogBySlug(slug)
      .then((res) => {
        setBlog(res.data.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: 'error',
        });
      });
  }, [slug]);

  return <BlogDetails blog={blog} selectBlog={selectBlog} />;
};

export default BlogDetailsContainer;
