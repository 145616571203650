const MinimumInfoAmountIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8C1.33325 4.3181 4.31802 1.33334 7.99992 1.33334C11.6818 1.33334 14.6666 4.3181 14.6666 8C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667ZM7.33325 7.33334V11.3333H8.66658V7.33334H7.33325ZM7.33325 4.66667V6H8.66658V4.66667H7.33325Z"
        fill="#3B82F6"
      />
    </svg>
  );
};

export default MinimumInfoAmountIcon;
