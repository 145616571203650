import CustomModal from '../common/Modal/CustomModal';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { IUserProfileInfo, RANKS } from '../../util/UserProfileHelper';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import UserProfileHeader from './UserProfileHeader';
import UserRank from './UserRank';
import { IRankInfo } from '../../types/common.type';
import StatisticIcon from '../../api/ui/svgIcons/StatisticIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FavouriteIcon from '../../api/ui/svgIcons/FavouriteIcon';
import { IGameSchema } from '../../types/Game.type';
import GameCard from '../GameCard/GameCard';
import { isMobile } from 'react-device-detect';
import { bindActionCreators } from 'redux';
import { changeUserProfileInfo } from '../../redux/actions/userActions';
import UserProfile from './UserProfile';
import UserProfileChangeImg from './UserProfileChangeImg';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';

interface IUserProfileModal {
  userProfileInfo: IUserProfileInfo;
  ranksInfo: IRankInfo[];
  favouriteGames: IGameSchema[];
  changeUserProfileInfo: (data: IUserProfileInfo) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '12px',
    border: '1px solid #1F2937',
    background: '#111827',
    boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.50)',
    width: '426px',
  },
  header: {
    padding: '16px',
    borderBottom: '1px solid #1F2937',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    width: '100%',
    '& .MuiSvgIcon-root': {
      color: '#fff',
      width: '20px',
      height: '20px',
    },
  },
  userAndRankContainer: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '16px',
  },
  headerText: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  learnRank: {
    color: '#9CA3AF',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    transition: 'color 0.3s ease-in-out',
  },
  staticsContainer: {
    padding: '16px',
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  statisticText: {
    color: '#9CA3AF',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  statisticItem: {
    padding: '8px 16px',
    background: '#0D121D',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  statisticItemName: {
    color: '#9CA3AF',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  statisticItemCount: {
    color: '#fff',
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '16px',
  },
  favouriteContainer: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '& path': {
      fill: '#9CA3AF',
    },
  },
  seeAll: {
    color: '#84CC16',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    cursor: 'pointer',
  },
  favouriteTitle: {
    color: '#9CA3AF',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  learRankContainer: {
    cursor: 'pointer',
    '&:hover': {
      '& span': {
        color: '#fff',
      },
      '& .MuiSvgIcon-root': {
        color: '#fff !important',
      },
    },
  },
}));

const UserProfileModal = ({
  userProfileInfo,
  ranksInfo,
  favouriteGames,
  changeUserProfileInfo,
}: IUserProfileModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [stage, setStage] = useState<number>(0);

  useEffect(() => {
    setOpen(!!userProfileInfo.open);
  }, [userProfileInfo]);

  const getNextRankName = () => {
    const currRankIndex: number = RANKS.findIndex(
      (rank: any) => rank.name === userProfileInfo.rank.rank
    );

    if (currRankIndex + 1 >= RANKS.length) return '';
    return RANKS[currRankIndex + 1].name;
  };

  const getMaxRankLevel = () => {
    const currentUserRankName: string = userProfileInfo.rank.rank;
    return ranksInfo.find(
      (rank: IRankInfo) => rank.name === currentUserRankName
    )?.max_level;
  };

  const onClose = () => {
    setOpen(false);
    changeUserProfileInfo({
      open: false,
      isCurrentUser: true,
      id: 0,
      username: '',
      totalBets: 0,
      totalWins: 0,
      avatar: null,
      rank: {
        rank: '',
        progress: 0,
        level: 0,
      },
    });
    setStage(0);
  };

  return (
    <CustomModal open={open} onClose={() => onClose()}>
      {open && (
        <>
          {stage === 0 && (
            <Box className={classes.root}>
              <Box className={classes.header}>
                <span className={classes.headerText}>
                  {t('userInformation')}
                </span>
                <Box
                  onClick={() => onClose()}
                  display={'flex'}
                  alignItems={'center'}
                  style={{
                    cursor: 'pointer',
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: '#6B7280',
                    },
                  }}
                >
                  <CloseIcon />
                </Box>
              </Box>
              <Box className={classes.userAndRankContainer}>
                <UserProfileHeader
                  username={userProfileInfo.username}
                  userId={String(userProfileInfo.id)}
                  img={userProfileInfo.avatar}
                  stage={'EDIT'}
                  onClickProfile={() => setStage(1)}
                />
                <UserRank
                  currentRankName={userProfileInfo.rank.rank}
                  nextRankName={getNextRankName()}
                  level={userProfileInfo.rank.level}
                  srcInfo={RANKS.find(
                    (item) => item.name === userProfileInfo.rank.rank
                  )}
                  progress={userProfileInfo.rank.progress}
                  maxLevel={getMaxRankLevel() || 0}
                />
              </Box>
              <Box
                padding={'0px 32px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                onClick={() => {
                  onClose();
                  navigate(AppRoutePath.RANKSYSTEM());
                }}
                className={classes.learRankContainer}
              >
                <span className={classes.learnRank}>
                  {t('learnAboutRanks')}
                </span>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <ArrowForwardIosIcon
                    style={{
                      width: '16px',
                      height: '16px',
                      color: '#6B7280',
                      cursor: 'pointer',
                      transition: 'color 0.3s ease-in-out',
                    }}
                  />
                </Box>
              </Box>
              <Box className={classes.staticsContainer}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'8px'}
                  padding={'4px 16px'}
                >
                  <StatisticIcon />
                  <span className={classes.statisticText}>
                    {t('statistic')}
                  </span>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                  <Box className={classes.statisticItem}>
                    <span className={classes.statisticItemName}>
                      {t('totalWins')}
                    </span>{' '}
                    <span className={classes.statisticItemCount}>
                      {userProfileInfo.totalWins}
                    </span>
                  </Box>
                  <Box className={classes.statisticItem}>
                    <span className={classes.statisticItemName}>
                      {t('totalWager')}
                    </span>{' '}
                    <span className={classes.statisticItemCount}>
                      {userProfileInfo.totalBets}
                    </span>
                  </Box>
                </Box>
              </Box>
              {userProfileInfo.isCurrentUser && (
                <Box className={classes.favouriteContainer}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    padding={'0px 16px'}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                      <FavouriteIcon />
                      <span className={classes.favouriteTitle}>
                        {t('airbetMenu.favourites')}
                      </span>
                    </Box>
                    <span
                      className={classes.seeAll}
                      onClick={() => navigate('/favourites')}
                    >
                      {t('seeAll')}
                    </span>
                  </Box>
                  <Box display={'flex'} gap={'16px'} justifyContent={'center'}>
                    {favouriteGames
                      .slice(0, 4)
                      .map((game: IGameSchema, index) => {
                        return (
                          <Box key={index}>
                            <GameCard
                              gameName={game.game_title}
                              gameProvider={game.provider}
                              backgroundImageUrl={game.image || ''}
                              isFavorite={true}
                              isAvailable={
                                !game.coming_soon && !game.is_restricted
                              }
                              comingSoon={!!game.coming_soon}
                              addToFavorites={() => {}}
                              removeFromFavorite={() => {}}
                              startPlaying={() =>
                                navigate(`/game/${game.slug}`)
                              }
                              isMobile={isMobile}
                              agregator_image={game.agregator_image}
                              gameCardStyle={{
                                width: '84px',
                                height: '112px',
                              }}
                              hideActions={true}
                              gameId={game.game_id}
                            />
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {stage === 1 && (
            <UserProfile
              setStage={setStage}
              username={userProfileInfo.username}
              avatar={userProfileInfo.avatar}
              onClose={() => onClose()}
            />
          )}
          {stage === 2 && (
            <UserProfileChangeImg
              setStage={setStage}
              onClose={() => onClose()}
              avatar={userProfileInfo.avatar}
            />
          )}
        </>
      )}
    </CustomModal>
  );
};

const mapStateToProps = (state: any) => ({
  userProfileInfo: state.user.userProfileInformation,
  ranksInfo: state.user.ranksInfo,
  favouriteGames: state.game.favourite,
});

const dispatchToProps = (dispatch: any) => ({
  changeUserProfileInfo: bindActionCreators(changeUserProfileInfo, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(UserProfileModal);
