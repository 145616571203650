import React, { FC } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IWinner } from '../../types/LeaderBoard.type';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  root: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    marginBottom: '8px',
    padding: '0px 1px',
  },
  playerInfoWrapper: {
    width: '100%',
    height: '46px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  playerEachInfoWrapper: {
    width: '100%',
    height: '46px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '16px',
  },
  playerInfo: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
    background: 'rgba(255, 255, 255, 1)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '110px',
  },
  userPositionsEachWrapper: {
    width: '160px',
    padding: '12px 16px',
    gap: '10px',
    display: 'flex',
  },
  positionNumber: {
    width: '20px',
    height: '20px',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    '& span': {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '24px',
      color: '#FFF',
    },
  },
  positionNumberInnerWrapper: {
    width: '18px',
    height: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '24px',
    background: 'blue',
  },
}));

interface LeaderBoardsPlayerProps {
  winner: IWinner;
}

const LeaderBoardsPlayer: FC<LeaderBoardsPlayerProps> = ({ winner }) => {
  const classes = useStyles();

  const getBorderColor = () => {
    switch (winner.place) {
      case 1:
        return {
          background:
            'linear-gradient(90.37deg, #CFA00B 11.38%, #B67C09 50.32%)',
        };
      case 2:
        return {
          background:
            'linear-gradient(90.37deg, #ACACAC 11.38%, #737373 50.32%)',
        };
      case 3:
        return {
          background:
            'linear-gradient(90.37deg, #B77553 11.38%, #A26044 50.32%)',
        };
      default:
        return {
          background: 'rgba(31, 41, 55, 1)',
        };
    }
  };

  const getBackgroundColors = () => {
    switch (winner.place) {
      case 1:
        return {
          background: 'rgba(170, 109, 7, 0.1)',
        };
      case 2:
        return {
          background: 'rgba(167, 167, 167, 0.2)',
        };
      case 3:
        return {
          background: 'rgba(164, 98, 69, 0.2)',
        };
      default:
        return {
          background: 'rgba(17, 24, 39, 1)',
        };
    }
  };

  const getPositionNumberColors = () => {
    switch (winner.place) {
      case 1:
        return {
          background: 'linear-gradient(180deg, #C48309 0%, #6C3B02 100%)',
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.2) inset',
        };
      case 2:
        return {
          background: 'linear-gradient(180deg, #606060 0%, #1F1F1F 100%)',
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.2) inset',
        };
      case 3:
        return {
          background: 'linear-gradient(180deg, #A05C41 0%, #462D1F 100%)',
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.25) inset',
        };
      default:
        return {
          background: 'rgba(31, 41, 55, 1)',
        };
    }
  };

  const getPositionNumberBorderColor = () => {
    switch (winner.place) {
      case 1:
        return {
          background: 'linear-gradient(180deg, #FAE737 0%, #AE5D02 100%)',
        };
      case 2:
        return {
          background: 'linear-gradient(180deg, #ADADAD 0%, #444444 100%)',
        };
      case 3:
        return {
          background: 'linear-gradient(180deg, #E2A786 0%, #90533B 100%)',
        };
      default:
        return {
          background: 'rgba(31, 41, 55, 1)',
        };
    }
  };

  return (
    <Box className={classes.root} style={getBorderColor()}>
      <Box
        width={'100%'}
        style={{
          background: '#0D121D',
          borderRadius: '8px',
        }}
      >
        <Box
          className={classes.playerInfoWrapper}
          style={getBackgroundColors()}
        >
          <Box
            className={classes.playerEachInfoWrapper}
            style={{ gap: '10px' }}
          >
            <Box
              className={classes.positionNumber}
              style={getPositionNumberBorderColor()}
            >
              <Box
                className={classes.positionNumberInnerWrapper}
                style={getPositionNumberColors()}
              >
                <span>{winner.place}</span>
              </Box>
            </Box>
            <span
              className={classes.playerInfo}
              style={{ maxWidth: !isMobile ? '110px' : '70px' }}
            >
              {winner.username}
            </span>
          </Box>
          <Box className={classes.playerEachInfoWrapper}>
            <span className={classes.playerInfo}>
              ${parseFloat(winner.wagered_amount).toFixed(2)}
            </span>
          </Box>
          <Box
            className={classes.playerEachInfoWrapper}
            style={{ paddingRight: '16px', justifyContent: 'flex-end' }}
          >
            <span
              className={classes.playerInfo}
              style={{ maxWidth: !isMobile ? '140px' : '95px' }}
            >
              {winner.prize}
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LeaderBoardsPlayer;
