import { ISvgIcon } from '../../../types/common.type';

const SlotIcon = ({ size = 16, className, fill }: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1173_640)">
        <g filter="url(#filter0_d_1173_640)">
          <path
            d="M9.39583 1.1875C9.39583 1.1875 9.12638 1.37562 8.8125 1.66667C8.58881 1.87409 8.3771 2.20783 8.1875 2.54167C7.62188 2.78665 6.74002 3.15429 5.95833 4C5.17535 4.84711 4.55599 6.16233 4.375 8.125C4.20976 8.05254 4.00453 8 3.77083 8C2.7955 8 1.33333 8.49867 1.33333 10.9167C1.33333 12.988 3.08867 14.6667 5 14.6667C6.788 14.6667 8.66667 12.988 8.66667 10.9167C8.66667 8.482 6.87517 8 6.22917 8C6.02637 8 5.85596 8.04782 5.70833 8.10417C5.87935 6.45666 6.3784 5.50072 6.9375 4.89583C7.17603 4.63777 7.43122 4.45248 7.6875 4.29167C7.54072 6.47391 8.50724 8.20055 9.02083 9.22917C9.21505 9.68294 9.33333 10.2389 9.33333 10.9167C9.33333 12.0273 8.89717 13.0128 8.22917 13.7708C9.23917 14.6882 10.7173 14.963 12.0833 14.2917C13.632 13.531 14.516 11.3557 13.6667 9.625C12.6673 7.591 10.9485 7.937 10.3958 8.20833C10.2729 8.2687 10.1684 8.34161 10.0833 8.41667C9.43661 7.11977 8.60111 5.44251 9.22917 3.27083C9.26305 3.15515 9.47455 2.86262 9.70833 2.64583C9.94212 2.42905 10.1458 2.29167 10.1458 2.29167L9.39583 1.1875ZM10 3.375C10 3.375 9.355 4.67567 10.0417 6.125C10.769 6.79033 11.9887 6.71083 12.6667 6.52083C12.6667 6.52083 12.3967 5.03883 11.5833 4.35417C10.7347 3.64083 10 3.375 10 3.375Z"
            fill={fill || '#9CA3AF'}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1173_640"
          x="-0.666666"
          y="0.1875"
          width="16.6633"
          height="17.4792"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1173_640"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1173_640"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1173_640">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SlotIcon;
