import Preferences from '../../components/Settings/Preferences/Preferences';
import { connect } from 'react-redux';
import { IUserInfo } from '../../types/common.type';

export interface IPlayerAccountInfo {
  playerInfo: IUserInfo;
  onClose: () => void;
}

const PreferencesContainer = ({ playerInfo, onClose }: IPlayerAccountInfo) => {
  return <Preferences playerInfo={playerInfo} onClose={onClose} />;
};

const mapStateToProps = (state: any) => ({
  playerInfo: state.user.playerInfo,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(PreferencesContainer);
