import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%',
    height: isMobile ? '416px' : '208px',
    borderRadius: '12px',
    flexDirection: isMobile ? 'column' : 'row',
    margin: '32px 0px',
    padding: '0px 24px',
  },
  imageSkeleton: {
    width: isMobile ? '100%' : '377px',
    height: '208px',
    maxHeight: '208px',
    borderBottomLeftRadius: isMobile ? '0px' : '12px',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: isMobile ? '12px' : '0px',
    background: 'rgb(43 55 80 / 42%)',
  },
  statusSkeletonBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: '16px',
    height: '24px',
  },
  rightContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    justifyContent: 'space-between',
  },
  infoTextSkeletonBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '16px',
  },
}));

const PromotionPageItemSkeleton = () => {
  const classes = useStyles();

  const skeletonArray = Array.from({ length: 5 }, (v, i) => i);

  return (
    <>
      {skeletonArray.map((index) => (
        <Box key={index} className={classes.root}>
          <Box style={{ width: !isMobile ? '377px' : '100%' }}>
            <Skeleton variant="rounded" className={classes.imageSkeleton} />
          </Box>
          <Box className={classes.rightContentWrapper}>
            <Box className={classes.statusSkeletonBox}>
              <Skeleton
                variant="rounded"
                width={74}
                height={30}
                style={{ background: 'rgb(43 55 80 / 42%)' }}
              />
              <Skeleton
                variant="rounded"
                width={173}
                height={30}
                style={{ background: 'rgb(43 55 80 / 42%)' }}
              />
            </Box>
            <Box className={classes.infoTextSkeletonBox}>
              <Skeleton
                variant="text"
                width={100}
                height={30}
                style={{ background: 'rgb(43 55 80 / 42%)' }}
              />
              <Skeleton
                variant="text"
                width={74}
                height={30}
                style={{ background: 'rgb(43 55 80 / 42%)' }}
              />
            </Box>

            <Skeleton
              variant="rounded"
              width={140}
              height={30}
              style={{ background: 'rgb(43 55 80 / 42%)' }}
            />
          </Box>
        </Box>
      ))}
    </>
  );
};

export default PromotionPageItemSkeleton;
