import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Tabs from './Tabs';
import { IBlog } from '../../types/common.type';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    boxSizing: 'border-box',
    margin: '0 auto',
  },
}));

interface BlogSwitcherProps {
  blogs: IBlog[];
  onSelectBlog: (slug: string) => void;
}

const BlogSwitcher = ({ blogs, onSelectBlog }: BlogSwitcherProps) => {
  const classes = useStyles();
  const tabs = ['All', 'Casino', 'Sports', 'Crypto', 'Gambling', 'Other'];

  return (
    <Box className={classes.root}>
      <Tabs blogs={blogs} tabs={tabs} onSelectBlog={onSelectBlog} />
    </Box>
  );
};

export default BlogSwitcher;
