import { Box } from '@mui/material';
import BetsTable from '../../components/BetTable/BetsTable';
import { useEffect, useState } from 'react';
import { IBetsTableItem } from '../../types/common.type';
import { isMobile } from 'react-device-detect';
import { socket } from '../../api/airbet.socket';

const BetsTableContainer = () => {
  const tableHeader = isMobile
    ? ['Game', 'user', 'payout']
    : ['Game', 'user', 'bet', 'multiplayer', 'payout'];
  const betTypes = ['All Bets', 'High Rollers', 'Rare Wins'];
  const [tableData, setTableData] = useState<IBetsTableItem[]>([]);

  const updateData = (newItem: any, oldData: any) => {
    const removeLastItem =
      oldData.length <= 99 ? oldData : oldData.slice(0, 50);
    return [newItem, ...removeLastItem];
  };

  const handleLastBetsUpdate = (arr: any) => {
    if (Array.isArray(arr)) {
      setTableData(arr);
    } else {
      setTableData((prev: any) => updateData(arr, prev));
    }
  };

  useEffect(() => {
    socket.emit('lastBets', {});
    socket.on('lastBets', handleLastBetsUpdate);

    return () => {
      socket.off('lastBets', handleLastBetsUpdate);
    };
  }, []);

  return (
    <Box width={'100%'}>
      {tableData && (
        <BetsTable
          tableData={tableData}
          tableHeader={tableHeader}
          betTypes={betTypes}
        />
      )}
    </Box>
  );
};
export default BetsTableContainer;
