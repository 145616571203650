import { ISvgIcon } from '../../../types/common.type';

const AirbetOriginalMenuIcon = ({
  size = 16,
  className,
  pathClassName,
  fill,
}: ISvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.1437 1.91011C10.121 1.60739 9.06936 1.57106 8.01217 1.61577C7.04905 1.65676 6.10549 1.7853 5.21224 2.18489C3.86071 2.7894 3.16026 3.87826 2.83239 5.31455H3.09785C3.88865 5.31455 4.68038 5.30896 5.47118 5.31921C5.65188 5.322 5.74689 5.27357 5.81768 5.10032C6.04029 4.55915 6.45479 4.20799 7.00807 4.04685C7.78676 3.82051 8.58314 3.80654 9.37767 3.95091C9.79589 4.02729 10.1927 4.17819 10.4023 4.58988C10.5839 4.9457 10.5466 5.32387 10.4675 5.70203C10.4004 6.02245 10.1992 6.20595 9.89276 6.2637C9.61239 6.31586 9.32644 6.34287 9.04142 6.36895C7.78489 6.48445 6.52558 6.57666 5.27092 6.71358C4.49968 6.79835 3.75266 7.00513 3.07363 7.40099C2.1543 7.93657 1.73235 8.77302 1.62523 9.80226C1.44267 11.558 2.25489 12.7941 3.92591 13.2421C5.06786 13.5485 6.22193 13.5513 7.37226 13.2672C8.13698 13.0782 8.79831 12.7149 9.27707 12.0703C9.39909 11.9073 9.49876 11.7276 9.61705 11.5431V13.2887C10.3399 13.2346 11.0552 13.1676 11.7724 13.134C12.0025 13.1229 12.0668 13.0362 12.1012 12.8313C12.3881 11.1454 12.6899 9.46135 12.9768 7.77543C13.1258 6.89801 13.2739 6.02059 13.3848 5.13758C13.4639 4.50512 13.3577 3.88664 13.0485 3.31846C12.6331 2.55467 11.9466 2.1467 11.1428 1.90918L11.1437 1.91011ZM8.90356 10.5586C8.30278 11.0672 7.58743 11.2758 6.82457 11.2954C6.37375 11.3066 5.90896 11.2609 5.47211 11.1557C4.91138 11.0206 4.56209 10.6368 4.54439 10.0305C4.52669 9.43155 4.68131 8.88293 5.26533 8.61281C5.62859 8.44421 6.03843 8.33803 6.43802 8.29239C7.39834 8.18155 8.36519 8.12845 9.32923 8.03997C9.57234 8.01761 9.81079 7.95148 10.0725 7.90118C9.94119 8.89597 9.73162 9.86001 8.90542 10.5595L8.90356 10.5586Z"
        fill={fill || '#9CA3AF'}
        className={pathClassName}
      />
    </svg>
  );
};

export default AirbetOriginalMenuIcon;
