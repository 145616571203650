import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import BlogBanner from './BlogBanner';
import BlogSwitcher from './BlogSwitcher';
import { useNavigate } from 'react-router-dom';
import { IBlog } from '../../types/common.type';
import { isMobile } from 'react-device-detect';
import BlogBannerSkeleton from './BlogBannerSkeleton';
import BlogTabsSkeleton from './BlogTabsSkeleton';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#0D121D',
    width: '100%',
    margin: '0 auto',
    minWidth: '0px',
    gap: isMobile ? '32px' : '',
    height: '100%',
  },
  containerFluid: {
    width: '100%',
    backgroundColor: '#0D121D',
    marginTop: '16px',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    maxWidth: '100%',
    minWidth: '0px',
    margin: '48px auto',
  },
}));

interface BlogProps {
  blogs: IBlog[];
  bannerBlogs: IBlog[];
  loading: boolean;
}

const Blog = ({ blogs, bannerBlogs, loading }: BlogProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const selectBlogHandler = (slug: string) => {
    navigate('/blog/' + slug);
  };

  return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      <Box className={classes.root}>
        <div className={classes.containerFluid}>
          {!loading ? (
            <BlogBanner blogs={bannerBlogs} onSelectBlog={selectBlogHandler} />
          ) : (
            <BlogBannerSkeleton />
          )}
        </div>
        <div className={classes.containerFluid}>
          {!loading ? (
            <BlogSwitcher blogs={blogs} onSelectBlog={selectBlogHandler} />
          ) : (
            <BlogTabsSkeleton />
          )}
        </div>
      </Box>
    </div>
  );
};
export default Blog;
