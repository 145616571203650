import React, { FC } from 'react';
import CustomModal from '../common/Modal/CustomModal';
import BonusWheelContainer from '../../containers/BonusWheelContainer/BonusWheelContainer';
import { globalBonusWheelModalClose } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

interface BonusWheelModalProps {
  openBonusWheelModal: boolean;
}

const BonusWheelModal: FC<BonusWheelModalProps> = ({ openBonusWheelModal }) => {
  const dispatch = useDispatch();
  const onCloseBonusWheelModal = () => {
    dispatch(globalBonusWheelModalClose(false));
  };

  return (
    <CustomModal
      open={openBonusWheelModal}
      onClose={onCloseBonusWheelModal}
      fullScreen={isMobile && '100%'}
      customStyle={isMobile ? { height: '100%', display: 'flex' } : ''}
    >
      <BonusWheelContainer
        width={!isMobile ? 350 : 300}
        height={!isMobile ? 350 : 300}
      />
    </CustomModal>
  );
};

export default BonusWheelModal;
