import React, { FC } from 'react';
import CustomModal from '../common/Modal/CustomModal';
import { Box } from '@mui/material';
import { IBonusWheelWinPrize } from '../../types/Bonus.type';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { getBaseUrlStatic } from '../../api/baseUrl';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: !isMobile ? '450px' : '370px',
    height: '360px',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(27, 40, 69, 0.20) 100%), #111827',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '32px',
  },
  winTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    '& :nth-child(1)': {
      fontSize: !isMobile ? '14px' : '12px',
      fontWeight: 600,
      lineHeight: !isMobile ? '16px' : '12px',
      background: 'rgba(255, 255, 255, 0.5)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textTransform: 'uppercase',
    },
    '& :nth-child(2)': {
      fontSize: !isMobile ? '24px' : '18px',
      fontWeight: 600,
      lineHeight: !isMobile ? '24px' : '18px',
      background: 'rgba(255, 255, 255, 1)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textTransform: 'uppercase',
    },
  },
  winBox: {
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(1, 6, 23, 0.25)',
    gap: '16px',
    padding: '16px 32px',
    '& :nth-child(2)': {
      fontSize: !isMobile ? '48px' : '36px',
      fontWeight: 700,
      lineHeight: !isMobile ? '64px' : '48px',
      background: 'linear-gradient(180deg, #ECCF9C 1.5%, #E2B776 101.5%)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  continuePlaying: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '32px',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '16px 32px',
    boxShadow: '0px 2px 8px 0px rgba(101, 163, 13, 0.3)',
    background: 'rgba(101, 163, 13, 1)',
    cursor: 'pointer',
    '& span': {
      fontSize: !isMobile ? '24px' : '18px',
      fontWeight: 700,
      lineHeight: !isMobile ? '24px' : '18px',
      background: 'rgba(255, 255, 255, 1)',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textTransform: 'uppercase',
    },
  },
}));

interface bonusWheelWinModalProps {
  isOpenBonusWheelWinModal: boolean;
  onCloseBonusWheelWinModal: () => void;
  wonPrize: null | IBonusWheelWinPrize;
}

const BonusWheelWinModal: FC<bonusWheelWinModalProps> = ({
  isOpenBonusWheelWinModal,
  onCloseBonusWheelWinModal,
  wonPrize,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <CustomModal
      open={isOpenBonusWheelWinModal}
      onClose={onCloseBonusWheelWinModal}
    >
      <Box className={classes.root}>
        <Box className={classes.winTextWrapper}>
          <span>{t('congratulation')}!</span>
          <span>{t("you'veWon")}</span>
        </Box>
        <Box className={classes.winBox}>
          <img
            src={getBaseUrlStatic() + '/' + wonPrize?.image}
            alt={'won_img'}
            style={{ width: '24px', height: '24px' }}
          />
          <span>{wonPrize?.name}</span>
        </Box>
        <Box
          className={classes.continuePlaying}
          onClick={() => onCloseBonusWheelWinModal()}
        >
          <span>{t('continuePlaying')}</span>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default BonusWheelWinModal;
