import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CustomInput from '../common/Inputs/CustomInput';
import CustomCheckBox from '../common/CheckBox/CustomCheckBox';
import { makeStyles } from '@mui/styles';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { ChangeEvent } from 'react';
import { isMobile } from 'react-device-detect';

interface IRegistrationContainer {
  disableRegistration: boolean;
  onChangeLoginInfo: (key: string, value: string) => void;
  onRegister: () => void;
  loading: boolean;
  agreeMarketingPromotions: boolean;
  setAgreeMarketingPromotions: (agree: boolean) => void;
  agreeTermsAndConditions: boolean;
  setAgreeTermsAndConditions: (agree: boolean) => void;
}

const useStyles = makeStyles(() => ({
  checkboxLabel: {
    color: '#9CA3AF',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
}));
const RegistrationContainer = ({
  disableRegistration,
  onChangeLoginInfo,
  onRegister,
  loading,
  agreeMarketingPromotions,
  setAgreeMarketingPromotions,
  agreeTermsAndConditions,
  setAgreeTermsAndConditions,
}: IRegistrationContainer) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={isMobile ? 'fit-content' : '100%'}
      justifyContent={isMobile ? '' : 'space-between'}
    >
      <Box>
        <Box marginBottom={'24px'}>
          <CustomInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeLoginInfo('username', event.target.value)
            }
            label={t('username')}
            isRequired={true}
            placeholder={t('username')}
          />
        </Box>
        <Box marginBottom={'24px'}>
          <CustomInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeLoginInfo('email', event.target.value)
            }
            label={t('email')}
            isRequired={true}
            placeholder={t('email')}
          />
        </Box>
        <Box>
          <CustomInput
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeLoginInfo('password', event.target.value)
            }
            label={t('password')}
            isRequired={true}
            placeholder={t('password')}
            type={'password'}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'16px'}
          marginTop={'32px'}
        >
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <CustomCheckBox
              checked={agreeMarketingPromotions}
              onChange={() =>
                setAgreeMarketingPromotions(!agreeMarketingPromotions)
              }
            />
            <span className={classes.checkboxLabel}>
              {t('agreeMarketingPromotions')}{' '}
              <span
                style={{
                  color: '#fff',
                }}
              >
                AIRBET.IO
              </span>
            </span>
          </Box>
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <CustomCheckBox
              checked={agreeTermsAndConditions}
              onChange={() =>
                setAgreeTermsAndConditions(!agreeTermsAndConditions)
              }
            />
            <span className={classes.checkboxLabel}>
              {t('agreeTermsAndCondition.firstPart')}
              <span
                style={{
                  color: '#fff',
                  padding: '0px 3px',
                }}
              >
                {t('termsAndCondition')}
              </span>
              {t('agreeTermsAndCondition.secondPart')}
            </span>
          </Box>
        </Box>
      </Box>
      <Box width={'100%'} marginTop={isMobile ? '32px' : '0px'}>
        <CustomPrimaryButton
          style={{ width: '100%', textTransform: 'uppercase' }}
          disabled={disableRegistration}
          loading={loading}
          onClick={() => onRegister()}
        >
          {t('header.createAccount')}
        </CustomPrimaryButton>
      </Box>
    </Box>
  );
};

export default RegistrationContainer;
