import { useEffect, useRef, useState } from 'react';
import { IGameFilterParams, IGameSchema } from '../../types/Game.type';
import { getGames } from '../../api/services/Game.api';
import { isMobile } from 'react-device-detect';
import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';

interface IGlobalSearchContainer {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const GlobalSearchContainer = ({ open, setOpen }: IGlobalSearchContainer) => {
  const initialFilterParams: IGameFilterParams = {
    per_page: '28',
    device: isMobile ? 'mobile' : 'desktop',
    category: 0,
    provider: '',
    sort: 'popular',
    search_name: '',
    page: 1,
  };
  const [searchValue, setSearchValue] = useState<string>('');
  const [games, setGames] = useState<IGameSchema[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [blockedGames, setBlockedGames] = useState<string>('show');
  const [filterParams, setFilterParams] =
    useState<IGameFilterParams>(initialFilterParams);
  const [selectedProviders, setProviders] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>('popular');
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);
  const [totalResult, setTotalResult] = useState<number>(0);
  const currentPageRef = useRef<number>(1);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      onLoadGame();
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, [filterParams]);

  useEffect(() => {
    if (!open) {
      setSearchValue('');
    }
  }, [open]);

  const onChangeBlockedGames = (value: string): void => {
    setBlockedGames(value);
    const currentParams: IGameFilterParams = {
      ...filterParams,
      show_available_only: 1,
    };
    if (value === 'show') {
      delete currentParams.show_available_only;
      setFilterParams(currentParams);
    } else {
      setFilterParams(currentParams);
    }
  };

  const onLoadGame = (): void => {
    setLoading(true);
    getGames({ ...filterParams })
      .then((resp: any) => {
        const result = resp.data.data.games;
        setTotalResult(result.total);
        setGames(result.data.length > 0 ? resp.data.data.games.data : []);
        currentPageRef.current = 1;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeProviders = (providerId: number): void => {
    if (providerId === -1) {
      setProviders([]);
    } else {
      const isAlreadyInArray = selectedProviders.includes(String(providerId));
      const result = isAlreadyInArray
        ? [
            ...selectedProviders.filter(
              (item: string) => item !== String(providerId)
            ),
          ]
        : [...selectedProviders, String(providerId)];

      setProviders(result);
      setFilterParams({
        ...filterParams,
        provider: result.join(','),
      });
    }
  };

  const onChangeSort = (value: string): void => {
    setSortBy(value);
    setFilterParams({
      ...filterParams,
      sort: value,
    });
  };

  const onChangeSearchValue = (value: string): void => {
    setSearchValue(value);
    if (value.trim().length > 2) {
      setFilterParams({
        ...filterParams,
        search_name: value,
      });
    }

    if (value.trim().length === 0) {
      setFilterParams({
        ...filterParams,
        search_name: '',
      });
    }
  };

  const clearSearchValue = () => {
    setSearchValue('');
    setFilterParams({
      ...filterParams,
      search_name: '',
    });
  };

  const loadMoreGames = (): void => {
    currentPageRef.current = currentPageRef.current + 1;
    setLoadMoreLoading(true);
    getGames({ ...filterParams, page: currentPageRef.current })
      .then((resp: any) => {
        const gamesData = resp.data.data['games'];
        if (gamesData) {
          setTotalResult(gamesData.total);
          setGames([...games, ...gamesData.data]);
        }
      })
      .finally(() => {
        setLoadMoreLoading(false);
      });
  };

  return (
    <GlobalSearch
      games={games}
      loading={loading}
      open={open}
      setOpen={setOpen}
      searchValue={searchValue}
      clearSearchValue={clearSearchValue}
      onChangeBlockedGames={onChangeBlockedGames}
      selectedBlockGame={blockedGames}
      onChangeProviders={onChangeProviders}
      selectedProviders={selectedProviders}
      selectedSortBy={sortBy}
      onChangeSort={onChangeSort}
      onChangeSearchValue={onChangeSearchValue}
      loadMoreLoading={loadMoreLoading}
      loadMoreGames={loadMoreGames}
      totalResult={totalResult}
    />
  );
};

export default GlobalSearchContainer;
