import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import OpenSidebarIcon from '../../api/ui/svgIcons/OpenSidebarIcon';
import AirbetLogo from '../../api/ui/svgIcons/AirbetLogo';
import CherryIcon from '../../api/ui/svgIcons/CherryIcon';
import { useTranslation } from 'react-i18next';
import SportIcon from '../../api/ui/svgIcons/SportIcon';
import AppHeaderAuthorizedContent from './AppHeaderAuthorizedContent';
import AppHeaderUnAuthorizedContent from './AppHeaderUnAuthorizedContent';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import GrabBonus from '../GrabBonus/GrabBonus';
import WelcomeBonusModal from '../GrabBonus/WelcomeBonusModal';
import { isMobile } from 'react-device-detect';
import DepositModal from '../Account/DepositModal';
import BonusClaimNotificationModal from '../Bonus/BonusClaimNotificationModal';
import { IUserInfo } from '../../types/common.type';
import BonusWheelModal from '../BonusWheelPage/BonusWheelModal';
import { AppRoutePath } from '../../util/appRoutePath';
import { bindActionCreators } from 'redux';
import { changeGlobalDepositModal } from '../../redux/actions/userActions';

interface IAppHeader {
  children: ReactNode;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  isChatOpen: boolean;
  setIsChatOpen: (isOpen: boolean) => void;
  isNotificationOpen: boolean;
  setIsNotificationOpen: (isOpen: boolean) => void;
  openLogin: () => void;
  openRegistration: () => void;
  isUserAuthenticated: boolean;
  hideMainHeader: boolean;
  playerInfo: IUserInfo;
  globalBonusWheelModalOpen: boolean;
  globalDepositModal: boolean;
  changeGlobalDepositModalFn: (isOpen: boolean) => void;
  setSelectedPageMobileVersion: (pageName: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '0px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
  },
  header: {
    borderBottom: '1px solid #FFFFFF0D',
    background: '#0D121D',
    boxShadow: '0px 2px 4px 0px #03071280',
    display: 'flex',
    justifyContent: 'space-between',
    padding: isMobile ? '12px 8px 12px 16px' : '0px',
    boxSizing: 'border-box',
    minWidth: '0px',
    zIndex: '9',
    [theme.breakpoints.up('sm')]: {
      height: '72px',
      minHeight: '72px',
      maxHeight: '72px',
    },
  },
  body: {
    height: '100%',
    minHeight: '0px',
    width: '100%',
  },
  OpenSidebarContainer: {
    display: 'flex',
    alignItems: 'center',
    transition: 'width 0.3s',
  },
  OpenSidebarIcon: {
    cursor: 'pointer',
    transform: 'scaleX(1)',
    transition: 'transform 0.3s',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    margin: '0px 20px',
  },
  airbetLogo: {
    paddingLeft: '24px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    transition: 'padding 0.3s, width 0.3s',
  },
  casinoContainer: {
    padding: '8px 16px 8px 12px',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'transparent',
    background: 'transparent',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '24px',
    transition: 'background-color 0.3s, border-color 0.3s',
    '&:hover': {
      '& span': {
        color: '#fff !important',
      },
      '& path': {
        fill: '#1D4ED8 !important',
      },
    },
    [theme.breakpoints.down(790)]: {
      display: 'none',
    },
  },
  activeCasinoContainer: {
    background: '#111827',
    borderColor: 'rgba(255, 255, 255, 0.05)',
    '& span': {
      color: '#fff !important',
    },
    '& path': {
      fill: '#1D4ED8 !important',
    },
  },
  casinoContainerText: {
    color: '#FFFFFF80',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    opacity: '0.92',
    transition: 'color 0.3s',
  },
  sportIconContainer: {
    padding: '8px 12px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '8px',
    border: '1px solid',
    borderColor: 'transparent',
    background: 'transparent',
    borderRadius: '12px',
    cursor: 'pointer',
    '&:hover': {
      '& span': {
        color: '#fff !important',
      },
      '& path': {
        fill: '#84CC16 !important',
      },
    },
    [theme.breakpoints.down(950)]: {
      display: 'none',
    },
  },
  activeSportContainer: {
    background: '#111827',
    borderColor: 'rgba(255, 255, 255, 0.05)',
    '& span': {
      color: '#fff !important',
    },
    '& path': {
      fill: '#84CC16 !important',
    },
  },
  sportText: {
    color: '#FFFFFF80',
    opacity: '0.92',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  sportSoon: {
    color: '#FFF',
    fontSize: '9px',
    fontWeight: 700,
    lineHeight: '12px',
    textTransform: 'uppercase',
    background: '#374151',
    padding: '0px 4px',
    borderRadius: '6px 2px',
  },
}));

const AppHeader = ({
  children,
  isSidebarOpen,
  setIsSidebarOpen,
  isChatOpen,
  setIsChatOpen,
  isNotificationOpen,
  setIsNotificationOpen,
  openLogin,
  openRegistration,
  isUserAuthenticated,
  hideMainHeader,
  playerInfo,
  globalBonusWheelModalOpen,
  globalDepositModal,
  changeGlobalDepositModalFn,
  setSelectedPageMobileVersion,
}: IAppHeader) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const openSideBarButtonRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles({
    isSidebarOpen,
    isChatOrNotificationOpen: isNotificationOpen || isChatOpen,
    isUserAuthenticated,
  });
  const [openWelcomeBonus, setOpenWelcomeBonus] = useState<boolean>(false);
  const [isOpenDepositModal, setIsOpenDepositModal] = useState<boolean>(false);

  const onClickSidebarOpenButton = (): void => {
    if (openSideBarButtonRef?.current) {
      openSideBarButtonRef.current.style.transform = isSidebarOpen
        ? 'scaleX(-1)'
        : 'scaleX(1)';
    }
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={classes.root}>
      {(!isUserAuthenticated ||
        (isUserAuthenticated && !Number(playerInfo.player.total_deposit))) && (
        <GrabBonus
          setOpenWelcomeBonus={setOpenWelcomeBonus}
          openRegistration={openRegistration}
        />
      )}
      <div
        className={classes.header}
        style={{
          display: hideMainHeader ? 'none' : '',
        }}
      >
        <Box
          display={'flex'}
          style={{
            alignItems: isMobile ? 'center' : '',
            position: isMobile ? 'relative' : 'static',
          }}
        >
          {!isMobile && (
            <div
              className={classes.OpenSidebarContainer}
              style={{
                width: isSidebarOpen ? '240px' : '170px',
              }}
            >
              <div
                ref={openSideBarButtonRef}
                className={classes.OpenSidebarIcon}
                onClick={() => onClickSidebarOpenButton()}
              >
                <OpenSidebarIcon />
              </div>
              <div
                className={classes.airbetLogo}
                style={{
                  borderLeft: !isSidebarOpen
                    ? '1px solid rgba(255, 255, 255, 0.05)'
                    : '',
                  borderRight: isSidebarOpen
                    ? '1px solid rgba(255, 255, 255, 0.05)'
                    : '',
                  paddingLeft: !isSidebarOpen ? '24px' : '0px',
                  paddingRight: isSidebarOpen ? '97px' : '0px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/');
                }}
              >
                <AirbetLogo />
              </div>
            </div>
          )}
          {!isMobile && (
            <Box display={'flex'} alignItems={'center'}>
              <div
                className={`${classes.casinoContainer} ${location.pathname === '/casino' && classes.activeCasinoContainer}`}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => navigate(AppRoutePath.CASINO())}
              >
                <CherryIcon />
                <span className={classes.casinoContainerText}>
                  {t('header.casino')}
                </span>
              </div>
              <div
                className={`${classes.sportIconContainer} ${location.pathname.includes(AppRoutePath.SPORT()) && classes.activeSportContainer}`}
                onClick={() => navigate(AppRoutePath.SPORT())}
              >
                <SportIcon fill={'#FFFFFF80'} />
                <span className={classes.sportText}>{t('header.sport')}</span>
                {/*<span className={classes.sportSoon}>{t('header.soon')}</span>*/}
              </div>
            </Box>
          )}
          {isMobile && (
            <Box
              display={'flex'}
              alignItems={'center'}
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(0%, -50%)',
              }}
              onClick={() => {
                navigate('/');
                setSelectedPageMobileVersion('lobby');
              }}
            >
              <AirbetLogo width={62} height={16} />
            </Box>
          )}
        </Box>
        <Box width={'100%'} display={'flex'}>
          {isUserAuthenticated ? (
            <AppHeaderAuthorizedContent
              isChatOpen={isChatOpen}
              setIsChatOpen={setIsChatOpen}
              isNotificationOpen={isNotificationOpen}
              setIsNotificationOpen={setIsNotificationOpen}
              setIsOpenDepositModal={setIsOpenDepositModal}
            />
          ) : (
            <AppHeaderUnAuthorizedContent
              isChatOpen={isChatOpen}
              setIsChatOpen={setIsChatOpen}
              openLogin={openLogin}
              openRegistration={openRegistration}
            />
          )}
        </Box>
      </div>
      <div className={classes.body}>{children}</div>
      {openWelcomeBonus && (
        <WelcomeBonusModal
          setOpenWelcomeBonus={setOpenWelcomeBonus}
          openWelcomeBonus={openWelcomeBonus}
        />
      )}
      {(isOpenDepositModal || globalDepositModal) && (
        <DepositModal
          setIsOpenDepositModal={setIsOpenDepositModal}
          isOpenDepositModal={isOpenDepositModal || globalDepositModal}
          changeGlobalDepositModalFn={changeGlobalDepositModalFn}
        />
      )}
      <BonusClaimNotificationModal />
      {globalBonusWheelModalOpen && (
        <BonusWheelModal openBonusWheelModal={globalBonusWheelModalOpen} />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: state.user.isUserAuthorized,
  hideMainHeader: state.user.hideMainHeader,
  playerInfo: state.user.playerInfo,
  globalBonusWheelModalOpen: state.user.globalBonusWheelModalOpen,
  globalDepositModal: state.user.globalDepositModal,
});

const dispatchToProps = (dispatch: any) => ({
  changeGlobalDepositModalFn: bindActionCreators(
    changeGlobalDepositModal,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(AppHeader);
