const GoogleAuthIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.04235 5.00607C3.13931 2.82726 5.39381 1.33331 7.99992 1.33331C9.79685 1.33331 11.3059 1.99395 12.4605 3.06968L10.5484 4.98181C9.85752 4.32121 8.97865 3.98483 7.99992 3.98483C6.26353 3.98483 4.79381 5.15756 4.26962 6.73331C4.13625 7.13331 4.06049 7.56058 4.06049 7.99998C4.06049 8.43938 4.13625 8.86665 4.26962 9.26665C4.79381 10.8424 6.26353 12.0151 7.99992 12.0151C8.89685 12.0151 9.66052 11.7788 10.2575 11.3788C10.9635 10.906 11.4333 10.2 11.5878 9.36665H7.99992V6.78785H14.2787C14.3575 7.22418 14.3999 7.67878 14.3999 8.15151C14.3999 10.1818 13.6727 11.8908 12.412 13.0515C11.309 14.0697 9.79992 14.6666 7.99992 14.6666C5.39381 14.6666 3.13931 13.1727 2.04235 10.9939C1.59084 10.0939 1.33325 9.07571 1.33325 7.99998C1.33325 6.92425 1.59084 5.90605 2.04235 5.00607Z"
        fill="white"
      />
    </svg>
  );
};

export default GoogleAuthIcon;
