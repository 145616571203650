import { ISvgIcon } from '../../../types/common.type';

const ProviderIcon = ({ size = 16, className, pathClassName }: ISvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      className={className}
    >
      <path
        d="M13.75 7.66663C13.75 7.25241 13.4142 6.91663 13 6.91663H11.5C11.3619 6.91663 11.25 6.8047 11.25 6.66663C11.25 6.52855 11.3619 6.41663 11.5 6.41663H13C13.4142 6.41663 13.75 6.08084 13.75 5.66663V4.16663C13.75 4.02855 13.8619 3.91663 14 3.91663C14.1381 3.91663 14.25 4.02855 14.25 4.16663V5.66663C14.25 6.08084 14.5858 6.41663 15 6.41663H16.5C16.6381 6.41663 16.75 6.52855 16.75 6.66663C16.75 6.8047 16.6381 6.91663 16.5 6.91663H15C14.5858 6.91663 14.25 7.25241 14.25 7.66663V9.16663C14.25 9.3047 14.1381 9.41663 14 9.41663C13.8619 9.41663 13.75 9.3047 13.75 9.16663V7.66663ZM4 3.91663H8C8.41421 3.91663 8.75 4.25241 8.75 4.66663V8.66663C8.75 9.08084 8.41421 9.41663 8 9.41663H4C3.58579 9.41663 3.25 9.08084 3.25 8.66663V4.66663C3.25 4.25241 3.58579 3.91663 4 3.91663ZM4 11.9166H8C8.41421 11.9166 8.75 12.2524 8.75 12.6666V16.6666C8.75 17.0808 8.41421 17.4166 8 17.4166H4C3.58579 17.4166 3.25 17.0808 3.25 16.6666V12.6666C3.25 12.2524 3.58579 11.9166 4 11.9166ZM11.25 12.6666C11.25 12.2524 11.5858 11.9166 12 11.9166H16C16.4142 11.9166 16.75 12.2524 16.75 12.6666V16.6666C16.75 17.0808 16.4142 17.4166 16 17.4166H12C11.5858 17.4166 11.25 17.0808 11.25 16.6666V12.6666Z"
        fill="#9CA3AF"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
};

export default ProviderIcon;
