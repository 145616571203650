import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import MasterCardIcon from '../../api/ui/svgIcons/CryptoIcons/MasterCardIcon';
import VisaIcon from '../../api/ui/svgIcons/CryptoIcons/VisaIcon';
import ApplePayIcon from '../../api/ui/svgIcons/CryptoIcons/ApplePayIcon';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import { changeGlobalUserLoginModalOpen } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';

interface INoCryptoBanner {
  isUserAuthenticated: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    minWidth: '0px',
    height: '80px',
    borderRadius: '16px',
    border: '1px solid rgba(255, 255, 255, 0.08)',
    background: '#111827',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '32px',
    paddingRight: '20px',
    marginTop: '48px',
  },
  noCrypto: {
    color: '#fff',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '32px',
    textTransform: 'uppercase',
  },
  noProblem: {
    marginLeft: '8px',
    color: '#fff',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '32px',
    textTransform: 'uppercase',
  },
  divider: {
    background: 'rgba(255, 255, 255, 0.10)',
    width: '1px',
    height: '32px',
  },
}));

const NoCryptoBanner = ({ isUserAuthenticated }: INoCryptoBanner) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box className={classes.root}>
      <Box display={'flex'} alignItems={'center'}>
        <span className={classes.noCrypto}>{t('noCrypto')}</span>
        <span className={classes.noProblem}>{t('noProblem')}</span>
      </Box>
      <Box display={'flex'} alignItems={'center'} gap={'32px'}>
        <MasterCardIcon fill={'#fff'} />
        <VisaIcon fill={'#fff'} />
        <ApplePayIcon fill={'#fff'} />
        <Box className={classes.divider} />
        <CustomPrimaryButton
          style={{
            textTransform: 'uppercase',
          }}
          onClick={() =>
            !isUserAuthenticated
              ? dispatch(changeGlobalUserLoginModalOpen(true))
              : navigate(AppRoutePath.DEPOSIT())
          }
        >
          {t('buyNow')}
        </CustomPrimaryButton>
      </Box>
    </Box>
  );
};

export default NoCryptoBanner;
