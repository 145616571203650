import React, { FC } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  grabBonusWrapper: {
    width: '100%',
    minHeight: '40px',
    background: '#65A30D',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
  },
  grabIcon: {
    width: '15px',
    height: '13px',
    marginBottom: '2px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  grabText: {
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 600,
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  grabTextMobile: {
    lineHeight: '24px',
    fontSize: '11px',
    fontWeight: 600,
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  grabPercentDeposit: {
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  grabPercentDepositMobile: {
    lineHeight: '24px',
    fontSize: '11px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
}));

interface GrabBonusProps {
  setOpenWelcomeBonus: (isOpen: boolean) => void;
  openRegistration: () => void;
  isUserAuthenticated: boolean;
}

const GrabBonus: FC<GrabBonusProps> = ({
  isUserAuthenticated,
  setOpenWelcomeBonus,
  openRegistration,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      className={classes.grabBonusWrapper}
      onClick={() =>
        isUserAuthenticated ? setOpenWelcomeBonus(true) : openRegistration()
      }
    >
      <Box className={classes.content}>
        <Box display={'flex'} alignItems={'center'} boxSizing={'border-box'}>
          <img
            src={'/imgs/grab_bonus.png'}
            alt={'bonus icon'}
            className={classes.grabIcon}
          />
        </Box>
        <span className={!isMobile ? classes.grabText : classes.grabTextMobile}>
          {t('grabBonusForFirstDeposit')}
        </span>
        <span
          className={
            !isMobile
              ? classes.grabPercentDeposit
              : classes.grabPercentDepositMobile
          }
        >
          {t('grabPercentDeposit')}
        </span>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: state.user.isUserAuthorized,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(GrabBonus);
