import { ISvgIcon } from '../../../types/common.type';

const SportIcon = ({ className, fill = '#6B7280' }: ISvgIcon) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <style>
        {`
          .sport-path {
            transition: fill 0.3s;
          }
        `}
      </style>
      <path
        className="sport-path"
        d="M10.4163 1.66667C15.0186 1.66667 18.7496 5.39762 18.7496 10C18.7496 14.6023 15.0186 18.3333 10.4163 18.3333C5.81394 18.3333 2.08298 14.6023 2.08298 10C2.08298 5.39762 5.81394 1.66667 10.4163 1.66667ZM11.8076 13.3333H9.02423L7.87509 14.9141L8.33664 16.3359C8.99106 16.5506 9.69014 16.6667 10.4163 16.6667C11.1425 16.6667 11.8416 16.5506 12.496 16.3359L12.9567 14.9141L11.8076 13.3333ZM4.82759 9.05992L3.75149 9.84125L3.74964 10C3.74964 11.4414 4.20709 12.776 4.98473 13.8664L6.57676 13.8666L7.67842 12.3499L6.82176 9.70825L4.82759 9.05992ZM16.0042 9.05992L14.0101 9.70825L13.1534 12.3499L14.2551 13.8666L15.8479 13.8664C16.6256 12.776 17.083 11.4414 17.083 10L17.0805 9.84083L16.0042 9.05992ZM12.3258 3.61082L11.2492 4.39411V6.49161L13.4942 8.12244L15.3601 7.51661L15.8219 6.09733C14.9721 4.92237 13.7486 4.03545 12.3258 3.61082ZM8.50679 3.61082C7.08363 4.03554 5.85994 4.92279 5.01015 6.09815L5.47175 7.51661L7.33759 8.12244L9.58256 6.49161V4.39411L8.50679 3.61082Z"
        fill={fill}
      />
    </svg>
  );
};

export default SportIcon;
