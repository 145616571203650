import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sportSwiperData } from './SportSwiper';
import { AppRoutePath } from '../../util/appRoutePath';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ISportCardItem {
  sport: sportSwiperData;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '180px',
    height: '158px',
    maxHeight: '96px',
    maxWidth: '180px',
    minWidth: '0px',
    minHeight: '0px',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderRadius: '8px',
    background: '#111827',
    transition: 'background 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgb(32 41 58)',
    },
    '& path': {
      fill: '#FFF',
    },
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
  imgContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    minHeight: '0px',
    minWidth: '0px',
  },
  title: {
    lineHeight: '24px',
    fontWeight: 600,
    fontSize: '14px',
    color: '#fff',
  },
}));
const SportCardItem = ({ sport }: ISportCardItem) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const onClick = () => {
    navigate(AppRoutePath.SPORT() + `?bt-path=%${sport.link}`);
  };

  return (
    <Box className={classes.root} onClick={() => onClick()}>
      <Box className={classes.imgContent}>{<sport.icon />}</Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        minHeight={'40px'}
      >
        <span className={classes.title}>{t(sport.text)}</span>
      </Box>
    </Box>
  );
};

export default SportCardItem;
