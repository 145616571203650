import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import UserProfileHeader from '../../UserProfile/UserProfileHeader';
import { IPlayerAccountInfo } from '../../../containers/Settings/AccountInfoContainer';
import EmailInformation from './EmailInformation';
import PhoneInformation from './PhoneInformation';
import { isMobile } from 'react-device-detect';
import SubpageHeader from '../SubpageHeader';
import {useDispatch} from "react-redux";
import {changeUserProfileInfo} from "../../../redux/actions/userActions";

const useStyles = makeStyles(() => ({
  root: {
    border: isMobile ? '' : '1px solid #1F2937',
    background: '#111827',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: isMobile ? 0 : '12px',
    position: isMobile ? 'fixed' : 'static',
  },

  body: {
    margin: isMobile ? '16px 8px' : '16px',
    borderRadius: '8px',
    color: '#fff',
  },
  content: {
    marginTop: '16px',
  },
  title: {
    padding: '0 16px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  profileModal: {
    width: '416px',
    backgroundColor: '#111827',
    borderRadius: '12px',
    border: '1px solid #FFFFFF0D',
    color: '#fff',
  },
  input: {
    border: 'none',
    backgroundColor: '#0D121D',
    outline: 'none',
    margin: '4px 16px',
    height: '48px',
    borderRadius: '8px',
    flex: '1',
    paddingLeft: '16px',
    color: '#fff',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
  },
}));

const AccountInfo = ({
  playerInfo,
  changeEmail,
  changePhone,
  onClose,
}: IPlayerAccountInfo) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!playerInfo.player || Object.keys(playerInfo.player).length === 0)
    return null;

  const onClickProfile = () => {
      dispatch(changeUserProfileInfo({
      open: true,
      isCurrentUser: true,
      id: playerInfo.player.id,
      username: playerInfo.player.username,
      totalWins: playerInfo.player.total_win,
      totalBets: playerInfo.player.total_bet,
      avatar: playerInfo.player.avatar,
      rank: playerInfo.player.rank,
    }));
  }

  return (
    <div className={classes.root}>
      <SubpageHeader onClose={onClose} heading={t('accountInfo')} />
      <div
        style={{
          height: isMobile ? 'calc(100vh - 100px)' : 'auto',
          overflow: 'scroll',
        }}
      >
        <Box
          style={{
            background: '#FFFFFF0D',
            height: '1px',
            width: '100%',
          }}
        />
        <Box className={classes.body}>
          <div className={classes.title}> {t('profileInformation')}</div>
          <Box className={classes.content}>
            <UserProfileHeader
              username={playerInfo.player.username}
              userId={String(playerInfo.player.id)}
              img={playerInfo.player.avatar}
              stage={'EDIT'}
              onClickProfile={onClickProfile}
            />
          </Box>
        </Box>
        <Box
          style={{
            background: '#FFFFFF0D',
            width: '100%',
            height: '1px',
          }}
        />
        <Box className={classes.body}>
          <div className={classes.title}> {t('contactInformation')}</div>
          <Box className={classes.content}></Box>
        </Box>
        <Box className={classes.body}>
          <Box className={classes.content}>
            <EmailInformation
              playerInfo={playerInfo}
              onChangeEmail={changeEmail}
            />
          </Box>
          <Box className={classes.content}>
            <PhoneInformation
              playerInfo={playerInfo}
              onChangePhone={changePhone}
            />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default AccountInfo;
